import api from "api.js";
import Logger from "Includes/Logger.js";
import Store from "App/Store.js";

/**
 * Cookie helper
 *
 * Enables interaction with cookies which persist into HOPS Bridge.
 *
 * @package HOPS
 * @subpackage Helpers
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Cookies {

	/**
	 * Get the value of a named cookie.
	 *
	 * @param {String} cookie
	 * @return {String|undefined}
	 */
	static get(cookie) {
		return this.getAll()[cookie];
	}


	/**
	 * Get all currently set cookies.
	 * 
	 * @return {Object}
	 */
	static getAll() {
		const cks = {};
		const strs = document.cookie.split(";").map(c => c.trim()).filter(c => c);
		strs.forEach(cookie => {
			const coms = cookie.split("=");
			cks[(coms[0] || "").trim()] = (coms[1] || "").trim();
		});
		return cks;
	}


	/**
	 * Get all expected cookies.
	 *
	 * Returns an object of cookies to set to obtain a correctly 
	 * functioning session as indicated by our current state.
	 * 
	 * @return {Object}
	 */
	static getAllExpected() {

		const state = Store.getState();

		return {
			[api.HOPS_NEW_CLIENT_COOKIE]: window.location.origin,
			[api.HOPS_AUTH_COOKIE]: state.auth?.token,
			[api.HOPS_AUTH_ORG_COOKIE]: state.org,
			[api.HOPS_ADMIN_MASQUERADE_COOKIE]: state.authAdmin?.token
		};

	}


	/**
	 * Get all expected cookies with values as complete cookie value strings.
	 *
	 * @return {Object}
	 */
	static getAllExpectedComplete() {

		const cookies = this.getAllExpected();
		const cookiesComplete = {};

		for (const cookie in cookies) {
			if (cookies.hasOwnProperty(cookie)) {
				cookiesComplete[cookie] = this.createValueString(cookies[cookie]);
			}
		}

		return cookiesComplete;

	}


	/**
	 * Set the value of a cookie.
	 * 
	 * Refer also: `createValueString()`.
	 *
	 * @param {String} cookie
	 * @param {mixed} value
	 * @param {Integer|null|undefined} expires s/`null` (now)/`undefined` (no)
	 * @return {void}
	 */
	static set(cookie, value, expires=undefined) {
		Logger.log(`Setting cookie "${cookie}" to "${value}"...`);
		document.cookie = `${cookie}=${this.createValueString(value, expires)}`;
	}


	/**
	 * Set all cookies used by HOPS.
	 * 
	 * @return {void}
	 */
	static setAll() {

		const cookies = this.getAllExpected();

		for (const cookie in cookies) {
			if (cookies.hasOwnProperty(cookie)) {
				this.set(cookie, cookies[cookie]);
			}
		}

	}


	/**
	 * Delete a cookie by name.
	 * 
	 * @param {String} cookie
	 * @return {void}
	 */
	static delete(cookie) {
		this.set(cookie, null, null);
	}


	/**
	 * Create a value string for a cookie value.
	 *
	 * Constructs everything after the `=` in a cookie definition.
	 *
	 * @param {String} cookie
	 * @param {mixed} value
	 * @param {Integer|null|undefined} expires s/`null` (now)/`undefined` (no)
	 * @return {void}
	 */
	static createValueString(value, expires=undefined) {

		const coms = [`${(value || "")}`];
		coms.push(`domain=${api.cookieDomain}`);
		coms.push("path=/");

		if (expires) {
			const expiry = new Date();
			expiry.setTime((expiry.getTime() + (expires * 1000)));
			coms.push(`expires=${expiry.toUTCString()}`);
		}
		else if (expires === null) {
			coms.push(`expires=Thu; 01 Jan 1970 00:00:01 GMT`);
		}

		if (!this.DISABLE_COOKIES_SAMESITE_NONE_SECURE) {
			coms.push(`SameSite=None`);
			coms.push(`Secure`);
		}

		return coms.join(";");

	}


	/**
	 * Get whether to disable setting `SameSite=None;Secure;` globally 
	 * on all cookies that would ordinarily have these attributes.
	 *
	 * DEVELOPMENT USE ONLY!
	 * 
	 * @return {Boolean}
	 */
	static get DISABLE_COOKIES_SAMESITE_NONE_SECURE() {
		return Store.getState().cookieDisableSsns;
	}

}

export default Cookies;
