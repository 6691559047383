import React from "react";
import UriClickableComponent from "Includes/UriClickableComponent.js";
import {CardActionArea as MuiCardActionArea} from "@material-ui/core";

/**
 * Card action area
 *
 * An abstraction over the Material base component to use our click logic.
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing
 */
class CardActionArea extends UriClickableComponent {

	/**
	 * Render.
	 *
	 * We only actually render an action area when not disabled, 
	 * as the `CardActionArea` Material `disabled` prop disables 
	 * pointer events, so can't easily interact with anything within.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		if (this.disabled) return this.props.children;
		else return this.renderActioned();
	}


	/**
	 * Render as an action area.
	 *
	 * @return {ReactNode}
	 */
	renderActioned() {
		return (
			<MuiCardActionArea
				{...this.props}
				component="div"
				disabled={this.disabled}
				onClick={this.handleClick}
				tabIndex={this.props.tabIndex}>
				{this.props.children}
			</MuiCardActionArea>
		);
	}


	/**
	 * Get whether we're disabled.
	 *
	 * @return {Boolean}
	 */
	get disabled() {
		return (this.props.disabled || !(this.props.onClick || this.props.uri));
	}

}

export default CardActionArea;
