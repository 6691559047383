export default {

	/**
	 * API URI
	 * 
	 * @type {String|null}
	 */
	api: null,

	/**
	 * App version
	 *
	 * @type {String}
	 */
	appv: process.env.REACT_APP_APP,

	/**
	 * Authentication
	 *
	 * @type {Object}
	 */
	auth: {

		/**
		 * Account data
		 *
		 * @type {Object}
		 */
		account: {

			/**
			 * Account ID
			 *
			 * @type {Integer|null}
			 */
			Id: null,

			/**
			 * UI name
			 *
			 * @type {String|null}
			 */
			Name: null,

			/**
			 * First name
			 *
			 * @type {String|null}
			 */
			Fname: null,

			/**
			 * First name (formal)
			 * 
			 * @type {String|null}
			 */
			FnameFormal: null,

			/**
			 * Middle name
			 *
			 * @type {String|null}
			 */
			Mname: null,

			/**
			 * Surname
			 *
			 * @type {String|null}
			 */
			Sname: null,

			/**
			 * First name (proposed)
			 *
			 * @type {String|null}
			 */
			FnameProposed: null,

			/**
			 * First name (formal, proposed)
			 * 
			 * @type {String|null}
			 */
			FnameFormalProposed: null,

			/**
			 * Middle name (proposed)
			 *
			 * @type {String|null}
			 */
			MnameProposed: null,

			/**
			 * Surname (proposed)
			 *
			 * @type {String|null}
			 */
			SnameProposed: null,

			/**
			 * Username
			 *
			 * @type {String|null}
			 */
			Username: null

		},

		/**
		 * Expiration time
		 *
		 * @type {Integer|null}
		 */
		expiration: null,

		/**
		 * User's organisations
		 * 
		 * @type {Array}
		 */
		orgs: [],

		/**
		 * Token
		 *
		 * @type {String|null}
		 */
		token: null

	},

	/**
	 * Authentication (when admin logged in)
	 * 
	 * @type {Object}
	 */
	authAdmin: {

		/**
		 * Account data
		 *
		 * @type {Object}
		 */
		account: {

			/**
			 * Account ID
			 *
			 * @type {Integer|null}
			 */
			Id: null,

			/**
			 * UI name
			 *
			 * @type {String|null}
			 */
			Name: null,

			/**
			 * First name
			 *
			 * @type {String|null}
			 */
			Fname: null,

			/**
			 * First name (formal)
			 * 
			 * @type {String|null}
			 */
			FnameFormal: null,

			/**
			 * Middle name
			 *
			 * @type {String|null}
			 */
			Mname: null,

			/**
			 * Surname
			 *
			 * @type {String|null}
			 */
			Sname: null,

			/**
			 * First name (proposed)
			 *
			 * @type {String|null}
			 */
			FnameProposed: null,

			/**
			 * First name (formal, proposed)
			 * 
			 * @type {String|null}
			 */
			FnameFormalProposed: null,

			/**
			 * Middle name (proposed)
			 *
			 * @type {String|null}
			 */
			MnameProposed: null,

			/**
			 * Surname (proposed)
			 *
			 * @type {String|null}
			 */
			SnameProposed: null,

			/**
			 * Username
			 *
			 * @type {String|null}
			 */
			Username: null

		},

		/**
		 * Expiration time
		 *
		 * @type {Integer|null}
		 */
		expiration: null,

		/**
		 * User's organisations
		 * 
		 * @type {Array}
		 */
		orgs: [],

		/**
		 * Token
		 *
		 * @type {String|null}
		 */
		token: null

	},

	/**
	 * HOPSA client settings
	 *
	 * These are synced from the user's account.
	 * 
	 * @type {Object}
	 */
	clientSettings: {

		/**
		 * Org change confirmation dialog enabled
		 *
		 * @type {Boolean}
		 */
		OrgChangeConfirmation: true

	},

	/**
	 * Disable setting `SameSite=None;Secure;` on cookies
	 * 
	 * @type {Boolean}
	 */
	cookieDisableSsns: false,

	/**
	 * Cookie `domain` value
	 *
	 * @type {String|null}
	 */
	cookieDomain: null,

	/**
	 * Dark theme?
	 * 
	 * @type {Boolean}
	 */
	dark: false,

	/**
	 * Dev mode?
	 * 
	 * @type {Boolean}
	 */
	dev: false,

	/**
	 * Active feature flags
	 * 
	 * @type {Array}
	 */
	flags: [],

	/**
	 * Enable logging
	 * 
	 * @type {Boolean}
	 */
	logger: false,

	/**
	 * Organisation ID
	 *
	 * @type {Integer|null}
	 */
	org: null,

	/**
	 * Organisation ID when admin logged-in
	 * 
	 * @type {Integer|null}
	 */
	orgAdmin: null,

	/**
	 * Raildays mode (dev toggle)?
	 * 
	 * @type {Boolean}
	 */
	raildays: false,

	/**
	 * Active Raildays ID card
	 *
	 * @type {Integer}
	 */
	raildaysActiveCardIndex: 0,

	/**
	 * Telemetry enabled?
	 *
	 * @type {Boolean}
	 */
	telemetry: false,

	/**
	 * Whether the user initiated an update.
	 *
	 * Set before we reload after the user indicates "update now".
	 *
	 * On next launch, we know the user initiated the update and can respond.
	 */
	userUpdateRequested: false

};
