import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import Divider from "@material-ui/core/Divider";
import Flex from "Components/Flex.js";
import LibraryBrowserContent from "./LibraryBrowserContent.js";
import LibraryBrowserToolbar from "./LibraryBrowserToolbar.js";
import LibraryService from "./LibraryService.js";
import Loader from "Components/Loader.js";
import Paper from "Components/Paper.js";
import ProgressDialog from "Components/ProgressDialog.js";
import String from "Components/String.js";
import UploadDropZone from "Components/UploadDropZone.js";
import scss from "./LibraryBrowser.module.scss";
import withSnack from "Hoc/withSnack.js";

/**
 * Library browser
 *
 * Browsing interface for the library files system.
 * 
 * @package HOPS
 * @subpackage Library
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LibraryBrowser extends AsyncStatefulComponent {

	/**
	 * Ref to our `UploadDropZone`
	 *
	 * @type {UploadDropZone}
	 */
	dropZoneRef = React.createRef();

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Content browser key
		 *
		 * Used to force re-renders when required, e.g. after replace 
		 * file, when we want to re-render so the new thumbnail shows.
		 *
		 * @type {Integer}
		 */
		contentBrowserKey: 0,

		/**
		 * Library API data
		 *
		 * Array of library set objects.
		 * 
		 * @type {Array|null}
		 */
		data: null,

		/**
		 * Error?
		 *
		 * @type {Boolean}
		 */
		error: false,

		/**
		 * Loading?
		 * 
		 * @type {Boolean}
		 */
		loading: true,

		/**
		 * Sort order
		 *
		 * See `LibrarySortPicker`.
		 * 
		 * @type {String}
		 */
		sort: "-Timestamp",

		/**
		 * Result of the last upload attempt
		 *
		 * @type {Object|null}
		 */
		lastUploadResult: null,

		/**
		 * Uploading?
		 *
		 * @type {Boolean}
		 */
		uploading: false,

		/**
		 * Count of files to upload
		 *
		 * @type {Integer|null}
		 */
		uploadingCount: null,

		/**
		 * Uploading progress
		 *
		 * @type {Integer|null}
		 */
		uploadingProgress: null

	};


	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		super.componentDidMount();
		this.getCurrentContent();
	}


	/**
	 * Component updated.
	 *
	 * We reload when the target entity changes.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		const entityId = ((prevProps.entityId !== this.props.entityId) && (this.props.entityId !== this.state.lastUploadResult?.LibraryEntity?.EntityId));
		const entityType = ((prevProps.entityType !== this.props.entityType) && (this.props.entityType !== this.state.lastUploadResult?.LibraryEntity?.EntityType));
		if (entityId || entityType) this.getCurrentContent();
	}


	/**
	 * Load our library content.
	 *
	 * @return {void}
	 */
	getCurrentContent() {

		const {entityId, entityType} = this.props;
		const valid = (entityId && entityType);
		this.setState({error: false, loading: valid, data: null});

		if (valid) {
			LibraryService.getEntityLibrary(entityId, entityType).then(data => {
				this.setState({data});
			}).catch(() => {
				this.setState({error: true});
			}).finally(() => {
				this.setState({loading: false});
			});
		}

	}


	/**
	 * Deleting files.
	 * 
	 * @return {void}
	 */
	handleFilesDeleted = files => {

		const sets = [...this.state.data];

		files.forEach(file => {
			const set = sets.find(s => s.Files.map(f => f.Uuid).includes(file));
			if (set) sets[sets.indexOf(set)] = {...set, Files: set.Files.filter(f => (f.Uuid !== file))};
		});

		this.setState({data: sets});

	};


	/**
	 * Files moved between sets.
	 *
	 * @param {Array} files File objects that were moved
	 * @param {Object} setNew Set the files were moved to
	 * @param {Object} setOld Set the files were moved from
	 * @return {void}
	 */
	handleFilesMoved = (files, setNew, setOld) => {

		const sets = [...this.state.data];
		const uuids = files.map(f => f.Uuid);

		const seto = sets.find(({Uuid}) => (Uuid === setOld.Uuid));
		const setoi = sets.indexOf(seto);
		sets[setoi] = {...seto, Files: seto.Files.filter(f => !uuids.includes(f.Uuid))};

		const setn = sets.find(({Uuid}) => (Uuid === setNew.Uuid));
		const setni = sets.indexOf(setn);
		sets[setni] = {...setn, Files: [...setn.Files, ...files]};

		this.setState({data: sets});

	};


	/**
	 * Renaming a file.
	 * 
	 * @param {String} options.Uuid File UUID
	 * @param {String} Name
	 * @return {void}
	 */
	handleFileRename = ({Uuid}, Name) => {

		const sets = [...this.state.data];
		const set = sets.find(s => s.Files.find(f => (f.Uuid === Uuid)));
		const file = set.Files.find(f => (f.Uuid === Uuid));

		set.Files = [...set.Files];
		set.Files[set.Files.indexOf(file)] = {...file, Name};
		sets[sets.indexOf(set)] = {...set};

		this.setState({data: sets});

	};


	/**
	 * Deleting a set.
	 *
	 * @param {Object} set
	 * @return {void}
	 */
	handleDeleteSet = set => {
		this.setState({
			data: this.state.data.filter(({Uuid}) => (Uuid !== set.Uuid))
		});
	};


	/**
	 * Renaming a set.
	 *
	 * @param {String} options.Uuid Set UUID
	 * @param {String} Name
	 * @return {void}
	 */
	handleRenameSet = ({Uuid}, Name) => {

		const sets = [...this.state.data];
		const set = sets.find(set => (Uuid === set.Uuid));
		sets[sets.indexOf(set)] = {...set, Name};

		this.setState({data: sets});

	};


	/**
	 * Replacing a file.
	 *
	 * @param {Object} file File object
	 * @param {File} f File to upload
	 * @return {void}
	 */
	handleReplaceFile = (file, f) => {

		/**
		 * Uploading
		 */
		this.setState({
			uploading: true,
			uploadingCount: 1,
			uploadingProgress: 0
		});

		/**
		 * Replace the file
		 */
		LibraryService.replaceFile(file.Uuid, f, this.handleUploadProgress).then(newFile => {

			const sets = [...this.state.data];
			const set = sets.find(({Files}) => Files.map(f => f.Uuid).includes(file.Uuid));

			const seti = sets.indexOf(set);
			const filei = set.Files.indexOf(set.Files.find(f => (f.Uuid === file.Uuid)));

			sets[seti] = {...set, Files: [...set.Files]};
			sets[seti].Files[filei] = newFile;
			this.setState({data: sets, contentBrowserKey: (this.state.contentBrowserKey + 1)});

			this.props.snack("The file has been replaced. The original link still works and now references the new file.", "success");

		}).catch(e => {
			this.props.snack(e);
		}).finally(() => {
			this.setState({uploading: false});
		});

	};


	/**
	 * Sort order changed.
	 *
	 * @param {String} sort
	 * @return {void}
	 */
	handleSortChange = sort => {
		this.setState({sort});
	};


	/**
	 * Uploading.
	 *
	 * @param {Array} files `File` instances
	 * @param {String} set optional Set UUID to add to
	 * @return {void}
	 */
	handleUpload = (files, set=null) => {

		/**
		 * Uploading
		 */
		this.setState({
			uploading: true,
			uploadingCount: files.length,
			uploadingProgress: 0
		});

		/**
		 * Entity details
		 */
		const {entityId, entityType} = this.props;

		/**
		 * Upload now
		 */
		LibraryService.uploadToEntity(entityId, entityType, files, (set?.Uuid || null), this.handleUploadProgress).then(data => {

			this.setState({lastUploadResult: data});

			/**
			 * Created a set
			 */
			if (data.LibrarySet) {

				if (!set) {
					const sets = (this.state.data || []);
					this.setState({data: [data.LibrarySet, ...sets]});
				}
				else {
					const sets = [...this.state.data];
					const target = sets.find(({Uuid}) => (Uuid === set.Uuid));
					sets[sets.indexOf(target)] = data.LibrarySet;
					this.setState({data: sets});
				}

			}

			/**
			 * We have created a temporary Library entity 
			 * and want to inform the rendering component now
			 */
			if (data.LibraryEntity) {
				const {EntityId, EntityType} = data.LibraryEntity;
				if (EntityId !== this.props.entityId) this.props.onChangeEntityId?.(EntityId);
				if (EntityType !== this.props.entityType) this.props.onChangeEntityType?.(EntityType);
			}

			/**
			 * Notify the user
			 */
			if (data.UploadErrors?.length) {
				if (data.UploadErrors.length === files.length) {
					this.props.snack("There was an error uploading your files.", "error");
				}
				else this.props.snack("Not all files uploaded successfully.", "warning");
			}
			else this.props.snack("Upload complete.", "success");

		}).catch(e => {
			this.props.snack(e);
		}).finally(() => {
			this.setState({uploading: false});
		});

	};


	/**
	 * Toolbar upload button clicked.
	 * 
	 * @return {void}
	 */
	handleUploadClick = () => {
		if (this.dropZoneRef) {
			this.dropZoneRef.handleSelectClick();
		}
	};


	/**
	 * Upload progress handler.
	 *
	 * @param {ProgressEvent} e
	 * @return {void}
	 */
	handleUploadProgress = e => {
		let percent = Math.ceil(((e.loaded / e.total) * 100));
		if (percent < 0) percent = 0;
		if (percent > 100) percent = 100;
		this.setState({uploadingProgress: percent});
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return <>
			<UploadDropZone
				disabled={(this.disabled || !this.props.canUpload)}
				maxSizeMb={LibraryService.MAXIMUM_FILE_SIZE}
				mimes={LibraryService.ALLOWED_MIME_TYPES}
				multiple={true}
				onFileSelected={this.handleUpload}
				onMount={ref => (this.dropZoneRef = ref)}>
				<Paper className={scss.paper}>
					<Flex flexGrow={1} gap={0.75}>
						<LibraryBrowserToolbar
							canUpload={this.props.canUpload}
							disabled={this.disabled}
							onSortChange={this.handleSortChange}
							onUpload={this.handleUploadClick}
							sort={this.state.sort} />
						<Divider />
						{(this.state.loading && <Loader size={30} />)}
						{(this.state.error && <Flex mt={0.5}><String centre={true} color="textSecondary" str="Error." /></Flex>)}
						{(this.ready && this.renderMain())}
					</Flex>
				</Paper>
			</UploadDropZone>
			<ProgressDialog
				fileCount={this.state.uploadingCount}
				open={this.state.uploading}
				progress={this.state.uploadingProgress}
				title="Uploading" />
		</>;
	}


	/**
	 * Render main content.
	 * 
	 * @return {ReactNode}
	 */
	renderMain() {
		return (
			<LibraryBrowserContent
				canDelete={this.props.canDelete}
				canEdit={this.props.canUpload}
				disabled={this.disabled}
				key={this.state.contentBrowserKey}
				onDeleteSet={this.handleDeleteSet}
				onFileMenu={this.handleFileMenu}
				onFileRename={this.handleFileRename}
				onFilesDeleted={this.handleFilesDeleted}
				onFilesMoved={this.handleFilesMoved}
				onRenameSet={this.handleRenameSet}
				onReplaceFile={this.handleReplaceFile}
				onUpload={this.handleUpload}
				sets={this.state.data}
				sort={this.state.sort} />
		);
	}


	/**
	 * Get whether we should be in a disabled state.
	 * 
	 * @return {Boolean}
	 */
	get disabled() {
		return !!(this.props.disabled || this.state.loading || this.state.uploading);
	}


	/**
	 * Get whether we're ready to render the main content.
	 * 
	 * @return {Boolean}
	 */
	get ready() {
		return (!this.state.loading && !this.state.error);
	}


	/**
	 * Render error state.
	 * 
	 * @return {ReactNode}
	 */
	static renderError() {
		return (
			<Flex alignSelf="center" justifySelf="center">
				<String color="textSecondary" str="Error" />
			</Flex>
		);
	}

}

export default withSnack(LibraryBrowser);
