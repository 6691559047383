import api from "api.js";
import apis from "Resources/Apis.json";
import PermissionsQueryResult from "./PermissionsQueryResult.js";

/**
 * Auth service
 *
 * @package HOPS
 * @subpackage Auth
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class AuthService {

	/**
	 * Authenticate as a user.
	 *
	 * This does not handle local authentication!
	 * 
	 * @param {String} username
	 * @param {String} password
	 * @param {Boolean} prolonged optional Wants prolonged session?
	 * @return {Promise}
	 */
	static auth(username, password, prolonged=false) {
		const body = {username, password};
		if (prolonged) body.prolonged = true;
		return api.axios().post(apis.auth, body).then(({data}) => {
			return {...data, auth: this.getAuthTokenData(data.auth)};
		});
	}


	/**
	 * Start an admin login session.
	 *
	 * @param {Integer} uid User ID to masquerade as
	 * @return {Promise} Resolves with admin masquerade token
	 */
	static authAdmin(uid) {
		return api.call({
			url: "/api/auth/admin",
			method: "POST",
			data: {uid: uid.toString(), restful: "1"}
		}).then(({data}) => {
			const pl = this.getAuthTokenPayload(data.auth);
			return {expiration: pl.Expiration, org: data.org, token: data.auth};
		});
	}


	/**
	 * Commence authentication via OpenID/Microsoft.
	 *
	 * The API will set a `Location` header to redirect to Microsoft.
	 * 
	 * @param {Integer} org Organisation to authenticate into
	 * @return {Promise} Resolves with Microsoft URI to redirect to
	 */
	static authMs(org) {
		return api.axios().request({
			url: `/api/auth/openid/ms/${org}`,
			params: {rest: "1"}
		}).then(({data}) => data);
	}


	/**
	 * Complete authentication via OpenID/Microsoft.
	 * 
	 * @param {String} id_token OpenID token (issued by Microsoft)
	 * @return {Promise}
	 */
	static authMsComplete(id_token) {
		return api.axios().request({
			url: `/api/auth/openid/ms/complete`,
			method: "POST",
			params: {id_token, rest: "1"}
		}).then(({data}) => {
			return {auth: this.getAuthTokenData(data.auth), org: data.org};
		});
	}


	/**
	 * Report logout to HOPS so it can update login logs.
	 *
	 * This does not clear local authentication!
	 * 
	 * @return {Promise}
	 */
	static logout() {
		return api.axios().request({
			url: apis.auth,
			method: "DELETE",
			headers: {"Hops-Admin-Masquerade-Token": ""},
			withCredentials: false
		});
	}


	/**
	 * Ping the authentication endpoint and get a new auth token.
	 *
	 * @param {String} auth optional Override authentication token
	 * @return {Promise}
	 */
	static ping(auth=null) {
		const headers = (auth ? {Authorization: auth} : undefined);
		return api.call({url: `${apis.auth}/ping`, method: "POST", headers}).then(({data}) => {
			return this.getAuthTokenData(data.auth);
		});
	}


	/**
	 * Get users the user can admin masquerade as.
	 *
	 * @return {Promise} Resolves with API user objects
	 */
	static getAdminMasqueradableUsers() {
		return api.call({url: "/api/auth/admin/users"}).then(({data}) => data);
	}


	/**
	 * Get the user's permissions index.
	 *
	 * @return {Promise} Resolves with API permissions index object
	 */
	static getUserPermissionsIndex() {
		return api.call({url: "/api/auth/permissions"}).then(({data}) => data);
	}


	/**
	 * Get state data from an authentication token.
	 * 
	 * @param {String} at
	 * @return {Object}
	 */
	static getAuthTokenData(at) {
		const data = this.getAuthTokenPayload(at);
		return {expiration: data.Expiration, pwcr: data.Pwcr, token: at};
	}


	/**
	 * Get the payload from an authentication token (JWT).
	 *
	 * @param {String} at Access token
	 * @return {Object} Object expected per the HOPS auth API
	 */
	static getAuthTokenPayload(at) {
		const pl = at.split(".")[1];
		return JSON.parse(atob(pl.replace(/-/g, "+").replace(/_/g, "/")));
	}


	/**
	 * Query the authenticated user's permissions.
	 *
	 * `Permissions` is an array of arrays where the first 
	 * item in each sub-array is the permission ID to query 
	 * and the second item is optionally the permission variable.
	 * 
	 * @param {Array} Permissions
	 * @return {Promise} Resolves with a `PermissionsQueryResult`
	 */
	static queryPermissions(Permissions) {
		return api.call({
			url: `/api/auth/permissions/mine/has`,
			params: {Permissions: JSON.stringify(Permissions)}
		}).then(({data}) => new PermissionsQueryResult(data));
	}

}

export default AuthService;
