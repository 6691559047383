import React from "react";
import Navigator from "App/Navigator.js";

/**
 * "URI-clickable" component
 *
 * A component base with a method designated for use as a click handler 
 * which internally routes if the component has a `uri` prop or otherwise 
 * invokes the `onClick` prop when it is given.
 *
 * @package HOPS
 * @subpackage Includes
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UriClickableComponent extends React.PureComponent {

	/**
	 * Swallow click events
	 *
	 * @type {Boolean}
	 */
	swallowClickEvents = false;


	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleClick = this.handleClick.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);

	}


	/**
	 * Clicked.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleClick(e) {

		if (this.swallowClickEvents) {
			e.preventDefault();
			e.stopPropagation();
		}

		if (this.props.uri) {
			if (!this.sameOrigin && this.props.forceCurrentTab) {
				window.location = this.props.uri;
			}
			else if (e?.ctrlKey || e?.metaKey || !this.sameOrigin || this.props.blank) {
				window.open(this.props.uri, (this.props.target || "_blank"));
			}
			else Navigator.navigate(this.uriInternal);
		}
		else if (this.props.onClick) {
			this.props.onClick?.(e, this.props.value);
		}

	}


	/**
	 * Key press handler.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleKeyPress(e) {
		if ((e.which === 13) || (e.which === 32)) {
			this.handleClick(e);
		}
	}


	/**
	 * Get whether our URI is to the same origin.
	 * 
	 * @return {Boolean}
	 */
	get sameOrigin() {
		const origin = this.props.uri.startsWith(window.location.origin);
		const relative = this.props.uri.startsWith("/");
		return (origin || relative);
	}


	/**
	 * Get the URI to route to internally.
	 * 
	 * @return {String}
	 */
	get uriInternal() {
		return this.props.uri.replace(window.location.origin, "");
	}

}

export default UriClickableComponent;
