import Paper from "Components/Paper.js";
import LibraryService from "./LibraryService.js";
import LibraryThumbnail from "./LibraryThumbnail.js";
import scss from "./LibraryFileCard.module.scss";
import {memo} from "react";

const LibraryFileCard = memo(({file, onClick}) => {

	return (
		<Paper
			className={`${scss.root} ${(onClick ? scss.clickable : "")}`.trim()}
			onClick={onClick}>
			<div
				className={scss.container}>
				<LibraryThumbnail
					file={file} />
				<a
					href={LibraryService.getCurrentOriginDownloadUri(file.Uuid)}
					rel="noreferrer"
					target="_blank">
					{file.Name}
				</a>
			</div>
		</Paper>
	);

});

export default LibraryFileCard;
