import React from "react";
import Flex from "Components/Flexx.js";
import PaperMui from "@material-ui/core/Paper";

/**
 * Paper component
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Paper extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<PaperMui
				className={this.props.classNamePaper}
				onClick={this.props.onClick}
				square={this.props.square}
				variant="outlined">
				<Flex
					px={1}
					py={1}
					{...this.props} />
			</PaperMui>
		);
	}

}

export default Paper;
