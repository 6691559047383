import React from "react";
import {Tab, Tabs} from "@material-ui/core";
import scss from "./UiDrawerTabs.module.scss";

/**
 * UI drawer tab strip
 *
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiDrawerTabs extends React.PureComponent {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleChange = this.handleChange.bind(this);

	}


	/**
	 * Tab changed.
	 * 
	 * @param {Event} e
	 * @param {Object} func
	 * @return {void}
	 */
	handleChange(e, func) {
		this.props.onChange(func);
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Tabs
				classes={this.constructor.containerClasses}
				indicatorColor={this.constructor.tabColour}
				onChange={this.handleChange}
				textColor={this.constructor.tabColour}
				value={(this.props.value || false)}>
				{
					this.props.functions?.map((f, key) => (
						<Tab
							classes={this.constructor.tabClasses}
							key={key}
							label={f.Label}
							tabIndex={0}
							value={f.Label} />
					))
				}
			</Tabs>
		);
	}


	/**
	 * Classes to add to the container.
	 * 
	 * @type {Object}
	 */
	static containerClasses = {
		flexContainer: scss.Container,
		indicator: scss.Indicator,
		root: scss.Tabs
	};

	/**
	 * Classes to add to tabs.
	 *
	 * @type {Object}
	 */
	static tabClasses = {root: scss.Tab};

	/**
	 * Colour name for tabs.
	 *
	 * @type {String}
	 */
	static tabColour = "primary";

}

export default UiDrawerTabs;
