/**
 * App utility methods
 *
 * @package HOPS
 * @subpackage Helpers
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class App {

	/**
	 * Get application version.
	 *
	 * @return {String} Environment version ID e.g. commit SHA (when available)
	 */
	static getAppv() {
		return (process.env.REACT_APP_APP || "(Undefined)");
	}

}

export default App;
