import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import ClearAdornment from "Components/ClearAdornment.js";
import {TextField as TextFieldMui, InputAdornment} from "@material-ui/core";

/**
 * Text field
 * 
 * A wrapper around Material UI's `TextField`.
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class TextField extends AsyncStatefulComponent {

	/**
	 * Input ref
	 * 
	 * @type {ReactRef}
	 */
	inputRef = React.createRef();

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Value
		 *
		 * @type {String|null}
		 */
		value: null

	};


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		super.componentDidMount();

		this.updateValue(() => setTimeout(() => {
			const v = this.ref?.current?.value;
			if (v && (v !== (this.state.value || ""))) {
				this.handleChangeReal(v, true);
			}
		}, 250));
	}


	/**
	 * Component updated.
	 * 
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			if (this.props.value !== this.state.value) {
				this.updateValue();
			}
		}
	}


	/**
	 * Update our value.
	 *
	 * @param {Function} cb optional Callback
	 * @return {void}
	 */
	updateValue(cb=null) {
		this.setState({value: (this.props.value || null)}, cb);
	}


	/**
	 * Blurred.
	 * 
	 * @return {void}
	 */
	handleBlur = () => {

		if (this.props.onCommit) {
			this.props.onCommit((this.state.value || null), (this.props.name || this.props.label));
		}

		if (this.props.onBlur) {
			this.props.onBlur();
		}

	};


	/**
	 * Handle change.
	 * 
	 * @param {Event} e
	 * @param {Boolean} forceReporting optional (`false`)
	 * @return {void}
	 */
	handleChange = (e, forceReporting=false) => {
		this.handleChangeReal((e.target.value || null), forceReporting);
	};


	/**
	 * Handle change.
	 *
	 * @param {String} value
	 * @param {Boolean} forceReporting optional (`false`)
	 * @return {void}
	 */
	handleChangeReal = (value, forceReporting=false) => {
		value = (value || null);
		if (value && this.pattern && !(new RegExp(this.pattern, "g").test(value))) return;
		if (this.props.onCommit && !forceReporting) this.setState({value});
		else if (this.props.onChange) this.props.onChange(value, (this.props.name || this.props.label));
	};


	/**
	 * Enter key handler.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleEnter = e => {
		if (((!this.props.multiline || (this.props.multilineEnter && !(e?.ctrlKey || e?.metaKey))) || ((e?.ctrlKey || e?.metaKey) && !this.props.multilineEnter)) && this.props.canEnter) {
			this.props.onEnter(e);
		}
		else if (this.props.multiline && (e?.ctrlKey || e?.metaKey)) {
			this.props.onChange(`${this.props.value}\n`);
		}
	};


	/**
	 * Key press handler.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleKey = e => {
		if ((e.which === 13) && this.props.onEnter) {
			if (this.props.forceOnEnterDirect) {
				this.props.onEnter(e);
			}
			else this.handleEnter(e);
		}
		else if ((this.props.type === "number") && [69, 169, 61, 173, 187, 189].includes(e.which)) {
			e.preventDefault();
		}
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<TextFieldMui
				autoComplete={this.props.autoComplete}
				autoFocus={this.props.autoFocus}
				className={this.props.classNameRoot}
				color={(this.props.color || "primary")}
				disabled={this.props.disabled}
				error={this.props.error}
				fullWidth={this.props.fullWidth}
				helperText={this.props.helperText}
				inputProps={this.inputProps}
				inputRef={this.ref}
				InputProps={this.InputProps}
				InputLabelProps={this.InputLabelProps}
				label={this.props.label}
				multiline={this.props.multiline}
				name={this.props.name}
				onBlur={this.handleBlur}
				onChange={this.handleChange}
				onKeyDown={this.handleKey}
				placeholder={(this.props.placeholder || (this.props.placeholderLabel && this.props.label))}
				required={this.props.required}
				rows={this.props.rows}
				rowsMax={this.props.rowsMax}
				size={(this.props.size || "small")}
				style={this.props.style}
				type={this.props.type}
				value={((!this.props.onCommit ? this.props.value : this.state.value)?.toString() || "")}
				variant={(this.props.variant || "outlined")}
				onKeyUp={e => {
					if (this.props.onClear && e.key === "Escape") {
						this.props.onClear();
					}
				}}
				onFocus={this.props.onFocus} />
		);
	}


	/**
	 * Render clear button.
	 * 
	 * @return {ReactNode}
	 */
	renderClear() {
		return (
			// prevent text field focus loss on click
			<span onMouseDown={e => e.preventDefault()}>
				<ClearAdornment onClick={this.props.onClear} />
			</span>
		);
	}


	/**
	 * Get whether we're currently clearable.
	 * 
	 * @return {Boolean}
	 */
	get clearable() {
		return (this.props.onClear && this.props.value);
	}


	/**
	 * Get the regex pattern.
	 * 
	 * @return {String|undefined}
	 */
	get pattern() {
		if (this.props.pattern) {
			return this.props.pattern;
		}
		else if (this.props.type === "number") {
			if (this.props.isPrice) {
				return "^[0-9]+(.[0-9]{1,2})?$";
			}
			else if (this.props.allowDecimals) {
				return "^[0-9]+(.[0-9]+)?$";
			}
			else {
				return "^[0-9]+$";
			}
		}
		else return undefined;
	}


	/**
	 * `inputProps`
	 * 
	 * @return {Object}
	 */
	get inputProps() {
		return {
			...this.props.inputProps,
			className: this.props.className,
			maxLength: (this.props.varchar ? 250 : this.props.maxLength),
			minLength: this.props.minLength,
			max: this.props.max,
			min: (((this.props.type === "number") && !this.props.min) ? 0 : this.props.min),
			pattern: this.pattern,
			step: this.props.step
		};
	}


	/**
	 * `InputProps`.
	 * 
	 * @return {Object}
	 */
	get InputProps() {
		return {
			endAdornment: (this.clearable ? this.renderClear() : (this.props.icon ? <InputAdornment>{this.props.icon}</InputAdornment> : undefined)),
			style: {
				fontSize: this.props.fontSize,
				fontWeight: (this.props.bold ? "bold" : null),
				height: this.props.height,
				lineHeight: (this.props.multiline ? 1.6 : null),
				marginTop: (this.props.multilineLabel ? "0.375rem" : undefined)
			},
			...this.props.InputProps
		};
	}


	/**
	 * `InputLabelProps`.
	 * 
	 * @return {Object}
	 */
	get InputLabelProps() {
		return {
			shrink: this.props.shrink,
			style: {
				position: (this.props.multilineLabel ? "initial" : undefined)
			},
			...this.props.InputLabelProps
		};
	}


	/**
	 * Get our input ref.
	 * 
	 * @return {ReactRef} [description]
	 */
	get ref() {
		return (this.props.inputRef || this.inputRef);
	}

}

export default TextField;
