import React from "react";
import Helmet from "react-helmet";
import Theme from "Resources/Theme.json";
import cloneDeep from "lodash.clonedeep";
import useAuth from "Hooks/useAuth.js";
import {memo, useEffect, useMemo} from "react";
import {useSelector} from "react-redux";
import {createMuiTheme, ThemeProvider as MuiThemeProvider} from "@material-ui/core/styles";

const ThemeProvider = memo(({children}) => {

	/**
	 * Get the org
	 */
	const {org} = useAuth();

	/**
	 * Get the org's accent colour
	 */
	const orgAccentColour = org?.HopsAccentColour;

	/**
	 * Are we in dark theme mode?
	 */
	const isDarkTheme = useSelector(store => store.dark);

	/**
	 * Create the Material theme object to pass to 
	 * Material UI's theme provider — this will 
	 * theme all the Material app sections.
	 */
	const muiTheme = useMemo(() => {

		/**
		 * We must clone so we don't mutate the original theme 
		 * definition below, as we will need the original again 
		 * later e.g. if the org changes and we are switching to 
		 * a different accent colour
		 */
		const theme = cloneDeep(Theme.App);

		/**
		 * Apply the org's custom accent colour when applicable
		 */
		if (orgAccentColour) {
			theme.palette.primary.main = `#${orgAccentColour}`;
		}

		/**
		 * Apply dark mode when applicable
		 */
		theme.palette.type = (isDarkTheme ? "dark" : "light");

		/**
		 * Create the Material theme now
		 */
		return createMuiTheme(theme);

	}, [orgAccentColour, isDarkTheme]);


	/**
	 * When the theme object changes, we need to 
	 * reflect those changes onto the CSS variables 
	 * that we use to theme some of our components
	 */
	useEffect(() => {

		const cssVars = {
			"--accent-color": muiTheme.palette.primary.main,
			"--base-color": muiTheme.palette.background.default,
			"--divider-color": muiTheme.palette.divider,
			"--paper-color": muiTheme.palette.background.paper,
			"--typography-color": muiTheme.palette.text.main
		};

		for (const cssVar of Object.entries(cssVars)) {
			const [v, value] = cssVar;
			document.documentElement.style.setProperty(v, value);
		}

	}, [muiTheme]);


	return (
		<MuiThemeProvider theme={muiTheme}>
			{children}
			<Helmet>
				<meta name="theme-color" content={muiTheme.palette.primary.main} />
			</Helmet>
		</MuiThemeProvider>
	);

});

export default ThemeProvider;
