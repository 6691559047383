import Store from "App/Store.js";
import AuthService from "Auth/AuthService.js";
import {StorePersistor} from "App/Store.js";
import TelemetryService from "Telemetry/TelemetryService.js";

export default authAdmin => {

	TelemetryService.report("dAuthAdmin", {Account: (authAdmin?.account?.Id || null), Token: !!authAdmin?.token, TokenAccount: (authAdmin?.token ? AuthService.getAuthTokenPayload(authAdmin?.token)?.AccountId : null)});

	Store.dispatch({type: "authAdmin", authAdmin});

	StorePersistor.flush();

};
