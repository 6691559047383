import Bghost from "App/Bghost.js";
import Store from "App/Store.js";
import dAuth from "Dispatchers/dAuth.js";
import UserAccountService from "UserAccounts/UserAccountService.js";

export default () => {
	return new Promise((resolve, reject) => {
		Bghost.register(UserAccountService.getAccountInfo(Store.getState().auth.token, "")).then(account => {
			dAuth(account);
			resolve();
		}).catch(reject);
	});
};
