import api from "api.js";
import Bghost from "App/Bghost.js";
import Cookies from "Helpers/Cookies.js";
import Messenger from "Includes/Messenger.js";
import Store from "App/Store.js";
import {StorePersistor} from "App/Store.js";
import TelemetryService from "Telemetry/TelemetryService.js";
import dAccess from "Dispatchers/dAccess.js";
import dNoticesHopsClearOrg from "./dNoticesHopsClearOrg.js";

export default (unexpected=false) => {

	/**
	 * Cancel background tasks
	 */
	Bghost.clearAll();

	/**
	 * Clear any set cookies
	 */
	Cookies.delete(api.HOPS_AUTH_COOKIE);
	Cookies.delete(api.HOPS_AUTH_ORG_COOKIE);
	Cookies.delete(api.HOPS_ADMIN_MASQUERADE_COOKIE);

	/**
	 * Message Raildays
	 */
	Messenger.postMessage("hops.auth", {token: null});

	/**
	 * Telemetry
	 */
	TelemetryService.report("dReset");

	/**
	 * Reset the state
	 */
	Store.dispatch({type: "reset", unexpected});

	/**
	 * Make sure we've flushed the state
	 */
	StorePersistor.flush();

	/**
	 * Clear organisation notices
	 *
	 * (Retains system-level notices.)
	 */
	dNoticesHopsClearOrg();

	/**
	 * When we've been logged out unexpectedly, make 
	 * sure we show the login form straightaway, not 
	 * the homepage as would be the default case
	 */
	if (unexpected) {
		dAccess(false);
	}

};
