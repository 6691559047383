import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import Picker from "./Picker.js";

/**
 * "Searchable list"
 *
 * A searchable list of options using `Picker`.
 *
 * Gets options asynchronously by calling the `OptionsSource` prop, 
 * which should return an array of `PickerOption` instances.
 * 
 * Refer to source for implementation details.
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class SearchableList extends AsyncStatefulComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 * 
		 * @type {Object}
		 */
		this.state = {

			/**
			 * List of options
			 *
			 * @type {Array}
			 */
			options: null,

			/**
			 * Input value
			 * 
			 * @type {String}
			 */
			value: ""

		};

		/**
		 * Timer ID
		 *
		 * @type {Integer|null}
		 */
		this.timer = null;

		/**
		 * Method binds
		 */
		this.getCurrentOptions = this.getCurrentOptions.bind(this);
		this.handleChange = this.handleChange.bind(this);

	}


	/**
	 * Component unmounting.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {
		super.componentWillUnmount();
		this.clearTimerWhenSet();
	}


	/**
	 * Clear results timer when set.
	 *
	 * @return {void}
	 */
	clearTimerWhenSet() {
		clearTimeout(this.timer);
		this.timer = null;
	}


	/**
	 * Get options using our current value.
	 * 
	 * @return {void}
	 */
	getCurrentOptions() {

		/**
		 * Get ready
		 */
		this.clearTimerWhenSet();

		/**
		 * Minimum characters
		 */
		if (!this.isValidValue()) return;

		/**
		 * Loading
		 */
		this.setState({
			error: false,
			loading: true,
			options: null
		});

		/**
		 * Get our options
		 */
		this.props.OptionsSource(this.state.value).then(options => {
			this.setState({options});
		}).catch(() => {
			this.setState({error: true});
		}).finally(() => {
			this.setState({loading: false});
		});

	}


	/**
	 * Input value changed.
	 *
	 * @param {Event} e
	 * @param {String} value
	 * @return {void}
	 */
	handleChange(e, value, reason) {

		if ((reason === "reset") && this.props.ignoreResetEvents) {
			return;
		}

		this.clearTimerWhenSet();
		this.setState({
			value,
			error: false,
			loading: this.isValidValue(value),
			options: null
		});
		this.timer = setTimeout(this.getCurrentOptions, 250);

	}


	/**
	 * Get whether a value is valid for submission.
	 *
	 * @param {String} value optional Defaults to state
	 * @return {Boolean}
	 */
	isValidValue(value=null) {
		value = ((value !== null) ? value : this.state.value);
		return (value && (value?.length >= 3));
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Picker
				blurOnSelect={true}
				classes={this.props.classes}
				color={this.props.color}
				disableClearable={this.props.disableClearable}
				dontAddValueOption={true}
				error={this.state.error}
				filterOptions={this.constructor.filterOptions}
				freeSolo={true}
				fullWidth={this.props.fullWidth}
				inputEndAdornment={this.props.inputEndAdornment}
				label={this.props.label}
				loading={this.autocompleteLoading}
				loadingText={this.autocompleteLoadingText}
				inputValue={this.state.value}
				noOptionsText={this.props.noOptionsText}
				onChange={this.props.onChange}
				onInputChange={this.handleChange}
				options={(this.state.options || [])}
				placeholder={this.props.placeholder}
				renderOption={this.props.renderOption}
				selectOnFocus={true}
				size={this.props.size}
				style={this.props.style}
				styleInput={this.props.styleInput}
				styleInputInput={this.props.styleInputInput}
				value={this.state.value}
				variant={this.props.variant} />
		);
	}


	/**
	 * Get whether to render the `Autocomplete` as `loading`.
	 *
	 * This isn't necessarily just when actually loading as we 
	 * hijack the "loading" text to display empty/error messages too.
	 *
	 * @return {Boolean}
	 */
	get autocompleteLoading() {
		return (this.state.loading || !this.isValidValue() || this.empty);
	}


	/**
	 * Get loading text to display.
	 * 
	 * @return {String|undefined}
	 */
	get autocompleteLoadingText() {
		if (this.state.loading) {
			return this.props.loadingText;
		}
		else if (!this.isValidValue()) {
			return this.props.emptyText;
		}
		else if (!this.state.options?.length) {
			return this.props.noOptionsText;
		}
		else return undefined;
	}


	/**
	 * Get whether we're in an empty state.
	 * 
	 * @return {Boolean}
	 */
	get empty() {
		return !this.state.options?.length;
	}


	/**
	 * Filter options.
	 * 
	 * @param {Array} options
	 * @return {Array}
	 */
	static filterOptions(options) {
		return options;
	}

}

export default SearchableList;
