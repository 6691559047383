import React from "react";
import PickerOption from "./PickerOption.js";
import PickerPopper from "./PickerPopper.js";
import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";

/**
 * Picker component
 *
 * You should pass an array of `PickerOption` instances as `options`.
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Picker extends React.PureComponent {

	/**
	 * Value changed.
	 *
	 * @param {Event} e
	 * @param {mixed} v
	 * @return {void}
	 */
	handleChange = (e, v) => {
		if (this.props.handleChange) {
			this.props.onChange(v, (this.props.name || this.props.label));
		}
		else this.props.onChange(e, v);
	};


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Autocomplete
				blurOnSelect={this.props.blurOnSelect}
				className={this.props.className}
				classes={this.props.classes}
				ChipProps={{size: (this.small ? "small" : null)}}
				disabled={this.props.disabled}
				disableClearable={this.props.disableClearable}
				disableCloseOnSelect={this.props.multiple}
				defaultValue={this.props.defaultValue}
				filterOptions={this.props.filterOptions}
				freeSolo={this.props.freeSolo}
				fullWidth={this.props.fullWidth}
				getOptionDisabled={this.getOptionDisabled}
				getOptionLabel={this.getOptionLabel}
				getOptionSelected={this.getOptionSelected}
				groupBy={this.props.groupBy}
				loading={!!(this.props.loading || this.props.error)}
				loadingText={this.loadingText}
				inputValue={this.props.inputValue}
				multiple={this.props.multiple}
				noOptionsText={(this.props.noOptionsText || (!this.props.empty ? "No matches" : "None available"))}
				onChange={this.handleChange}
				onOpen={this.props.onOpen}
				onInputChange={this.props.onInputChange}
				openOnFocus={true}
				options={this.options}
				PopperComponent={PickerPopper}
				renderOption={this.renderOption}
				renderInput={this.renderInput}
				renderTags={this.props.renderTags}
				selectOnFocus={true}
				size={this.props.size}
				style={this.props.style}
				value={this.props.value} />
		);
	}


	/**
	 * Render our input.
	 * 
	 * @param {Object} props
	 * @return {ReactNode}
	 */
	renderInput = props => (
		<TextField
			{...props}
			autoFocus={this.props.autoFocus}
			color={this.props.color}
			helperText={this.props.helperText}
			InputProps={this.resolveInputProps(props.InputProps)}
			InputLabelProps={{shrink: this.props.shrink}}
			label={this.props.label}
			placeholder={(this.props.placeholder || (this.props.placeholderLabel ? this.props.label : ""))}
			required={this.props.required}
			size={(this.props.size || "small")}
			style={{...props.style, ...this.props.styleInput}}
			variant={(this.props.variant || "outlined")} />
	);


	/**
	 * Get whether an option should be disabled.
	 */
	getOptionDisabled = option => {
		if (this.props.getOptionDisabled) {
			return this.props.getOptionDisabled(option);
		}
		else if (option instanceof PickerOption) {
			return option.disabled;
		}
		else return false;
	};


	/**
	 * Get option's label.
	 *
	 * @param {mixed} option
	 * @return {String} (Not guaranteed - see implementation details!)
	 */
	getOptionLabel = option => {
		if (this.props.getOptionLabel) {
			return this.props.getOptionLabel(option);
		}
		else if (option instanceof PickerOption) {
			return option.label;
		}
		else return (option || "");
	};


	/**
	 * Get whether an option should be selected.
	 *
	 * @param {Object} option
	 * @param {mixed} value
	 * @return {Boolean}
	 */
	getOptionSelected = (option, value) => {
		if (this.props.getOptionSelected) {
			return this.props.getOptionSelected(option, value);
		}
		else return (option === value);
	};


	/**
	 * Match a value to find if we have an option for it.
	 *
	 * @param {mixed} value
	 * @param {Array} options
	 * @return {Boolean}
	 */
	matchValueOption = (value, options) => {
		if (!this.props.matchValueOption) {
			for (const opt of options) {
				if (this.getOptionSelected(opt, value)) {
					return true;
				}
			}
			return false;
		}
		else return this.props.matchValueOption(value, options);
	};


	/**
	 * Render an option.
	 *
	 * Refer to source for implementation details.
	 * 
	 * @param {mixed} option
	 * @return {ReactNode|undefined}
	 */
	renderOption = option => {
		if (this.props.renderOption) {
			return this.props.renderOption(option);
		}
		else if (option instanceof PickerOption) {
			return option.render();
		}
		else return this.getOptionLabel(option);
	};


	/**
	 * Resolve our `InputProps`.
	 * 
	 * @param {Object|null} props
	 * @return {Object}
	 */
	resolveInputProps(props) {
		if (this.props.inputEndAdornment) {
			props.endAdornment = this.props.inputEndAdornment;
		}
		props.style = {...props.style, ...this.props.styleInputInput, ...this.styleInput};
		return props;
	}


	/**
	 * Get `Autocomplete` `loadingText`.
	 * 
	 * @return {String}
	 */
	get loadingText() {
		if (this.props.error) return (this.props.errorText || "Error.");
		else return (this.props.loadingText || "Loading...");
	}


	/**
	 * Get our `options` array.
	 * 
	 * @return {Array}
	 */
	get options() {

		const value = this.props.value;

		let rawOptions = this.props.options;

		if (!Array.isArray(rawOptions)) {
			rawOptions = [];
		}

		const options = [...rawOptions];

		if (!this.props.dontAddValueOption) {

			let valueIterable = (!this.props.multiple ? (value ? [value] : []) : value);

			if (!Array.isArray(valueIterable)) {
				valueIterable = [];
			}

			for (const val of valueIterable) {
				if (!this.matchValueOption(val, options)) {
					options.push(val);
				}
			}

		}

		return options;

	}


	/**
	 * Get whether to render as the small size.
	 * 
	 * @return {Boolean}
	 */
	get small() {
		return ((this.props.size === "small") || !this.props.size);
	}


	/**
	 * Styles (input).
	 * 
	 * @return {Object}
	 */
	get styleInput() {
		return {
			minHeight: ((this.small && this.props.multiple) ? "4.2rem" : undefined)
		};
	}

}

export default Picker;
