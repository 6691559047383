import React from "react";
import Container from "Components/Container.js";
import HtmlHelper from "Helpers/Html.js";
import ReactHtmlParser from "@hedgedoc/html-to-react";
import Typography from "@material-ui/core/Typography";
import scss from "./Html.module.scss";

/**
 * Component to render **user-supplied** HTML.
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Html extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Typography
				className={this.props.className}
				component="div"
				style={this.props.style}>
				<Container
					className={`${scss.Html} ${this.props.classNameInner}`}
					gap={(this.props.gap || 0.5)}
					style={this.constructor.styles}>
					{ReactHtmlParser(this.html, this.parserOptions)}
				</Container>
			</Typography>
		);
	}


	/**
	 * Get props to apply to a transformed node by its tag name.
	 *
	 * See `Html.reactHtmlParserTransform` (`src/Helpers`).
	 * 
	 * @param {String} node Tag name
	 * @return {Object|undefined}
	 */
	getNodeProps = node => {
		if (node === "a") {
			return {color: this.props.linkColor};
		}
		else return undefined;
	};


	/**
	 * Get our HTML.
	 * 
	 * @return {String}
	 */
	get html() {

		let html = this.props.html?.toString();
		if (!html) return "";

		html = html.replace(/\r/g, "");

		html = html.split("\n").filter(l => l).map(h => {
			if (!h.trim().startsWith("<") && !h.trim().endsWith(">")) {
				return `<p>${h}</p>`;
			}
			else return h;
		}).join(" ");

		return HtmlHelper.purifyHtml(HtmlHelper.convertPlainTextLinks(html));

	}


	/**
	 * Get `ReactHtmlParser` options.
	 * 
	 * @return {Object}
	 */
	get parserOptions() {
		return {
			transform: (node, i) => HtmlHelper.reactHtmlParserTransform(node, i, this.getNodeProps)
		};
	}


	/**
	 * Styles.
	 * 
	 * @type {Object}
	 */
	static styles = {
		userSelect: "text",
		wordBreak: "break-word"
	};

}

export default Html;
