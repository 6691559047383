/**
 * Time utility methods
 *
 * @package HOPS
 * @subpackage Helpers
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Time {

	/**
	 * Convert a time object to YYYY-MM-DD HH:MM:SS.
	 * 
	 * @param {Object} t A time object with a Moment/DayJS-like API
	 * @return {Boolean|null}
	 */
	static ymdhms(t) {
		return (t?.format("YYYY-MM-DD HH:mm:ss") || null);
	}


	/**
	 * Prepare a friendly format string to present a time as.
	 *
	 * When `t1` is the same day as `t2` (intended to be today, but 
	 * we can't implement this, as we can't assume which time API 
	 * is in use), we don't include the date, so it displays time only.
	 *
	 * @param {Object} t1 A time object with a Moment/DayJS-like API
	 * @param {Object} t2 A time object with a Moment/DayJS-like API
	 * @param {String} options.dateFormat optional
	 * @param {String} options.timeFormat optional
	 * @param {Boolean} options.friendly optional Disable "friendly" display
	 * @param {Boolean} options.dateOnlyWhenDifferentDates optional Don't display the time when the dates are different
	 * @param {Boolean} options.timezone optional Append timezone identifier
	 * @return {String}
	 */
	static prepareFormat(t1, t2, {dateFormat="DD/MM/YYYY", timeFormat="YY:mm", friendly=true, dateOnlyWhenDifferentDates=false, timezone=false}={}) {
		const isSameDay = t1.isSame(t2, "day");
		let format = ((!isSameDay || !friendly || !timeFormat) ? dateFormat : "");
		if (timeFormat && (isSameDay || !dateOnlyWhenDifferentDates || !format)) format = `${format} ${timeFormat}`;
		if (timezone && timeFormat) format = `${format} z`;
		return format.trim();
	}

}

export default Time;
