import React from "react";
import dUpdateCompleted from "Dispatchers/dUpdateCompleted.js";
import withAuth from "Hoc/withAuth.js";
import withSnack from "Hoc/withSnack.js";
import {connect} from "react-redux";

/**
 * Updated successfully banner
 *
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Updated extends React.PureComponent {

	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		if (this.props.Updated && this.props.authed && this.props.userUpdateRequested) {
			this.props.snack("Updated successfully.", "success");
		}
		dUpdateCompleted();
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return null;
	}

}

export default connect(({userUpdateRequested, Updated}) => ({userUpdateRequested, Updated}))(withAuth(withSnack(Updated)));
