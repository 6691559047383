import State from "./State.js";
import StatePersistent from "./StatePersistent.js";

/**
 * Reducer
 *
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Reducer {

	/**
	 * API URI reducer.
	 *
	 * @param {Object} state
	 * @param {String|null} options.api API URI
	 * @return {Object}
	 */
	static api(state, {api}) {
		return {...state, api};
	}


	/**
	 * App version reducer.
	 *
	 * @param {Object} state
	 * @param {String} options.appv
	 * @return {Object}
	 */
	static appv(state, {appv}) {
		return {...state, appv, Updated: (appv !== state.appv)};
	}


	/**
	 * Access denied/OK status.
	 *
	 * @param {Object} state
	 * @param {Boolean} options.Access
	 * @param {Object|null} options.AccessReason
	 * @return {Object}
	 */
	static access(state, {Access, AccessReason}) {
		return {...state, Access, AccessReason: (!Access ? AccessReason : null)};
	}


	/**
	 * Task run started/stopped.
	 *
	 * @param {Object} state
	 * @param {Boolean} options.ActiveTaskRun optional
	 * @return {Object}
	 */
	static activeTaskRun(state, {ActiveTaskRun}) {
		return {...state, ActiveTaskRun};
	}


	/**
	 * Authenticate the store.
	 * 
	 * @param {Object} state
	 * @param {String} options.auth Authentication token
	 * @param {Integer|null} options.org Org ID
	 * @return {Object}
	 */
	static auth(state, {auth, org}) {
		return {
			...state,
			auth: {
				...state.auth,
				...auth
			},
			org: (org || state.org)
		};
	}


	/**
	 * Admin authentication handler.
	 * 
	 * @param {Object} state
	 * @param {Object} options.authAdmin
	 * @return {Object}
	 */
	static authAdmin(state, {authAdmin}) {
		return {
			...state,
			authAdmin: {
				...state.authAdmin,
				...authAdmin
			},
			orgAdmin: authAdmin.org
		};
	}


	/**
	 * Admin authentication clearer.
	 * 
	 * @param {Object} state
	 * @return {Object}
	 */
	static authAdminClear(state) {
		return {
			...state,
			authAdmin: StatePersistent.authAdmin,
			orgAdmin: null
		};
	}


	/**
	 * Chat reducer.
	 * 
	 * @param {Object} state
	 * @param {Array|null} Chats
	 * @return {Object}
	 */
	static chats(state, {Chats}) {
		return {...state, Chats};
	}


	/**
	 * Chat pane visibility.
	 * 
	 * @param {Object} state
	 * @return {Object}
	 */
	static chatPane(state) {
		return {
			...state,
			ChatPane: !state.ChatPane,
			NotificationsPane: false
		};
	}


	/**
	 * Client settings updater.
	 *
	 * The new settings are *merged* in.
	 *
	 * @param {Object} state
	 * @param {Object} options.clientSettings
	 * @return {Object}
	 */
	static clientSettings(state, {clientSettings}) {
		return {
			...state,
			clientSettings: {
				...state.clientSettings,
				...clientSettings
			}
		};
	}


	/**
	 * Client settings (local) updater.
	 *
	 * The new settings are *merged* in.
	 *
	 * @param {Object} state
	 * @param {Object} options.clientSettings
	 * @return {Object}
	 */
	static clientSettingsLocal(state, {clientSettings}) {
		return {
			...state,
			ClientSettingsLocal: {
				...state.ClientSettingsLocal,
				...clientSettings
			}
		};
	}


	/**
	 * Comms message counts reducer.
	 *
	 * @param {Object} state
	 * @param {Object} options.CommsMessageCounts
	 * @return {Object}
	 */
	static commsMessageCounts(state, {CommsMessageCounts}) {
		return {
			...state,
			CommsMessageCounts
		};
	}


	/**
	 * Increment/decrement the value of a Comms folder's message count.
	 *
	 * @param {Object} state
	 * @param {String} options.folder
	 * @param {Integer} options.inc
	 * @return {Object}
	 */
	static commsMessageCountInc(state, {folder, inc}) {
		return {
			...state,
			CommsMessageCounts: {
				...state.CommsMessageCounts,
				[folder]: Math.max(((state.CommsMessageCounts?.[folder] || 0) + inc), 0)
			}
		};
	}


	/**
	 * Cookie "disable same-site-none-secure" reducer.
	 *
	 * @param {Object} state
	 * @param {Boolean} options.cookieDisableSsns
	 * @return {Object}
	 */
	static cookieDisableSsns(state, {cookieDisableSsns}) {
		return {...state, cookieDisableSsns};
	}


	/**
	 * Cookie domain reducer.
	 * 
	 * @param {Object} state
	 * @param {String|null} options.cookieDomain
	 * @return {Object}
	 */
	static cookieDomain(state, {cookieDomain}) {
		return {...state, cookieDomain};
	}


	/**
	 * Dark theme toggle.
	 *
	 * @param {Object} state
	 * @param {Boolean} options.dark
	 * @return {Object}
	 */
	static dark(state, {dark}) {
		return {...state, dark};
	}


	/**
	 * Dev mode toggle.
	 * 
	 * @param {Object} state
	 * @return {Object}
	 */
	static dev(state) {
		return {...state, dev: !state.dev};
	}


	/**
	 * Feature flags changed.
	 *
	 * @param {Object} state
	 * @param {Array} options.flags
	 * @return {Object}
	 */
	static flags(state, {flags}) {
		return {...state, flags};
	}


	/**
	 * History timestamp change.
	 * 
	 * @param {Object} state
	 * @return {Object}
	 */
	static history(state) {
		return {...state, HistoryTimestamp: (new Date()).getTime()};
	}


	/**
	 * Kiosk mode state changed.
	 *
	 * @param {Object} state
	 * @param {Boolean} options.KioskMode
	 * @return {Object}
	 */
	static kioskMode(state, {KioskMode}) {
		return {...state, KioskMode};
	}


	/**
	 * Logger reducer.
	 *
	 * @param {Object} state
	 * @param {Boolean} options.logger
	 * @return {Object}
	 */
	static logger(state, {logger}) {
		return {...state, logger};
	}


	/**
	 * Logout dialog visibility changed.
	 *
	 * @param {Object} state
	 * @param {Boolean} options.open Open
	 * @return {Object}
	 */
	static logoutDialog(state, {open}) {
		return {...state, LogoutDialog: open};
	}


	/**
	 * Update HOPS notices.
	 * 
	 * @param {Object} state
	 * @param {Object|null} options.NoticesHops
	 * @return {Object}
	 */
	static noticesHops(state, {NoticesHops}) {
		return {...state, NoticesHops};
	}


	/**
	 * Mark a notification as read.
	 *
	 * @param {Object} state
	 * @param {String} options.Uuid Notification UUID
	 * @return {Object}
	 */
	static notificationRead(state, {Uuid}) {
		return {
			...state,
			Notifications: state.Notifications.filter(n => (n.Uuid !== Uuid))
		};
	}


	/**
	 * Store user notifications.
	 * 
	 * @param {Object} state
	 * @param {Array} options.Notifications
	 * @return {Object}
	 */
	static notifications(state, {Notifications}) {
		return {...state, Notifications};
	}


	/**
	 * Notifications visibility toggled.
	 * 
	 * @param {Object} state
	 * @return {Object} 
	 */
	static notificationsPane(state) {
		return {
			...state,
			ChatPane: false,
			NotificationsPane: !state.NotificationsPane
		};
	}


	/**
	 * Organisation changed.
	 *
	 * @param {Object} state
	 * @param {Integer} options.org Active organisation ID
	 * @param {String|null|undefined} options.uri optional URI to use as target after switch
	 * @return {Object}
	 */
	static org(state, {org, uri}) {
		return {...state, org, OrgSwitchTargetUri: (uri || null)};
	}


	/**
	 * Permissions updated.
	 *
	 * @param {Object} state
	 * @param {Object|null} options.Permissions
	 * @return {Object}
	 */
	static permissions(state, {Permissions}) {
		return {...state, Permissions};
	}


	/**
	 * Raildays mode toggler.
	 * 
	 * @param {Object} state
	 * @param {Boolean} options.raildays
	 * @return {Object}
	 */
	static raildays(state, {raildays}) {
		return {...state, raildays};
	}


	/**
	 * Raildays active card ID changed.
	 *
	 * @param {Object} state
	 * @param {Integer} options.raildaysActiveCardIndex
	 * @return {Object}
	 */
	static raildaysActiveCardIndex(state, {raildaysActiveCardIndex}) {
		return {...state, raildaysActiveCardIndex};
	}


	/**
	 * Route changed.
	 *
	 * @param {Object} state
	 * @param {Object} options.Route
	 * @return {Object}
	 */
	static route(state, {Route}) {
		return {...state, Route};
	}


	/**
	 * Reset the state.
	 *
	 * The state is restored to its initial state.
	 *
	 * Note: All properties defined in `persistsReset` are retained!
	 *
	 * @param {Object} state
	 * @param {Boolean} options.unexpected
	 * @return {Object}
	 */
	static reset(state, {unexpected}) {
		const retained = {};
		this.persistsReset.forEach(key => (retained[key] = state[key]));
		return {...State, ...StatePersistent, ...retained, AuthResetWasUnexpected: unexpected};
	}


	/**
	 * Generic state setter.
	 *
	 * @param {Object} state Current state
	 * @param {Object} props Apply this state
	 * @return {Object}
	 */
	static set(state, props) {
		return {...state, ...props};
	}


	/**
	 * Telemetry reporting toggler.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	static telemetry(state) {
		return {...state, telemetry: !state.telemetry};
	}


	/**
	 * Force `UiDrawer` to close if it isn't already closed.
	 *
	 * @param {Object} state Current state
	 * @return {Object}
	 */
	static uiDrawerClose(state) {
		return {...state, UiDrawer: false};
	}


	/**
	 * Force `UiDrawer` to open if it isn't already opened.
	 *
	 * @param {Object} state Current state
	 * @return {Object}
	 */
	static uiDrawerOpen(state) {
		return {...state, UiDrawer: true};
	}


	/**
	 * Toggle `UiDrawer` visibility.
	 * 
	 * @param {Object} state Current state
	 * @return {Object}
	 */
	static uiDrawerToggle(state) {
		return {...state, UiDrawer: !state.UiDrawer};
	}


	/**
	 * Update available.
	 * 
	 * @param {Object} state
	 * @return {Object}
	 */
	static updateAvailable(state) {
		return {...state, UpdateAvailable: true};
	}


	/**
	 * Update completed.
	 * 
	 * @param {Object} state
	 * return {Object}
	 */
	static updateCompleted(state) {
		return {...state, userUpdateRequested: false};
	}


	/**
	 * Updating now.
	 *
	 * @param {Object} state
	 * @param {Boolean} options.userUpdateRequested Initiated by user?
	 * @return {Object}
	 */
	static updating(state, {userUpdateRequested}) {
		return {...state, userUpdateRequested};
	}


	/**
	 * Update the user's function list.
	 * 
	 * @param {Object} state
	 * @param {Array|null} options.UserFunctions
	 * @return {Object}
	 */
	static userFunctions(state, {UserFunctions}) {
		return {...state, UserFunctions};
	}


	/**
	 * State reducer.
	 *
	 * Calls the method given by the `type` property of `action`, passing 
	 * the `action` itself, within this class to get the reduced state.
	 * 
	 * @param {Object} state
	 * @param {Object} action
	 * @return {Object}
	 */
	static reduce(state, action) {
		if (Reducer.hasOwnProperty(action.type)) {
			state = Reducer[action.type](state, action);
		}
		return state;
	}


	/**
	 * State keys which survive a reset
	 * 
	 * @type {Array}
	 */
	static persistsReset = [
		"api",
		"appv",
		"cookieDisableSsns",
		"cookieDomain",
		"dark",
		"dev",
		"flags",
		"logger",
		"raildays",
		"userUpdateRequested",
		"HistoryTimestamp",
		"NoticesHops",
		"Route",
		"Session",
		"UpdateAvailable",
		"Updated"
	];

}

export default Reducer.reduce;
