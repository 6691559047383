import React from "react";
import dDark from "Dispatchers/dDark.js";
import Switch from "Components/Switch.js";
import {connect} from "react-redux";

/**
 * F12 dark theme toggle
 *
 * @package HOPS
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class F12Dark extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Switch
				checked={this.props.dark}
				helperText="Enable dark mode in native screens."
				onChange={() => dDark(!this.props.dark)}
				label="Dark Mode" />
		);
	}

}

export default connect(({dark}) => ({dark}))(F12Dark);
