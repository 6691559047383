import React from "react";
import Container from "Components/Container.js";
import String from "Components/String.js";
import UiBarOrgSelect from "./UiBarOrgSelect.js";
import withAuth from "Hoc/withAuth.js";

/**
 * UI bar organisation
 *
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiBarOrg extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		if (this.props.auth.orgs.filter(o => o.UserWorking).length > 1) {
			return this.constructor.renderOrgSelect();
		}
		else return this.renderOrgName();
	}

	/**
	 * Render as name.
	 * 
	 * @return {ReactNode}
	 */
	renderOrgName() {
		return (
			<Container>
				<String
					bold={true}
					oneline={true}
					str={this.props.auth.orgs.find(o => o.UserWorking)?.NameShort} />
			</Container>
		);
	}


	/**
	 * Render as selection dropdown.
	 * 
	 * @return {ReactNode}
	 */
	static renderOrgSelect() {
		return <UiBarOrgSelect />;
	}

}

export default withAuth(UiBarOrg);
