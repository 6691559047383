import React from "react";
import Container from "Components/Container.js";
import scss from "./PickerOptionComponent.module.scss";

/**
 * Picker option component
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class PickerOptionComponent extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container
				className={scss.PickerOptionComponent}
				gap={this.constructor.spacing}
				py={this.constructor.spacing}>
				{this.props.children}
			</Container>
		);
	}


	/**
	 * Spacing multiplier
	 * 
	 * @type {Float}
	 */
	static spacing = 0.3;

}

export default PickerOptionComponent;
