import React from "react";

/**
 * `React.PureComponent` extension which ignores unmounted state updates
 *
 * @package HOPS
 * @subpackage Includes
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class AsyncStatefulComponent extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Cache our initial state
		 *
		 * This lets us restore it later.
		 * 
		 * @type {Object}
		 */
		this._initialState = {...this.state};

		/**
		 * Mounted?
		 *
		 * @type {Boolean}
		 */
		this._mounted = false;

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this._mounted = true;
	}


	/**
	 * Component unmounted.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {
		this._mounted = false;
	}


	/**
	 * Reset the state to our cached initial state.
	 * 
	 * @return {void}
	 */
	resetState() {
		this.setState(this._initialState);
	}


	/**
	 * Update the state.
	 *
	 * The state update is ignored if the component's unmounted!
	 *
	 * @param {Object} state Object to pass to React `setState()`
	 * @param {Function|undefined} callback Callback to call when complete
	 * @return {void}
	 */
	setState(state, callback=undefined) {
		if (this._mounted) {
			super.setState(state, callback);
		}
	}

}

export default AsyncStatefulComponent;
