import React from "react";
import TextField from "Components/TextField.js";

/**
 * Login form username input
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LoginFormUsernameInput extends React.PureComponent {

	/**
	 * Input
	 *
	 * @type {ReactRef}
	 */
	inputRef = React.createRef();


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.loginStatus !== this.props.loginStatus) {
			if (this.props.autoFocusOnError && this.props.loginStatus) {
				setTimeout(() => {
					if (this.inputRef?.current) {
						this.inputRef.current.select();
					}
				});
			}
		}
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<TextField
				disabled={this.props.disabled}
				error={(this.error || this.props.error)}
				helperText={(this.props.helperText || this.helperText)}
				inputRef={(this.props.inputRef || this.inputRef)}
				label="Username"
				name="username"
				onChange={this.props.onChange}
				placeholder="Username"
				required={true}
				shrink={true}
				value={this.props.value}
				varchar={true}
				variant="standard" />
		);
	}


	/**
	 * Get whether we've an error.
	 * 
	 * @return {ReactNode}
	 */
	get error() {
		return (this.props.loginStatus === 404);
	}


	/**
	 * Input helper text.
	 * 
	 * @return {String}
	 */
	get helperText() {
		if (!this.error) return null;
		else return "Unknown/incorrect username";
	}

}

export default LoginFormUsernameInput;
