import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import AuthService from "Auth/AuthService.js";
import Dialog from "Components/Dialog.js";
import Navigator from "App/Navigator.js";
import dReset from "Dispatchers/dReset.js";
import withRaildays from "Hoc/withRaildays.js";

/**
 * Logout dialog
 *
 * @package HOPS
 * @subpackage Login
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LogoutDialog extends AsyncStatefulComponent {

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Loading?
		 * 
		 * @type {Boolean}
		 */
		loading: false

	};


	/**
	 * Logout.
	 * 
	 * @return {void}
	 */
	logout = () => {

		/**
		 * Loading
		 */
		this.setState({loading: true});

		/**
		 * Logout!
		 */
		AuthService.logout().then(() => {
			// ...
		}).catch(() => {
			// ...
		}).finally(() => {

			dReset();
			this.setState({loading: false});

			this.props.onClose();

			if (this.props.raildays) {
				Navigator.navigate("/login");
			}
			else Navigator.navigate("/");

		});

	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				content="Are you sure you want to logout?"
				loading={this.state.loading}
				open={this.props.open}
				onClose={this.props.onClose}
				onOk={this.logout}
				title="Logout" />
		);
	}

}

export default withRaildays(LogoutDialog);
