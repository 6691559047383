import React, {useEffect, useState} from "react";
import FileIcon from "Components/FileIcon.js";
import LibraryService from "Library/LibraryService.js";
import scss from "./LibraryThumbnail.module.scss";

/**
 * Library thumbnail icon component
 *
 * Renders the correct thumbnail for a file.
 *
 * @param {Object} props
 * @return {ReactNode}
 */
const LibraryThumbnail = props => {

	const [imgError, setImgError] = useState(false);

	const {Uuid, Mime} = props.file;
	const thumbUri = LibraryService.getThumbnailUri(Uuid);

	/**
	 * Clear any thumbnail loading error when the target file changes
	 */
	useEffect(() => {
		setImgError(false);
	}, [Uuid]);

	/**
	 * Render the thumbnail or the generic file icon image
	 */
	if (Mime.startsWith("image/") && !imgError) {
		return (
			<img
				alt=""
				className={scss.thumb}
				onError={() => setImgError(true)}
				src={thumbUri} />
		);
	}
	else return <FileIcon mime={Mime} />;

};

export default LibraryThumbnail;
