import React from "react";
import ChatPaneItem from "./ChatPaneItem.js";
import Paper from "@material-ui/core/Paper";

/**
 * Chat pane conversation list
 *
 * @package HOPS
 * @subpackage Chat
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class ChatPaneList extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return this.props.chats.map((chat, key) => {

			const isSender = (chat.Sender?.Id === this.props.userAccount.Id);
			const correspondent = (isSender ? chat.Recipient?.Id : chat.Sender?.Id);
			const correspondentObj = this.props.correspondents[correspondent];

			return (!this.props.paper ? this.renderItem(chat, correspondentObj, key) : this.renderPaper(chat, correspondentObj, key));

		});
	}


	/**
	 * Render a chat pane item for a chat.
	 *
	 * The "correspondent object" is the object representing the
	 * user account of the chat correspondent from the perspective
	 * of the authenticated user.
	 *
	 * @param {Object} chat Chat object
	 * @param {Object} correspondent Correspondent object
	 * @param {mixed} key optional React node key
	 * @return {ReactNode}
	 */
	renderItem(chat, correspondent, key=undefined) {
		return (
			<ChatPaneItem
				chat={chat}
				correspondent={correspondent}
				key={key}
				onClick={this.props.onSelectChat}
				onDelete={this.props.onDeleteChat}
				onMarkedRead={this.props.onMarkChatRead}
				visible={this.props.ChatPane} />
		);
	}


	/**
	 * Render a chat pane item for a chat within a `Paper` element.
	 *
	 * The "correspondent object" is the object representing the
	 * user account of the chat correspondent from the perspective
	 * of the authenticated user.
	 *
	 * @param {Object} chat Chat object
	 * @param {Object} correspondent Correspondent object
	 * @param {mixed} key optional React node key
	 * @return {ReactNode}
	 */
	renderPaper(chat, correspondent, key=undefined) {
		return (
			<Paper
				elevation={0}
				key={key}
				square={true}>
				{this.renderItem(chat, correspondent)}
			</Paper>
		);
	}

}

export default ChatPaneList;
