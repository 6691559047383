import Bghost from "App/Bghost.js";
import dAuth from "Dispatchers/dAuth.js";
import AuthService from "Auth/AuthService.js";

export default () => {
	return new Promise((resolve, reject) => {
		Bghost.register(AuthService.ping()).then(auth => {
			dAuth(auth);
			resolve();
		}).catch(reject);
	});
};
