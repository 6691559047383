import dClientSettings from "./dClientSettings.js";
import AuthService from "Auth/AuthService.js";
import Messenger from "Includes/Messenger.js";
import Store from "App/Store.js";
import TelemetryService from "Telemetry/TelemetryService.js";
import UpdateHopsCookies from "Tasks/UpdateHopsCookiesTask.js";
import {StorePersistor} from "App/Store.js";

export default (auth, org) => {

	if (auth.hasOwnProperty("token")) {
		Messenger.postMessage("hops.auth", {token: auth.token});
	}

	TelemetryService.report("dAuth", {Account: (auth?.account?.Id || null), Token: !!auth?.token, TokenAccount: (auth?.token ? AuthService.getAuthTokenPayload(auth?.token)?.AccountId : null)});

	Store.dispatch({type: "auth", auth, org});

	UpdateHopsCookies();

	StorePersistor.flush();

	dClientSettings(auth.hopsa);

};
