import dActiveTaskRun from "Dispatchers/dActiveTaskRun.js";
import Logger from "Includes/Logger.js";
import Store from "App/Store.js";
import Tasks from "./Tasks.js";
import TelemetryService from "Telemetry/TelemetryService.js";

/**
 * Tasker
 * 
 * A utility for scheduled tasks.
 *
 * Tasks are asynchronous functions which we run synchronously, 
 * one at-a-time, so tasks may act on the results of the previous 
 * task. There is a single chain of tasks and tasks are executed 
 * in the order specified. Tasks can have different requested 
 * execution frequencies; the actual loop frequency is then 
 * based on the lowest requested frequency (i.e. quickest 
 * frequency) of all the tasks, and the actual tasks to 
 * run this loop is determined dynamically. Tasks may 
 * define execution constraints, so as only running 
 * when authenticated/online - refer to `Tasks.js`.
 * 
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Tasker {

	/**
	 * Constructor.
	 *
	 * @param {Array} tasks Task objects to register
	 * @param {Boolean} run optional Run immediately (`false`)
	 * @return {self}
	 */
	constructor(tasks, run=false) {

		/**
		 * Destroyed?
		 *
		 * This stops all tasks.
		 * 
		 * @type {Boolean}
		 */
		this.destroyed = false;

		/**
		 * Are we currently running?
		 * 
		 * @type {Boolean}
		 */
		this.running = false;

		/**
		 * Registered tasks
		 *
		 * @type {Array} Task objects
		 */
		this.tasks = tasks;

		/**
		 * Interval at which to call `run()`
		 * 
		 * @type {Integer}
		 */
		this.interval = this.constructor.determineTaskRunInterval(tasks);

		/**
		 * Intervals elapsed
		 * 
		 * @type {Integer}
		 */
		this.intervalElapsed = 0;

		/**
		 * Stored interval ID
		 * 
		 * @type {Integer|null}
		 */
		this.intervalId = setInterval(() => this.run(), (this.interval * 1000));

		/**
		 * Store the interval on which tasks were last executed
		 *
		 * Allows us to track when tasks are due.
		 * 
		 * @type {Object} Task ID => last executed interval
		 */
		this.intervalTasks = {};

		/**
		 * Run immediately
		 */
		if (run) this.run();

	}


	/**
	 * Destroy scheduled task runs.
	 *
	 * This will not affect any currently active run!
	 */
	destroy() {
		this.destroyed = true;
		if (this.intervalId) clearTimeout(this.intervalId);
	}


	/**
	 * Run all tasks.
	 *
	 * @param {Boolean} all optional Run all tasks (not just due) (`false`)
	 * @param {Array} except optional Array of task IDs not to run
	 * @return {void}
	 */
	async run(all=false, except=[]) {

		/**
		 * Disallow concurrency
		 */
		if (this.running || this.destroyed) return;
		else this.running = true;

		/**
		 * Tasks are running
		 */
		dActiveTaskRun(true);

		/**
		 * Log this interval
		 */
		this.intervalElapsed++;

		/**
		 * Run ID
		 */
		const run = this.intervalElapsed;

		/**
		 * Log this run
		 */
		Logger.log(`Starting task run #${run}.`);

		/**
		 * Telemetry ping
		 */
		TelemetryService.report(`Task run ${run}.`);

		/**
		 * Determine the tasks to run
		 */
		const tasks = (all ? this.tasks : this.getDueTasks());

		/**
		 * Iterate over our tasks
		 */
		for (const task of tasks) {

			/**
			 * We've been destroyed
			 */
			if (this.destroyed) return;

			/**
			 * Task is excluded
			 */
			if (except.includes(task.id)) continue;

			/**
			 * Can we run this task?
			 */
			const {requireAuth, requireAuthAdmin} = task;
			if (requireAuth && !Store.getState().auth.token) continue;
			else if (requireAuthAdmin && !Store.getState().authAdmin.token) continue;

			/**
			 * Run the task!
			 */
			try {
				Logger.log(`Running task "${task.id}".`);

				await task.task(...(task.taskParams || []));

				Logger.log(`Completed task "${task.id}".`);

				TelemetryService.report(`Task::${task.id}`);
			}
			catch (e) {

				/**
				 * Errors are OK
				 *
				 * Even if we e.g. couldn't refresh authentication when 
				 * needed, the next task will run its own check first 
				 * to determine if we're authenticated and it can run.
				 */
				Logger.log(`"${task.id}" task error: ${e}.`);
				TelemetryService.report(`TaskError::${task.id}`, {Error: (e?.toString() || null)});

			}

			/**
			 * Record the task's interval
			 */
			this.intervalTasks[task.id] = this.intervalElapsed;

		}

		/**
		 * We're done!
		 */
		this.running = false;

		/**
		 * Tasks no longer running
		 *
		 * We make sure no extra run started while the tasks 
		 * were running to avoid incorrectly updating the state.
		 *
		 * (Possible when a new run is forced.)
		 */
		if (run === this.intervalElapsed) {
			dActiveTaskRun(false);
		}

	}


	/**
	 * Force update all tasks to indicate they ran in the current interval.
	 *
	 * This is necessary for scenarios such as post-login where on launch 
	 * we were not authenticated - otherwise the first run after login 
	 * would immediately run all tasks, needlessly, due to seeing 
	 * the `requireAuth` tasks as being unrun and in need of run.
	 */
	alignAllTasks() {
		this.tasks.forEach(task => {
			this.intervalTasks[task.id] = this.intervalElapsed;
		});
	}


	/**
	 * Get all tasks which are now due a run.
	 *
	 * This based on `intervalElapsed` and `intervalTasks` - see them 
	 * to obtain more information about how task runs are scheduled.
	 */
	getDueTasks() {
		const elapsed = (this.intervalElapsed * this.interval);
		return this.tasks.filter(task => {
			const id = task.id;
			const last = this.intervalTasks[id];
			if (!last) return true;
			else return (elapsed > ((last * (this.interval - 1)) + task.frequency));
		});
	}


	/**
	 * Determine the interval at which to run tasks.
	 *
	 * We run as often as the most frequently requested task.
	 *
	 * Only the tasks which need to run, based on their requested 
	 * frequency, will actually run on each interval. This model 
	 * eliminates concurrency issues - there's only one active 
	 * task chain, and we just run due items on each interval.
	 */
	static determineTaskRunInterval(tasks) {
		return Math.min(...tasks.map(task => task.frequency));
	}

}

export default new Tasker(Tasks);
