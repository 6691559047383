import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import ChatService from "./ChatService.js";
import Dialog from "Components/Dialog.js";
import dChats from "Dispatchers/dChats.js";
import withChat from "Hoc/withChat.js";
import withSnack from "Hoc/withSnack.js";

/**
 * Chat deletion dialog
 *
 * @package HOPS
 * @subpackage Chat
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class ChatDeleteDialog extends AsyncStatefulComponent {

	/**
	 * State
	 *
	 * @type {Objecr}
	 */
	state = {

		/**
		 * Deleting?
		 * 
		 * @type {Boolean}
		 */
		deleting: false

	};


	/**
	 * Delete the conversation.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleDelete = e => {

		/**
		 * Events
		 */
		e.preventDefault();
		e.stopPropagation();

		/**
		 * Deleting
		 */
		this.setState({deleting: true});

		/**
		 * Make the deletion
		 */
		ChatService.delete(this.props.user.Id).then(() => {

			const chats = this.props.Chats.filter(c => {
				return (![c.Sender?.Id, c.Recipient?.Id].includes(this.props.user.Id));
			});
			dChats({...this.props.ChatData, Conversations: chats});

			this.props.snack("Conversation deleted.", "success");
			this.props.onDelete?.();

		}).catch(e => {
			this.props.snack(e);
		}).finally(() => {
			this.setState({deleting: false});
		});

	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				content={this.strings}
				loading={this.state.deleting}
				onClose={this.props.onClose}
				onOk={this.handleDelete}
				open={this.props.open}
				title="Delete chat" />
		);
	}


	/**
	 * Strings.
	 * 
	 * @return {Array}
	 */
	get strings() {
		return [
			`Are you sure you want to delete this chat?`,
			`Your entire conversation with ${this.props.user.Fname} will be deleted.`,
			`${this.props.user.Fname} will still be able to access the conversation.`
		];
	}

}

export default withChat(withSnack(ChatDeleteDialog));
