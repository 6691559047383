import React from "react";
import AppHelper from "Helpers/App.js";
import Container from "Components/Container.js";
import String from "Components/String.js";
import Strings from "./F12.strings.json";
import F12Api from "./F12Api.js";
import F12Dark from "./F12Dark.js";
import F12Dev from "./F12Dev.js";
import F12CookieDisableSsns from "./F12CookieDisableSsns.js";
import F12CookieDomain from "./F12CookieDomain.js";
import F12Flags from "./F12Flags.js";
import F12Logger from "./F12Logger.js";
import F12Raildays from "./F12Raildays.js";
import F12Telemetry from "./F12Telemetry.js";
import {Divider} from "@material-ui/core";

export default () => (
	<Container>
		<F12Api />
		<F12CookieDomain />
		<F12Dark />
		<F12Dev />
		<F12Logger />
		<F12Telemetry />
		<F12Raildays />
		<F12CookieDisableSsns />
		<Divider />
		<F12Flags />
		<Divider />
		<Container gap={0.5} mt={1}>
			<String str={`App ID: ${AppHelper.getAppv()}`} />
			<String str={Strings.warning} />
		</Container>
	</Container>
);
