import React from "react";
import dLogger from "Dispatchers/dLogger.js";
import Switch from "Components/Switch.js";
import {connect} from "react-redux";

/**
 * F12 control to toggle logging via `Logger`
 *
 * @package HOPS
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class F12Logger extends React.PureComponent {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleChange = this.handleChange.bind(this);

	}


	/**
	 * Toggled.
	 * 
	 * @return {void}
	 */
	handleChange() {
		dLogger(!this.props.logger);
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Switch
				checked={this.props.logger}
				helperText="Debug logs will be emitted to the browser console."
				onChange={this.handleChange}
				label="Enable Logger" />
		);
	}

}

export default connect(({logger}) => ({logger}))(F12Logger);
