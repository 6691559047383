import React from "react";
import Routes from "./Routes.js";
import RouteWrapper from "Wrappers/RouteWrapper.js";
import MainDenied from "./MainDenied.js";
import withAuth from "Hoc/withAuth.js";
import withFlags from "Hoc/withFlags.js";
import {Route, Switch} from "react-router-dom";

/**
 * Router
 *
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Router extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Wrapped routes
		 *
		 * See: `getWrappedRoute()`.
		 *
		 * @type {Array}
		 */
		this.wrappedRoutes = [];

	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Switch>
				{this.renderRoutes()}
			</Switch>
		);
	}


	/**
	 * Render our routes.
	 * 
	 * @return {Array}
	 */
	renderRoutes() {
		return Routes.map((route, key) => {

			const routePath = (route.uri || route.uris);

			if ((this.props.authed && route.publicOnly) || (route.flag && !this.props.hasFlag(route.flag))) {
				return null;
			}
			else if ((!this.props.authed && !route.public) || (route.permission && !this.props.hasPermission(...route.permission)) || (route.hideWhenAdminAuthed && this.props.isAdminAuthed)) {
				return (
					<Route
						exact={!route.inexact}
						key={key}
						path={routePath}
						component={RouteWrapper(MainDenied, route)} />
				);
			}
			else {
				return (
					<Route
						exact={!route.inexact}
						key={key}
						path={routePath}
						component={this.getWrappedRoute(route)} />
				);
			}

		});
	}


	/**
	 * Get a wrapped component instance for a route object.
	 *
	 * This enables us to use `RouteWrapper` to enable app-level 
	 * notification when the active route object changes.
	 *
	 * All wrapped routes are cached for the lifetime of the 
	 * router to ensure no wasteful instances are created 
	 * (which would cause unwanted continual remounts).
	 *
	 * @param {Object} route
	 * @return {WrappedRouteComponent}
	 */
	getWrappedRoute(route) {

		/**
		 * Have we already wrapped this route?
		 */
		const match = this.wrappedRoutes.find(r => (r.route === route));

		/**
		 * Construct the wrapper when needed
		 */
		if (!match) {
			const wrapped = RouteWrapper(route.component, route);
			this.wrappedRoutes.push({route, wrapped});
			return wrapped;
		}
		else return match.wrapped;

	}

}

export default withAuth(withFlags(Router));
