import React from "react";
import api from "api.js";
import Button from "Components/Button.js";
import Clipboard from "Helpers/Clipboard.js";
import Container from "Components/Container.js";
import Cookies from "Helpers/Cookies.js";
import withTimes from "Hoc/withTimes.js";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

/**
 * Debugg info panel
 * 
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiDebug extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container columns="max-content">
				<Button
					label={(this.props.label || "Copy to Clipboard")}
					onClick={() => Clipboard.copy(JSON.stringify(this.info, null, "\t"))}
					variant="outlined" />
			</Container>
		);
	}


	/**
	 * Format a time for our display.
	 *
	 * @param {Integer} time Unix timestamp
	 * @param {Boolean} timezone optional Use timezones (`false`)
	 * @param {Boolean} forceLondonTime optional Force to local time (`false`)
	 * @return {String}
	 */
	formatTime = (time, timezone=false, forceLondonTime=false) => {
		return this.props.formatTime(time, {timeFormat: "HH:mm:ss", friendly: false, timezone, timezoneOverride: (forceLondonTime ? "Europe/London" : null)});
	};


	/**
	 * Get our info object.
	 * 
	 * @return {Object}
	 */
	get info() {

		const ts = Math.floor((Date.now() / 1000));

		return {
			Access: this.props.Access,
			AccessReason: this.props.AccessReason,
			Api: this.props.api,
			Appv: process.env.REACT_APP_APP,
			Auth: !!this.props.auth?.token,
			AuthAccount: this.props.auth?.account,
			AuthCookie: !!Cookies.get(api.HOPS_AUTH_COOKIE),
			AuthExpiration: (this.props.auth?.token ? this.formatTime(this.props.auth?.expiration) : null),
			AuthExpirationTz: ((this.props.auth?.token && this.props.org) ? this.formatTime(this.props.auth?.expiration, true) : null),
			AuthMasquerade: !!this.props.authAdmin?.token,
			AuthMasqueradeAccount: this.props.authAdmin?.account,
			AuthMasqueradeCookie: !!Cookies.get(api.HOPS_ADMIN_MASQUERADE_COOKIE),
			AuthMasqueradeExpiration: (this.props.authAdmin?.token ? this.formatTime(this.props.authAdmin?.expiration) : null),
			AuthMasqueradeExpirationTz: ((this.props.authAdmin?.token && this.props.org) ? this.formatTime(this.props.authAdmin?.expiration, true) : null),
			CookieDisableSsns: this.props.cookieDisableSsns,
			CookieDomain: this.props.cookieDomain,
			Dev: this.props.dev,
			Flags: this.props.flags,
			HopsNewClientCookie: !!Cookies.get(api.HOPS_NEW_CLIENT_COOKIE),
			Logger: this.props.logger,
			Org: this.props.org,
			OrgAdmin: this.props.orgAdmin,
			OrgCookie: Cookies.get(api.HOPS_AUTH_ORG_COOKIE),
			Session: this.props.Session,
			Timestamp: this.formatTime(ts, false, true),
			TimestampTz: (this.props.org ? this.formatTime(ts, true, false) : null),
			UpdateAvailable: this.props.UpdateAvailable,
			Uri: `${this.props.location.pathname}${this.props.location.search}`
		};

	}

}

export default connect(state => state)(withTimes(withRouter(UiDebug)));
