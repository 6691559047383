import api from "api.js";
import Flex from "Components/Flexx.js";
import Hidden from "Components/Hidden.js";
import Link from "Components/Link.js";
import Loadable from "Components/Loadable.js";
import MenuItem from "./DepartmentMenuItem.js";
import Paper from "Components/Paper.js";
import Skeleton from "@material-ui/lab/Skeleton";
import qs from "query-string";
import useData from "Hooks/useData.js";
import scss from "./DepartmentMenu.module.scss";
import {memo, useCallback, useState} from "react";
import {useLocation} from "react-router-dom";

export default memo(props => {

	const {data} = props;
	const location = useLocation();


	/**
	 * Determine our department ID
	 */
	const departmentIdReliable = (props.departmentId || location.pathname.match("/(?:dept|departments)/([0-9]+)/?")?.[1]);
	let departmentId = parseInt((departmentIdReliable || qs.parse(location.search).d));
	if (isNaN(departmentId) || (departmentId <= 0)) departmentId = null;


	/**
	 * Is this a route where we expect to render this menu?
	 */
	const isValidPath = (!!departmentIdReliable || [
		"/index_department.php",
		"/department_details.php",
		"/department_roster_settings.php",
		"/department_staff_confirmation.php",
		"/department_stafflist.php",
		"/department_time_register.php",
		"/department_competence_settings.php",
		"/dept_competence.php",
		"/dept_noticeboard_edit.php",
		"/dept_turn_count.php",
		"/duty_hours.php",
		"/diagrams.php",
		"/documents.php",
		"/rostering_principles.php",
		"/roster_period_construct_menu.php",
		"/roster_period_new.php",
		"/roster_swaps.php",
		"/roster_unassign_turn_report.php",
		"/user_roster_menu.php",
		"/view_roster_menu.php",
		"/visits.php",
		"/visits_by_user.php"
	].includes(location.pathname));


	/**
	 * Key of the expanded menu
	 */
	const [openMenuKey, setOpenMenuKey] = useState(null);


	/**
	 * Get the department menu data
	 */
	const state = useData(useCallback(
		() => {
			if (data) {
				return data;
			}
			else if (!departmentId || !isValidPath) {
				return null;
			}
			else {
				return api.call({url: `/api/departments/${departmentId}/menu`}).then(({data}) => data);
			}
		},
		[data, departmentId, isValidPath]
	));


	/**
	 * Don't render when there's no department ID
	 */
	if ((!departmentId || !isValidPath) && !data) {
		return null;
	}


	/**
	 * Render!
	 */
	return (
		<Paper
			className={scss.root}
			px={0}
			py={0}
			square={true}>
			<Loadable
				loading={false}
				error={state.error}
				errorMessage="Error loading department information."
				errorPadding={0.5}
				noCentre={true}
				noLoader={true}
				onRetry={state.fetch}>
				<Flex className={scss.container} gap={0.25}>
					<Hidden hidden={!state.loading}>
						<Skeleton
							height={30}
							variant="text"
							width={360} />
						<Skeleton
							height={25}
							variant="rect" />
					</Hidden>
					<Hidden hidden={state.loading}>
						<Link
							bold={true}
							color="inherit"
							label={`${state.data?.Directorship.Name}: ${state.data?.Name}`}
							uri={`/dept/${state.data?.Id}`}
							variant="h5" />
						<Flex
							columnar={true}
							gap={0}
							ml={-0.5}
							wrap={true}>
							{
								(state.data?.Menu || []).map((item, key) => (
									<MenuItem
										departmentId={state.data?.Id}
										key={key}
										item={item}
										menuOpen={(key === openMenuKey)}
										onMenuOpen={() => setOpenMenuKey(key)}
										onMenuClose={() => setOpenMenuKey(null)} />
								))
							}
						</Flex>
					</Hidden>
				</Flex>
			</Loadable>
		</Paper>
	);

});
