import React from "react";
import Button from "Components/Button.js";
import UiBarTitle from "./UiBarTitle.js";
import scss from "./UiBarControl.module.scss";

export default () => (
	<Button
		className={scss.UiBarControl}
		color="secondary"
		label={UiBarTitle}
		squared={true}
		uri="/"
		variant="outlined" />
);
