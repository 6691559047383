import Reducer from "./Reducer.js";
import State from "./State.js";
import StatePersistent from "./StatePersistent.js";
import {createStore, applyMiddleware} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import {persistStore, persistReducer} from "redux-persist";
import {createStateSyncMiddleware, initMessageListener} from "redux-state-sync";
import storage from "redux-persist/lib/storage";

/**
 * `redux-persist`
 */
const rpc = {
	storage,
	key: "app",
	whitelist: Object.keys(StatePersistent)
};

/**
 * `redux-state-sync`
 */
const rss = {
	whitelist: [
		"api",
		"auth",
		"clientSettings",
		"clientSettingsLocal",
		"cookieDisableSsns",
		"cookieDomain",
		"dark",
		"dev",
		"flags",
		"logger",
		"notificationRead",
		"reset"
	],
	broadcastChannelOption: {webWorkerSupport: false}
};

/**
 * Create the store
 */
const store = createStore(
	persistReducer(rpc, Reducer),
	{...State, ...StatePersistent},
	composeWithDevTools(applyMiddleware(createStateSyncMiddleware(rss)))
);

/**
 * Create the store persistor
 */
const persistor = persistStore(store);

/**
 * Initialise synchronisation listener
 */
initMessageListener(store);

export default store;
export {store as Store, persistor as StorePersistor};
