import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Container from "Components/Container.js";
import ReactHelper from "Helpers/React.js";
import Styles from "Resources/Styles.json";
import UiBarContainer from "./UiBarContainer.js";
import UiBarHomeButton from "./UiBarHomeButton.js";
import UiBarIconStrip from "./UiBarIconStrip.js";
import UiBarLoginButton from "./UiBarLoginButton.js";
import UiBarMenuButton from "./UiBarMenuButton.js";
import UiBarOrg from "./UiBarOrg.js";
import UiBarSearchbar from "./UiBarSearchbar.js";
import UiBarTitleButton from "./UiBarTitleButton.js";
import rem from "Helpers/Rem.js";
import scss from "./UiBar.module.scss";
import withAuth from "Hoc/withAuth.js";
import withMobile from "Hoc/withMobile.js";
import {connect} from "react-redux";

/**
 * UI bar
 *
 * The main header bar.
 *
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiBar extends React.PureComponent {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Padding multiplier
		 * 
		 * @type {Float}
		 */
		const px = this.constructor.px;

		/**
		 * Method binds
		 */
		this.authedIsMobile = this.authedIsMobile.bind(this);
		this.authedNotMobile = this.authedNotMobile.bind(this);
		this.notAuthedIsMobile = this.notAuthedIsMobile.bind(this);

		/**
		 * Items
		 * 
		 * @type {Array}
		 */
		this.items = [
			{
				render: UiBarTitleButton,
				col: "max-content",
				when: () => !this.authed
			},
			{
				render: UiBarOrg,
				col: `calc(${Styles.uiDrawerWidth} - ${rem(3.5)} - ${rem(px)})`,
				when: this.authedNotMobile
			},
			{
				render: UiBarIconStrip,
				col: "max-content",
				when: this.authedIsMobile
			},
			{
				render: UiBarSearchbar,
				col: "auto",
				when: () => this.authed
			},
			{
				render: UiBarIconStrip,
				col: "max-content",
				when: this.authedNotMobile
			},
			{
				render: UiBarLoginButton,
				col: "auto",
				when: this.notAuthedIsMobile
			}
		];

	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<AppBar
				className={`${scss.uiBar} ${(this.props.isAdminAuthed ? scss.adminAuthed : "")}`}
				color="primary"
				position="fixed"
				style={this.constructor.styles}>
				<Container
					alignContent="center"
					columnar={true}
					columns={(!this.props.isMobile ? "max-content 1fr" : "1fr")}
					gap={0.5}
					px={(!this.authed ? 1 : this.constructor.px)}
					rows="75%">
					{(this.authedNotMobile() && !this.props.KioskMode && <UiBarHomeButton style={this.constructor.stylesButton} />)}
					{(this.authedNotMobile() && this.props.KioskMode && <UiBarMenuButton style={this.constructor.stylesButton} />)}
					<UiBarContainer
						gap={(((this.props.auth.orgs.length === 1) && !this.props.isMobile) ? 0 : 1)}
						style={this.stylesContainer}>
						{
							this.currentItems.map(({render}, key) => {
								return (
									<React.Fragment key={key}>
										{ReactHelper.render(render, this.props)}
									</React.Fragment>
								);
							})
						}
					</UiBarContainer>
				</Container>
			</AppBar>
		);
	}


	/**
	 * Get whether we're authenticated and on a "mobile" viewport.
	 * 
	 * @return {Boolean}
	 */
	authedIsMobile() {
		return (this.authed && this.props.isMobile);
	}


	/**
	 * Get whether we're authenticated and not on a "mobile" viewport.
	 * 
	 * @return {Boolean}
	 */
	authedNotMobile() {
		return (this.authed && !this.props.isMobile);
	}


	/**
	 * Get whether we're not authenticated and on a "mobile" viewport.
	 */
	notAuthedIsMobile() {
		return (!this.props.authed && this.props.isMobile);
	}


	/**
	 * Get whether we're authenticated.
	 *
	 * @return {Boolean}
	 */
	get authed() {
		return this.props.authedUser;
	}


	/**
	 * Get current items to render.
	 * 
	 * @return {Array}
	 */
	get currentItems() {
		return this.items.filter(i => (!i.when ? true : i.when()));
	}


	/**
	 * Styles (container).
	 * 
	 * @type {Object}
	 */
	get stylesContainer() {
		return {
			gridTemplateColumns: this.currentItems.map(i => i.col).join(" ")
		};
	}


	/**
	 * X-padding multiplier
	 * 
	 * @type {Float}
	 */
	static px = 0.5;


	/**
	 * Styles.
	 *
	 * @type {Object}
	 */
	static styles = {
		alignContent: "center",
		boxShadow: "none",
		display: "grid",
		gridAutoFlow: "column",
		gridTemplateColumns: "100%",
		gridTemplateRows: "1fr",
		height: Styles.uiBarHeight,
		maxHeight: Styles.uiBarHeight,
		justifyContent: "space-between",
		zIndex: 10000
	};

	/**
	 * Button styles.
	 * 
	 * @type {Object}
	 */
	static stylesButton = {height: "100%"};

	/**
	 * Organisation selector styles.
	 * 
	 * @type {Object}
	 */
	static stylesOrgSelect = {height: "100%"};

}

export default connect(({KioskMode}) => ({KioskMode}))(withAuth(withMobile(UiBar)));
