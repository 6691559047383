/*
 * @author HOPS
 * @copyright (c) 2024 HOPS
 */

import dayjs from "dayjs";
import useAuth from "./useAuth";

dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));

const locale = "en-GB"; // adhere to UK locale

/**
 * Date parser hook for the active org.
 * @returns {object}
 */
export const useOrgDate = () => {
	const {org} = useAuth(store => store);

	/**
	 * Get the current timezone of the org we are looking at.
	 * @returns {object}
	 */
	const orgTimezone = org?.Timezone ?? "Europe/London";

	/**
	 * Get the current date of the org we are looking at.
	 * @returns {object}
	 */
	const getOrgDate = () => dayjs(new Date()).tz(orgTimezone);

	/**
	 * Parse the date of the org into a dayjs object, or string if format argument is given.
	 * @returns {object|string}
	 */
	const parseOrgDate = (date, format) => {
		if (!date) {
			return "";
		}

		if (typeof date === "number") {
			date = dayjs(date * 1000).tz(orgTimezone); // epoch seconds to milliseconds;
		}

		let d = dayjs(date, orgTimezone).locale(locale);
		if (format) {
			d = d.format(format);
		}

		return d;
	};
	return {
		getOrgDate,
		parseOrgDate
	};
};
