import React from "react";
import Menu from "Components/Menu.js";

/**
 * Menu for library file items
 * 
 * @package HOPS
 * @subpackage Library
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LibraryFileMenu extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Menu
				{...this.props}
				items={this.items} />
		);
	}


	/**
	 * Menu items.
	 * 
	 * @return {Array}
	 */
	get items() {
		return [
			{
				label: "Rename",
				onClick: this.props.onRename,
				hidden: !this.props.showRename
			},
			{
				label: "Replace",
				onClick: this.props.onReplace,
				hidden: !this.props.showReplace
			},
			{
				label: `Move${((this.props.affectedFiles > 1) ? ` ${this.props.affectedFiles} files` : "")}`,
				onClick: this.props.onMove,
				hidden: !this.props.showMove
			},
			{
				label: `Delete${((this.props.affectedFiles > 1) ? ` ${this.props.affectedFiles} files` : "")}`,
				onClick: this.props.onDelete,
				hidden: !this.props.showDelete
			}
		];
	}

}

export default LibraryFileMenu;
