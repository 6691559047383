import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import Dialog from "Components/Dialog.js";
import Flex from "Components/Flex.js";
import LibraryService from "./LibraryService.js";
import Picker from "Components/Picker.js";
import String from "Components/String.js";
import withSnack from "Hoc/withSnack.js";

/**
 * Library file set moving dialog
 * 
 * @package HOPS
 * @subpackage Library
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LibraryFileMoveDialog extends AsyncStatefulComponent {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Selected new set
		 *
		 * @type {Object|null}
		 */
		set: null,

		/**
		 * Submitting?
		 * 
		 * @type {Boolean}
		 */
		submitting: false

	};


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if ((prevProps.open !== this.props.open) && this.props.open) {
			this.setState({submitting: false, set: null});
		}
	}


	/**
	 * Selected set changed.
	 * 
	 * @param {Object} set
	 * @return {void}
	 */
	handleSetChange = set => {
		this.setState({set});
	};


	/**
	 * Submitting.
	 * 
	 * @return {void}
	 */
	handleSubmit = () => {

		this.setState({submitting: true});

		LibraryService.moveFiles(
			this.props.set,
			this.state.set.Uuid,
			this.props.files
		).then(results => {

			if (results.length === this.props.files.length) {
				this.props.snack("Moved files.", "success");
			}
			else this.props.snack("Not all files were moved successfully.", "warning");

			this.props.onClose();
			this.props.onMoved(results, this.state.set);

		}).catch(e => {
			this.props.snack(e);
			this.setState({submitting: false});
		});

	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				loading={this.state.submitting}
				okDisabled={!this.state.set}
				onClose={this.props.onClose}
				onOk={this.handleSubmit}
				open={this.props.open}
				title={this.title}>
				<Flex gap={1.5}>
					<Picker
						getOptionLabel={o => o.Name}
						label="New Set"
						handleChange={true}
						onChange={this.handleSetChange}
						options={this.availableSets}
						placeholderLabel={true}
						shrink={true}
						value={this.state.set}
						variant="standard" />
					<String color="textSecondary" str={this.content} />
				</Flex>
			</Dialog>
		);
	}


	/**
	 * Get our available set options.
	 * 
	 * @return {Array}
	 */
	get availableSets() {
		return this.props.sets.filter(s => {
			return (s.Uuid !== this.props.set);
		}).sort((a, b) => {
			if (a.Name > b.Name) return 1;
			else if (a.Name < b.Name) return -1;
			else return 0;
		});
	}


	/**
	 * Get the content text.
	 * 
	 * @return {String}
	 */
	get content() {
		if (!this.isMultiple) return "The file will be moved into the selected set.";
		else return "All the files you've selected will be moved to your selected set.";
	}


	/**
	 * Get the title text.
	 * 
	 * @return {String}
	 */
	get title() {
		if (!this.isMultiple) return `Move file`;
		else return `Move ${this.props.files.length} files`;
	}


	/**
	 * Get whether multiple files will be moved.
	 * 
	 * @return {Boolean}
	 */
	get isMultiple() {
		return (this.props.files.length > 1);
	}

}

export default withSnack(LibraryFileMoveDialog);
