import React from "react";
import DateIoMoment from "@date-io/moment";
import History from "./History.js";
import Main from "./Main.js";
import Snackbars from "Resources/Snackbars.json";
import Store from "./Store.js";
import ThemeProvider from "Providers/ThemeProvider.js";
import moment from "moment";
import worker from "worker.js";
import {CssBaseline, withStyles} from "@material-ui/core";
import {ErrorGate} from "Components/ErrorGate.js";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {PersistGate} from "redux-persist/integration/react";
import {Provider as ReduxProvider} from "react-redux";
import {SnackbarProvider} from "@heron-web/material";
import {StorePersistor} from "./Store.js";
import {Router} from "react-router-dom";
import * as Sentry from "@sentry/react";

/**
 * HOPS
 *
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class App extends React.Component {

	/**
	 * Constructor.
	 *
	 * We configure Moment so Monday is the first day of the week.
	 *
	 * Affects e.g. Material UI Pickers calendar views.
	 *
	 * @return {self}
	 */
	constructor() {
		super();
		moment.updateLocale("en", {week: {dow: 1}});
	}


	/**
	 * Component mounting.
	 * 
	 * @return {void}
	 */
	componentDidMount() {

		/**
		 * Transition from `new.heritage-ops.org.uk`
		 *
		 * THIS SHOULD BE REMOVED ONE DAY.
		 */
		if (window.location.origin === "https://new.heritage-ops.org.uk") {

			let promise = new Promise();

			if ("serviceWorker" in navigator) {
				promise = new Promise(resolve => {
					navigator.serviceWorker.getRegistrations().then(sws => {
						for (const sw of sws) {
							sw.unregister();
						}
						resolve();
					}).catch(() => {
						resolve();
					});
				});
			}

			promise.then(() => {
				window.location.href = "https://www.hops.org.uk";
			});

		}
		else worker();

	}


	/**
	 * Component unmounting.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {
		window.removeEventListener("beforeunload", this.handleUnload);
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Sentry.ErrorBoundary fallback={<ErrorGate noUiDebug={true} />}>
				<ReduxProvider store={Store}>
					<PersistGate loading={null} persistor={StorePersistor}>
						<MuiPickersUtilsProvider utils={DateIoMoment}>
							<ThemeProvider>
								<CssBaseline />
								<SnackbarProvider {...this.notistackProps} {...Snackbars}>
									<Sentry.ErrorBoundary fallback={<ErrorGate noUiDebug={true} />}>
										<Router history={History}>
											<Main />
										</Router>
									</Sentry.ErrorBoundary>
								</SnackbarProvider>
							</ThemeProvider>
						</MuiPickersUtilsProvider>
					</PersistGate>
				</ReduxProvider>
			</Sentry.ErrorBoundary>
		);
	}


	/**
	 * Notistack configuration.
	 * 
	 * @reurn {Object}
	 */
	get notistackProps() {
		return {
			classes: {
				containerAnchorOriginBottomCenter: this.props.classes.notistackAlert
			}
		};
	}


	/**
	 * CSS classes.
	 * 
	 * @type {Object}
	 */
	static classes = {
		notistackAlert: {
			zIndex: 1000000000
		}
	};

}

export default withStyles(App.classes)(App);
