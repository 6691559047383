import api from "api.js";
import dNotifications from "Dispatchers/dNotifications.js";
import Store from "App/Store.js";

/**
 * Notification service
 *
 * @package HOPS
 * @subpackage Notifications
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class NotificationService {

	/**
	 * Get all unread notifications.
	 *
	 * @return {Promise} Resolves with the array of notification objects
	 */
	static get() {
		return api.call({url: `/api/notifications`}).then(({data}) => data);
	}


	/**
	 * Mark a notification as read by UUID.
	 *
	 * @param {String} uuid
	 * @return {Promise}
	 */
	static markRead(uuid) {
		return api.call({url: `/api/notifications/${uuid}/read`, method: "POST"});
	}


	/**
	 * Mark all unread notifications as read.
	 * 
	 * @return {Promise}
	 */
	static markAllRead() {
		return api.call({url: `/api/notifications/read`, method: "POST"});
	}


	/**
	 * Mark all unread notifications as read and remove them from the local state.
	 *
	 * @return {Promise}
	 */
	static markAllReadAndUpdateState() {

		const toDismiss = Store.getState().Notifications.filter(n => n.Dismissable).map(n => n.Uuid);

		return this.markAllRead().then(() => {
			const remaining = Store.getState().Notifications.filter(n => !toDismiss.includes(n.Uuid));
			dNotifications(remaining);
		});

	}


	/**
	 * Send a notification.
	 *
	 * Only available to admin/developer users.
	 *
	 * @param {Object} data
	 * @return {Promise}
	 */
	static send(data) {
		return api.call({url: `/api/notifications`, method: "POST", data});
	}

}

export default NotificationService;
