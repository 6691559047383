import React from "react";
import mime from "mime";
import {FileIcon as ReactFileIcon} from "react-file-icon";
import {defaultStyles as ReactFileIconStyles} from "react-file-icon";

/**
 * File icon component
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class FileIcon extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (!this.renderAsImage ? this.renderIcon() : this.renderImage());
	}


	/**
	 * Render as an icon.
	 * 
	 * @return {ReactNode}
	 */
	renderIcon() {
		return (
			<ReactFileIcon
				extension={this.extension}
				{...ReactFileIconStyles[this.extension]} />
		);
	}


	/**
	 * Render as an image.
	 * 
	 * @return {ReactNode
	 */
	renderImage() {
		return (
			<img
				alt={this.extension}
				src={this.props.src} />
		);
	}


	/**
	 * Get our file extension.
	 * 
	 * @return {String}
	 */
	get extension() {
		return mime.getExtension(this.props.mime);
	}


	/**
	 * Get whether we are a renderable image.
	 * 
	 * @return {Boolean}
	 */
	get isImage() {
		return this.constructor.imgMimes.includes(this.props.mime);
	}


	/**
	 * Get whether to render as an image thumbnail.
	 * 
	 * @return {Boolean}
	 */
	get renderAsImage() {
		return (this.props.src && this.isImage);
	}


	/**
	 * Mime types we reference as an image.
	 * 
	 * @type {Array}
	 */
	static imgMimes = ["image/jpeg", "image/png"];

}

export default FileIcon;
