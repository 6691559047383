import React from "react";
import scss from "./Select.module.scss";
import {FormControl, InputLabel, Select as MuiSelect} from "@material-ui/core";

/**
 * Select component
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Select extends React.PureComponent {

	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		if ((this.props.defaultValue !== undefined) && !this.props.value) {
			this.reportChange(this.props.defaultValue);
		}
	}


	/**
	 * Value changed.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleChange = e => {
		let val;
		const v = e.target.value;
		if (v === "") val = null;
		else if (this.props.options) val = this.props.options.find(o => (this.getItemValue(o).toString() === v));
		else val = v;
		this.reportChange(val);
	};


	/**
	 * Report value change.
	 *
	 * @param {mixed} val
	 * @return {void}
	 */
	reportChange = val => {
		this.props.onChange(val, (this.props.name || this.props.label));
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<FormControl
				margin="dense"
				size="small"
				style={this.constructor.styles}
				variant={(this.props.variant || "standard")}>
				{(this.props.label && this.renderLabel())}
				<MuiSelect
					className={scss.select}
					disabled={(this.props.disabled || this.props.loading || this.props.error)}
					MenuProps={this.constructor.MenuProps}
					native={true}
					onChange={this.handleChange}
					required={this.props.required}
					value={((![null, undefined].includes(this.props.value) && !this.props.loading && !this.props.error) ? this.getItemValue(this.props.value) : "")}
					variant={(this.props.variant || "standard")}>
					{(this.placeholder && this.renderPlaceholder())}
					{
						this.props.options?.filter(o => !o?.hidden).map((item, key) => (
							<option
								disabled={this.getItemDisabled(item)}
								key={key}
								title={this.getItemTooltip(item)}
								value={this.getItemValue(item)}>
								{this.getItemLabel(item)}
							</option>
						))
					}
					{this.props.children}
				</MuiSelect>
			</FormControl>
		);
	}


	/**
	 * Render the label.
	 * 
	 * @return {ReactNode}
	 */
	renderLabel() {
		return (
			<InputLabel
				margin="dense"
				required={this.props.required}
				shrink={true}>
				{this.props.label}
			</InputLabel>
		);
	}


	/**
	 * Render the placeholder option.
	 * 
	 * @return {ReactNode}
	 */
	renderPlaceholder() {
		return (
			<option disabled={!this.props.allowNullOption} value="">
				{this.placeholder}
			</option>
		);
	}


	/**
	 * Get whether an item shuld be disabled.
	 *
	 * @param {mixed} item
	 * @return {Boolean}
	 */
	getItemDisabled = item => {
		if (this.props.getItemDisabled) {
			return this.props.getItemDisabled(item);
		}
		else return false;
	};


	/**
	 * Get an item's label.
	 *
	 * @param {mixed} item
	 * @return {String}
	 */
	getItemLabel = item => {
		if (this.props.getItemLabel) {
			return this.props.getItemLabel(item);
		}
		else return (item?.label || item?.Name || item);
	};


	/**
	 * Get an item's tooltip.
	 * 
	 * @param {mixed} item
	 * @return {String}
	 */
	getItemTooltip = item => {
		if (this.props.getItemTooltip) {
			return this.props.getItemTooltip(item);
		}
		else return undefined;
	};


	/**
	 * Get an item's value.
	 *
	 * @param {mixed} item
	 * @return {mixed}
	 */
	getItemValue = item => {
		if (this.props.getItemValue) {
			const val = this.props.getItemValue(item);
			if (val === null) return "";
			else return val;
		}
		else return (item?.value || item?.Uuid || item?.Id || item);
	};


	/**
	 * Get the placeholder text.
	 * 
	 * @return {String}
	 */
	get placeholder() {
		if (this.props.loading) return "Loading...";
		else if (this.props.error) return "Error.";
		else return (this.props.placeholder || this.props.label);
	}


	/**
	 * `MenuProps`.
	 * 
	 * @type {Object}
	 */
	static MenuProps = {disableScrollLock: true, transitionDuration: 0};

	/**
	 * Styles.
	 * 
	 * @type {Object}
	 */
	static styles = {marginTop: 0, marginBottom: 0};

}

export default Select;
