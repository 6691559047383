/**
 * `document` global-scope browser helpers
 *
 * @package HOPS
 * @subpackage Helpers
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Document {

	/**
	 * Blur the active element when set.
	 *
	 * @return {void}
	 */
	static blur() {
		if (document.activeElement) {
			document.activeElement.blur();
		}
	}


	/**
	 * `blur()` after a timeout.
	 * 
	 * @return {void}
	 */
	static blurTimeout() {
		setTimeout(Document.blur);
	}


	/**
	 * Compute the pixel value of a `rem` value.
	 *
	 * @param {Number} rem
	 * @return {Number} Pixels
	 */
	static computeRem(rem) {
		const css = window.getComputedStyle(document.documentElement);
		return (rem * parseInt(css.fontSize));
	}


	/**
	 * Set the `document` title.
	 * 
	 * @param {String} title optional (`null`)
	 * @param {Boolean} hops optional Add "HOPS" text (`true`)
	 * @return {void}
	 */
	static setTitle(title=null, hops=true) {
		const doct = [];
		if (title) doct.push(title);
		if (hops || !title) doct.push((!title ? "HOPS Heritage Railways" : "HOPS"));
		document.title = doct.join(" - ");
	}

}

export default Document;
