import React from "react";
import {connect} from "react-redux";

/**
 * `withClientSettings` HOC wrapper to wrap a component with access to client settings
 *
 * @param {ReactComponent} Component Component to wrap
 * @return {ReactComponent} Wrapped component
 */
const withClientSettings = Component => {

	/**
	 * Class wrapper
	 *
	 * @package HOPS
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright Heritage Operations Processing Limited
	 */
	return connect(({clientSettings, ClientSettingsLocal}) => ({clientSettings, ClientSettingsLocal}))(class extends React.PureComponent {

		/**
		 * Render.
		 *
		 * @return {ReactNode}
		 */
		render() {
			return (
				<Component
					{...this.props}
					clientSettings={this.settings} />
			);
		}


		/**
		 * Get the settings.
		 *
		 * This is the networked settings overridden with local settings.
		 * 
		 * @return {Object}
		 */
		get settings() {
			return {
				...this.props.clientSettings,
				...this.props.ClientSettingsLocal
			};
		}

	});

};

export default withClientSettings;
