import Bghost from "App/Bghost.js";
import AuthService from "Auth/AuthService.js";
import dPermissions from "Dispatchers/dPermissions.js";

export default () => {
	return new Promise((resolve, reject) => {
		Bghost.register(AuthService.getUserPermissionsIndex(), null, "ui").then(perms => {
			dPermissions(perms);
			resolve();
		}).catch(reject);
	});
};
