import React from "react";
import Container from "Components/Container.js";
import Flex from "Components/Flex.js";
import LoginFormBase from "./LoginFormBase.js";
import PasswordChangeStrings from "PasswordChange/PasswordChange.strings.json";
import String from "Components/String.js";
import Strings from "./LoginForm.strings.json";
import TextField from "Components/TextField.js";
import Ul from "Components/Ul.js";
import UserAccountService from "UserAccounts/UserAccountService.js";

/**
 * Login form - password change
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LoginFormPwc extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<LoginFormBase
				disabled={this.props.disabled}
				footer={this.constructor.renderFooter()}
				noFullWidthSubmit={this.props.noFullWidthSubmit}
				noMarginBottomOnControls={this.props.noMarginBottomOnControls}
				noTitle={this.props.noTitle}
				linkLabel="Cancel Login"
				onLinkClick={this.props.onWantsLoginCancel}
				sticky={this.props.sticky}
				str={Strings.caption.pwc}
				submitLabel="Change Password"
				title="Time to Change Your Password">
				<TextField
					autoFocus={true}
					disabled={this.props.disabled}
					error={(this.newPasswordError !== null)}
					helperText={this.newPasswordError}
					inputProps={UserAccountService.passwordInputRulesProps}
					inputRef={this.props.passwordNewRef}
					label="New Password"
					name="passwordNew"
					onChange={this.props.onChange}
					placeholder="New Password"
					required={true}
					shrink={true}
					type="password"
					value={this.props.passwordNew}
					variant="standard" />
				<TextField
					disabled={this.props.disabled}
					error={(this.newPasswordConfirmError !== null)}
					helperText={this.newPasswordConfirmError}
					inputProps={UserAccountService.passwordInputRulesProps}
					inputRef={this.props.passwordNewConfirmRef}
					label="New Password (Retype)"
					name="passwordNewConfirm"
					onChange={this.props.onChange}
					placeholder="New Password"
					required={true}
					shrink={true}
					type="password"
					value={this.props.passwordNewConfirm}
					variant="standard" />
				{(this.hasSecurityQuestions && this.renderSecurityQuestions())}
			</LoginFormBase>
		);
	}


	/**
	 * Render security questions.
	 * 
	 * @return {ReactNode}
	 */
	renderSecurityQuestions() {
		return (
			<Flex>
				<String str="Security Questions" />
				{
					Object.keys((this.props.securityQuestions || {})).map((s, key) => {

						const id = s.replace("Q", "");
						const sq = this.props.securityQuestions[s];

						if (!sq) return null;

						return (
							<TextField
								autoComplete="off"
								disabled={this.props.disabled}
								error={this.props.securityQuestionsError}
								helperText={(this.props.securityQuestionsError ? "Please check your security question answers." : undefined)}
								inputRef={((key === 0) ? this.props.securityQuestionsInputRef : undefined)}
								key={key}
								label={sq.Text}
								multilineLabel={true}
								name={`sq${id}`}
								onChange={this.props.onChange}
								placeholder={`Security Question ${(key + 1)}`}
								required={true}
								shrink={true}
								value={this.props[`sq${id}`]}
								varchar={true}
								variant="standard" />
						);

					})
				}
			</Flex>
		);
	}


	/**
	 * Get whether we have security questions needing response.
	 * 
	 * @return {Boolean}
	 */
	get hasSecurityQuestions() {
		return Object.values((this.props.securityQuestions || {})).some(sq => sq);
	}


	/**
	 * New password field error text.
	 *
	 * @return {String|null}
	 */
	get newPasswordError() {
		if (this.props.passwordNew === this.props.password) {
			return PasswordChangeStrings.errors.PASSWORD_NOT_CHANGED;
		}
		else return null;
	}


	/**
	 * New password (confirmation) field error text.
	 *
	 * @return {String|null}
	 */
	get newPasswordConfirmError() {
		if (this.props.passwordNewConfirm !== this.props.passwordNew) {
			if (this.props.passwordNewConfirm) {
				return PasswordChangeStrings.errors.PASSWORD_CONFIRM_MISMATCH;
			}
		}
		return null;
	}


	/**
	 * Render the footer.
	 * 
	 * @return {ReactNode}
	 */
	static renderFooter() {
		return (
			<Container>
				<String bold={true} str={PasswordChangeStrings.rulesHeading} />
				<Ul items={PasswordChangeStrings.rules} />
			</Container>
		);
	}

}

export default LoginFormPwc;
