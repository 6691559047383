import React from "react";
import HelpIcon from "@material-ui/icons/Help";
import IconButton from "Components/IconButton.js";
import Menu from "Components/Menu.js";
import scss from "./UiBar.module.scss";

/**
 * Help button for the `UiBar`
 * 
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiBarIconStripHelpButton extends React.PureComponent {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Menu anchor
		 *
		 * @type {HTMLElement|null}
		 */
		menuAnchor: null,

		/**
		 * Menu open?
		 * 
		 * @type {Boolean}
		 */
		menuOpen: false

	};


	/**
	 * Menu opened.
	 * 
	 * @return {void} 
	 */
	handleMenuOpen = e => {
		this.setState({
			menuAnchor: e.currentTarget,
			menuOpen: true
		});
	};


	/**
	 * Menu closed.
	 * 
	 * @return {void}
	 */
	handleMenuClose = () => {
		this.setState({
			menuOpen: false
		});
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<>
				<IconButton
					className={scss.iconButton}
					color="secondary"
					icon={HelpIcon}
					onClick={this.handleMenuOpen}
					title="Help" />
				<Menu
					anchorEl={this.state.menuAnchor}
					onClose={this.handleMenuClose}
					items={this.constructor.menuItems}
					open={this.state.menuOpen}
					transitionDuration={0} />
			</>
		);
	}


	/**
	 * Menu items.
	 * 
	 * @type {Array}
	 */
	static menuItems = [
		{
			label: "Helpdesk",
			uri: "/helpdesk.php"
		},
		{
			label: "Terms of Use, Security, GDPR",
			uri: "/terms.php"
		},
		{
			label: "Cookies",
			uri: "/cookies.php"
		},
		{
			label: "Privacy",
			uri: "/privacy.php"
		},
		{
			label: "Contact Us",
			uri: "/contact.php"
		}
	];

}

export default UiBarIconStripHelpButton;
