import React from "react";
import Container from "Components/Container.js";
import Dialog from "Components/Dialog.js";
import Link from "Components/Link.js";
import String from "Components/String.js";
import Strings from "./OrgSelection.strings.json";
import OrgSelectionOrg from "./OrgSelectionOrg.js";
import {Divider} from "@material-ui/core";

/**
 * Organisation selection dialog
 *
 * @package HOPS
 * @subpackage OrgSelection
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class OrgSelectionDialog extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				disabled={true}
				hideDefaultActions={true}
				onClose={this.props.onClose}
				open={this.props.open}
				size="md"
				title={Strings.title}>
				<Container gap={2} mb={1}>
					{this.hasOrgs ?
						<>
							{this.renderOrgs()}
							<Divider />
							<String str={Strings.caption} />
						</> :
						this.renderEmpty()
					}
				</Container>
			</Dialog>
		);
	}


	/**
	 * Render empty state.
	 * 
	 * @return {ReactNode}
	 */
	renderEmpty() {
		return (
			<Container>
				<Link label="Cancel Login" onClick={this.props.onClose} />
				<String str={Strings.empty} />
			</Container>
		);
	}


	/**
	 * Render organisations.
	 * 
	 * @return {ReactNode}
	 */
	renderOrgs() {
		return (
			<Container
				fullCentre={true}
				style={this.constructor.orgContainerStyles}>
				{
					this.orgs.map((org, key) => {
						return (
							<OrgSelectionOrg
								onClick={this.props.onSelect}
								org={org}
								key={key} />
						);
					})
				}
			</Container>
		);
	}


	/**
	 * Get whether we've organisations to render.
	 *
	 * @return {Boolean}
	 */
	get hasOrgs() {
		return !!this.orgs.length;
	}


	/**
	 * Get organisations to render.
	 *
	 * We only want users where the org is working (#242).
	 *
	 * @return {Array}
	 */
	get orgs() {
		return this.props.orgs?.filter(o => o?.UserWorking);
	}


	/**
	 * Organisations container styles.
	 * 
	 * @type {Object}
	 */
	static orgContainerStyles = {
		gridAutoRows: "18rem",
		gridTemplateColumns: "repeat(auto-fit, minmax(18rem, 1fr))"
	};

}

export default OrgSelectionDialog;
