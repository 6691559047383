import axios from "axios";
import moment from "moment";
import CheckAuthExpirationTask from "Tasks/CheckAuthExpirationTask.js";
import Store from "App/Store.js";
import dAccess from "Dispatchers/dAccess.js";
import dReset from "Dispatchers/dReset.js";

/**
 * API class
 *
 * An abstraction around Axios to handle HOPS API interactions.
 *
 * @package HOPS
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Api {

	/**
	 * API origin
	 */
	static API_ORIGIN = (
		window.location.hostname.endsWith("nwops.co.uk") ?
			"nwops.co.uk" :
			(
				window.location.hostname.endsWith("hops.org.au") ?
					"hops.org.au" :
					"hops.org.uk"
			)
	);

	/**
	 * API origin endpoint
	 */
	static API_ORIGIN_ENDPOINT = `api.${this.API_ORIGIN}`;

	/**
	 * Cookie name to use when authenticating
	 */
	static HOPS_AUTH_COOKIE = "_HOPS_AUTH_";

	/**
	 * Cookie name to use when setting organisation ID
	 */
	static HOPS_AUTH_ORG_COOKIE = "_HOPS_ACTIVE_ORG_ID_";

	/**
	 * Cookie name to self-declare as New HOPS client
	 */
	static HOPS_NEW_CLIENT_COOKIE = "_HOPS_NEW_CLIENT_";

	/**
	 * Cookie name to set when admin masquerading
	 */
	static HOPS_ADMIN_MASQUERADE_COOKIE = "_HOPS_ADMIN_MASQUERADE_TOKEN_";


	/**
	 * Make an API call (uses Axios).
	 *
	 * The Axios instance is constructed by calling `axios()`.
	 * 
	 * @param {Object} request Request object for Axios
	 * @param {Boolean} autoHandleAuthErrors optional (`true`)
	 * @return {Promise} Axios request promise
	 */
	static call(request, autoHandleAuthErrors=true) {

		/**
		 * Axios instance
		 */
		const axios = this.axios();

		/**
		 * Apply interceptors
		 */
		if (autoHandleAuthErrors) {
			const interceptors = this.interceptors;
			axios.interceptors.response.use(interceptors.response, interceptors.error);
		}

		/**
		 * Make the request
		 */
		return axios.request(request);

	}


	/**
	 * Create a new Axios instance with our configuration.
	 *
	 * Uses the base given by `base` and the headers from `headers`.
	 *
	 * We will be authenticated when authentication is available.
	 *
	 * @return {Object}
	 */
	static axios() {
		return axios.create({
			baseURL: this.base,
			headers: this.headers,
			timeout: 60000,
			withCredentials: true
		});
	}


	/**
	 * Get our API base URI.
	 *
	 * The origin is given by `originApi`.
	 *
	 * The scheme always matches our current scheme when not in the origin.
	 *
	 * @return {String}
	 */
	static get base() {
		const api = this.originApi;
		if (api.includes("://")) return api;
		else return `${this.scheme}://${this.originApi}`;
	}


	/**
	 * Define the headers to apply to current requests.
	 * 
	 * @return {Object}
	 */
	static get headers() {

		const state = Store.getState();
		const admin = state.authAdmin.token;
		const auth = state.auth.token;
		const org = state.org;
		const session = state.Session;

		const headers = {
			"Content-Type": "application/json",
			"Hops-Is-New-Client": true,
			"Hopsa-Session": session
		};

		/**
		 * Authentication
		 */
		if (auth) {
			headers["Hops-Auth"] = auth;
			if (org) {
				headers["Hops-Active-Org-Id"] = org;
				if (admin) {
					headers["Hops-Admin-Masquerade-Token"] = admin;
				}
			}
		}

		return headers;

	}


	/**
	 * Define the interceptors to apply to current requests.
	 * 
	 * @return {Object}
	 */
	static get interceptors() {

		/**
		 * Cache this so we know the org at the time the request originated
		 */
		const org = Store.getState().org;

		/**
		 * Cache this so we know the page URI at the time the request originated
		 */
		const currentUri = `${window.location.pathname}${window.location.search}`;

		/**
		 * Generate our interceptors now
		 */
		return {

			/**
			 * Response handler
			 *
			 * @param {Object} response
			 * @return {Object}
			 */
			response(response) {
				return response;
			},

			/**
			 * Error handler
			 *
			 * @param {Error} error
			 * @return {Error}
			 */
			error(error) {

				/**
				 * When we get a Forbidden response, handle it now
				 */
				if (error?.response?.status === 403) {

					/**
					 * Determine whether authentication has expired
					 */
					const admin = Store.getState().authAdmin?.token;
					const authExpired = !CheckAuthExpirationTask(false);
					const authAdminExpired = (admin && !CheckAuthExpirationTask(false, true));

					/**
					 * HOPS globally returns a JSON body:
					 *
					 * ```json
					 * {
					 *     "hops.auth": {
					 *         "at": false,	// Valid auth token presented
					 *         "pid": 123,	// Operation's permission ID
					 *         "pvar": 999	// Operation's permission variable
					 *     }
					 * }
					 * ```
					 * 
					 * Therefore if we have a response that looks like 
					 * this and `at` is `false`, the server considers 
					 * our token to be invalid or expired, so the 
					 * best option is to force a logout as we have 
					 * no way of getting back to a good state and 
					 * users can end up being confused and bombarded 
					 * by misleading access denied messages (#285).
					 *
					 * If authentication has not expired, we may 
					 * able to inform the user of the permission details.
					 */
					const hopsAuthData = error?.response?.data?.["hops.auth"];

					/**
					 * Work out course of action now (see `hopsAuthData`)
					 */
					if ((hopsAuthData?.at !== false) && !(authExpired || authAdminExpired)) {

						if (hopsAuthData?.at) {
							dAccess(
								false,
								{
									pid: hopsAuthData?.pid,
									pvar: hopsAuthData?.pvar,
									org: (hopsAuthData?.org || org),
									request: error.request.url,
									response: error.response.status,
									uri: currentUri,
									timestamp: (new moment()).format("DD/MM/YYYY HH:mm:ss")
								}
							);
						}

					}
					else dReset(true);

				}

				return Promise.reject(error);

			}

		};

	}


	/**
	 * Get our current window origin.
	 * 
	 * @return {String}
	 */
	static get origin() {
		return window.location.origin.split("://")[1];
	}


	/**
	 * Get the API origin to use.
	 *
	 * This uses the value in our Redux store when set.
	 * 
	 * Otherwise, we use `API_ORIGIN_ENDPOINT`. This should exclude the scheme.
	 *
	 * @return {String}
	 */
	static get originApi() {
		const stored = Store.getState().api;
		return (stored || this.API_ORIGIN_ENDPOINT);
	}


	/**
	 * Get our `domain` to use for cookies.
	 *
	 * This uses the value in our Redux store when set.
	 *
	 * Otherwise, we default to `API_ORIGIN`.
	 * 
	 * @return {String}
	 */
	static get cookieDomain() {
		const stored = Store.getState().cookieDomain;
		return (stored || this.API_ORIGIN);
	}


	/**
	 * Get our current window scheme.
	 * 
	 * @return {String}
	 */
	static get scheme() {
		return window.location.origin.split("://")[0];
	}

}

export default Api;
