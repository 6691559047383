import React from "react";
import EmptyState from "./EmptyState.js";
import Flex from "./Flex.js";
import Link from "./Link.js";
import Loader from "./Loader.js";
import String from "./String.js";

/**
 * A component for managing loading/error states
 *
 * The children are rendered only when not loading and not errored.
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Loadable extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		if (this.props.loading) {
			return (
				!this.props.noLoader ?
					<Loader
						disableCentre={this.props.noCentre}
						size={(this.props.loaderSize || 25)} /> :
					null
			);
		}
		else if (this.props.error) {
			return (
				<Flex alignItems={(!this.props.noCentre ? "center" : undefined)} gap={0.5} px={this.props.errorPadding} py={this.props.errorPadding}>
					<String color="error" str={(this.props.errorMessage || "Error.")} />
					{(this.props.onRetry && <Link label="Retry" onClick={this.props.onRetry} />)}
				</Flex>
			);
		}
		else if (this.props.empty) {
			return <EmptyState message={this.props.emptyMessage} />;
		}
		else return this.props.children;
	}

}

export default Loadable;
