import React from "react";
import Flex from "Components/Flexx.js";
import Picker from "Components/Picker.js";
import String from "Components/Stringx.js";
import UserAvatar from "Components/UserAvatar.js";

/**
 * User picker component
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UserPicker extends React.PureComponent {

	/**
	 * Get whether an option should be selected.
	 *
	 * @param {Object} opt
	 * @param {mixed} v
	 * @return {Boolean}
	 */
	static getOptionSelected = (opt, v) => ((opt === v) || (opt?.Id === v?.Id));


	/**
	 * User selected.
	 *
	 * @param {Event} e
	 * @param {Object} user
	 */
	handleChange = (e, user) => {
		this.props.onChange(user, ((this.props.name !== undefined) ? this.props.name : this.props.label));
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Picker
				autoFocus={this.props.autoFocus}
				className={this.props.className}
				disabled={this.props.disabled}
				error={this.props.error}
				filterOptions={(this.props.controlled ? options => options : undefined)}
				getOptionDisabled={this.props.getOptionDisabled}
				getOptionLabel={this.getOptionLabel}
				getOptionSelected={this.constructor.getOptionSelected}
				helperText={this.props.helperText}
				label={this.props.label}
				loading={this.props.loading}
				loadingText={this.props.loadingText}
				multiple={this.props.multiple}
				noOptionsText={this.props.noOptionsText}
				onChange={this.handleChange}
				onInputChange={this.props.onInputChange}
				onOpen={this.props.onOpen}
				options={this.props.users}
				placeholder={this.props.placeholder}
				placeholderLabel={this.props.placeholderLabel}
				renderOption={this.renderOption}
				required={this.props.required}
				shrink={this.props.shrink}
				size={this.props.size}
				style={this.props.style}
				value={this.props.value}
				variant={this.props.variant} />
		);
	}


	/**
	 * Render a user option.
	 *
	 * @param {Object} user
	 * @return {ReactNode}
	 */
	renderOption = user => {
		return (
			<Flex columnar={true}>
				<UserAvatar
					dims="3rem"
					small={true}
					surname={this.props.surnameFirst}
					user={user} />
				<div>
					<String content={this.getOptionLabel(user)} />
					{(this.props.getOptionCaption && <String color="textSecondary" content={this.props.getOptionCaption(user)} variant="caption" />)}
					{(!!user.Orgs?.length && this.props.showUsersOrgsWhenAvailable && this.constructor.renderOptionOrgs(user.Orgs))}
				</div>
			</Flex>
		);
	};


	/**
	 * Get user option label.
	 * 
	 * @param {String} user
	 * @return {String}
	 */
	getOptionLabel = user => {
		if (this.props.surnameFirst) {
			return `${(user.Account?.Sname || user.Sname)}; ${(user.Account?.Fname || user.Fname)}`;
		}
		else return `${(user.Account?.Fname || user.Fname)} ${(user.Account?.Sname || user.Sname)}`;
	};


	/**
	 * Render orgs for a user option.
	 * 
	 * @param {Array} orgs
	 * @return {ReactNode}
	 */
	static renderOptionOrgs(orgs) {

		const label = orgs.map(org => org.NameShort).join(" • ");

		return (
			<String
				color="textSecondary"
				content={label}
				variant="caption" />
		);

	}

}

export default UserPicker;
