import React from "react";
import Container from "Components/Container.js";
import IconButton from "Components/IconButton.js";
import String from "Components/String.js";
import scss from "./Alert.module.scss";
import {Alert as MuiAlert} from "@material-ui/lab";
import {ExpandLess, ExpandMore} from "@material-ui/icons";

/**
 * Alert component
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Alert extends React.PureComponent {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 * 
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Collapsed?
			 * 
			 * @type {Boolean}
			 */
			collapsed: (props.collapsible && props.initialCollapsed)

		};

	}


	/**
	 * Component updated.
	 * 
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.initialCollapsed !== this.props.initialCollapsed) {
			if (this.props.collapsible) {
				this.setState({collapsed: this.props.initialCollapsed});
			}
		}
	}


	/**
	 * Toggle collapsed status.
	 * 
	 * @return {void}
	 */
	toggleCollapsed = () => this.setState({collapsed: !this.state.collapsed});


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<MuiAlert
				className={this.className}
				closeText={this.props.closeText}
				icon={this.props.icon}
				onClose={this.props.onClose}
				severity={(this.props.variant || "info")}
				variant={this.props.variantv}>
				<Container
					alignItems="center"
					columns={`1fr ${(this.props.collapsible ? "max-content" : "")}`}
					mb={(this.props.dense && -0.25)}>
					<String bold={true} str={this.props.title} />
					{(this.props.collapsible && this.renderCollapse())}
				</Container>
				<Container
					display={(this.state.collapsed && "none")}
					fullWidth={true}
					mt={this.constructor.gap}>
					{(this.props.children || this.renderContent())}
				</Container>
			</MuiAlert>
		);
	}


	/**
	 * Render the collapse button.
	 * 
	 * @return {ReactNode}
	 */
	renderCollapse() {
		return (
			<IconButton
				color="inherit"
				dimensions="2.25rem"
				icon={(this.state.collapsed ? ExpandMore : ExpandLess)}
				onClick={this.toggleCollapsed}
				size="small"
				title={(this.state.collapsed ? "Expand" : "Collapse")} />
		);
	}


	/**
	 * Render content text.
	 * 
	 * @return {ReactNode}
	 */
	renderContent() {
		return (
			<String
				ContainerProps={this.constructor.ContainerProps}
				str={this.props.content} />
		);
	}


	/**
	 * Get our `className`.
	 * 
	 * @return {String}
	 */
	get className() {
		const className = [scss.alert, this.props.className];
		if (this.props.dense) className.push(scss.dense);
		return className.join(" ");
	}


	/**
	 * Gap between items.
	 * 
	 * @type {Float} Theme spacing multiplier
	 */
	static gap = 0.5;

	/**
	 * Title styles.
	 * 
	 * @type {Object}
	 */
	static titleStyles = {fontWeight: "bold"};

	/**
	 * `ContainerProps`
	 * 
	 * @type {Object}
	 */
	static ContainerProps = {gap: this.gap};

}

export default Alert;
