import React from "react";
import {withWidth} from "@material-ui/core";

/**
 * `withMobile()` HOC wrapper to wrap a component with `ComponentWithMobile`
 * 
 * @param {ReactComponent} Component Component to wrap
 * @return {ReactComponent} Wrapped component with `isMobile`
 */
const withMobile = Component => {

	/**
	 * Class wrapper
	 *
	 * @package HOPS
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright Heritage Operations Processing Limited
	 */
	const cwm = class ComponentWithMobile extends React.PureComponent {

		/**
		 * Render.
		 * 
		 * @return {ReactNode}
		 */
		render() {
			return (
				<Component
					bp={this.props.width}
					bpi={this.bpi}
					bpGt={this.bpGt}
					isMobile={!this.bpGt("md")}
					{...this.props} />
			);
		}


		/**
		 * Get whether we're above a given breakpoint.
		 * 
		 * @param {String} bp
		 * @return {void}
		 */
		bpGt = bp => {
			return (this.bpi >= this.constructor.bps.indexOf(bp));
		};


		/**
		 * Get our breakpoint index.
		 * 
		 * @return {Integer}
		 */
		get bpi() {
			return this.constructor.bps.indexOf(this.props.width);
		}


		/**
		 * Breakpoints.
		 *
		 * @type {Array}
		 */
		static bps = ["xs", "sm", "md", "lg", "xl"];

	};

	return withWidth()(cwm);

};

export default withMobile;
