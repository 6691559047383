import React from "react";
import Container from "Components/Container.js";
import Loader from "Components/Loader.js";

export default ({viewport}) => (
	<Container
		alignContent={(viewport && "center")}
		fullCentre={viewport}
		justifyContent={(viewport && "center")}
		px={(viewport && 1)}
		pt={1}
		pb={(viewport && 4)}
		viewport={viewport}>
		<Loader />
	</Container>
);
