import React from "react";
import Container from "Components/Container.js";
import HopsOrgEmergencyNotice from "./HopsOrgEmergencyNotice.js";
import HopsOrgNoticeboardNotice from "./HopsOrgNoticeboardNotice.js";
import HopsSystemNotice from "./HopsSystemNotice.js";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

/**
 * HOPS Notices container
 *
 * @package HOPS
 * @subpackage NoticesHops
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class HopsNotices extends React.PureComponent {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Notices
		 *
		 * @type {Object|null}
		 */
		notices: this.notices

	};

	/**
	 * Timer ID
	 * 
	 * @return {void}
	 */
	timer = null;


	/**
	 * Update notices to exclude expired ones.
	 * 
	 * @return {void}
	 */
	checkExpired = () => {
		if (Object.keys((this.state.notices || {})).length) {
			this.setState({notices: this.notices});
		}
	};


	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		this.timer = setInterval(this.checkExpired, 60000);
	}


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		const route = (this.props.location.pathname !== prevProps.location.pathname);
		if ((prevProps.notices !== this.props.notices) || route) {
			this.setState({notices: this.notices});
		}
	}


	/**
	 * Component unmounting.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {
		if (this.timer) clearTimeout(this.timer);
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return ((Object.entries((this.state.notices || {})).length && this.shouldRender) ? this.renderNotices() : null);
	}


	/**
	 * Render the notices.
	 * 
	 * @return {ReactNode}
	 */
	renderNotices() {
		return (
			<Container>
				{(this.state.notices.HopsSystemNotice && <HopsSystemNotice content={this.state.notices.HopsSystemNotice.Notice} title={this.state.notices.HopsSystemNotice.NoticeTitle} />)}
				{(this.state.notices.OrgEmergencyNotice && <HopsOrgEmergencyNotice content={this.state.notices.OrgEmergencyNotice.Notice} initialCollapsed={(this.props.location.pathname !== "/")} />)}
				{(this.state.notices.OrgNoticeboardNotice && <HopsOrgNoticeboardNotice content={this.state.notices.OrgNoticeboardNotice.Notice} />)}
			</Container>
		);
	}


	/**
	 * Get whether we have notices to render.
	 * 
	 * @return {Boolean}
	 */
	get hasNotices() {
		if (!this.props.notices) return false;
		else return !!Object.values((this.props.notices || {})).length;
	}


	/**
	 * Get the notices to render.
	 * 
	 * @return {Array}
	 */
	get notices() {
		if (this.hasNotices) {
			const notices = {};
			const time = Math.floor((Date.now()) / 1000);
			for (const [key, notice] of Object.entries(this.props.notices)) {
				const route = ((this.props.location.pathname === "/") || notice?.GlobalUi);
				const expiry = (!notice?.Expiry || (time <= notice?.Expiry));
				if (route && expiry && notice) {
					notices[key] = notice;
				}
			}
			return notices;
		}
		else return null;
	}


	/**
	 * Get whether we should render.
	 *
	 * We do not render if we're on the homescreen, unless we have 
	 * the `forceRendering` prop, as the homescreen renders the 
	 * component customly (using `forceRendering`) - see #206.
	 * 
	 * @return {Boolean}
	 */
	get shouldRender() {
		return ((this.props.location.pathname !== "/") || this.props.forceRendering);
	}

}

export default connect(({NoticesHops}) => ({notices: NoticesHops}))(withRouter(HopsNotices));
