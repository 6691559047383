import React from "react";
import dAuthAdminClear from "Dispatchers/dAuthAdminClear.js";
import dLogout from "Dispatchers/dLogoutDialog.js";
import IconButton from "Components/IconButton.js";
import Menu from "Components/Menu.js";
import Tasker from "App/Tasker.js";
import scss from "./UiBar.module.scss";
import withAuth from "Hoc/withAuth.js";
import withSnack from "Hoc/withSnack.js";
import {connect} from "react-redux";
import {AccountCircle as UserIcon} from "@material-ui/icons";

/**
 * User button for the `UiBar`
 *
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiBarIconStripUserButton extends React.PureComponent {

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Menu Anchor element
		 * 
		 * @type {HTMLElement|null}
		 */
		menuAnchor: null,

		/**
		 * Menu open?
		 *
		 * @type {Boolean}
		 */
		menuOpen: false

	};


	/**
	 * Menu opened.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleMenuOpen = e => {
		this.setState({
			menuAnchor: e.currentTarget,
			menuOpen: true
		});
	};


	/**
	 * Close the menu.
	 * 
	 * @return {void}
	 */
	handleMenuClose = () => {
		this.setState({menuOpen: false});
	};


	/**
	 * Task run wanted.
	 * 
	 * @return {void}
	 */
	handleTasker = async () => {
		await Tasker.run(true);
		this.props.snack("Refreshed your session.", "success");
	};


	/**
	 * Render.
	 * 
	 * @retrun {ReactNode}
	 */
	render() {
		return <>
			<IconButton
				className={scss.iconButton}
				color="secondary"
				icon={UserIcon}
				onClick={this.handleMenuOpen}
				title={`${(!this.props.isAdminAuthed ? "Me" : this.props.userAccount?.Name)} (U:${this.props.org?.UserId} A:${this.props.userAccount?.Id} R:${this.props.org?.Id})`} />
			<Menu
				anchorEl={this.state.menuAnchor}
				onClose={this.handleMenuClose}
				items={this.menuItems.filter(m => !m.hidden)}
				open={this.state.menuOpen}
				transitionDuration={0} />
		</>;
	}


	/**
	 * Menu items.
	 * 
	 * @type {Object}
	 */
	get menuItems() {
		return [
			{
				label: `My ${this.props.org?.NameShort} Details`,
				uri: "/user"
			},
			{
				label: `My HOPS Account`,
				uri: `/user/account`
			},
			{
				label: `Developer Tools`,
				uri: "/f12",
				hidden: !this.props.dev
			},
			{
				label: `Raildays UI`,
				uri: "/raildays",
				hidden: !this.props.dev
			},
			{
				disabled: this.props.ActiveTaskRun,
				label: `Refresh Session`,
				onClick: this.handleTasker
			},
			{
				label: "Logout",
				onClick: dLogout
			},
			{
				label: "Exit Admin Login",
				onClick: dAuthAdminClear,
				hidden: !this.props.isAdminAuthed
			}
		];
	}

}

export default connect(({dev, ActiveTaskRun}) => ({dev, ActiveTaskRun}))(withAuth(withSnack(UiBarIconStripUserButton)));
