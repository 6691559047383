import React from "react";
import Dialog from "Components/Dialog.js";
import String from "Components/String.js";
import Strings from "./PasswordChangeInvalidDialog.strings.json";

/**
 * Password change invalid dialog
 *
 * @package HOPS
 * @subpackage PasswordChange
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class PasswordChangeInvalidDialog extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				onClose={this.props.onClose}
				open={this.props.open}
				title="Password invalid">
				<String color="textSecondary" str={Strings} />
			</Dialog>
		);
	}

}

export default PasswordChangeInvalidDialog;
