import api from "api.js";

/**
 * Chat service
 *
 * @package HOPS
 * @subpackage Chat
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class ChatService {

	/**
	 * Get whether the user can correspond with a given user.
	 *
	 * @param {Integer} user ID
	 * @return {Promise} Resolves with `boolean`
	 */
	static checkCanCorrespond(user) {
		return api.call({
			url: `/api/chat/messageable/${user}`
		}).then(({data}) => !!data);
	}

	/**
	 * Get a conversation between the user and another user.
	 *
	 * Messages will be sorted newest first.
	 *
	 * @param {Integer} User ID
	 * @param {Integer} MaxTimestamp optional Get messages older than this
	 * @param {Integer} MinTimestamp optional Get messages newer than this
	 * @param {Boolean} MarkRead optional Mark conversation read (`false`)
	 * @param {Integer} Limit optional Messages to get (25)
	 * @return {Promise}
	 */
	static getConversation(User, MaxTimestamp=null, MinTimestamp=null, MarkRead=false, Limit=25) {
		return api.call({
			url: `/api/chat/${User}`,
			params: {MaxTimestamp, MinTimestamp, Limit, MarkRead: (MarkRead ? 1 : 0)}
		}).then(({data}) => data);
	}


	/**
	 * Get the conversations a user is involved in.
	 * 
	 * Most recently updated conversations will be first.
	 * 
	 * @return {Promise}
	 */
	static getConversations() {
		return api.call({url: `/api/chat`}, false).then(({data}) => data);
	}


	/**
	 * Get the users the user can message.
	 * 
	 * @return {Promise}
	 */
	static getMessageableUsers() {
		return api.call({url: `/api/chat/messageable`}).then(({data}) => data);
	}


	/**
	 * Send a message between the user and another user.
	 * 
	 * @param {Integer} User ID
	 * @param {String} Message
	 * @return {Promise}
	 */
	static send(User, Message) {
		return api.call({url: `/api/chat`, data: {User, Message}, method: "POST"}).then(({data}) => data);
	}


	/**
	 * Mark the user's conversation with another user as read.
	 *
	 * @param {Integer} User ID
	 * @return {Promise}
	 */
	static read(User) {
		return api.call({url: `/api/chat/${User}/read`, method: "POST"});
	}


	/**
	 * Delete the user's conversation with another user.
	 *
	 * Deletes from the user's perspective only!
	 *
	 * @param {Integer} User ID
	 * @return {Promise}
	 */
	static delete(User) {
		return api.call({url: `/api/chat/${User}`, method: "DELETE"});
	}

}

export default ChatService;
