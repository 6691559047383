import React from "react";
import LoginForm from "./LoginForm.js";
import Styles from "Resources/Styles.json";
import withMobile from "Hoc/withMobile.js";

/**
 * Login view
 *
 * @package HOPS
 * @subpackage Login
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LoginView extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (!this.props.isMobile ? null : this.renderLogin());
	}


	/**
	 * Render login form.
	 * 
	 * @return {ReactNode}
	 */
	renderLogin() {
		return (
			<LoginForm
				noFullWidthSubmit={true}
				noMarginBottomOnControls={true}
				noTitle={this.props.noTitle}
				sticky={Styles.uiBarHeight} />
		);
	}

}

export default withMobile(LoginView);
