/**
 * Methods to interact with events
 *
 * @package HOPS
 * @subpackage Includes
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class EventSwallower {

	/**
	 * Swallow an event.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	static swallow(e) {
		e.preventDefault();
	}

}

export default EventSwallower;
