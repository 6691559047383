import Store from "App/Store.js";

/**
 * Logger
 *
 * Emits only when `logger` is truthy in our Redux store.
 *
 * @package HOPS
 * @subpackage Includes
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Logger {

	/**
	 * Log a message.
	 *
	 * @param {String} msg
	 * @return {void}
	 */
	static log(msg) {
		if (Store.getState().logger) {
			// eslint-disable-next-line no-console
			console.log(`${msg}`);
		}
	}

}

export default Logger;
