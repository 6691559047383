/**
 * Permissions query result
 *
 * Represents data returned by `GET` `/api/auth/permissions/mine/has`.
 *
 * @package HOPS
 * @subpackage Auth
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class PermissionsQueryResult {

	/**
	 * Constructor.
	 *
	 * @param {Array} data API data
	 * @return {self}
	 */
	constructor(data) {

		/**
		 * API data
		 *
		 * @type {Array}
		 */
		this.data = data;

	}


	/**
	 * Get whether a given permission ID/permission variable 
	 * is authenticated within the dataset.
	 *
	 * @param {Integer} pid
	 * @param {Integer} pvar optional (`0`)
	 * @return {Boolean}
	 */
	has(pid, pvar=0) {
		return !!this.data.find(p => ((p.Pid === pid) && (p.Pvar === pvar)))?.Authed;
	}

}

export default PermissionsQueryResult;
