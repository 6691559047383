import React from "react";
import dOrg from "Dispatchers/dOrg.js";
import Document from "Helpers/Document.js";
import {MenuItem, Select} from "@material-ui/core";
import ChangeOrgConfirmDialog from "./ChangeOrgConfirmDialog.js";
import withAuth from "Hoc/withAuth.js";
import withClientSettings from "Hoc/withClientSettings.js";

/**
 * Organisation selection dropdown
 *
 * @package HOPS
 * @subpackage OrgSelection
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class OrgSelectionSelect extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 * 
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Confirmation dialog open?
			 * 
			 * @type {Boolean}
			 */
			dialog: false,

			/**
			 * Target organisation ID
			 *
			 * Used when awaiting user confirmation.
			 * 
			 * @type {Integer|null}
			 */
			target: null

		};

		/**
		 * Method binds
		 */
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeConfirm = this.handleChangeConfirm.bind(this);
		this.handleCloseConfirmDialog = this.handleCloseConfirmDialog.bind(this);

	}


	/**
	 * Changed.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleChange(e) {
		if (this.props.clientSettings.OrgChangeConfirmation) {
			this.setState({dialog: true, target: e.target.value});
		}
		else this.setState({target: e.target.value}, this.handleChangeConfirm);
	}


	/**
	 * Change confirmed.
	 * 
	 * @return {void}
	 */
	handleChangeConfirm() {
		dOrg(this.state.target);
		this.handleCloseConfirmDialog();
	}


	/**
	 * Close confirmation dialog.
	 * 
	 * @return {void}
	 */
	handleCloseConfirmDialog() {
		Document.blurTimeout();
		this.setState({target: null});
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<React.Fragment>
				<Select
					className={this.props.className}
					onChange={this.handleChange}
					onClose={Document.blurTimeout}
					margin={((this.props.size === "small") ? "dense" : null)}
					MenuProps={this.constructor.MenuProps}
					style={this.props.style}
					value={this.props.org?.Id}
					variant={(this.props.variant || "outlined")}>
					{this.renderOrgs()}
				</Select>
				<ChangeOrgConfirmDialog
					onClose={this.handleCloseConfirmDialog}
					onConfirm={this.handleChangeConfirm}
					open={(this.state.target !== null)} />
			</React.Fragment>
		);
	}


	/**
	 * Render organisations.
	 * 
	 * @return {ReactNode}
	 */
	renderOrgs() {
		return this.props.auth.orgs.filter(o => o.UserWorking).map((org, key) => (
			<MenuItem key={key} value={org.Id}>
				{org.Name}
			</MenuItem>
		));
	}


	/**
	 * `Menu` props.
	 *
	 * Disable scroll lock to stop the page jumping about.
	 * 
	 * @type {Object}
	 */
	static MenuProps = {disableScrollLock: true, transitionDuration: 0};

}

export default withAuth(withClientSettings(OrgSelectionSelect));
