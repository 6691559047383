import api from "api.js";
import Bghost from "App/Bghost.js";
import Store from "App/Store.js";
import dUpdateAvailable from "Dispatchers/dUpdateAvailable.js";

export default () => {

	if (process.env.REACT_APP_APP && !Store.getState().UpdateAvailable) {
		return new Promise((resolve, reject) => {
			Bghost.register(api.call({url: "/version.txt", baseURL: null}, false)).then(({data}) => {
				if (data && data.trim && (data?.trim() !== process.env.REACT_APP_APP)) {
					dUpdateAvailable();
				}
				resolve();
			}).catch(reject);
		});
	}
	else return null;

};
