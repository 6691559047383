import React from "react";
import Alert from "Components/Alert.js";
import Button from "Components/Button.js";
import Container from "Components/Container.js";
import String from "Components/String.js";
import dUpdating from "Dispatchers/dUpdating.js";

/**
 * Update available banner
 *
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UpdateAvailable extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Alert
				title="HOPS Update Available"
				variant="success">
				<Container gap={0.5}>
					<String str="Please refresh the page to update HOPS." />
					<Container columns="max-content">
						<Button
							label="Update Now"
							onClick={this.constructor.update}
							variant="outlined" />
					</Container>
				</Container>
			</Alert>
		);
	}


	/**
	 * Update now!
	 * 
	 * @return {void}
	 */
	static update() {
		dUpdating(true);
		window.history.go(0);
	}

}

export default UpdateAvailable;
