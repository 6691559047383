import {useCallback, useEffect, useMemo, useRef, useState} from "react";

/**
 * Abstract data fetching hook
 *
 * Returns an object describing the status of the data fetch.
 *
 * @param {Function} fetcher Asynchronous data fetching function
 * @return {Object}
 */
const useData = fetcher => {

	const fetchId = useRef(0);

	const [data, setData] = useState(null);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(true);

	/**
	 * Function that actually fetches the data
	 *
	 * @return {void}
	 */
	const fetch = useCallback(
		async () => {

			fetchId.current++;
			const currentFetchId = fetchId.current;

			setError(null);
			setLoading(true);

			try {

				const data = await fetcher();

				if (fetchId.current === currentFetchId) {
					setData(data);
				}

			}
			catch (error) {
				if (fetchId.current === currentFetchId) {
					setError(error);
				}
			}

			if (fetchId.current === currentFetchId) {
				setLoading(false);
			}

		},
		[fetcher]
	);

	/**
	 * Fetch the data
	 */
	useEffect(() => fetch(), [fetch, fetcher]);

	/**
	 * Hook values
	 */
	const empty = !data?.length;

	return useMemo(() => {
		return {data, error, empty, loading, fetch, setData, ready: (!loading && !error)};
	}, [data, error, empty, loading, fetch, setData]);

};

export default useData;
