import Bghost from "App/Bghost.js";
import ChatService from "Chat/ChatService.js";
import Store from "App/Store.js";
import dChats from "Dispatchers/dChats.js";

export default () => {
	return new Promise((resolve, reject) => {

		if (Store.getState().authAdmin?.token) {
			reject(new Error("Chat isn't available while admin logged-in."));
			return;
		}

		if (!Store.getState().auth?.orgs?.find(o => o.ChatEnabled)) {
			reject(new Error("No chat-enabled organisations available."));
			return;
		}

		Bghost.register(ChatService.getConversations(), null, "ui").then(chats => {
			dChats(chats);
			resolve();
		}).catch(reject);

	});
};
