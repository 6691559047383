import React from "react";
import Checkbox from "Components/Checkbox.js";
import ClientSettingsService from "Services/ClientSettingsService.js";
import Dialog from "Components/Dialog.js";
import Flex from "Components/Flex.js";
import String from "Components/String.js";
import Strings from "./ChangeOrgConfirmDialog.strings.json";

/**
 * Change organisation confirmation dialog
 *
 * @package HOPS
 * @subpackage OrgSelection
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class ChangeOrgConfirmDialog extends React.PureComponent {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * "Don't show again" checkbox value
		 * 
		 * @type {Boolean}
		 */
		dontShowAgain: false

	};


	/**
	 * Confirmation "OK" button clicked.
	 *
	 * When we've selected "don't show again," we notify the 
	 * server, but we don't need to wait for the response - 
	 * if an error occurs, user doesn't need to be told, they'll 
	 * see the dialog again (unwanted) next time, but there's no 
	 * point making them wait for the network call to succeed here...
	 * 
	 * @return {void}
	 */
	handleConfirm = () => {

		if (this.state.dontShowAgain) {
			ClientSettingsService.update({OrgChangeConfirmation: false}).catch(() => null);
		}

		this.props.onConfirm();

	};


	/**
	 * Toggle the "don't show again" checkbox.
	 *
	 * @return {void}
	 */
	handleToggleDontShowAgain = () => {
		this.setState({dontShowAgain: !this.state.dontShowAgain});
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				onClose={this.props.onClose}
				onOk={this.handleConfirm}
				open={this.props.open}
				title={Strings.title}>
				<Flex gap={0.5}>
					<String
						color="textSecondary"
						str={Strings.content} />
					<Checkbox
						checked={this.state.dontShowAgain}
						label="Don't show this again"
						onChange={this.handleToggleDontShowAgain} />
				</Flex>
			</Dialog>
		);
	}

}

export default ChangeOrgConfirmDialog;
