import React from "react";
import PickerOptionComponent from "./PickerOptionComponent.js";

/**
 * Picker option
 *
 * A representation of an option to be rendered in a Picker.
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class PickerOption {

	/**
	 * Construct an instance.
	 *
	 * @param {mixed} data Option data
	 * @param {String} query Query string
	 * @param {Boolean} disabled optional Disabled? (`false`)
	 * @return {self}
	 */
	constructor(data, query, disabled=false) {

		/**
		 * Option data
		 * 
		 * @type {mixed}
		 */
		this.data = data;

		/**
		 * Query
		 * 
		 * @type {String}
		 */
		this.query = query;

		/**
		 * Disabled?
		 * 
		 * @type {Boolean}
		 */
		this.disabled = disabled;

	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<PickerOptionComponent>
				{this.data}
			</PickerOptionComponent>
		);
	}


	/**
	 * Get label.
	 * 
	 * @return {String}
	 */
	get label() {
		return this.data;
	}

}

export default PickerOption;
