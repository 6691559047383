import api from "api.js";
import Cookies from "Helpers/Cookies.js";
import Store from "App/Store.js";

/**
 * Telemetry service
 *
 * Streams telemetry events up to HOPS.
 *
 * @package HOPS
 * @subpackage Telemetry
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class TelemetryService {

	/**
	 * Event ID this session
	 *
	 * (Enable chronological ordering irrespective of order received on API.)
	 * 
	 * @type {Integer}
	 */
	static eid = 0;


	/**
	 * Report an event.
	 *
	 * @param {String} Event
	 * @param {Object} EventData optional
	 * @return {void}
	 */
	static report(Event, EventData=null) {

		if (!Store.getState().telemetry) return;

		this.eid++;

		/**
		 * Cache the state
		 */
		const cookies = Cookies.getAll();
		const state = Store.getState();

		/**
		 * Send the event
		 */
		api.call({
			url: `/api/hopsa/telemetry`,
			method: "POST",
			data: {
				App: state.appv,
				AppData: {
					Access: state.Access,
					AccessReason: state.AccessReason,
					AuthExpiration: state.auth.expiration,
					AuthExpirationAdmin: state.authAdmin.expiration,
					AuthToken: !!state.auth.token,
					AuthTokenAdmin: !!state.authAdmin.token,
					AuthTokenAdminUnique: (state.authAdmin.token !== state.auth.token),
					AuthCookie: !!cookies[api.HOPS_AUTH_COOKIE],
					AuthCookieAdmin: !!cookies[api.HOPS_ADMIN_MASQUERADE_COOKIE],
					AuthCookieAdminUnique: (Cookies[api.HOPS_ADMIN_MASQUERADE_COOKIE] !== Cookies[api.HOPS_AUTH_COOKIE]),
					CookieDisableSsns: state.cookieDisableSsns,
					CookieDomain: state.cookieDomain,
					DevMode: state.dev,
					Flags: state.flags,
					NewClientCookie: !!cookies[api.HOPS_NEW_CLIENT_COOKIE],
					OrgCookie: cookies[api.HOPS_AUTH_ORG_COOKIE],
					PermissionsIndexAvailable: (state.Permissions !== null),
					UserFunctionsAvailable: (state.UserFunctions !== null)
				},
				Event,
				EventData,
				EventId: this.eid,
				Org: state.org,
				OrgAdmin: state.orgAdmin,
				UserAccount: state.auth?.account?.Id,
				UserAccountAdmin: state.authAdmin?.account?.Id,
				Session: state.Session,
				Timestamp: Math.floor(Date.now() / 1000),
				Uri: `${window.location.pathname}${window.location.search}`
			},
			headers: {
				"Hops-Auth": null,
				"Hops-Active-Org-Id": null,
				"Hops-Admin-Masquerade-Token": null
			}
		}, false).catch(() => {
			// ...
		});

	}

}

export default TelemetryService;
