import Store from "App/Store.js";
import dAuthAdminClear from "Dispatchers/dAuthAdminClear.js";
import dReset from "Dispatchers/dReset.js";

/**
 * Check we're still authenticated.
 *
 * Optionally applies a buffer period to make the authentication appear 
 * to expire earlier than it actually does, ensuring it can never really 
 * run out, so we expire and log the user out before they start hitting 
 * issues due to an expired token (e.g. if they go offline before the 
 * auth refresh task would then run).
 * 
 * @param {Boolean} buffer optional Add buffer period (`true`)
 * @param {Boolean} admin optional Check admin authentication (`false`)
 * @return {Boolean} `false` when expired
 */
export default (buffer=true, admin=false) => {

	/**
	 * Current time
	 */
	const auth = (admin ? Store.getState().authAdmin : Store.getState().auth);
	const authed = auth.token;
	const expires = auth.expiration;
	const time = ((new Date()).getTime() / 1000);

	/**
	 * Are we expired?
	 */
	if (authed && ((buffer ? (expires - (20 * 60)) : expires) < time)) {
		if (admin) dAuthAdminClear();
		else dReset(true);
		return false;
	}
	else return true;

};
