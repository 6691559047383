import api from "api.js";
import UpdateHopsCookiesTask from "Tasks/UpdateHopsCookiesTask.js";

/**
 * Library service
 *
 * @package HOPS
 * @subpackage Library
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LibraryService {

	/**
	 * MIME types known to be allowed in the library
	 *
	 * @type {Array}
	 */
	static ALLOWED_MIME_TYPES = [
		"application/msword",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		"application/vnd.ms-powerpoint",
		"application/vnd.openxmlformats-officedocument.presentationml.presentation",
		"application/pdf",
		"application/vnd.ms-excel",
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"image/jpeg",
		"image/png"
	];

	/**
	 * Maximum file size (MB)
	 *
	 * @type {Integer}
	 */
	static MAXIMUM_FILE_SIZE = 10;


	/**
	 * Access a file.
	 *
	 * This uses the browser windowing mechanism to access a file.
	 * It works as we have cookies set in the browser anyway in 
	 * the current auth system (Sept. 2021) so although only a 
	 * plain GET URL is used, the user will be authenticated.
	 *
	 * @param {String} file UUID
	 * @return {Promise}
	 */
	static accessFile(file) {

		/**
		 * Since !484, cookies aren't set automatically
		 *
		 * We need them now so we're authenticated to the HOPS 
		 * domain when we open the file URI in the new tab.
		 */
		UpdateHopsCookiesTask();

		/**
		 * Open the file URI
		 */
		window.open(this.getFileUri(file));

	}


	/**
	 * Get a file's content.
	 *
	 * @param {String} file UUID
	 * @return {Promise} Resolves with content blob
	 */
	static getFileContent(file) {
		return api.call({
			url: `/api/library/files/${file}/content`,
			responseType: "blob"
		}).then(({data}) => data);
	}


	/**
	 * Get a file's download URI using our in-app (current origin) 
	 * download experience.
	 *
	 * @param {String} file UUID
	 * @return {String}
	 */
	static getCurrentOriginDownloadUri(file) {
		return `${window.location.origin}/download/${file}`;
	}


	/**
	 * Get the canonical permalink to a file.
	 *
	 * @param {String} file UUID
	 * @return {String}
	 */
	static getFileUri(file) {
		return `${api.base}/files/${file}`;
	}


	/**
	 * Get a file's thumbnail URI by its UUID.
	 *
	 * @param {String} Uuid
	 * @return {String}
	 */
	static getThumbnailUri(Uuid) {
		return `${api.base}/uploads/public/library/${Uuid.split("").join("/")}`;
	}


	/**
	 * Get the contents of an entity's library.
	 *
	 * @param {Integer} EntityId
	 * @param {Integer} EntityTypeId
	 * @return {Promise} Resolves with API response
	 */
	static getEntityLibrary(EntityId, EntityTypeId) {
		return api.call({
			url: `/api/library/entity/${EntityTypeId}/${EntityId}`
		}).then(({data}) => data);
	}


	/**
	 * Upload files against an entity's library.
	 *
	 * @param {?Integer} EntityId
	 * @param {?Integer} EntityTypeId
	 * @param {Array} Files `File` instances
	 * @param {String} optional Set Set UUID to upload to
	 * @param {Function} optional onUploadProgress Axios `onUploadProgress` handler
	 * @param {Boolean} optional Immutable Make the file record immutable (`false`)
	 * @return {Promise} Resolves with API data
	 */
	static uploadToEntity(EntityId, EntityTypeId, Files, Set=null, onUploadProgress=undefined, Immutable=false) {

		const fd = new FormData();
		if (EntityId) fd.append("EntityId", EntityId);
		if (EntityTypeId) fd.append("EntityType", EntityTypeId);
		if (Immutable) fd.append("Immutable", (Immutable ? 1 : 0));
		if (Set) fd.append("Set", Set);

		for (const file of Files) {
			fd.append("Files[]", file);
		}

		return api.call({
			url: `/api/library`,
			method: "POST",
			data: fd,
			timeout: 0,					// No timeout, we might have big files and slow connections!
			onUploadProgress
		}).then(({data}) => data);

	}


	/**
	 * Move files between sets.
	 *
	 * @param {String} OldSet UUID
	 * @param {String} NewSet UUID
	 * @param {Array} Files UUIDs to move
	 * @return {Promise} Resolves with API result object
	 */
	static moveFiles(OldSet, NewSet, Files) {
		return api.call({
			url: `/api/library/files/move`,
			method: "POST",
			data: {OldSet, NewSet, Files}
		}).then(({data}) => data);
	}


	/**
	 * Rename a file.
	 *
	 * @param {String} file UUID
	 * @param {String} Name
	 * @return {Promise}
	 */
	static renameFile(file, Name) {
		return api.call({
			url: `/api/library/files/${file}/name`,
			method: "PUT",
			data: {Name}
		});
	}


	/**
	 * Replace a file's content (real file).
	 *
	 * @param {String} file UUID
	 * @param {File} f File to upload
	 * @param {Function} onUploadProgress optional Axios `onUploadProgress` handler
	 * @return {Promise} Resolves with API data
	 */
	static replaceFile(file, f, onUploadProgress=undefined) {

		const fd = new FormData();
		fd.append("File", f);

		return api.call({
			url: `/api/library/files/${file}`,
			method: "POST",
			data: fd,
			onUploadProgress
		}).then(({data}) => data);

	}


	/**
	 * Rename a set.
	 *
	 * @param {String} set UUID
	 * @param {String} Name
	 * @return {Promise}
	 */
	static renameSet(set, Name) {
		return api.call({
			url: `/api/library/sets/${set}/name`,
			method: "PUT",
			data: {Name}
		});
	}


	/**
	 * Delete a set.
	 *
	 * @param {String} set UUID
	 * @return {Promise}
	 */
	static deleteSet(set) {
		return api.call({
			url: `/api/library/sets/${set}`,
			method: "DELETE"
		});
	}


	/**
	 * Delete files from a set,
	 *
	 * @param {String} set UUID
	 * @param {Array} Files UUIDs
	 * @return {Promise} Resolves with API result object
	 */
	static deleteSetFiles(set, Files) {
		return api.call({
			url: `/api/library/sets/${set}/files`,
			method: "DELETE",
			data: {Files}
		}).then(({data}) => data);
	}

}

export default LibraryService;
