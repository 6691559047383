import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import Card from "@material-ui/core/Card";
import CardActionArea from "Components/CardActionArea.js";
import CheckIcon from "@material-ui/icons/Check";
import Container from "Components/Container.js";
import IconButton from "Components/IconButton.js";
import Flex from "Components/Flex.js";
import Navigator from "App/Navigator.js";
import NotificationService from "./NotificationService.js";
import String from "Components/String.js";
import dNotificationRead from "Dispatchers/dNotificationRead.js";
import dOrg from "Dispatchers/dOrg.js";
import OrgService from "Services/OrgService.js";
import withAuth from "Hoc/withAuth.js";
import withSnack from "Hoc/withSnack.js";
import withTimes from "Hoc/withTimes.js";
import scss from "./NotificationCard.module.scss";

/**
 * Notification card
 *
 * An individual notification in `NotificationPane`.
 *
 * @package HOPS
 * @subpackage Notifications
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class NotificationCard extends AsyncStatefulComponent {

	// map of org id and org
	orgMap = new Map(this.props.orgs?.map(org => [org.Id, org]));

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Dismissing?
		 * 
		 * @type {Boolean}
		 */
		dismissing: false

	};


	/**
	 * Clicked.
	 * 
	 * @return {void}
	 */
	handleClick = () => {

		this.props.onClick?.();

		const {Uri} = this.props.notification;

		if (this.props.notificationOrg && (this.props.notificationOrg?.Id !== this.props.org?.Id)) {
			dOrg(this.props.notificationOrg?.Id, Uri);
		}
		else Navigator.navigate(Uri);

	};


	/**
	 * Dismissed.
	 * 
	 * @return {void}
	 */
	handleDismiss = e => {

		e.preventDefault();
		e.stopPropagation();

		this.setState({dismissing: true});

		NotificationService.markRead(this.props.notification.Uuid).then(() => {
			dNotificationRead(this.props.notification.Uuid);
		}).catch(e => {

			// already marked read in all probability
			if (e?.response?.status !== 404) {
				this.props.snack(e);
				this.setState({dismissing: false});
			}
			else dNotificationRead(this.props.notification.Uuid);

		});

	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Card elevation={0} square={true}>
				<Container gap={0}>
					<CardActionArea
						onClick={(this.props.notification.Uri && this.handleClick)}
						style={this.constructor.stylesActionArea}
						tabIndex={(!this.props.visible ? "-1" : "0")}>
						<Container
							alignItems="center"
							columns={(this.props.notification.Dismissable ? "1fr max-content" : "1fr")}
							gap={0}>
							{this.renderText()}
							{(this.props.notification.Dismissable && this.renderDismiss())}
						</Container>
					</CardActionArea>
				</Container>
			</Card>
		);
	}


	/**
	 * Render the dismiss button.
	 * 
	 * @return {ReactNode}
	 */
	renderDismiss() {
		return (
			<Container px={1}>
				<IconButton
					color="primary"
					dimensions="3rem"
					disabled={(this.state.dismissing || this.props.disabled)}
					icon={CheckIcon}
					onClick={this.handleDismiss}
					onMouseDown={e => e.stopPropagation()}
					tabIndex={(!this.props.visible ? "-1" : "0")}
					title="Done" />
			</Container>
		);
	}

	/**
	 * Render the notification's org logo.
	 * 
	 * @return {ReactNode}
	 */
	renderOrgLogo() {
		const org = this.orgMap.get(this.props.notification.Org);
		if (!org) {
			return null;
		}

		return (
			<img
				alt={org.Name}
				title={org.Name}
				src={OrgService.resolveLogoUri(org.Logo)}
				className={scss.orgLogo} />
		);
	}

	/**
	 * Render the notification's text.
	 * 
	 * @return {ReactNode}
	 */
	renderText() {
		return (
			<Container
				alignContent="center"
				gap={0.25}
				pb={0.5}
				px={1}
				pt={0.25}
				style={this.constructor.stylesText}>
				<Flex
					columnar={true}
					gap={0.5}
					alignItems="center">
					<String
						color="textSecondary"
						lineClamp={1}
						str={this.caption}
						style={this.constructor.stylesCaption}
						variant="overline" />
					{this.renderOrgLogo()}
				</Flex>
				<String str={this.props.notification.Text.replaceAll("\n", " ")} />
			</Container>
		);
	}


	/**
	 * Get the caption text.
	 * 
	 * @return {String}
	 */
	get caption() {

		const caption = [this.timestampString];

		if (this.props.notificationOrg) {
			caption.push(this.props.notificationOrg.Name);
		}

		return caption.join(" • ");

	}


	/**
	 * Get our timestamp string.
	 * 
	 * @return {String}
	 */
	get timestampString() {
		return this.props.formatTime(this.props.notification.Timestamp);
	}


	/**
	 * Styles for the card action area component.
	 * 
	 * @type {Object}
	 */
	static stylesActionArea = {height: "100%"};

	/**
	 * Styles for the caption.
	 *
	 * @type {Object}
	 */
	static stylesCaption = {textTransform: "none"};

	/**
	 * Styles for the text.
	 * 
	 * @type {Object}
	 */
	static stylesText = {minHeight: "4.5rem"};

}

export default withAuth(withTimes(withSnack(NotificationCard)));
