import React from "react";
import parse from "html-react-parser";
import Container from "Components/Container.js";
import PickerOptionComponent from "Components/PickerOptionComponent.js";
import String from "Components/String.js";
import withMobile from "Hoc/withMobile.js";
import {Typography} from "@material-ui/core";

/**
 * UI bar searchbar result component
 * 
 * A result item in the app bar searchbar.
 *
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiBarSearchbarResultComponent extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<PickerOptionComponent>
				<Container
					columnar={!this.props.isMobile}
					gap={(this.props.isMobile ? 0.5 : null)}
					justifyContent={(!this.props.isMobile ? "space-between" : null)}>
					<Typography
						style={this.constructor.styles}>
						{parse(this.searchStringHighlighted)}
					</Typography>
					<String
						color="textSecondary"
						str={this.props.result.Label} />
				</Container>
			</PickerOptionComponent>
		);
	}


	/**
	 * Get the label highlighted with our search query.
	 * 
	 * This uses the native `<mark>` element for the highlighting.
	 */
	get searchStringHighlighted() {
		const string = this.props.result.Title;
		const words = this.props.query.trim().split(/\s+/);
		const pattern = new RegExp(words.join("|"), "ig");
		const markedResult = string.replace(pattern, match => `<mark>${match}</mark>`);
		return markedResult;
	}


	/**
	 * Styles.
	 * 
	 * @type {Object}
	 */
	static styles = {
		userSelect: "text"
	};

}

export default withMobile(UiBarSearchbarResultComponent);
