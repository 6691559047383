import React from "react";
import Classes from "Resources/Classes.json";
import Str from "Components/Stringx.js";
import UriClickableComponent from "Includes/UriClickableComponent.js";
import {withRouter} from "react-router-dom";
import {Link as MuiLink} from "@material-ui/core";
import scss from "./Link.module.scss";

/**
 * Link
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Link extends UriClickableComponent {

	/**
	 * Clicked.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleClick(e) {

		e.stopPropagation();

		if (this.disabled || (!this.props.uri?.startsWith("mailto:") && !this.props.uri?.startsWith("tel:"))) {
			e.preventDefault();
			if (!this.disabled) {
				super.handleClick(e);
			}
		}

	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<MuiLink
				className={this.className}
				color={(this.props.color || undefined)}
				href={(!this.disabled ? (this.props.uri || undefined) : undefined)}
				onClick={this.handleClick}
				onKeyPress={this.handleKeyPress}
				style={this.props.style}
				tabIndex={(!this.disabled ? "0" : "-1")}
				title={this.props.title}>
				{(this.props.children || this.renderStr())}
			</MuiLink>
		);
	}


	/**
	 * Render string content.
	 * 
	 * @return {ReactNode}
	 */
	renderStr() {
		return (
			<Str
				bold={this.props.bold}
				color={this.props.color}
				content={this.label}
				p={this.props.p}
				variant={this.props.variant} />
		);
	}


	/**
	 * Get our `className`.
	 * 
	 * @return {String}
	 */
	get className() {
		const classes = [Classes.fitContentWidth, scss.link];
		if (this.disabled) {
			classes.push(scss.disabled);
			if (!this.props.labelWhenDisabled) {
				classes.push(scss.disabledFilter);
			}
		}
		if (this.props.className) {
			classes.push(this.props.className);
		}
		return classes.join(" ");
	}


	/**
	 * Get whether we're disabled.
	 * 
	 * @return {Boolean}
	 */
	get disabled() {
		return (this.props.disabled || !(this.props.uri || this.props.onClick));
	}


	/**
	 * Get the label.
	 * 
	 * @return {String}
	 */
	get label() {
		const lbl = this.props.label;
		if (this.props.endArrow) return `${lbl}${String.fromCharCode(160)}${String.fromCharCode(8594)}`;
		else if (this.props.startArrow) return `${String.fromCharCode(8592)}${String.fromCharCode(160)}${lbl}`;
		else return lbl;
	}

}

export default withRouter(Link);
