import React from "react";
import Navigator from "App/Navigator.js";
import PickerOption from "Components/PickerOption.js";
import SearchableList from "Components/SearchableList.js";
import SearchService from "Search/SearchService.js";
import UiBarSearchbarResult from "./UiBarSearchbarResult.js";
import scss from "./UiBarSearchbar.module.scss";
import withAuth from "Hoc/withAuth.js";
import scssControl from "./UiBarControl.module.scss";
import {Search as SearchIcon} from "@material-ui/icons";

/**
 * UI bar searchbar
 *
 * The searchbar in the app bar.
 *
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiBarSearchbar extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<div className={scss.UiBarSearchbarContainer}>
				<SearchableList
					classes={this.classes}
					color="secondary"
					disableClearable={true}
					emptyText="Type to search..."
					ignoreResetEvents={true}
					inputEndAdornment={this.constructor.icon}
					loadingText="Searching..."
					noOptionsText="No results"
					onChange={this.constructor.handleSelect}
					OptionsSource={this.constructor.getResults}
					placeholder={`Search ${(this.props.org?.NameShort || "")} HOPS...`}
					size="small"
					style={this.constructor.inputCommonStyles}
					styleInput={this.constructor.inputCommonStyles}
					styleInputInput={this.constructor.inputCommonStyles}
					variant="outlined" />
			</div>
		);
	}


	/**
	 * Get CSS classes.
	 * 
	 * @return {Object}
	 */
	get classes() {
		return {
			listbox: scss.UiBarSearchbarPopperListbox,
			option: scss.UiBarSearchbarOption,
			popper: scss.UiBarSearchbarPopper,
			root: `${scss.UiBarSearchbar} ${scssControl.UiBarControl} ${(this.props.isAdminAuthed ? scss.UiBarSearchbarAdmin : "")}`
		};
	}


	/**
	 * Get search results.
	 * 
	 * @param {String} query
	 * @return {Promise} Resolves with `SearchableList` options
	 */
	static getResults(query) {
		return SearchService.search(query).then(({results, totalCount}) => {
			const opts = results.map(r => new UiBarSearchbarResult(r, query));
			if (totalCount > results.length) {
				const diff = (totalCount - results.length);
				opts.push(new PickerOption(`+ ${diff} more`, null, true));
			}
			return opts;
		});
	}


	/**
	 * Result selected.
	 *
	 * We navigate to its URI.
	 * 
	 * @param {Event} e
	 * @param {UiBarSearchbarResult} result
	 * @return {void}
	 */
	static handleSelect(e, result) {
		const uri = result?.data?.Uri;
		if (uri) Navigator.navigate(uri);
	}


	/**
	 * Icon.
	 * 
	 * @type {ReactNode}
	 */
	static icon = <SearchIcon />;

	/**
	 * Input common styles.
	 * 
	 * @type {Object}
	 */
	static inputCommonStyles = {height: "100%"};

}

export default withAuth(UiBarSearchbar);
