import React from "react";
import Alert from "Components/Alert.js";
import Html from "Components/Html.js";

/**
 * HOPS important system notice alert
 * 
 * @package HOPS
 * @subpackage NoticesHops
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class HopsSystemNotice extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Alert
				collapsible={true}
				title={(this.props.title || "Important System Notice")}
				variant="error">
				<Html html={this.props.content} />
			</Alert>
		);
	}

}

export default HopsSystemNotice;
