import React from "react";
import dChats from "Dispatchers/dChats.js";
import withAuth from "./withAuth.js";
import {connect} from "react-redux";

/**
 * `withChat()` HOC
 *
 * Makes HOPS-Chat details available on components.
 *
 * @package HOPS
 * @subpackage Hoc
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
export default Component => {

	/**
	 * `withChat` wrapper
	 * 
	 * @package HOPS
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright Heritage Operations Processing Limited
	 */
	const cwc = class extends React.PureComponent {

		/**
		 * Chat with user marked as read/unread.
		 * 
		 * @param {Object} User
		 * @param {Boolean} Read optional
		 * @return {void}
		 */
		handleChatMarkedRead = (User, Read=true) => {

			const chats = this.props.Chats?.Conversations;

			const i = chats.indexOf(chats.find(c => {
				const ids = [c.Sender?.Id, c.Recipient?.Id];
				return (ids.includes(User?.Id));
			}));

			if (!isNaN(i)) {
				const Conversations = [...chats];
				Conversations[i] = {...Conversations[i], Read};
				dChats({...this.props.Chats, Conversations});
			}

		};


		/**
		 * New chat message received.
		 *
		 * @param {Object} chat
		 * @param {Object} message
		 * @return {void}
		 */
		handleNewMessage = (chat, message) => {

			const chats = (this.props.Chats?.Conversations || []);

			const i = chats.indexOf(chats.find(c => {
				const ids = [c.Sender?.Id, c.Recipient?.Id];
				return (ids.includes(chat.User?.Id));
			}));

			if (!isNaN(i) && (i >= 0)) {
				const Conversations = [...chats];
				Conversations[i] = message;
				dChats({...this.props.Chats, Conversations});
			}

		};


		/**
		 * Render.
		 * 
		 * @return {ReactNode}
		 */
		render() {
			return (
				<Component
					{...this.props}
					ChatData={this.props.Chats}
					Chats={(this.props.Chats?.Conversations || [])}
					Correspondents={(this.props.Chats?.Users || {})}
					onChatMarkedRead={this.handleChatMarkedRead}
					onNewMessage={this.handleNewMessage}
					unreadChatsCount={this.unreadChatsCount} />
			);
		}


		/**
		 * Get count of unread chat conversations.
		 * 
		 * @return {Integer}
		 */
		get unreadChatsCount() {
			if (this.props.Chats?.Conversations?.length) {
				return this.props.Chats?.Conversations.filter(c => {
					return (!c.Read && (c.Recipient.Username === this.props.userAccount.Username));
				}).length;
			}
			else return 0;
		}

	};

	return connect(({Chats}) => ({Chats}))(withAuth(cwc));

};
