/**
 * Raildays helper
 *
 * Enables detection of rendering inside Raildays.
 *
 * @package HOPS
 * @subpackage Helpers
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Raildays {

	/**
	 * Get whether we are currently rendering inside Raildays.
	 *
	 * This works by checking for the presence of the `HopsMessagingChannel` 
	 * global which would be set by the Raildays `WebView`.
	 * 
	 * @return {Boolean}
	 */
	static get active() {
		// eslint-disable-next-line no-undef
		return (typeof HopsMessagingChannel !== "undefined");
	}

}

export default Raildays;
