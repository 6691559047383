import React from "react";
import Classes from "Resources/Classes.json";
import Flex from "Components/Flex.js";
import {Typography} from "@material-ui/core";

/**
 * String
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class String extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		if (!this.strings.length) return null;
		else return (!this.props.p ? this.renderContainer() : this.renderMain());
	}


	/**
	 * Render within a `Flex`.
	 * 
	 * @return {ReactNode}
	 */
	renderContainer() {
		return (
			<Flex {...this.props.ContainerProps} gap={this.props.gap} title={this.props.title}>
				{this.renderMain()}
			</Flex>
		);
	}


	/**
	 * Render actual strings.
	 * 
	 * @return {ReactNode}
	 */
	renderMain() {
		return this.strings.map(({string, bold}, key) => (
			<Typography
				className={`${(this.props.oneline ? Classes.oneline : null)} ${this.props.className}`}
				color={((this.props.color !== "green") ? this.props.color : undefined)}
				gutterBottom={this.props.typographyGutterBottom}
				key={key}
				style={this.getStringStyles(bold)}
				title={(this.props.p ? this.props.title : undefined)}
				variant={this.props.variant}>
				{string.toString().trim()}
			</Typography>
		));
	}


	/**
	 * Styles.
	 * 
	 * @param {Boolean} bold optional Bold (default: derive from props)
	 * @return {Object}
	 */
	getStringStyles(bold=null) {
		const styles = {
			lineHeight: this.props.lineHeight,
			textDecoration: "inherit",
			wordBreak: (!this.props.noWordBreak ? "break-word" : undefined),
			...this.props.style
		};
		if (this.props.centre) styles.textAlign = "center";
		if (this.props.color === "green") styles.color = "#299c39";
		if (((typeof bold === "boolean") && bold) || this.props.bold) {
			styles.fontWeight = "bold";
		}
		if (this.props.lineClamp) {
			styles.display = "-webkit-box";
			styles.overflow = "hidden";
			styles.textOverflow = "ellipsis";
			styles.WebkitBoxOrient = "vertical";
			styles.WebkitLineClamp = (this.props.lineClamp || 1);
		}
		return styles;
	}


	/**
	 * Get our array of string objects for rendering.
	 *
	 * @return {Array}
	 */
	get strings() {

		/**
		 * Final strings
		 */
		const final = [];

		/**
		 * String or array?
		 */
		let str = this.props.str;
		if (!Array.isArray(str)) str = [this.props.str];

		/**
		 * Break on newlines
		 */
		str.filter(str => (str || !isNaN(str))).forEach(str => {
			if (isNaN(str) && str) {
				const s = (str?.string || str).replace(/\r/g, "");
				for (const x of s.split("\n")) {
					final.push(x);
				}
			}
			else if ((!isNaN(str) && str) || (str === 0)) {
				final.push(str);
			}
		});

		/**
		 * Map to string objects
		 */
		return final.filter(str => (str || !isNaN(str))).map(str => {
			if (typeof str === "object") return str;
			else return this.constructor.transformStringToStringObject(str);
		});

	}


	/**
	 * Transform a literal string to a string descriptor object.
	 *
	 * We use these objects internally to enable basic formatting.
	 *
	 * @param {String} str
	 * @return {Object}
	 */
	static transformStringToStringObject(string) {
		return {string};
	}

}

export default String;
