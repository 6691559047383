import React from "react";
import api from "api.js";
import {Avatar, Tooltip} from "@material-ui/core";

/**
 * User avatar component
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UserAvatar extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Tooltip arrow={true} title={`${(this.props.user.Account?.Fname || this.props.user.Fname)} ${(this.props.user.Account?.Sname || this.props.user.Sname)}`}>
				<Avatar
					alt={`${(this.props.user.Account?.Fname || this.props.user.Fname)} ${(this.props.user.Account?.Sname || this.props.user.Sname)}`}
					src={this.src}
					style={this.style} />
			</Tooltip>
		);
	}


	/**
	 * Get colour to use.
	 *
	 * @return {String} Hex code
	 */
	get colour() {

		/**
		 * All colours
		 */
		const colours = [...this.constructor.colours];

		/**
		 * Target index
		 */
		const i = (this.initial[0].toLowerCase().charCodeAt(0) - 97);

		/**
		 * Direction of travel
		 */
		let nth = true;

		/**
		 * We need to make `colours` length match the target index
		 */
		while (colours.length < i) {

			nth = !nth;

			/**
			 * Get lower and upper bounds
			 *
			 * This is here so that after exhausting all the available 
			 * colours, we traverse the list backwards to the start, so 
			 * ensuring a nice smooth gradient in lists and charts.
			 */
			const x = (nth ? colours.length : 0);
			const y = (nth ? 0 : colours.length);

			/**
			 * Add each colour in sequence onto our preparatory list of colours
			 */
			for (let i = x; (nth ? (i > y) : (i < y)); (nth ? i-- : i++)) {
				colours.push(colours[i]);
			}

		}

		return colours[i];

	}


	/**
	 * Get user's initial.
	 * 
	 * @return {String}
	 */
	get initial() {
		return (!this.props.surname ? (this.props.user.Account?.Fname || this.props.user.Fname) : (this.props.user.Account?.Sname || this.props.user.Sname))[0].toUpperCase();
	}


	/**
	 * Get user photo `src`.
	 * 
	 * @return {String|undefined}
	 */
	get src() {
		if (!this.props.user.Photo) return undefined;
		else return `${api.base}/uploads/user_photos/${this.props.user.Photo.split("").join("/")}`;
	}


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get style() {
		return {
			fontSize: (this.props.small && "1.4rem"),
			height: this.props.dims,
			width: this.props.dims,
			...this.props.style
		};
	}


	/**
	 * Colours.
	 *
	 * @type {Array}
	 */
	static colours = [
		"#f44336",
		"#e91e63",
		"#9c27b0",
		"#673ab7",
		"#3f51b5",
		"#2196f3",
		"#03a9f4",
		"#00bcd4",
		"#009688",
		"#4caf50",
		"#8bc34a",
		"#cddc39",
		"#ffeb3b",
		"#ffc107",
		"#ff9800",
		"#ff5722",
		"#795548",
		"#9e9e9e",
		"#607d8b"
	];

}

export default UserAvatar;
