import React from "react";
import TextField from "Components/TextField.js";

/**
 * Login form email input
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LoginFormEmailInput extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<TextField
				disabled={this.props.disabled}
				inputRef={this.props.inputRef}
				label="Email address"
				name="email"
				onChange={this.props.onChange}
				placeholder="Email address"
				required={true}
				shrink={true}
				type="email"
				value={this.props.value}
				varchar={true}
				variant="standard" />
		);
	}

}

export default LoginFormEmailInput;
