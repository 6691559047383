import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import ChatService from "./ChatService.js";
import UserPicker from "Components/UserPicker.js";
import withAuth from "Hoc/withAuth.js";

/**
 * Chat user picker
 *
 * Allows users to pick a user to chat with.
 *
 * @package HOPS
 * @subpackage Chat
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class ChatUserPicker extends AsyncStatefulComponent {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Loading?
		 *
		 * @type {Boolean}
		 */
		loading: true,

		/**
		 * Error?
		 * 
		 * @type {Boolean}
		 */
		error: false,

		/**
		 * Users selectable
		 * 
		 * @type {Array}
		 */
		users: []

	};


	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		super.componentDidMount();
		ChatService.getMessageableUsers().then(users => {
			this.setState({users});
		}).catch(() => {
			this.setState({error: true});
		}).finally(() => {
			this.setState({loading: false});
		});
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<UserPicker
				autoFocus={true}
				error={this.state.error}
				label="Select a user..."
				loading={this.state.loading}
				onChange={this.props.onSelectUser}
				showUsersOrgsWhenAvailable={this.props.hasMultipleOrgs}
				size="small"
				users={this.state.users} />
		);
	}

}

export default withAuth(ChatUserPicker);
