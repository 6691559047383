import React from "react";
import CardActionArea from "Components/CardActionArea.js";
import Image from "Components/Image.js";
import OrgService from "Services/OrgService.js";
import withAuth from "Hoc/withAuth.js";
import scss from "./UiDrawerOrgLogo.module.scss";

/**
 * Organisation logo in the drawer
 *
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiDrawerOrgLogo extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<CardActionArea
				className={scss.card}
				key={this.props.org?.Id}
				uri="/">
				<Image
					alt={this.props.org?.Name}
					className={scss.logo}
					classNameContainer={scss.logoContainer}
					noError={true}
					noLoader={true}
					src={OrgService.resolveLogoUri(this.props.org?.Logo)} />
			</CardActionArea>
		);
	}

}

export default withAuth(UiDrawerOrgLogo);
