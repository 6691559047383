import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import MuiCheckbox from "@material-ui/core/Checkbox";

/**
 * Checkbox component
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Checkbox extends React.PureComponent {

	/**
	 * Value changed.
	 *
	 * @return {void}
	 */
	handleChange = () => {
		this.props.onChange(
			this.props.value,
			(this.props.name || this.props.label)
		);
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<FormGroup style={this.constructor.styles}>
				<FormControlLabel
					control={this.renderCheckbox()}
					label={this.props.label} />
			</FormGroup>
		);
	}


	/**
	 * Render the checkbox.
	 * 
	 * @return {ReactNode}
	 */
	renderCheckbox() {
		return (
			<MuiCheckbox
				{...this.props}
				color="primary"
				onChange={this.handleChange}
				size="small" />
		);
	}


	/**
	 * Styles.
	 *
	 * @type {Object}
	 */
	static styles = {display: "block"};

}

export default Checkbox;
