import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import CheckNotificationsTask from "Tasks/CheckNotificationsTask.js";
import Container from "Components/Container.js";
import DropdownPane from "Components/DropdownPane.js";
import IconButton from "Components/IconButton.js";
import String from "Components/String.js";
import NotificationCard from "./NotificationCard.js";
import NotificationService from "./NotificationService.js";
import Strings from "Notifications/Notifications.strings.json";
import dNotificationsPane from "Dispatchers/dNotificationsPane.js";
import withAuth from "Hoc/withAuth.js";
import withNotifications from "Hoc/withNotifications.js";
import withSnack from "Hoc/withSnack.js";
import {ClearAll as ClearIcon} from "@material-ui/icons";
import {connect} from "react-redux";

/**
 * Notification pane
 *
 * @package HOPS
 * @subpackage Notifications
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class NotificationPane extends AsyncStatefulComponent {

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Clearing
		 * 
		 * @type {Boolean}
		 */
		clearing: false

	};


	/**
	 * Clearing all notifications.
	 * 
	 * @return {void}
	 */
	handleClear = () => {

		dNotificationsPane();
		this.setState({clearing: true});

		NotificationService.markAllReadAndUpdateState().catch(e => {
			this.props.snack(e);
		}).finally(() => {
			this.setState({clearing: false});
		});

	};


	/**
	 * Component updated.
	 *
	 * We refresh the user's notifications when the pane's been opened.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.NotificationsPane !== this.props.NotificationsPane) {
			if (this.props.NotificationsPane) {
				CheckNotificationsTask().catch(e => {
					this.props.snack(`Error refreshing notifications: ${e}.`, "error");
				});
			}
		}
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<DropdownPane
				icons={this.renderIcons()}
				org={this.props.org}
				label={Strings.label}
				onClose={dNotificationsPane}
				open={this.props.NotificationsPane}>
				{(this.props.Notifications?.length ? this.renderNotifications() : this.constructor.renderEmpty())}
			</DropdownPane>
		);
	}


	/**
	 * Render the icons.
	 * 
	 * @return {ReactNode}
	 */
	renderIcons() {
		return (
			<IconButton
				color="primary"
				disabled={(this.state.clearing || !this.props.Notifications?.length || !this.hasDismissable)}
				icon={ClearIcon}
				onClick={this.handleClear}
				tabIndex={(!this.props.NotificationsPane ? "-1" : "0")}
				title={Strings.clear} />
		);
	}


	/**
	 * Render notifications.
	 * 
	 * @return {ReactNode}
	 */
	renderNotifications() {
		return (
			<Container gap={0.5}>
				{
					this.props.Notifications?.map?.(notification => (
						<NotificationCard
							disabled={this.state.clearing}
							key={notification.Uuid}
							notification={notification}
							notificationOrg={(this.props.hasMultipleOrgs && this.props.auth.orgs.find(o => (o.Id === notification.Org)))}
							onClick={dNotificationsPane}
							visible={this.props.NotificationsPane} />
					))
				}
			</Container>
		);
	}


	/**
	 * Get whether we have a dismissable notification.
	 * 
	 * @return {Boolean}
	 */
	get hasDismissable() {
		return !!this.props.Notifications?.find?.(n => n.Dismissable);
	}


	/**
	 * Render the empty state.
	 * 
	 * @return {ReactNode}
	 */
	static renderEmpty() {
		return (
			<Container px={1} pb={2} pt={1}>
				<String
					centre={true}
					color="textSecondary"
					str={Strings.empty} />
			</Container>
		);
	}

}

export default connect(({NotificationsPane}) => ({NotificationsPane}))(
	withAuth(withNotifications(withSnack(NotificationPane)))
);
