import {useCallback, useMemo} from "react";
import {useSelector} from "react-redux";

export default () => {

	const {auth, authAdmin, org, orgAdmin, Permissions} = useSelector(store => store);


	/**
	 * Check whether the current authentication context has a given permission.
	 *
	 * @param {Integer} pid
	 * @param {?Integer} pvar optional
	 * @return {Boolean}
	 */
	const hasPermission = useCallback((pid, pvar=0) => {

		const vars = (Permissions?.[pid] || []);
		if (pvar === -1) pvar = null;

		/** 0 = must have 0 as the ONLY variable */
		if (pvar === 0) {
			return ((vars.length === 1) && (vars[0] === 0));
		}
		/** null (-1) = must have null (-1) or any non-0 variable */
		else if (pvar === null) {
			return (vars.includes(null) || !!vars.find(v => (v > 0)));
		}
		/** variable = must have null (-1) or the specific variable */
		else {
			return (vars.includes(null) || vars.includes(pvar));
		}

	}, [Permissions]);


	/**
	 * Are we in an active admin login session?
	 */
	const isAdminAuthed = (authAdmin?.token !== null);


	/**
	 * Get the selected auth details
	 */
	const activeAuth = (!isAdminAuthed ? auth : authAdmin);
	const activeOrgId = (!isAdminAuthed ? org : orgAdmin);
	const activeOrg = activeAuth?.orgs?.find?.(org => (org?.Id === activeOrgId));


	/**
	 * Return values
	 */
	return useMemo(() => {
		return {
			auth: activeAuth,
			authAdmin,
			authed: (auth?.token !== null),
			authedUser: (org !== null),
			hasMultipleOrgs: (activeAuth?.orgs?.length > 1),
			hasPermission,
			isAdminAuthed,
			isUserDetailsModerationEnabled: (org?.UserDetailsModerationEnabled && !hasPermission(396, 0)),
			permissions: Permissions,
			org: activeOrg,
			orgs: activeAuth?.orgs,
			orgIds: activeAuth?.orgs?.map?.(o => o.Id),
			userAccount: activeAuth?.account,
			userAccountReal: auth?.account
		};
	}, [
		activeAuth,
		activeOrg,
		auth,
		authAdmin,
		hasPermission,
		org,
		isAdminAuthed,
		Permissions
	]);

};
