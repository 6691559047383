import React from "react";
import CardActionArea from "Components/CardActionArea.js";
import Container from "Components/Container.js";
import Flex from "Components/Flex.js";
import IconButton from "Components/IconButton.js";
import String from "Components/String.js";
import {Paper} from "@material-ui/core";
import {Close as CloseIcon} from "@material-ui/icons";
import scss from "./Pane.module.scss";

/**
 * Pane component
 *
 * Paper with a header and an appropriate width with horizontal scrolling.
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Pane extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Paper
				className={(!this.props.inline ? this.classes : this.props.className)}
				elevation={(!this.props.inline ? 4 : 0)}
				onBlur={this.props.onBlur}
				onClick={this.props.onClick}
				onFocus={this.props.onFocus}>
				{(!this.props.noHeader && this.renderHeader())}
				{this.props.children}
			</Paper>
		);
	}


	/**
	 * Render the header.
	 * 
	 * @return {ReactNode}
	 */
	renderHeader() {
		if (this.props.onHeaderClick) {
			return this.renderHeaderActioned();
		}
		else return this.renderHeaderContent();
	}


	/**
	 * Render the header with action.
	 *
	 * @return {ReactNode}
	 */
	renderHeaderActioned() {
		return (
			<CardActionArea
				className={scss.header}
				component="div"
				disableTouchRipple={true}
				onClick={this.props.onHeaderClick}>
				{this.renderHeaderContent()}
			</CardActionArea>
		);
	}


	/**
	 * Render the header content.
	 * 
	 * @return {ReactNode}
	 */
	renderHeaderContent() {
		return (
			<Container
				alignItems="center"
				className={scss.header}
				columns={`${(this.props.avatar ? "max-content" : "")} 1fr max-content`.trim()}
				justifyContent="space-between"
				pl={1}
				pr={0.5}
				py={0.5}>
				{this.props.avatar}
				<Flex gap={0}>
					<String
						bold={true}
						color={this.props.labelColor}
						lineClamp={1}
						str={this.props.label}
						title={(this.props.tooltip ? this.props.tooltipContent : this.props.label)}
						variant="subtitle1" />
					<String
						color="textSecondary"
						lineClamp={1}
						str={this.props.labelCaption}
						title={this.props.labelCaptionTooltip}
						variant="body2" />
				</Flex>
				<Container
					columnar={true}
					gap={0.5}
					gridAutoColumns="max-content">
					{this.props.icons}
					<IconButton
						color="primary"
						icon={CloseIcon}
						onClick={this.props.onClose}
						tabIndex={(!this.props.visible ? "-1" : "0")}
						title="Close" />
				</Container>
			</Container>
		);
	}


	/**
	 * Get classes.
	 * 
	 * @return {ReactNode}
	 */
	get classes() {
		return [scss.paper, ...(this.props.classes || []), (this.props.className || "")].join(" ").trim();
	}

}

export default Pane;
