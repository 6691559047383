import "./index.scss";
import "array-flat-polyfill";
import api from "./api.js";
import App from "./App/App.js";
import React from "react";
import ReactDOM from "react-dom";
import replaceAllShim from "string.prototype.replaceall";
import {Api} from "@hps/hops-sdk-js";
import * as Sentry from "@sentry/react";

/**
 * Sentry integration
 */
if (process.env.REACT_APP_SENTRY_DSN) {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		release: process.env.REACT_APP_APP
	});
}

/**
 * Wire up `@hps/hops-sdk-js`
 */
Api.getHeaders = () => api.headers;
Api.getInterceptors = () => api.interceptors;
Object.defineProperty(Api, "baseURL", {get: () => api.base});

/**
 * `replaceAll` shim
 */
replaceAllShim.shim();

/**
 * Render!
 */
ReactDOM.render(<App />, document.getElementById("react"));
