import api from "api.js";

/**
 * Org service
 *
 * Utility methods for interacting with orgs.
 *
 * @package Hops
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class OrgService {

	/**
	 * Resolve an org logo URI.
	 *
	 * @param {String} name
	 * @return {String}
	 */
	static resolveLogoUri(name) {
		return `${api.base}/uploads/railway_logos/${name}`;
	}

	/**
	 * Create a new user at the active organisation.
	 *
	 * @param {Object} data API submission object
	 * @return {Promise} Resolves with API created user object
	 */
	static getOrgUsers(params) {
		return api.call({
			url: `/api/org/users`,
			method: "GET",
			params
		}).then(({data, status}) => {
			return {
				errors: (status !== 200),
				data
			};
		});
	}

}

export default OrgService;
