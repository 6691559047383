import React from "react";
import dDev from "Dispatchers/dDev.js";
import Switch from "Components/Switch.js";
import {connect} from "react-redux";

/**
 * F12 dev mode toggle
 *
 * @package HOPS
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class F12Dev extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Switch
				checked={this.props.dev}
				helperText="Enables extra development capabilities."
				onChange={dDev}
				label="Dev Mode" />
		);
	}

}

export default connect(({dev}) => ({dev}))(F12Dev);
