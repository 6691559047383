import React from "react";
import LoginFormBase from "./LoginFormBase.js";
import LoginFormEmailInput from "./LoginFormEmailInput.js";
import LoginFormUsernameInput from "./LoginFormUsernameInput.js";
import Strings from "./LoginForm.strings.json";

/**
 * Login form - password reset
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LoginFormPwr extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<LoginFormBase
				disabled={this.props.disabled}
				noFullWidthSubmit={this.props.noFullWidthSubmit}
				noMarginBottomOnControls={this.props.noMarginBottomOnControls}
				noTitle={this.props.noTitle}
				linkLabel="Back to Login"
				onLinkClick={this.props.onWantsLogin}
				sticky={this.props.sticky}
				str={Strings.caption.pwr}
				submitLabel="Reset Password"
				title="Password Reset">
				<LoginFormUsernameInput
					disabled={this.props.disabled}
					inputRef={this.props.usernameInputRef}
					loginStatus={this.props.error}
					onChange={this.props.onChange}
					value={this.props.username} />
				<LoginFormEmailInput
					disabled={this.props.disabled}
					inputRef={this.props.emailInputRef}
					onChange={this.props.onChange}
					value={this.props.email} />
			</LoginFormBase>
		);
	}

}

export default LoginFormPwr;
