/**
 * Messenger
 *
 * A wrapper around `postMessage` enabling 
 * safe communication with HOPS native wrappers.
 *
 * @package HOPS
 * @subpackage Includes
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Messenger {

	/**
	 * Post a message.
	 *
	 * Uses `postMessage` to broadcast a JSON-encoded 
	 * object containing `msg` and `data` keys. We **ONLY**
	 * broadcast to `HopsMessagingChannel`, as this whole 
	 * functionality is intended for use with Raildays, so 
	 * the native app needs to create the messaging channel.
	 *
	 * https://stackoverflow.com/a/55114952/2982461
	 * 
	 * @param {String} msg Message ID
	 * @param {Object} data Payload data
	 * @return {void}
	 */
	static postMessage(msg, data) {

		const payload = JSON.stringify({msg, data});

		// eslint-disable-next-line no-undef
		if ((typeof HopsMessagingChannel !== "undefined") && HopsMessagingChannel.postMessage) {
			// eslint-disable-next-line no-undef
			HopsMessagingChannel.postMessage(payload);
		}

	}

}

export default Messenger;
