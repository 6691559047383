import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import LibraryRenameDialog from "./LibraryRenameDialog.js";
import LibraryService from "./LibraryService.js";

/**
 * Library browser file renaming dialog
 * 
 * @package HOPS
 * @subpackage Library
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LibraryFileRenameDialog extends AsyncStatefulComponent {

	/**
	 * Submitting.
	 *
	 * @param {String} name
	 * @return {void}
	 */
	handleSubmit = name => {
		return LibraryService.renameFile(this.props.file?.Uuid, name).then(() => {
			this.props.onDone(name);
		});
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<LibraryRenameDialog
				{...this.props}
				onSubmit={this.handleSubmit}
				initialName={this.props.file?.Name}
				title="Rename File" />
		);
	}

}

export default LibraryFileRenameDialog;
