import api from "api.js";

/**
 * HOPS notices service
 *
 * @package HOPS
 * @subpackage NoticesHops
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class HopsNoticesService {

	/**
	 * Get notices.
	 *
	 * @return {Promise}
	 */
	static getNotices() {
		return api.call({url: `/api/notices`}, false).then(({data}) => data);
	}

}

export default HopsNoticesService;
