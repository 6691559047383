import Navigator from "App/Navigator.js";
import Store from "App/Store.js";
import dReset from "Dispatchers/dReset.js";

/**
 * Check our organisation access is valid.
 *
 * @return {void}
 */
export default () => {

	const org = Store.getState().org;
	const orgAdmin = Store.getState().orgAdmin;
	const orgs = Store.getState().auth?.orgs;
	const orgsAdmin = Store.getState().authAdmin?.orgs;

	const orgRevoked = (org && !orgs.map(o => o.Id).includes(org));
	const orgAdminRevoked = (orgAdmin && !orgsAdmin.map(o => o.Id).includes(orgAdmin));

	const orgNotWorking = (org && !orgs.find(o => (o.Id === org))?.UserWorking);
	const orgNotWorkingAdmin = (orgAdmin && !orgsAdmin.find(o => (o.Id === orgAdmin))?.UserWorking);

	/**
	 * Are we still allowed to be here?
	 */
	if (orgRevoked || orgAdminRevoked || orgNotWorking || orgNotWorkingAdmin) {
		dReset();
		Navigator.home();
	}

};
