import api from "api.js";
import dClientSettings from "Dispatchers/dClientSettings.js";

/**
 * Client settings service
 *
 * Enables management of the user's HOPSA client settings.
 *
 * @package HOPS
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class ClientSettingsService {

	/**
	 * Update the active user's client settings.
	 *
	 * This only applies to the *real* user (when admin-logged-in).
	 *
	 * @param {Object} settings
	 * @return {Promise} Resolves when updated on server (updates immediately locally)
	 */
	static update(settings) {

		dClientSettings(settings, true);

		return api.call({
			url: `/api/account/hopsa/settings`,
			method: "PUT",
			data: settings
		});

	}

}

export default ClientSettingsService;
