import React from "react";
import OrgSelectionSelect from "OrgSelection/OrgSelectionSelect.js";
import scss from "./UiBarOrgSelect.module.scss";
import scss2 from "./UiBarControl.module.scss";

export default () => (
	<OrgSelectionSelect
		className={`${scss.UiBarOrgSelect} ${scss2.UiBarControl}`}
		variant="outlined" />
);
