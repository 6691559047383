import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import CardActionArea from "Components/CardActionArea.js";
import ChatDeleteDialog from "./ChatDeleteDialog.js";
import ChatService from "./ChatService.js";
import Container from "Components/Container.js";
import IconButton from "Components/IconButton.js";
import String from "Components/String.js";
import withAuth from "Hoc/withAuth.js";
import withTimes from "Hoc/withTimes.js";
import withSnack from "Hoc/withSnack.js";
import {Badge} from "@material-ui/core";
import {DeleteOutlined as DeleteIcon, VisibilityOutlined as MarkReadIcon} from "@material-ui/icons";

/**
 * `ChatPane` item
 *
 * @package HOPS
 * @subpackage Chat
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class ChatPaneItem extends AsyncStatefulComponent {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Delete dialog
		 *
		 * @type {Boolean}
		 */
		delete: false,

		/**
		 * Loading?
		 * 
		 * @type {Boolean}
		 */
		loading: false

	};

	/**
	 * Clicked.
	 *
	 * @return {void}
	 */
	handleClick = () => this.props.onClick(this.correspondent);

	/**
	 * Delete clicked.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleDelete = e => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({delete: true});
	};

	/**
	 * Delete dialog closed.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleDeleteClose = e => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({delete: false});
	};

	/**
	 * Chat deleted.
	 * 
	 * @return {void}
	 */
	handleDeleted = () => {
		this.props.onDelete?.(this.props.chat, this.correspondent);
		this.setState({delete: false});
	};


	/**
	 * Mark the chat as read.
	 * 
	 * @return {void}
	 */
	handleMarkRead = e => {

		e.preventDefault();
		e.stopPropagation();
		this.setState({loading: true});

		ChatService.read(this.correspondent.Id).then(() => {
			this.props.onMarkedRead(this.correspondent);
		}).catch(e => {
			this.props.snack(e);
		}).finally(() => {
			this.setState({loading: false});
		});

	};


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {

		const caption = this.caption;

		return <>
			<CardActionArea
				disabled={this.state.loading}
				onClick={this.handleClick}
				tabIndex={(!this.props.visible ? "-1" : "0")}>
				<Container columns="1fr 3rem" px={1} py={0.5}>
					<Badge
						anchorOrigin={{horizontal: "left", vertical: "top"}}
						color="error"
						invisible={(this.props.chat.Read || !this.latestMessageSentToUser)}
						variant="dot">
						<Container gap={0.25}>
							<String
								bold={true}
								lineClamp={1}
								str={`${this.correspondent.Fname} ${this.correspondent.Sname}`}
								title={`${this.correspondent.Fname} ${this.correspondent.Sname}`} />
							<String
								lineClamp={3}
								str={this.props.chat.Text.replaceAll("\n", " ")}
								style={this.constructor.textStyles} />
							<String
								color="textSecondary"
								lineClamp={1}
								str={caption}
								title={caption}
								variant="caption" />
						</Container>
					</Badge>
					<Container gap={0.5}>
						<IconButton
							color="primary"
							component="div"
							dimensions="3rem"
							disabled={this.state.loading}
							icon={DeleteIcon}
							onClick={this.handleDelete}
							onMouseDown={e => e.stopPropagation()}
							tabIndex={(!this.props.visible ? "-1" : "0")}
							title="Delete" />
						{((this.latestMessageSentToUser && !this.props.chat.Read) && this.renderMarkReadButton())}
					</Container>
				</Container>
			</CardActionArea>
			<ChatDeleteDialog
				onClose={this.handleDeleteClose}
				onDelete={this.handleDeleted}
				open={this.state.delete}
				user={this.correspondent} />
		</>;

	}


	/**
	 * Render mark read button.
	 * 
	 * @return {ReactNode}
	 */
	renderMarkReadButton() {
		return (
			<IconButton
				color="primary"
				component="div"
				dimensions="3rem"
				disabled={this.state.loading}
				icon={MarkReadIcon}
				onClick={this.handleMarkRead}
				onMouseDown={e => e.stopPropagation()}
				tabIndex={(!this.props.visible ? "-1" : "0")}
				title="Mark Read" />
		);
	}


	/**
	 * Get the caption in the header.
	 * 
	 * @return {String}
	 */
	get caption() {

		const caption = [this.datetime];

		if (this.props.hasMultipleOrgs) {
			this.props.correspondent?.Orgs?.forEach(({NameShort}) => {
				caption.push(NameShort);
			});
		}

		return caption.join(" • ");

	}


	/**
	 * Get the user who is the user's correspondent.
	 *
	 * @return {Object}
	 */
	get correspondent() {
		if (this.latestMessageSentToUser) {
			return this.props.chat.Sender;
		}
		else return this.props.chat.Recipient;
	}


	/**
	 * Get the message datetime string to display.
	 * 
	 * @return {String}
	 */
	get datetime() {
		return this.props.formatTime(this.props.chat.Timestamp);
	}


	/**
	 * Get whether the latest conversation message was sent to the user.
	 *
	 * @return {Boolean}
	 */
	get latestMessageSentToUser() {
		return (this.props.chat.Recipient.Username === this.props.userAccount.Username);
	}


	/**
	 * Text styles.
	 * 
	 * @type {Object}
	 */
	static textStyles = {wordBreak: "break-word"};

}

export default withAuth(withTimes(withSnack(ChatPaneItem)));
