import React from "react";
import Select from "Components/Select.js";

/**
 * Library sort options
 * 
 * @package HOPS
 * @subpackage Library
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LibrarySortPicker extends React.PureComponent {

	/**
	 * Value changed.
	 *
	 * @param {Object} option
	 * @return {void}
	 */
	handleChange = option => this.props.onChange(option.value);


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Select
				disabled={this.props.disabled}
				onChange={this.handleChange}
				options={this.constructor.options}
				value={this.props.value} />
		);
	}


	/**
	 * Options.
	 * 
	 * @type {Array}
	 */
	static options = [
		{
			label: "A - Z",
			value: "+Name"
		},
		{
			label: "Z - A",
			value: "-Name"
		},
		{
			label: "Newest - Oldest",
			value: "-Timestamp"
		},
		{
			label: "Oldest - Newest",
			value: "+Timestamp"
		}
	];

}

export default LibrarySortPicker;
