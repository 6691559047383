import React from "react";
import Container from "Components/Container.js";
import Flex from "Components/Flex.js";
import Link from "Components/Link.js";
import Loader from "Components/Loader.js";
import String from "Components/String.js";
import Typography from "@material-ui/core/Typography";
import UiDrawerItem from "./UiDrawerItem.js";
import UiDrawerOrg from "./UiDrawerOrg.js";
import UiDrawerOrgLogo from "./UiDrawerOrgLogo.js";
import UiDrawerTabs from "./UiDrawerTabs.js";
import Styles from "Resources/Styles.json";
import dUiDrawerClose from "Dispatchers/dUiDrawerClose.js";
import dUiDrawerOpen from "Dispatchers/dUiDrawerOpen.js";
import rem from "Helpers/Rem.js";
import withAuth from "Hoc/withAuth.js";
import withMobile from "Hoc/withMobile.js";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Divider, List, SwipeableDrawer as Drawer} from "@material-ui/core";
import scss from "./UiDrawer.module.scss";

/**
 * UI drawer component
 *
 * The main app navigation menu.
 *
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiDrawer extends React.PureComponent {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {void}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 * 
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Active item
			 * 
			 * @type {Object|null}
			 */
			active: null,

			/**
			 * Active primary function
			 * 
			 * @type {Object|null}
			 */
			activeFunction: this.getFirstPrimaryFunctionLabel()

		};

		/**
		 * Method binds
		 */
		this.handleClick = this.handleClick.bind(this);
		this.handleTabChange = this.handleTabChange.bind(this);

	}


	/**
	 * Component updated.
	 * 
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {

		/**
		 * Route changed
		 */
		if (prevProps.location.pathname !== this.props.location.pathname) {
			this.setState({active: null});
		}

		/**
		 * Available functions changed
		 */
		if (prevProps.UserFunctions !== this.props.UserFunctions) {
			const first = this.getFirstPrimaryFunctionLabel();
			const currentMatch = this.activeFunction;
			this.setState({activeFunction: currentMatch?.Label || first});
		}

	}


	/**
	 * Get whether an item's allowed to be active.
	 * 
	 * @param {Object} item
	 * @return {Boolean}
	 */
	allowActive(item) {
		if (!this.state.active) return true;
		else return (item.Label === this.state.active.Label);
	}


	/**
	 * Get the label of the first available primary function.
	 *
	 * @return {String|null}
	 */
	getFirstPrimaryFunctionLabel() {
		if (!this.props.UserFunctions?.length) return null;
		else return this.props.UserFunctions[0].Label;
	}


	/**
	 * Clicked.
	 *
	 * @param {Object} active
	 * @return {void}
	 */
	handleClick(active) {
		this.setState({active});
	}


	/**
	 * Tab changed.
	 * 
	 * @param {Integer} activeFunction
	 * @return {void}
	 */
	handleTabChange(activeFunction) {
		this.setState({activeFunction});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Drawer
				disableBackdropTransition={true}
				disableDiscovery={true}
				hysteresis={0.25}
				minFlingVelocity={250}
				ModalProps={this.constructor.ModalProps}
				onClose={dUiDrawerClose}
				onOpen={dUiDrawerOpen}
				open={(this.props.UiDrawer || !this.useCollapsedVariant)}
				PaperProps={{className: scss.drawer, style: this.styles}}
				swipeAreaWidth={15}
				variant={(!this.useCollapsedVariant ? "permanent" : "temporary")}>
				<Container>
					<UiDrawerOrgLogo />
					{(this.props.isMobile && <UiDrawerOrg />)}
					{this.renderAuthDetails()}
					{this.renderMain()}
					{(this.hasDetails && this.renderDetails())}
				</Container>
			</Drawer>
		);
	}


	/**
	 * Render the auth details.
	 * 
	 * @return {ReactNode}
	 */
	renderAuthDetails() {
		return (
			<Flex
				className={`${scss.authDetails} ${(this.props.isAdminAuthed ? "admin" : "")}`}
				gap={0.25}
				px={0.5}
				py={0.5}>
				{(this.props.isAdminAuthed && <String centre={true} str="You are admin logged-in as" />)}
				<Typography>
					<span className="user-name">
						{`${this.props.userAccount?.Fname} ${this.props.userAccount?.Sname}`}
					</span>
					{(!this.props.isAdminAuthed && <span> logged-in</span>)}
				</Typography>
			</Flex>
		);
	}


	/**
	 * Render org details.
	 */
	renderDetails() {
		return (
			<Flex mt={-1} pb={1} px={1}>
				<Divider />
				{(this.props.org.WebsiteUrl && <Link label={`${this.props.org.NameShort} Website`} uri={this.orgWebsiteUrl} variant="body2" />)}
				{(this.props.org.TelephoneNo && this.renderDetailsTelNo())}
				{((this.props.org.AdminName || this.props.org.AdminEmail) && this.renderDetailsAdmin())}
				{(this.props.org.SystemSubLine && <String str={this.props.org.SystemSubLine} variant="body2" />)}
			</Flex>
		);
	}


	/**
	 * Render org details (admin details).
	 * 
	 * @return {ReactNode}
	 */
	renderDetailsAdmin() {
		return (
			<Flex gap={0.5}>
				<String
					str={`${this.props.org.NameShort} HOPS Admin`}
					variant="body2" />
				<Flex gap={0.25} pl={0.25}>
					{(this.props.org.AdminName && <String str={this.props.org.AdminName} variant="body2" />)}
					{(this.props.org.AdminEmail && <Link label="Send Email" uri={`mailto:${this.props.org.AdminEmail}`} variant="body2" />)}
				</Flex>
			</Flex>
		);
	}


	/**
	 * Render org details (telephone number).
	 * 
	 * @return {ReactNode}
	 */
	renderDetailsTelNo() {
		let telephoneNo = this.props.org.StaffTelephoneNo || this.props.org.TelephoneNo;
		if (telephoneNo) {
			telephoneNo = telephoneNo.trim().replace(/\s\s+/g, " ");
		}

		return (
			<Flex gap={0.5}>
				<String
					str={`Contact ${this.props.org.NameShort}`}
					variant="body2" />
				<Flex alignItems="center" columnar={true} gap={0.25} pl={0.25}>
					<String
						str="Tel: "
						variant="body2" />
					<Link
						label={telephoneNo}
						uri={`tel:${telephoneNo}`}
						variant="body2" />
				</Flex>
			</Flex>
		);
	}


	/**
	 * Render main items.
	 * 
	 * @return {ReactNode}
	 */
	renderMain() {
		if (this.props.UserFunctions && this.props.UserFunctions?.length) {
			return this.renderMainContent();
		}
		else return (this.loading ? <Container mb={1} pt={0.5}><Loader size={25} /></Container> : null);
	}


	/**
	 * Render main content.
	 * 
	 * @return {ReactNode}
	 */
	renderMainContent() {
		return (
			<Container mb={1} mx={1}>
				<UiDrawerTabs
					active={this.state.tab}
					functions={this.props.UserFunctions}
					onChange={this.handleTabChange}
					value={this.state.activeFunction} />
				{this.renderItems(this.activeFunction?.Children, true, this.activeFunction?.AlwaysExpanded)}
			</Container>
		);
	}


	/**
	 * Render an item.
	 * 
	 * @param {Object} i
	 * @param {mixed} key
	 * @param {Boolean} forceAlwaysOpen optional Force always open (`false`)
	 * @return {ReactNode|null}
	 */
	renderItem(i, key, forceAlwaysOpen=false) {
		return (
			<UiDrawerItem
				allowActive={this.allowActive(i)}
				forceAlwaysOpen={forceAlwaysOpen}
				item={i}
				onClick={this.handleClick}
				key={key} />
		);
	}


	/**
	 * Render drawer items.
	 *
	 * @param {Array} items Item objects to render
	 * @param {Boolean} noItems Render no items message when `items` empty
	 * @param {Boolean} forceAlwaysOpen optional Force always open (`false`)
	 * @return {ReactNode}
	 */
	renderItems(items, noItems=true, forceAlwaysOpen=false) {
		return (
			<List dense={!this.props.isMobile} disablePadding={true}>
				{items?.map((item, key) => this.renderItem(item, key, forceAlwaysOpen))}
				{((!items?.length && noItems) && this.renderNoItems())}
			</List>
		);
	}


	/**
	 * Render the "no items" text for a tab.
	 * 
	 * @return {ReactNode}
	 */
	renderNoItems() {
		return (
			<String
				centre={true}
				color="textSecondary"
				str="(None Available)"
				variant={(!this.useCollapsedVariant ? "inherit" : undefined)} />
		);
	}


	/**
	 * Get the active function object.
	 * 
	 * @return {Object|null}
	 */
	get activeFunction() {
		return this.props.UserFunctions?.find?.(f => (f.Label === this.state.activeFunction));
	}


	/**
	 * Get whether we have any org details to render.
	 * 
	 * @return {Boolean}
	 */
	get hasDetails() {
		if (this.props.org) {
			const {WebsiteUrl, TelephoneNo, AdminName, AdminEmail, SystemSubLine} = this.props.org;
			return !!(WebsiteUrl || TelephoneNo || AdminName || AdminEmail || SystemSubLine);
		}
		else return false;
	}


	/**
	 * Get whether to render in a loading state.
	 * 
	 * return {Boolean}
	 */
	get loading() {
		return (this.props.UserFunctions === null);
	}


	/**
	 * Resolve organisation's complete URL.
	 *
	 * @return {String|undefined}
	 */
	get orgWebsiteUrl() {
		const url = this.props.org.WebsiteUrl;
		if (url && !url.match(new RegExp("^http[s]?://", "g"))) {
			return `https://${url}`;
		}
		else return url;
	}


	/**
	 * Get whether to use the collapsed drawer variant.
	 *
	 * @return {Boolean}
	 */
	get useCollapsedVariant() {
		return (this.props.isMobile || this.props.KioskMode);
	}


	/**
	 * Define our styles.
	 * 
	 * @return {Object}
	 */
	get styles() {
		return {
			height: `calc(100% - ${Styles.uiBarHeight})`,
			marginTop: (this.useCollapsedVariant ? Styles.uiBarHeight : null),
			paddingTop: rem(),
			top: (!this.useCollapsedVariant ? Styles.uiBarHeight : null),
			width: Styles.uiDrawerWidth
		};
	}


	/**
	 * Mobile padding to apply.
	 * 
	 * @type {String}
	 */
	static mp = "1rem";

	/**
	 * Modal props.
	 * 
	 * @type {Object}
	 */
	static ModalProps = {onBackdropClick: dUiDrawerClose};

	/**
	 * Map state to props.
	 *
	 * @param {Boolean} options.KioskMode
	 * @param {Boolean} options.UiDrawer
	 * @param {Array|null} options.UserFunctions
	 * @return {Object}
	 */
	static mapStateToProps({KioskMode, UiDrawer, UserFunctions}) {
		return {KioskMode, UiDrawer, UserFunctions};
	}

}

export default connect(UiDrawer.mapStateToProps)(withAuth(withMobile(withRouter(UiDrawer))));
