import React from "react";
import Container from "Components/Container.js";
import Flags from "Resources/Flags.json";
import String from "Components/String.js";
import Switch from "Components/Switch.js";
import dFlags from "Dispatchers/dFlags.js";
import withFlags from "Hoc/withFlags.js";

/**
 * Feature flags UI
 *
 * @package HOPS
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class F12Flags extends React.PureComponent {

	/**
	 * Flag toggled.
	 *
	 * @param {String} flag
	 * @return {void}
	 */
	handleToggle = flag => {
		if (!this.props.isFlagOn(flag)) {
			dFlags([...this.props.flags, flag]);
		}
		else dFlags(this.props.flags.filter(f => (f !== flag)));
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container>
				<String bold={true} str="Feature Flags" />
				{this.renderContent()}
			</Container>
		);
	}


	/**
	 * Render content.
	 * 
	 * @return {ReactNode}
	 */
	renderContent() {
		if (Flags.length) return this.renderFlags();
		else return this.constructor.renderEmpty();
	}


	/**
	 * Render flags.
	 * 
	 * @return {ReactNode}
	 */
	renderFlags() {
		return Flags.map((flag, key) => (
			<Container
				alignItems="center"
				columns={`1fr ${(!flag.active ? "max-content" : "")}`}
				key={key}>
				<Switch
					checked={this.props.isFlagOn(flag.id)}
					label={flag.label}
					onChange={this.handleToggle}
					value={flag.id} />
				{(!flag.active && this.constructor.renderInactive())}
			</Container>
		));
	}


	/**
	 * Render the empty message.
	 * 
	 * @return {ReactNode}
	 */
	static renderEmpty() {
		return (
			<String
				color="textSecondary"
				str="No feature flags available." />
		);
	}


	/**
	 * Render the inactive flag warning.
	 * 
	 * @return {ReactNode}
	 */
	static renderInactive() {
		return (
			<String
				color="error"
				str="(Inactive)"
				title="This flag will be ignored even when enabled." />
		);
	}

}

export default withFlags(F12Flags);
