import React from "react";
import Navigator from "App/Navigator.js";
import dOrg from "Dispatchers/dOrg.js";
import qs from "query-string";
import withAuth from "Hoc/withAuth.js";
import {withRouter} from "react-router-dom";

/**
 * Navigation view
 *
 * This is a special view which accepts as URI query
 * string parameters an org ID and URI to route to.
 *
 * (It's use to e.g. handle incoming push notifications.)
 *
 * @package HOPS
 * @subpackage Navigation
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class NavigationView extends React.PureComponent {

	/**
	 * Component mounted.
	 *
	 * We immediately navigate as directed.
	 *
	 * @return {void}
	 */
	componentDidMount() {

		const q = qs.parse((this.props.location.search || ""));
		const org = parseInt(q.org);
		const {to} = q;

		if (!to) {
			Navigator.home();
		}
		else if (org && this.props.orgIds.includes(org) && (org !== this.props.org?.Id)) {
			dOrg(org, to);
		}
		else {
			Navigator.navigate(to);
		}

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return null;
	}

}

export default withAuth(withRouter(NavigationView));
