import React from "react";
import BackIcon from "@material-ui/icons/ArrowBack";
import Flex from "Components/Flex.js";
import Fab from "Components/Fab.js";
import HomeIcon from "@material-ui/icons/Home";
import scss from "./RaildaysUiControls.module.scss";
import {withRouter} from "react-router-dom";

/**
 * Raildays UI control strip
 *
 * Provides the global home/back controls at the bottom of the HOPS 
 * screens when we're rendering in the Raildays app mode.
 * 
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class RaildaysUiControls extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Flex
				alignItems="center"
				className={scss.root}
				columnar={true}
				justifyContent="center"
				pb={1}>
				<Fab
					color="secondary"
					disabled={!this.props.history.length}
					icon={BackIcon}
					inline={true}
					onClick={() => this.props.history.goBack()}
					size="small"
					variant="extended" />
				<Fab
					icon={HomeIcon}
					inline={true}
					size="medium"
					uri="/raildays"
					variant="extended" />
				<Fab
					className={scss.layoutPlaceholderIcon}
					color="secondary"
					icon={() => null}
					inline={true}
					size="small"
					variant="extended" />
			</Flex>
		);
	}

}

export default withRouter(RaildaysUiControls);
