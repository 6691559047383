import React from "react";
import Dialog from "Components/Dialog.js";
import Flex from "Components/Flex.js";
import Link from "Components/Link.js";
import Navigator from "App/Navigator.js";
import String from "Components/String.js";
import {Typography} from "@material-ui/core";

/**
 * Login error dialog
 *
 * @package HOPS
 * @subpackage Login
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LoginErrorDialog extends React.PureComponent {

	/**
	 * Handle a link click.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleLinkClick = (e, uri) => {
		this.props.onClose();
		Navigator.navigate(uri);
	};


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {

		const isUnhandled = ![401, 403].includes(this.props.error);

		return (
			<Dialog
				onClose={this.props.onClose}
				open={this.props.open}
				size={(!isUnhandled ? "md" : undefined)}
				title={(!isUnhandled ? "Login denied" : "Unhandled error")}>
				<Flex>
					{(!isUnhandled ? this.renderDefault() : this.constructor.renderUnhandled())}
				</Flex>
			</Dialog>
		);

	}


	/**
	 * Render the default state.
	 *
	 * @return {ReactNode}
	 */
	renderDefault() {
		return (
			<>
				<String str="Unfortunately your login attempt was unsuccessful." />
				<String str="Possible Causes:" bold={true} />
				<Flex pl={1}>
					<String str="Is your user name correct?" bold={true} />
					<Typography>
						Usernames are usually your first name, then a full stop, then your surname, eg, danny.scroggins (no spaces). Sometimes a number is appended, e.g., danny.scroggins2.
					</Typography>
					<Typography>
						If you work for more than one organisation that uses HOPS you might still have a separate username and password for each one. You will have a separate username and password for each organisation until you actively merge them together.
					</Typography>
					<String str="Is your password correct?" bold={true} />
					<Typography>
						Everyone has a password to access HOPS. Your first password will have been e-mailed to you, and on your first log in you will have to (or will have had to) change it to something secret to you. If you have forgotten your password you can retrieve it <Link onClick={this.handleLinkClick} value="/login?mode=pwr">here</Link>.
					</Typography>
					<String str="Is your organisation registered to use HOPS?" bold={true} />
					<Typography>
						HOPS is only available to registered organisations. Individuals cannot register. You can only log in to HOPS if the organisation you work for is registered. If your organisation is registered but you haven't received a username and password to access the system, please contact your manager.
					</Typography>
					<String str="Are cookies enabled?" bold={true} />
					<Typography>
						Cookies must be enabled to use this site. <Link onClick={this.handleLinkClick} value="/cookies.php">How does HOPS use cookies?</Link>
					</Typography>
				</Flex>
			</>
		);
	}


	/**
	 * Render the unhandled error state.
	 *
	 * @return {ReactNode}
	 */
	static renderUnhandled() {
		return (
			<>
				<String str="A login error occurred." />
				<String str="Please try again in a little while." />
				<String str="Still having problems? Let your HOPS Administrator know." />
			</>
		);
	}

}

export default LoginErrorDialog;
