import React from "react";
import Document from "Helpers/Document.js";
import dRoute from "Dispatchers/dRoute.js";

/**
 * Wrapper that enables tracking of route activations
 *
 * @package HOPS
 * @subpackage Wrappers
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const wrapRoute = (RouteComponent, route) => {

	/**
	 * Class wrapper
	 *
	 * @package HOPS
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright Heritage Operations Processing Limited
	 */
	const wrap = class WrappedRouteComponent extends React.PureComponent {

		/**
		 * We mounted.
		 * 
		 * @return {void}
		 */
		componentDidMount() {
			dRoute(route);
			Document.setTitle(route.title);
		}


		/**
		 * Render.
		 * 
		 * @return {ReactNode}
		 */
		render() {
			return <RouteComponent route={route} {...this.props} />;
		}

	};

	return wrap;

};

export default wrapRoute;
