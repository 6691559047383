/**
 * Background host
 *
 * Enables collection of background activity promises when can then 
 * be cancelled/cleared at a future time should they become redundant.
 *
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Bghost {

	/**
	 * Activate activity collection
	 *
	 * Cancellation of tasks is only enabled when this is `true`.
	 *
	 * @type {Boolean}
	 */
	static BGHOST_ACTIVE = false;

	/**
	 * All our promises
	 *
	 * @type {Array}
	 */
	static BGHOST_PROMISES = [];


	/**
	 * Register a new promise.
	 *
	 * Refer to source for details of this method.
	 * 
	 * @param {Promise} activity
	 * @param {Function} final optional Method to call on `activity.finally()`
	 * @param {String} series optional Series identifier to register to
	 * @return {Promise}
	 */
	static register(activity, final=null, series=null) {

		/**
		 * Construct the wrapper
		 */
		const promise = new Promise((resolve, reject) => {
			activity.then(res => {
				this.callbackWhenPromiseOk(promise, resolve, [res]);
			}).catch(rej => {
				this.callbackWhenPromiseOk(promise, reject, [rej]);
			}).finally(() => {
				if (final) this.callbackWhenPromiseOk(promise, final);
				this.unregister(promise);
			});
		});

		/**
		 * Store the wrapper
		 */
		this.BGHOST_PROMISES.push({promise, series});

		/**
		 * Return the wrapper
		 */
		return promise;

	}


	/**
	 * Unregister a given promise.
	 *
	 * This will have no effect when not currently registered.
	 *
	 * @param {Object} prms
	 * @return {void}
	 */
	static unregister(prms) {
		this.BGHOST_PROMISES = this.BGHOST_PROMISES.filter(p => (p !== prms));
	}


	/**
	 * Clear all active promises.
	 * 
	 * @return {void}
	 */
	static clearAll() {
		this.BGHOST_PROMISES = [];
	}


	/**
	 * Clear promises by series name.
	 * 
	 * @param {String} series
	 * @return {void}
	 */
	static clearSeries(series) {
		this.BGHOST_PROMISES = this.BGHOST_PROMISES.filter(p => (p.series !== series));
	}


	/**
	 * Run a function if a given promise is currently registered.
	 *
	 * @param {Promise} promise
	 * @param {Function} callback
	 * @param {Array} args optional Arguments to unpack to callback
	 * @return {void}
	 */
	static callbackWhenPromiseOk(promise, callback, args=[]) {
		if (this.BGHOST_PROMISES.map(p => p.promise).includes(promise) || !this.BGHOST_ACTIVE) {
			callback(...args);
		}
	}

}

export default Bghost;
