import Store from "App/Store.js";
import dNoticesHops from "./dNoticesHops.js";

export default () => {
	const notices = {...Store.getState().NoticesHops};
	Object.keys(notices).forEach(n => {
		if (n !== "HopsSystemNotice") {
			delete notices[n];
		}
	});
	dNoticesHops(notices);
};
