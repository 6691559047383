import React from "react";
import Container from "Components/Container.js";
import DOMPurify from "dompurify";
import Html from "Helpers/Html.js";
import ReactHtmlParser from "@hedgedoc/html-to-react";
import String from "Components/String.js";
import scss from "./ChatWindowMessage.module.scss";
import withTimes from "Hoc/withTimes.js";
import {Paper, Typography} from "@material-ui/core";
import {connect} from "react-redux";

/**
 * Chat window message item
 *
 * @package HOPS
 * @subpackage Chat
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class ChatWindowMessage extends React.Component {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container
				className={scss.container}
				gap={0.25}
				justifySelf={(!this.props.sentByCorrespondent && "flex-end")}>
				<Paper variant="outlined" style={this.styles}>
					<Container px={0.25} py={0.25}>
						<Typography component="div">
							{ReactHtmlParser(this.messageHtml, this.constructor.htmlParserOptions)}
						</Typography>
					</Container>
				</Paper>
				<Container
					justifyItems={(!this.props.sentByCorrespondent && "flex-end")}
					px={0.3}>
					<String
						color="textSecondary"
						str={this.caption}
						variant="caption" />
				</Container>
			</Container>
		);
	}


	/**
	 * Caption text.
	 * 
	 * @return {String}
	 */
	get caption() {

		let caption = null;
		const name = this.props.message.Sender.Fname;
		const time = this.props.formatTime(this.props.message.Timestamp);

		if (!this.props.sentByCorrespondent) {
			caption = [`You`, time];
		}
		else caption = [time, name];

		return caption.join(" • ");

	}


	/**
	 * Get HTML version of our message (with link tags).
	 * 
	 * @return {String}
	 */
	get messageHtml() {
		return DOMPurify.sanitize(`<p>${Html.convertPlainTextLinks(this.props.message.Text).replaceAll("\n", "<br>")}</p>`);
	}


	/**
	 * Styles.
	 * 
	 * @type {Object}
	 */
	get styles() {
		return {
			background: (this.props.sentByCorrespondent ? (this.props.dark ? "#212121" : "#fafafa") : (this.props.dark ? "#263238" : "#f1f8e9")),
			justifySelf: (this.props.sentByCorrespondent ? "flex-start" : "flex-end")
		};
	}


	/**
	 * `ReactHtmlParser` options.
	 * 
	 * @type {Object}
	 */
	static htmlParserOptions = {
		transform: Html.reactHtmlParserTransform
	};

}

export default connect(({dark}) => ({dark}))(withTimes(ChatWindowMessage));
