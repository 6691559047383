import React from "react";
import FormGroup from "./FormGroup.js";
import {Switch as SwitchMui} from "@material-ui/core";

/**
 * Switch component
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Switch extends React.PureComponent {

	/**
	 * Toggled.
	 * 
	 * @return {void}
	 */
	handleChange = () => this.props.onChange(this.props.value);


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<FormGroup
				control={this.renderSwitch()}
				helperText={this.props.helperText}
				label={this.props.label} />
		);
	}


	/**
	 * Render the switch.
	 * 
	 * @return {ReactNode}
	 */
	renderSwitch() {
		return (
			<SwitchMui
				color={(this.props.color || "primary")}
				checked={this.props.checked}
				onClick={this.handleChange}
				size="small" />
		);
	}

}

export default Switch;
