import React from "react";
import Flex from "Components/Flex.js";
import LibraryBrowserContentSet from "./LibraryBrowserContentSet.js";
import String from "Components/String.js";
import withTimes from "Hoc/withTimes.js";

/**
 * Library browser content container
 * 
 * @package HOPS
 * @subpackage Library
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LibraryBrowserContent extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Flex
				alignItems={(this.isEmpty && "center")}
				flexGrow={1}
				justifyContent={(this.isEmpty && "center")}>
				{(!this.isEmpty ? this.renderMain() : this.constructor.renderEmpty())}
			</Flex>
		);
	}


	/**
	 * Render main content.
	 * 
	 * @return {ReactNode}
	 */
	renderMain() {
		return this.sets.map((set, key) => (
			<LibraryBrowserContentSet
				canDelete={this.props.canDelete}
				canEdit={this.props.canEdit}
				disabled={this.props.disabled}
				key={key}
				neighbouringSets={this.props.sets}
				onFileMenu={this.props.onFileMenu}
				onFileRename={this.props.onFileRename}
				onFilesDeleted={this.props.onFilesDeleted}
				onFilesMoved={this.props.onFilesMoved}
				onDelete={this.props.onDeleteSet}
				onRename={this.props.onRenameSet}
				onReplaceFile={this.props.onReplaceFile}
				onUpload={this.props.onUpload}
				set={set} />
		));
	}


	/**
	 * Get whether we have any sets to render.
	 * 
	 * @return {Boolean}
	 */
	get isEmpty() {
		return !this.props.sets?.length;
	}


	/**
	 * Get sets prepared for rendering.
	 *
	 * Sets/files are sorted according to the current sort order.
	 *
	 * We also give all sets a default name based on their timestamp 
	 * when their is no name, so we have something consistent to display.
	 * 
	 * @return {Array}
	 */
	get sets() {

		/**
		 * Get sets
		 */
		const sets = this.props.sets;

		/**
		 * Sort order
		 */
		const sort = this.props.sort;
		const sortAsc = (sort[0] === "+");

		/**
		 * Sorting function
		 */
		const sorter = (a, b, sortField) => {
			if (a[sortField] > b[sortField]) return (sortAsc ? 1 : -1);
			else if (a[sortField] < b[sortField]) return (sortAsc ? -1 : 1);
			else return 0;
		};


		/**
		 * Map all sets
		 */
		return sets.map(set => {

			/**
			 * Handle set name
			 */
			if (!set.Name) {
				set.Name = this.props.formatTime(
					set.CreatedTime,
					{
						dateFormat: `ddd Do MMMM YYYY`,
						timeFormat: null,
						friendly: false
					}
				);
			}

			/**
			 * Sort the set's files
			 */
			set.Files = set.Files.sort((a, b) => {
				return sorter(
					a,
					b,
					((sort.substring(1) === "Name") ? "Name" : "UploadedTime")
				);
			});

			return set;

		}).sort((a, b) => {
			return sorter(
				a,
				b,
				((sort.substring(1) === "Name") ? "Name" : "CreatedTime")
			);
		});

	}


	/**
	 * Render the empty state.
	 * 
	 * @return {ReactNode}
	 */
	static renderEmpty() {
		return (
			<String
				color="textSecondary"
				str="No files." />
		);
	}

}

export default withTimes(LibraryBrowserContent);
