import rem from "Helpers/Rem.js";
import {memo} from "react";

export default memo(props => {

	const styles = {
		alignContent: props.alignContent,
		alignItems: props.alignItems,
		alignSelf: props.alignSelf,
		display: (!props.hidden ? "flex" : "none"),
		flexDirection: (props.columnar ? "row" : "column"),
		flexWrap: (props.wrap ? "wrap" : undefined),
		gap: rem(props.gap),
		height: props.height,
		justifyContent: props.justifyContent,
		justifyItems: props.justifyItems,
		justifySelf: props.justifySelf,
		marginTop: ((props.mt !== undefined) ? rem(props.mt) : undefined),
		marginBottom: ((props.mb !== undefined) ? rem(props.mb) : undefined),
		marginLeft: ((props.ml !== undefined) ? rem(props.ml) : undefined),
		marginRight: ((props.mr !== undefined) ? rem(props.mr) : undefined),
		paddingTop: ((props.pt !== undefined) ? rem(props.pt) : ((props.py !== undefined) ? rem(props.py) : undefined)),
		paddingBottom: ((props.pb !== undefined) ? rem(props.pb) : ((props.py !== undefined) ? rem(props.py) : undefined)),
		paddingLeft: ((props.pl !== undefined) ? rem(props.pl) : ((props.px !== undefined) ? rem(props.px) : undefined)),
		paddingRight: ((props.pr !== undefined) ? rem(props.pr) : ((props.px !== undefined) ? rem(props.px) : undefined)),
		minHeight: props.minHeight,
		position: props.position,
		width: (props.fullWidth ? "100%" : undefined),
		...props.style
	};

	return (
		<div
			className={props.className}
			style={styles}>
			{props.children}
		</div>
	);

});
