/**
 * Clipboard helper
 *
 * @package HOPS
 * @subpackage Helpers
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Clipboard {

	/**
	 * Copy a value to the clipboard.
	 * 
	 * @param {String} value
	 * @return {void}
	 */
	static copy(value) {

		const clip = document.createElement("textarea");
		clip.value = value;

		document.body.appendChild(clip);

		setTimeout(() => {
			clip.select();
			document.execCommand("Copy");
			document.body.removeChild(clip);
		});

	}

}

export default Clipboard;
