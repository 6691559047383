import React from "react";
import {IconButton, InputAdornment} from "@material-ui/core";
import {Clear as ClearIcon} from "@material-ui/icons";

export default ({onClick}) => (
	<InputAdornment position="end">
		<IconButton onClick={onClick} title="Clear" size="small">
			<ClearIcon />
		</IconButton>
	</InputAdornment>
);
