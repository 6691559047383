import api from "api.js";
import Cookies from "Helpers/Cookies.js";
import Store from "App/Store.js";
import {StorePersistor} from "App/Store.js";
import TelemetryService from "Telemetry/TelemetryService.js";

export default () => {
	TelemetryService.report("dAuthAdminClear");
	Cookies.delete(api.HOPS_ADMIN_MASQUERADE_COOKIE);
	Store.dispatch({type: "authAdminClear"});
	StorePersistor.flush();
};
