import Bghost from "App/Bghost.js";
import dNotifications from "Dispatchers/dNotifications.js";
import NotificationService from "Notifications/NotificationService.js";
import Store from "App/Store.js";

export default () => {
	return new Promise((resolve, reject) => {

		if (Store.getState().authAdmin?.token) {
			reject(new Error("Notifications aren't available while admin logged-in."));
			return;
		}

		Bghost.register(NotificationService.get(), null, "ui").then(notifications => {
			dNotifications(notifications);
			resolve();
		}).catch(reject);

	});

};
