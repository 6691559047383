import React from "react";
import HomeIcon from "@material-ui/icons/Home";
import IconButton from "Components/IconButton.js";
import Image from "Components/Image.js";
import OrgService from "Services/OrgService.js";
import scss from "./UiBar.module.scss";
import withAuth from "Hoc/withAuth.js";

/**
 * UI bar home button
 *
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiBarHomeButton extends React.PureComponent {

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Org logo loaded
		 * 
		 * @type {Boolean}
		 */
		orgLogoLoaded: false

	};


	/**
	 * Org logo loaded.
	 * 
	 * @return {void}
	 */
	handleOrgLogoLoad = () => this.setState({orgLogoLoaded: true});


	/**
	 * Component updated.
	 * 
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.useOrgLogo !== this.props.useOrgLogo) {
			this.setState({orgLogoLoaded: false});
		}
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<IconButton
				className={`${scss.iconButton} ${((this.props.useOrgLogo && this.state.orgLogoLoaded) && scss.iconButtonImage)}`}
				color="secondary"
				disableRipple={(this.props.useOrgLogo && this.state.orgLogoLoaded)}
				icon={this.icon}
				style={this.props.style}
				title="Home"
				uri="/" />
		);
	}


	/**
	 * Icon.
	 * 
	 * @return {ReactNode}
	 */
	get icon() {
		if (this.props.useOrgLogo) {
			return (
				<Image
					alt={this.props.org?.Name}
					errorComponent={<HomeIcon />}
					loadingComponent={<HomeIcon style={{position: "absolute"}} />}
					loaderOutsideContainer={true}
					onLoad={this.handleOrgLogoLoad}
					src={OrgService.resolveLogoUri(this.props.org?.Logo)}
					style={(this.state.orgLogoLoaded ? this.constructor.orgLogoStyles : null)} />
			);
		}
		else return <HomeIcon />;
	}


	/**
	 * Org logo styles.
	 * 
	 * @type {Object}
	 */
	static orgLogoStyles = {maxWidth: "90%"};

}

export default withAuth(UiBarHomeButton);
