import React from "react";
import TextField from "Components/TextField.js";

/**
 * Login form password input
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LoginFormPasswordInput extends React.PureComponent {

	/**
	 * Input
	 *
	 * @type {ReactRef}
	 */
	inputRef = React.createRef();


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.loginStatus !== this.props.loginStatus) {
			if (this.props.autoFocusOnError && this.props.loginStatus) {
				setTimeout(() => {
					if (this.inputRef?.current) {
						this.inputRef.current.select();
					}
				});
			}
		}
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<TextField
				disabled={this.props.disabled}
				error={this.error}
				helperText={this.helperText}
				inputRef={(this.props.inputRef || this.inputRef)}
				label="Password"
				name="password"
				onChange={this.props.onChange}
				placeholder="Password"
				required={true}
				shrink={true}
				type="password"
				value={this.props.value}
				variant="standard" />
		);
	}


	/**
	 * Get whether we've an error.
	 * 
	 * @return {ReactNode}
	 */
	get error() {
		return (this.props.loginStatus === 401);
	}


	/**
	 * Input helper text.
	 * 
	 * @return {String}
	 */
	get helperText() {
		if (!this.error) return null;
		else return (this.props.errorText || "You've entered your password incorrectly");
	}

}

export default LoginFormPasswordInput;
