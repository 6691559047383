import React from "react";
import rem from "Helpers/Rem.js";
import ReactHelper from "Helpers/React.js";
import UriClickableComponent from "Includes/UriClickableComponent.js";
import {IconButton as IconButtonMui, Tooltip} from "@material-ui/core";

/**
 * Icon button
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class IconButton extends UriClickableComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Tooltip title={(this.props.title || "")}>
				<div className={this.props.classNameOuter}>
					<IconButtonMui
						className={this.props.className}
						color={(this.props.color || "primary")}
						component={this.props.component}
						disabled={this.props.disabled}
						disableFocusRipple={this.props.disableRipple}
						disableRipple={this.props.disableRipple}
						onClick={this.handleClick}
						onMouseDown={this.props.onMouseDown}
						size={(this.props.size || "small")}
						style={this.styles}
						tabIndex={this.props.tabIndex}>
						{ReactHelper.render(this.props.icon)}
					</IconButtonMui>
				</div>
			</Tooltip>
		);
	}


	/**
	 * Dimensions.
	 * 
	 * @type {String} CSS
	 */
	get dimensions() {
		return (this.props.dimensions || rem(3));
	}


	/**
	 * Styles.
	 * 
	 * @type {Object}
	 */
	get styles() {
		return {
			height: this.dimensions,
			width: this.dimensions,
			...this.props.style
		};
	}

}

export default IconButton;
