import React from "react";
import F12App from "./F12App.js";
import F12Library from "./F12Library.js";
import F12Notifications from "./F12Notifications.js";
import TabPanel from "Components/TabPanel.js";
import withAuth from "Hoc/withAuth.js";

/**
 * F12 view content
 * 
 * @package HOPS
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class F12ViewContent extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (this.props.appOnly ? <F12App /> : this.renderTabbed());
	}


	/**
	 * Render tabbed.
	 * 
	 * @return {ReactNode}
	 */
	renderTabbed() {
		return <TabPanel sticky="10rem" tabs={this.tabs} />;
	}


	/**
	 * Tabs
	 * 
	 * @type {Array}
	 */
	get tabs() {
		return [
			{
				label: "App",
				uri: "/f12",
				render: () => <F12App />
			},
			{
				label: "Library",
				uri: "/f12/library",
				hidden: !this.props.org?.Id,
				render: () => <F12Library />
			},
			{
				label: "Notifications",
				hidden: ![6, 33].includes(this.props.org?.Id),
				uri: "/f12/notifications",
				render: () => <F12Notifications />
			}
		];
	}

}

export default withAuth(F12ViewContent);
