import {v4 as uuid} from "uuid";

export default {

	/**
	 * Access OK for current view
	 * 
	 * @type {Boolean}
	 */
	Access: true,

	/**
	 * Access denied reason object
	 * 
	 * @type {Boolean}
	 */
	AccessReason: null,

	/**
	 * Running tasks?
	 *
	 * @type {Boolean}
	 */
	ActiveTaskRun: false,

	/**
	 * Reset auth when unexpected (e.g. unhandled 403)?
	 *
	 * @type {Boolean}
	 */
	AuthResetWasUnexpected: false,

	/**
	 * Chat conversations
	 *
	 * @type {Array|null}
	 */
	Chats: null,

	/**
	 * Chat pane open
	 *
	 * @type {Boolean}
	 */
	ChatPane: false,

	/**
	 * Client settings
	 *
	 * Local cache of changed `clientSettings` to avoid race conditions.
	 *
	 * @type {Object|null}
	 */
	ClientSettingsLocal: null,

	/**
	 * Comms message counts
	 *
	 * Folder ID => count of messages in that folder
	 *
	 * @type {Object}
	 */
	CommsMessageCounts: {},

	/**
	 * Timestamp of last navigation
	 *
	 * Used as hack to force route reload when navigating to same view.
	 *
	 * @type {Integer}
	 */
	HistoryTimestamp: 0,

	/**
	 * Kiosk mode enabled
	 *
	 * @type {Boolean}
	 */
	KioskMode: false,

	/**
	 * Logout dialog visible
	 *
	 * @type {Boolean}
	 */
	LogoutDialog: false,

	/**
	 * Notices (HOPS API)
	 * 
	 * @type {Object|null}
	 */
	NoticesHops: null,

	/**
	 * Notifications
	 * 
	 * @type {Array}
	 */
	Notifications: [],

	/**
	 * Notifications pane open
	 * 
	 * @type {Boolean}
	 */
	NotificationsPane: false,

	/**
	 * URI to target when switching org
	 * 
	 * @type {String|null}
	 */
	OrgSwitchTargetUri: null,

	/**
	 * Permissions index
	 *
	 * @type {Object|null}
	 */
	Permissions: null,

	/**
	 * Route active
	 *
	 * @type {Object|null}
	 */
	Route: null,

	/**
	 * Session ID
	 *
	 * @type {String}
	 */
	Session: uuid().replace(/-/g, ""),

	/**
	 * UI drawer visible
	 *
	 * @type {Boolean} Mobile-only
	 */
	UiDrawer: false,

	/**
	 * Update available?
	 * 
	 * @type {Boolean}
	 */
	UpdateAvailable: false,

	/**
	 * Updated this session?
	 * 
	 * @type {Boolean}
	 */
	Updated: false,

	/**
	 * User functions we've available
	 * 
	 * @type {Array|null}
	 */
	UserFunctions: null

};
