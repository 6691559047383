import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import ChatDeleteDialog from "./ChatDeleteDialog.js";
import ChatService from "./ChatService.js";
import ChatUserPicker from "./ChatUserPicker.js";
import ChatWindowMessage from "./ChatWindowMessage.js";
import Container from "Components/Container.js";
import Flex from "Components/Flex.js";
import IconButton from "Components/IconButton.js";
import Loader from "Components/Loader.js";
import Pane from "Components/Pane.js";
import String from "Components/String.js";
import TextField from "Components/TextField.js";
import scss from "./ChatWindow.module.scss";
import throttle from "lodash.throttle";
import withAuth from "Hoc/withAuth.js";
import withSnack from "Hoc/withSnack.js";
import {ClickAwayListener, IconButton as IconButtonMui, InputAdornment} from "@material-ui/core";
import {DeleteOutlined as DeleteIcon, Send as SendIcon} from "@material-ui/icons";

/**
 * Chat window
 *
 * An individual chat window pane.
 *
 * @package HOPS
 * @subpackage Chat
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class ChatWindow extends AsyncStatefulComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 * 
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Allowed to send to correspondent
			 * 
			 * @type {Boolean|null} `null` = unknown
			 */
			canCorrespond: null,

			/**
			 * Correspondent's account details
			 *
			 * @type {Object|null}
			 */
			correspondent: null,

			/**
			 * Open?
			 *
			 * When closed, only the header is visible.
			 *
			 * The user clicks the header to expand.
			 *
			 * @type {Boolean}
			 */
			open: true,

			/**
			 * Display delete dialog
			 * 
			 * @type {Boolean}
			 */
			deleting: false,

			/**
			 * Message to send
			 *
			 * @type {String}
			 */
			message: "",

			/**
			 * Messages in the conversation
			 * 
			 * @type {Array}
			 */
			messages: [],

			/**
			 * Sending message
			 *
			 * @type {Boolean}
			 */
			sending: false,

			/**
			 * Loading?
			 * 
			 * @type {Boolean}
			 */
			error: false,

			/**
			 * Loading
			 * 
			 * @type {Boolean}
			 */
			loading: !!props.chat.User,

			/**
			 * Loading older messages
			 * 
			 * @type {Boolean}
			 */
			loadingOlderMessages: false,

			/**
			 * Focused?
			 * 
			 * @type {Boolean}
			 */
			focused: true,

			/**
			 * Unread messages
			 * 
			 * @type {Boolean}
			 */
			unread: false

		};

		/**
		 * Refs
		 * 
		 * @type {ReactRef}
		 */
		this.composer = React.createRef();
		this.messageContainer = React.createRef();
		this.messageContainerInner = React.createRef();

		/**
		 * New messages loader timer ID
		 * 
		 * @type {Integer|null}
		 */
		this.loadMessagesTimer = null;

		/**
		 * Scrolled to bottom?
		 *
		 * @type {Boolean}
		 */
		this.scrolledToBottom = true;

	}

	/**
	 * Check whether the user can correspond with the target user.
	 *
	 * @param {Function} callback optional
	 * @return {void}
	 */
	checkCanUserCorrespond = (callback=undefined) => {
		ChatService.checkCanCorrespond(this.props.chat.User?.Id).then(ok => {
			this.setState({canCorrespond: ok}, callback);
		}).catch(() => null);
	};

	/**
	 * Focus the composer input.
	 * 
	 * @return {void}
	 */
	focusComposer = () => this.composer.current?.focus();

	/**
	 * Blurred.
	 * 
	 * @return {void}
	 */
	handleBlur = () => this.setState({focused: false});

	/**
	 * Message changed.
	 * 
	 * @param {String} message
	 * @return {void}
	 */
	handleChangeMessage = message => {
		if (!this.state.sending) {
			this.setState({message: (message || "")});
		}
	};

	/**
	 * Close the window.
	 *
	 * @return {void}
	 */
	handleClose = () => this.props.onClose(this.props.chat);

	/**
	 * Focused.
	 * 
	 * @return {void}
	 */
	handleFocus = () => {
		if (this.state.open) {
			this.setState({focused: true}, () => {
				if (this.shouldMarkRead) {
					this.markConversationRead();
				}
			});
		}
	};

	/**
	 * Toggling open visibility.
	 * 
	 * @return {void}
	 */
	handleOpenToggle = () => {
		this.setState({
			focused: !this.state.open,
			open: !this.state.open
		});
	};

	/**
	 * Delete button clicked.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleDelete = e => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({deleting: true});
	};

	/**
	 * Delete dialog closed.
	 * 
	 * @return {void}
	 */
	handleDeleteClose = () => {
		this.setState({deleting: false});
	};

	/**
	 * Chat was deleted.
	 *
	 * @return {void}
	 */
	handleDeleted = () => {
		this.props.onDelete(this.props.chat, this.props.chat.User);
	};

	/**
	 * Message container scrolled.
	 *
	 * We need to load new messages.
	 * 
	 * @return {void}
	 */
	handleMessageContainerScroll = throttle(e => {

		if ((e.target.scrollTop === 0) && this.state.messages?.length) {

			this.setState({loadingOlderMessages: true});

			const getConversation = ChatService.getConversation(
				this.props.chat.User.Id,
				this.oldestMessageTimestamp
			);

			getConversation.then(({Messages}) => {
				this.setState(
					{
						messages: [...this.state.messages, ...Messages]
					},
					() => {
						setTimeout(() => this.scrollToMessage(Messages.length));
					}
				);
			}).catch(e => {
				this.props.snack(e);
			}).finally(() => {
				this.setState({loadingOlderMessages: false});
			});

		}

		else if (this.shouldMarkRead) {
			this.markConversationRead();
		}

		this.scrolledToBottom = this.isMessageContainerScrolledToBottom;

	}, 150);

	/**
	 * Handle new messages.
	 *
	 * We need to scroll the message pane and refocus the composer.
	 * 
	 * @return {void}
	 */
	handleNewMessages = () => {
		this.scrollMessageContainer();
	};

	/**
	 * Pane background clicked.
	 * 
	 * @return {void}
	 */
	handlePaneClick = () => {
		if (this.state.open) {
			this.handleFocus();
		}
	};

	/**
	 * Send a message.
	 * 
	 * @return {void}
	 */
	handleSend = () => {

		/**
		 * Sending!
		 */
		this.setState({sending: true});

		/**
		 * Get message
		 */
		const message = this.state.message.trim();

		/**
		 * Send the message!
		 */
		ChatService.send(this.props.chat.User.Id, message).then(message => {
			if (!this.state.messages.find(m => (m.Uuid === message.Uuid))) {
				this.setState({
					message: "",
					messages: [message, ...this.state.messages]
				}, this.handleNewMessages);
				this.props.onNewMessage(this.props.chat, message);
			}
		}).catch(e => {
			this.props.snack(e);
		}).finally(() => {
			this.setState({sending: false}, () => setTimeout(this.focusComposer));
		});

	};

	/**
	 * User selected.
	 * 
	 * @param {Object} user
	 * @return {void}
	 */
	handleSelectNewUser = user => {
		this.props.onSelectUser(this.props.chat.User, user);
	};

	/**
	 * Load new messages.
	 * 
	 * @return {void}
	 */
	loadNewMessages = () => {
		if (!this.isNew) {

			const getConversation = ChatService.getConversation(
				this.props.chat.User.Id,
				null,
				this.newestMessageTimestamp
			);

			getConversation.then(data => {

				const msgs = data.Messages;

				if (msgs.length) {

					// Set things up
					let scrollPos = null;

					// We need to know whether to scroll message container
					if (this.messageContainer?.current) {
						const msgContainer = this.messageContainer?.current;
						scrollPos = msgContainer.scrollTop;
					}

					// Determine whether to mark messages as read
					const atb = this.isMessageContainerScrolledToBottom;
					const fcs = this.state.focused;
					const opn = this.state.open;
					const unread = (!!msgs.length && (!fcs || !opn || !atb));

					// Update the state
					this.setState({
						messages: [...msgs, ...this.state.messages],
						unread: (this.state.unread || unread)
					}, () => {
						if (this.messageContainer?.current) {
							if (atb && scrollPos) {
								this.scrollMessageContainer(scrollPos);
							}
						}
					});

					// We need to mark messages read
					if (!unread && msgs.length) {
						this.markConversationRead();
					}

					// We have a new message
					const msg = {...msgs[0]};
					if (!unread) msg.Read = true;
					this.props.onNewMessage(this.props.chat, msg);

				}

			}).catch(() => {});

		}
	};

	/**
	 * Mark the conversation as read.
	 * 
	 * @return {void}
	 */
	markConversationRead = () => {

		this.setState({unread: false});
		this.props.onMarkedRead?.(this.props.chat.User, true);

		ChatService.read(this.props.chat.User.Id).then(() => {
			// ...
		}).catch(() => {
			// ...
		});

	};

	/**
	 * Scroll the message container to the bottom.
	 *
	 * When `scrollTop` is set, the scroll will only occur 
	 * if the container's current `scrollTop` matches.
	 *
	 * @param {Integer} scrollTop optional
	 * @return {void}
	 */
	scrollMessageContainer = (scrollTop=null) => {
		setTimeout(() => {
			if (this.messageContainer.current) {

				let scrollToOk = true;

				if (scrollTop) {
					const st = this.messageContainer.current.scrollTop;
					scrollToOk = (scrollTop === st);
				}

				if (scrollToOk) {
					this.messageContainer.current.scrollTo({
						top: this.messageContainer.current.scrollHeight
					});
					this.scrolledToBottom = true;
				}

			}
		}, 50);
	};

	/**
	 * Scroll to a specific message DOM node by index.
	 * 
	 * @param {Integer} i
	 * @return {void}
	 */
	scrollToMessage = i => {
		if (this.messageContainerInner?.current) {
			const node = this.messageContainerInner.current.children[i];
			if (node) node.scrollIntoView();
		}
	};


	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		super.componentDidMount();

		if (!this.isNew) {

			const getConversation = ChatService.getConversation(
				this.props.chat.User.Id, null, null, true
			);

			getConversation.then(data => {
				this.setState({
					messages: data.Messages,
					correspondent: data.User
				}, this.handleNewMessages);
			}).catch(() => {
				this.setState({error: true});
			}).finally(() => {
				this.setState({loading: false});
			});

			this.checkCanUserCorrespond(() => setTimeout(this.focusComposer, 150));

		}

		setTimeout(() => this.setState({focused: true}));

		this.loadMessagesTimer = setInterval(this.loadNewMessages, 5000);

		window.addEventListener("resize", this.resizeHandler);

	}


	/**
	 * Component unmounting.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {
		clearTimeout(this.loadMessagesTimer);
		window.removeEventListener("resize", this.resizeHandler);
	}


	/**
	 * Component updated.
	 * 
	 * @param {Object} prevProps
	 * @param {Object} prevState
	 * @return {void}
	 */
	componentDidUpdate(prevProps, prevState) {

		if (prevProps.chat.User?.Id !== this.props.chat.User?.Id) {
			this.checkCanUserCorrespond(() => setTimeout(this.focusComposer, 150));
		}

		if (prevState.unread !== this.state.unread) {
			this.props.onHasInvisibleUnreadMessagesChange?.(this.state.unread);
		}

	}


	/**
	 * Resize handler.
	 *
	 * We maintain scroll at the bottom (e.g. Android viewport 
	 * height change when keyboard is focused - causes last 
	 * message to appear below keyboard unless we reset scroll).
	 *
	 * @return {void}
	 */
	resizeHandler = throttle(() => {

		/**
		 * Active element check to address Android issues
		 *
		 * It's not perfect - now we'd always scroll if the 
		 * input's focused, even if user has scrolled up, 
		 * but that should be very rare and ensures that 
		 * when the Android keyboard appears, messages 
		 * aren't under it.
		 */
		if (this.scrolledToBottom || (document.activeElement === this.composer?.current)) {
			this.scrollMessageContainer();
		}

	}, 50);


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {

		const orgsLbl = (this.props.hasMultipleOrgs && this.state.correspondent?.Orgs?.map(o => o.NameShort)?.join(" • "));

		return <>
			<ClickAwayListener onClickAway={this.handleBlur}>
				<Pane
					className={this.props.className}
					classes={this.classes}
					icons={(this.state.messages?.length ? this.renderIcons() : null)}
					inline={this.props.inline}
					label={this.label}
					labelCaption={orgsLbl}
					labelCaptionTooltip={orgsLbl}
					labelColor={(this.state.unread ? "error" : undefined)}
					noHeader={this.props.noHeader}
					onClose={this.handleClose}
					onHeaderClick={this.handleOpenToggle}
					tooltip={true}
					tooltipContent={`${this.label}${(this.state.unread ? " (Unread)" : "")}`}>
					<Flex
						minHeight="0rem"
						onBlur={this.handleBlur}
						onClick={this.handlePaneClick}
						onFocus={this.handleFocus}
						pb={0.5}
						px={0.5}>
						{(!this.isNew ? this.renderContent() : this.renderNew())}
					</Flex>
				</Pane>
			</ClickAwayListener>
			{(!this.isNew && this.renderDeleteDialog())}
		</>;

	}


	/**
	 * Render content.
	 *
	 * @return {ReactNode}
	 */
	renderContent() {
		return <>
			<Flex
				alignItems={(!this.state.loading && "flex-end")}
				flexGrow={1}
				fullWidth={true}
				pr={(this.state.messages?.length && 1)}
				innerRef={this.messageContainer}
				onScroll={this.handleMessageContainerScroll}
				style={this.stylesMessageContainer}>
				{this.renderLoadingOlderMessages()}
				<Container
					alignItems="flex-end"
					flexGrow={1}
					fullWidth={true}
					innerRef={this.messageContainerInner}
					pt={(!this.state.loading ? 2.5 : 0)}
					rows="1fr"
					style={{marginTop: (!this.state.loading && "auto"), position: "relative"}}>
					{this.renderContentMain()}
					<Flex className={scss.composer} py={0.25}>
						<TextField
							autoFocus={true}
							disabled={(this.state.error || (this.state.canCorrespond === false))}
							canEnter={this.canSend}
							inputRef={this.composer}
							InputProps={{endAdornment: this.renderSend()}}
							label="New Message"
							maxLength={65535}
							multiline={true}
							multilineEnter={true}
							onChange={this.handleChangeMessage}
							onEnter={this.handleSend}
							placeholder="Type a message..."
							rowsMax={3}
							shrink={true}
							size="small"
							value={this.state.message}
							variant="outlined" />
					</Flex>
				</Container>
			</Flex>
		</>;
	}


	/**
	 * Render the main content.
	 * 
	 * @return {ReactNode}
	 */
	renderContentMain() {
		if (this.state.loading) return <Loader size={25} FlexProps={this.constructor.loaderFlexProps} />;
		else if (this.state.error) return <String centre={true} color="textSecondary" str="Error loading messages." />;
		else if (!this.state.messages?.length) return <String centre={true} color="textSecondary" str="Type your first message!" />;
		else return this.renderMessages();
	}


	/**
	 * Render the deletion dialog.
	 * 
	 * @return {ReactNode}
	 */
	renderDeleteDialog() {
		return (
			<ChatDeleteDialog
				onClose={this.handleDeleteClose}
				onDelete={this.handleDeleted}
				open={this.state.deleting}
				user={this.props.chat.User} />
		);
	}


	/**
	 * Render icons.
	 * 
	 * @return {ReactNode}
	 */
	renderIcons() {
		return (
			<IconButton
				color="primary"
				icon={DeleteIcon}
				onClick={this.handleDelete}
				title="Delete" />
		);
	}


	/**
	 * Render loading older messages.
	 * 
	 * @return {ReactNode}
	 */
	renderLoadingOlderMessages() {
		return (
			<Loader
				FlexProps={this.loadingOlderMessagesProps}
				size={25} />
		);
	}


	/**
	 * Render messages.
	 *
	 * @return {ReactNode}
	 */
	renderMessages() {
		return [...this.state.messages].reverse().map((message, key) => (
			<ChatWindowMessage
				key={key}
				message={message}
				sentByCorrespondent={(message.Sender.Username !== this.props.userAccount.Username)} />
		));
	}


	/**
	 * Render new chat picker.
	 *
	 * @return {ReactNode}
	 */
	renderNew() {
		return (
			<Container className={scss.userPickerContainer} px={0.5}>
				<ChatUserPicker onSelectUser={this.handleSelectNewUser} />
			</Container>
		);
	}


	/**
	 * Render send button.
	 * 
	 * @return {ReactNode}
	 */
	renderSend() {
		return (
			<InputAdornment position="end">
				<IconButtonMui
					className={scss.send}
					color="primary"
					disabled={!this.canSend}
					onClick={this.handleSend}
					size="small"
					title="Send">
					<SendIcon fontSize="small" />
				</IconButtonMui>
			</InputAdornment>
		);
	}


	/**
	 * Get whether we can send.
	 * 
	 * @return {Boolean}
	 */
	get canSend() {
		const msg = (this.state.message.trim() !== "");
		return (!this.state.sending && !this.state.loading && msg);
	}


	/**
	 * Get CSS classes to apply to the `Pane`.
	 * 
	 * @return {Array}
	 */
	get classes() {
		return [
			scss.pane,
			(this.state.open ? scss.paneOpen : scss.paneClosed)
		];
	}


	/**
	 * Get whether the message container is scrolled to the bottom.
	 * 
	 * @return {Boolean}
	 */
	get isMessageContainerScrolledToBottom() {
		if (this.messageContainer.current) {
			const scrollTop = this.messageContainer.current.scrollTop;
			const scrollHeight = this.messageContainer.current.scrollHeight;
			const offsetHeight = this.messageContainer.current.offsetHeight;
			return (scrollTop === (scrollHeight - offsetHeight));
		}
		else return false;
	}


	/**
	 * Get whether this is a new chat.
	 */
	get isNew() {
		return !this.props.chat.User;
	}


	/**
	 * Get the chat label.
	 *
	 * @return {String}
	 */
	get label() {
		if (this.isNew) return "New Chat";
		else return `${this.props.chat.User.Fname} ${this.props.chat.User.Sname}`;
	}


	/**
	 * Get the timestamp of our newest message.
	 *
	 * @return {Integer|null}
	 */
	get newestMessageTimestamp() {
		if (!this.state.messages.length) return null;
		else return this.state.messages[0].Timestamp;
	}


	/**
	 * Get the timestamp of our oldest message.
	 * 
	 * @return {Integer|null}
	 */
	get oldestMessageTimestamp() {
		if (!this.state.messages.length) return null;
		else return this.state.messages[(this.state.messages.length - 1)].Timestamp;
	}


	/**
	 * Get whether we should currently mark messages as read.
	 * 
	 * @return {Boolean}
	 */
	get shouldMarkRead() {
		const atBottom = this.isMessageContainerScrolledToBottom;
		const hasUnread = this.state.unread;
		const isFocused = this.state.focused;
		const isOpen = this.state.open;
		return (!this.isNew && atBottom && hasUnread && isFocused && isOpen);
	}


	/**
	 * Styles for the message container.
	 * 
	 * @return {Object}
	 */
	get stylesMessageContainer() {
		return {
			overflowY: (this.state.messages?.length && "scroll"),
			position: "relative"
		};
	}


	/**
	 * Props for the "loading older messages" loader container.
	 * 
	 * @return {Object}
	 */
	get loadingOlderMessagesProps() {
		return {
			style: {
				display: (!this.state.loadingOlderMessages ? "none" : undefined),
				left: "47.5%",
				position: "absolute",
				top: "0rem"
			}
		};
	}


	/**
	 * `FlexProps` for the message loader.
	 *
	 * @type {Object}
	 */
	static loaderFlexProps = {style: {alignSelf: "center"}};

}

export default withAuth(withSnack(ChatWindow));
