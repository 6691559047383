import Bghost from "App/Bghost.js";
import Store from "App/Store.js";
import dAuthAdmin from "Dispatchers/dAuthAdmin.js";
import UserAccountService from "UserAccounts/UserAccountService.js";

export default () => {
	return new Promise((resolve, reject) => {
		Bghost.register(UserAccountService.getAccountInfo(Store.getState().auth.token, Store.getState().authAdmin.token), null, "admin").then(account => {
			dAuthAdmin({...account, org: Store.getState().orgAdmin});
			resolve();
		}).catch(reject);
	});
};
