import React from "react";
import Container from "Components/Container.js";
import LoginForm from "Login/LoginForm.js";
import Styles from "Resources/Styles.json";
import rem from "Helpers/Rem.js";

const MainLoginStyles = {
	overflowY: "auto",
	position: "sticky",
	maxHeight: `calc(100vh - ${Styles.uiBarHeight} - ${rem(2)})`,
	top: `calc(${Styles.uiBarHeight} + ${rem()})`
};

export default () => (
	<Container pl={1} style={MainLoginStyles}>
		<LoginForm />
	</Container>
);
