import React from "react";
import Container from "Components/Container.js";
import Divider from "@material-ui/core/Divider";
import Document from "Helpers/Document.js";
import LoginView from "Login/LoginView.js";
import Pre from "Components/Pre.js";
import String from "Components/String.js";
import Strings from "./MainDenied.strings.json";
import TelemetryService from "Telemetry/TelemetryService.js";
import UiDebug from "Ui/UiDebug.js";
import dAccess from "Dispatchers/dAccess.js";
import dOrg from "Dispatchers/dOrg.js";
import withAuth from "Hoc/withAuth.js";
import withMobile from "Hoc/withMobile.js";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

/**
 * Access denied screen.
 *
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class MainDenied extends React.PureComponent {

	/**
	 * Component mounted.
	 * 
	 * @return {self}
	 */
	componentDidMount() {

		const route = this.props.route;
		let AccessReason = this.props.AccessReason;


		/**
		 * Resolve the actual access reason when needed
		 */
		if (!AccessReason && route) {

			AccessReason = {
				pid: route.permission?.[0],
				pvar: route.permission?.[1]
			};

			dAccess(false, AccessReason);

		}


		/**
		 * Carry out global operations now
		 */
		Document.setTitle((this.strings.titleDocument || this.strings.title));
		TelemetryService.report("MainDenied", {AccessReason});

		/**
		 * Ensure we're at the right org!
		 */
		this.switchToTargetOrg();

	}


	/**
	 * Try to switch the user to the target organisation when 
	 * access was denied because they're viewing a resource 
	 * at another railway.
	 * 
	 * @return {void}
	 */
	switchToTargetOrg() {

		const targetOrgId = parseInt(this.props.AccessReason?.org);

		if (targetOrgId !== this.props.org?.Id) {
			if (this.props.orgIds?.includes(targetOrgId)) {
				dOrg(targetOrgId, (this.targetUri || "/"));
			}
		}

	}


	/**
	 * Render main UI.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container>
				<String str={this.strings.title} variant="h5" />
				<String color="textSecondary" str={this.strings.help} />
				{(this.props.authed && this.renderDetails())}
				{(this.props.authed && <UiDebug />)}
				{(this.props.authed && <String str={this.strings.helpFault} />)}
				{((!this.props.authed && this.props.isMobile) && <Container mt={0.5}><Divider /><LoginView /></Container>)}
			</Container>
		);
	}


	/**
	 * Render details.
	 * 
	 * @return {ReactNode}
	 */
	renderDetails() {
		return (
			<Pre error={true}>
				<p><strong>P: </strong> {(this.props.AccessReason?.pid ?? "-")}</p>
				<p><strong>V: </strong> {(this.props.AccessReason?.pvar ?? "-")}</p>
				<p><strong>R: </strong> {(this.props.AccessReason?.org ?? "-")}</p>
				<p><strong>T: </strong> {(this.targetUri || "-")}</p>
				<p><strong>I: </strong> {(this.props?.AccessReason?.internal ? "Yes" : "-")}</p>
				<p><strong>X: </strong> {`${(this.props.AccessReason?.request || "-")} ${(this.props.AccessReason?.response ? `(${this.props.AccessReason?.response})` : "")}`.trim()}</p>
			</Pre>
		);
	}


	/**
	 * Strings.
	 * 
	 * @return {Object}
	 */
	get strings() {
		return (this.props.authed ? Strings.authed : (!this.props.AuthResetWasUnexpected ? Strings.auth : Strings.authUnexpected));
	}


	/**
	 * Get the target URI that the user was trying to reach.
	 *
	 * @return {String}
	 */
	get targetUri() {
		return (this.props.AccessReason?.uri || `${this.props.location.pathname}${this.props.location.search}`);
	}

}

export default connect(({Access, AccessReason, AuthResetWasUnexpected}) => ({Access, AccessReason, AuthResetWasUnexpected}))(withAuth(withMobile(withRouter(MainDenied))));
