import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import Dialog from "Components/Dialog.js";
import LibraryService from "./LibraryService.js";
import withSnack from "Hoc/withSnack.js";

/**
 * Library file deletion dialog
 * 
 * @package HOPS
 * @subpackage Library
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LibraryFileDeletionDialog extends AsyncStatefulComponent {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Submiting?
		 *
		 * @type {Boolean}
		 */
		submitting: false

	};


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if ((prevProps.open !== this.props.open) && this.props.open) {
			this.setState({submitting: false});
		}
	}


	/**
	 * Submitting.
	 * 
	 * @return {void}
	 */
	handleSubmit = () => {

		this.setState({submitting: true});

		LibraryService.deleteSetFiles(this.props.set, this.props.files).then(results => {

			if (Object.values(results).every(r => r)) {
				this.props.snack("Deleted.", "success");
			}
			else this.props.snack("Not all files were deleted successfully.", "warning");

			this.props.onClose();
			this.props.onDeleted(Object.keys(results).filter(f => results[f]));

		}).catch(e => {
			this.props.snack(e);
			this.setState({submitting: false});
		});

	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				content={this.content}
				loading={this.state.submitting}
				onClose={this.props.onClose}
				onOk={this.handleSubmit}
				open={this.props.open}
				title={this.title} />
		);
	}


	/**
	 * Get the content text.
	 *
	 * @return {String}
	 */
	get content() {
		if (!this.isMultiple) return "Are you sure you want to permanently delete this file?";
		else return "Are you sure you want to permanently delete the files you've selected?";
	}


	/**
	 * Get the title text.
	 *
	 * @return {String}
	 */
	get title() {
		if (!this.isMultiple) return `Delete file`;
		else return `Delete ${this.props.files.length} files`;
	}


	/**
	 * Get whether multiple files will be deleted.
	 * 
	 * @return {Boolean}
	 */
	get isMultiple() {
		return (this.props.files.length > 1);
	}

}

export default withSnack(LibraryFileDeletionDialog);
