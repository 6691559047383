import api from "api.js";
import apis from "Resources/Apis.json";

/**
 * User account service
 *
 * @package HOPS
 * @subpackage UserAccounts
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UserAccountService {

	/**
	 * Get the active user account information.
	 *
	 * @param {String} auth optional Authentication token (get another user)
	 * @param {String} authAdmin optional Admin masquerade token
	 * @return {Promise} Resolves with API response
	 */
	static getAccountInfo(auth=null, authAdmin=null) {

		/**
		 * Headers
		 */
		const headers = {};
		if (auth !== null) headers["Hops-Auth"] = auth;
		if (authAdmin !== null) headers["Hops-Admin-Masquerade-Token"] = authAdmin;

		/**
		 * Make the call
		 */
		return api.call({
			headers: (Object.keys(headers).length ? headers : null),
			url: apis.account.info,
			withCredentials: false
		}).then(({data}) => {
			const body = {...data, orgs: data.railways};
			delete body.railways;
			return body;
		});

	}


	/**
	 * Get account history event information.
	 *
	 * @param {Integer} account optional Account ID to get (defaults to authed)
	 * @return {Promise} Resolves with API response
	 */
	static getHistoryInfo(account=null) {
		return api.call({
			url: (account ? `/api/accounts/${account}/history` : `/api/account/info/history`)
		}).then(({data}) => data);
	}


	/**
	 * Get all available security questions.
	 *
	 * @return {Promise} Resolves with API response
	 */
	static getSecurityQuestionsAvailable() {
		return api.call(`/api/account/secqs/available`).then(({data}) => data);
	}


	/**
	 * Set the active user's security questions.
	 *
	 * @param {Object} data API request body
	 * @return {Promise}
	 */
	static setSecurityQuestions(data) {
		return api.call({
			url: `/api/account/secqs`,
			method: "PUT",
			data
		});
	}


	/**
	 * Forgotten/reset password endpoint.
	 * 
	 * @param {String} username
	 * @param {String} email
	 * @return {Promise}
	 */
	static resetPassword(username, email) {
		return api.call({
			url: "/api/account/password/reset",
			method: "POST",
			data: {username, email}
		});
	}


	/**
	 * Get password reset token.
	 *
	 * @param {String} token
	 * @return {Promise} Resolves with API data
	 */
	static getPasswordResetToken(token) {
		return api.call({url: `/api/auth/pwr/${token}`}).then(({data}) => data);
	}


	/**
	 * Set the active user's password.
	 *
	 * You can optionally override the auth token 
	 * 	(e.g. for use during login flow scenarios).
	 * 
	 * @param {String|null} current
	 * @param {String} next
	 * @param {String|null} sq1a Security question 1 answer
	 * @param {String|null} sq2a Security question 2 answer
	 * @param {String|null} sq3a Security question 3 answer
	 * @param {String} token optional Password reset token (instead of `current`)
	 * @param {String} auth optional Authentication token
	 * @return {Promise}
	 */
	static setActiveUserPassword(current, next, sq1a, sq2a, sq3a, token=null, auth=null) {

		const headers = (auth ? {Authorization: auth} : undefined);

		return api.call({
			url: apis.account.password,
			method: "PUT",
			data: {current, next, sq1a: (sq1a || null), sq2a: (sq2a || null), sq3a: (sq3a || null), token},
			headers
		});

	}


	/**
	 * Password input rules props.
	 *
	 * Designed for use with `input` elements.
	 * 
	 * @type {Object}
	 */
	static passwordInputRulesProps = {maxLength: 32, minLength: 8};
}

export default UserAccountService;
