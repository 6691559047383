import React from "react";
import IconButton from "Components/IconButton.js";
import dNotificationsPane from "Dispatchers/dNotificationsPane.js";
import dUiDrawerClose from "Dispatchers/dUiDrawerClose.js";
import withNotifications from "Hoc/withNotifications.js";
import {Badge} from "@material-ui/core";
import {Notifications as NotificationsIcon} from "@material-ui/icons";
import {connect} from "react-redux";
import scss from "./UiBar.module.scss";

/**
 * Notifications bar button
 *
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiBarIconStripNotificationsButton extends React.PureComponent {

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Notifications pane open?
		 *
		 * We use this so we avoid re-opening pane 
		 * immediately after closed by the pane's 
		 * click away listener.
		 * 
		 * @type {Boolean}
		 */
		open: false

	};


	/**
	 * Component updated.
	 * 
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.NotificationsPane !== this.props.NotificationsPane) {
			setTimeout(() => this.setState({open: this.props.NotificationsPane}));
		}
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Badge
				badgeContent={this.props.Notifications?.length}
				color="error"
				overlap="circle">
				<IconButton
					className={scss.iconButton}
					color="secondary"
					icon={NotificationsIcon}
					onClick={this.handleClick}
					title="Notifications" />
			</Badge>
		);
	}


	/**
	 * Clicked.
	 *
	 * Event propagation is stopped so we don't trigger 
	 * the pane's click away listener immediately!
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleClick = e => {
		if (this.state.open) return;
		e.preventDefault();
		e.stopPropagation();
		dNotificationsPane();
		dUiDrawerClose();
	};

}

export default connect(({NotificationsPane}) => ({NotificationsPane}))(withNotifications(UiBarIconStripNotificationsButton));
