import React from "react";
import dCookieDisableSsns from "Dispatchers/dCookieDisableSsns.js";
import Switch from "Components/Switch.js";
import Strings from "./F12.strings.json";
import {connect} from "react-redux";

/**
 * F12 control to set cookie `same-site-none-secure` usage
 *
 * @package HOPS
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class F12CookieDisableSsns extends React.PureComponent {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleChange = this.handleChange.bind(this);

	}


	/**
	 * Toggled.
	 * 
	 * @return {void}
	 */
	handleChange() {
		dCookieDisableSsns(!this.props.cookieDisableSsns);
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Switch
				checked={!this.props.cookieDisableSsns}
				helperText={Strings.resetWarning}
				onChange={this.handleChange}
				label="Set cookies to be SameSite=None and Secure" />
		);
	}

}

export default connect(({cookieDisableSsns}) => ({cookieDisableSsns}))(F12CookieDisableSsns);
