import React from "react";
import Button from "Components/Button.js";
import Flex from "Components/Flex.js";
import String from "Components/String.js";
import UploadIcon from "@material-ui/icons/Publish";
import scss from "./UploadDropZone.module.scss";
import withSnack from "Hoc/withSnack.js";

/**
 * Upload drop zone component
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UploadDropZone extends React.PureComponent {

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Is there an active drag over?
		 * 
		 * @type {Boolean}
		 */
		dragOver: false

	};


	/**
	 * Component mounted.
	 *
	 * We provide a ref to parents.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		if (this.props.onMount) {
			this.props.onMount(this);
		}
	}


	/**
	 * Drag event handler.
	 *
	 * We have to cancel the default behaviour to enable dropping.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleDrag = e => {

		e.preventDefault();
		e.stopPropagation();

		if (!this.state.dragOver) {
			this.setState({dragOver: true});
		}

	};


	/**
	 * Drag left the component.
	 * 
	 * @return {void}
	 */
	handleDragLeave = () => {
		if (this.state.dragOver) {
			this.setState({dragOver: false});
		}
	};


	/**
	 * Drop handler.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleDrop = e => {

		/**
		 * Disable the event handler
		 */
		e.preventDefault();
		e.stopPropagation();

		/**
		 * Get all dropped items
		 */
		const items = Array.from(e?.dataTransfer?.items || []);

		/**
		 * Get file items
		 */
		const files = items.filter(i => (i?.kind === "file"));

		/**
		 * Upload the files now
		 */
		if (files && files?.length) {
			this.handleUpload(files.map(i => i.getAsFile()));
		}

	};


	/**
	 * Select button clicked.
	 *
	 * @param {String} data optional Custom data to feed to `onFileSelected`
	 * @return {void}
	 */
	handleSelectClick = (data=null) => {

		const accept = this.acceptString;
		const input = document.createElement("input");
		if (accept) input.accept = accept;
		if (this.props.multiple) input.multiple = true;
		input.onchange = this.handleSelected;
		input.style.display = "none";
		input.type = "file";

		if (data) input.setAttribute("data-custom", data);

		document.body.appendChild(input);
		input.click();

	};


	/**
	 * File selected.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleSelected = e => {
		if (e.target.files?.length) {
			const data = (e.target && e.target.getAttribute && e.target.getAttribute("data-custom"));
			this.handleUpload(e.target.files, data);
		}
		e.target.parentNode?.removeChild?.(e.target);
	};


	/**
	 * Upload an image file.
	 * 
	 * @param {Array} files `File` instances
	 * @param {String} data optional Custom data to feed to `onFileSelected`
	 * @return {void}
	 */
	handleUpload = (files, data=null) => {

		this.setState({dragOver: false});

		for (const file of files) {
			if (!this.reportFileValidity(file)) {
				return;
			}
		}

		this.props.onFileSelected((this.props.multiple ? files : files[0]), data);

	};


	/**
	 * Report a file's upload validity.
	 *
	 * It must meet the active MIME type/size criteria.
	 *
	 * Snackbars are shown to the user when an error occurs.
	 *
	 * @param {File} file
	 * @return {Boolean}
	 */
	reportFileValidity = file => {
		if (!this.props.mimes.includes(file.type)) {
			this.props.snack(`${file.name} is an invalid file.`, "warning");
			return false;
		}
		else if (this.props.maxSizeMb && (file.size > (this.props.maxSizeMb * 1024 * 1000))) {
			this.props.snack(`${file.name} is too large to upload (allowed size is ${this.props.maxSizeMb} MB).`, "warning");
			return false;
		}
		else return true;
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Flex
				alignContent="center"
				className={`${scss.drop} ${((this.state.dragOver && !this.props.disabled) && scss.dropOver)} ${(this.props.children ? "dropInert": "")}`}
				innerRef={this.props.innerRef}
				justifyContent="center"
				justifyItems="center"
				onDragLeave={this.handleDragLeave}
				onDragOver={(!this.props.disabled ? this.handleDrag : undefined)}
				onDrop={(!this.props.disabled ? this.handleDrop : undefined)}
				style={this.props.style}>
				{(this.props.children ? this.props.children : this.renderMain())}
			</Flex>
		);
	}


	/**
	 * Render the main content.
	 *
	 * @return {ReadtNode}
	 */
	renderMain() {
		return (
			<Flex alignItems="center" mb={1} mt={2}>
				<Button
					disabled={this.props.disabled}
					label={(this.props.label || "Select File")}
					loading={this.props.loading}
					onClick={this.handleSelectClick}
					size="large"
					startIcon={(this.props.icon || <UploadIcon />)}
					variant="contained" />
				<Flex
					alignItems="center"
					gap={0.5}>
					<String
						color="textSecondary"
						str={(this.props.caption || "or drop a file here")} />
					{(this.props.maxSizeMb && this.renderMaxSize())}
				</Flex>
			</Flex>
		);
	}


	/**
	 * Render maximum size text.
	 * 
	 * @return {ReactNode}
	 */
	renderMaxSize() {
		return (
			<String
				color="textSecondary"
				str={`Maximum File Size: ${this.props.maxSizeMb} MB`}
				variant="caption" />
		);
	}


	/**
	 * Get our HTML `accept` attribute string from our allowed MIME types.
	 * 
	 * @return {String}
	 */
	get acceptString() {

		const mimes = (this.props.mimes || []);
		let str = mimes.join(",");

		for (const mime of mimes) {
			const ft = mime.split("/")[1];
			if (ft) str += `,.${ft}`;
		}

		return str;

	}

}

export default withSnack(UploadDropZone);
