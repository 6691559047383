import React from "react";
import Pane from "Components/Pane.js";
import {ClickAwayListener} from "@material-ui/core";
import scss from "./DropdownPane.module.scss";

/**
 * Dropdown pane
 *
 * A global flyout.
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class DropdownPane extends React.PureComponent {

	/**
	 * Closing menu.
	 *
	 * @return {void}
	 */
	handleClose = () => {
		if (this.props.open) {
			this.props.onClose();
		}
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<ClickAwayListener onClickAway={this.handleClose}>
				<Pane
					classes={this.classes}
					icons={this.props.icons}
					label={this.props.label}
					onClose={this.handleClose}
					visible={this.props.open}>
					{this.props.children}
				</Pane>
			</ClickAwayListener>
		);
	}


	/**
	 * Get classes.
	 * 
	 * @return {ReactNode}
	 */
	get classes() {
		return [
			scss.paper,
			(this.props.open ? scss.paperVisible : undefined),
			...(this.props.classes || [])
		];
	}

}

export default DropdownPane;
