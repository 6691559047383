import React from "react";
import ReactHelper from "Helpers/React.js";
import UriClickableComponent from "Includes/UriClickableComponent.js";
import {Fab as FabMui, Tooltip} from "@material-ui/core";

/**
 * Floating action buttom
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Fab extends UriClickableComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Tooltip arrow={true} title={(this.props.title || "")}>
				<FabMui
					className={this.props.className}
					color={(this.props.color || "primary")}
					disabled={this.props.disabled}
					onClick={this.handleClick}
					size={this.props.size}
					style={(!this.props.inline ? this.constructor.styles : this.props.style)}
					type={this.props.type}
					variant={this.props.variant}>
					{ReactHelper.render(this.props.icon)}
					{this.props.label}
				</FabMui>
			</Tooltip>
		);
	}


	/**
	 * Styles
	 * 
	 * @type {Object}
	 */
	static styles = {
		position: "fixed",
		bottom: "3rem",
		right: "3.75rem",
		zIndex: 10000000
	};

}

export default Fab;
