import React from "react";
import Button from "Components/Button.js";
import Navigator from "App/Navigator.js";

const UIBAR_LOGIN_BUTTON_STYLES = {display: "flex", justifyContent: "flex-end"};

export default () => (
	<div style={UIBAR_LOGIN_BUTTON_STYLES}>
		<Button
			color="secondary"
			label="Login"
			onClick={() => Navigator.navigate("/login")}
			variant="text" />
	</div>
);
