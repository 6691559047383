import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import AuthService from "Auth/AuthService.js";
import LoginErrorDialog from "./LoginErrorDialog.js";
import LoginFormLogin from "./LoginFormLogin.js";
import LoginFormPwc from "./LoginFormPwc.js";
import LoginFormPwr from "./LoginFormPwr.js";
import Navigator from "App/Navigator.js";
import OrgSelectionDialog from "OrgSelection/OrgSelectionDialog.js";
import PasswordChangeInvalidDialog from "PasswordChange/PasswordChangeInvalidDialog.js";
import String from "Components/String.js";
import Strings from "./LoginForm.strings.json";
import UserAccountService from "UserAccounts/UserAccountService.js";
import dLogin from "Dispatchers/dLogin.js";
import qs from "query-string";
import withRaildays from "Hoc/withRaildays.js";
import withSnack from "Hoc/withSnack.js";
import {withRouter} from "react-router-dom";

/**
 * Login form
 * 
 * @package HOPS
 * @subpackage Login
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LoginForm extends AsyncStatefulComponent {

	/**
	 * Email input
	 * 
	 * @type {ReactRef}
	 */
	emailRef = React.createRef();

	/**
	 * Password input
	 * 
	 * @type {ReactRef}
	 */
	passwordRef = React.createRef();

	/**
	 * Password (new) input
	 * 
	 * @type {ReactRef}
	 */
	passwordNewRef = React.createRef();

	/**
	 * Password (new, confirmation) input
	 * 
	 * @type {ReactRef}
	 */
	passwordNewConfirmRef = React.createRef();

	/**
	 * Security questions input ref
	 *
	 * @type {ReactRef}
	 */
	sqInputRef = React.createRef();

	/**
	 * Username input
	 *
	 * @type {ReactRef}
	 */
	usernameRef = React.createRef();


	/**
	 * Authenticating.
	 *
	 * @param {Object} auth Authentication API object
	 * @param {Object} info Account info API object
	 * @param {String|null} o optional Organisation target shortcode
	 * @return {void}
	 */
	handleAuth = (auth, info, o=null) => {
		let org = null;
		if (this.props.raildays) org = info.orgs?.find(o => o?.UserWorking)?.Id;
		if (!org && o) org = info.orgs.find(r => ((r.Shortcode?.toLowerCase() === o?.toLowerCase()) && r?.UserWorking))?.Id;
		if (!org && (info.orgs?.filter(o => o?.UserWorking).length === 1)) org = info.orgs?.find(o => o?.UserWorking)?.Id;
		if (!org) this.setState({auth, info, orgSelection: true});
		else this.handleAuthDone(auth, info, org);
	};


	/**
	 * Authentication completed!
	 * 
	 * @param {Object} auth Authentication API object
	 * @param {Object} info Account info API object
	 * @param {Integer} org Organisation ID
	 * @return {void}
	 */
	handleAuthDone = (auth, info, org) => {
		dLogin({...auth, ...info}, org);
		if (this.props.location.pathname === "/login") {
			if (!this.props.raildays) {
				Navigator.home();
			}
			else Navigator.navigate("/raildays");
		}
	};


	/**
	 * Authentication readied with a token.
	 *
	 * We need to get the user account info and then actually auth.
	 *
	 * @param {Object} auth
	 * @param {String|null} target Login target railway shortcode
	 * @return {Promise}
	 */
	handleAuthReady = (auth, target) => {
		return UserAccountService.getAccountInfo(auth.token).then(info => {
			this.handleAuth(auth, info, target);
		});
	};


	/**
	 * Cancel a login attempt.
	 *
	 * @param {Boolean} loading optional Reset loading state
	 * @return {void}
	 */
	handleCancelLogin = loading => {

		const state = {
			auth: null,
			info: null,
			error: false,
			email: "",
			orgSelection: false,
			username: "",
			password: "",
			passwordNew: "",
			passwordNewConfirm: "",
			target: ""
		};

		if (loading) state.loading = false;
		this.setState(state, this.handleModeChangeLogin);

	};


	/**
	 * Error dialog dismissed.
	 * 
	 * @return {void}
	 */
	handleErrorDismiss = () => {
		this.setState({errorOpen: false});
	};


	/**
	 * An input value changed.
	 *
	 * @param {String} value
	 * @param {String} field
	 * @return {void}
	 */
	handleInputChange = (value, field) => {
		this.setState({[field]: value, error: null});
	};


	/**
	 * Mode changed (login/PWC/PWR).
	 *
	 * @param {String} mode
	 * @param {Function} callback optional
	 * @return {ReactNode}
	 */
	handleModeChange = (mode, callback=null) => {
		this.setState({mode, error: null}, () => {

			let ref = this.usernameRef;

			if (this.state.mode === "pwr") {
				if (this.state.username) {
					ref = this.emailRef;
				}
			}
			else if (this.state.username) {
				ref = this.passwordRef;
			}

			if (this.allowModeFromQueryString) {
				Navigator.updateQueryString({mode});
			}

			setTimeout(() => {
				if (ref?.current) {
					ref.current.focus();
				}
			});

			if (callback) callback();

		});
	};


	/**
	 * Switch to login mode.
	 *
	 * @return {void}
	 */
	handleModeChangeLogin = () => this.handleModeChange("login");


	/**
	 * Switch to password change mode.
	 * 
	 * @return {void}
	 */
	handleModeChangePwc = () => this.handleModeChange("pwc");


	/**
	 * Switch to password reset mode.
	 * 
	 * @return {void}
	 */
	handleModeChangePwr = () => this.handleModeChange("pwr");


	/**
	 * Organisation selected.
	 *
	 * @param {Object} org
	 * @return {void}
	 */
	handleOrgSelect = org => {
		this.setState({orgSelection: false});
		this.handleAuthDone(this.state.auth, this.state.info, org.Id);
	};


	/**
	 * Password change invalid dialog closed.
	 * 
	 * @return {void}
	 */
	handlePwcInvalidDialogClose = () => {
		this.setState({pwcInvalidDialog: false});
	};


	/**
	 * Password change required.
	 * 
	 * @return {void}
	 */
	handlePwcr = () => {
		this.handleModeChange("pwc", () => {
			this.setState({loading: false}, () => {
				if (this.passwordNewRef.current) {
					this.passwordNewRef.current.focus();
				}
			});
		});
	};


	/**
	 * We're submitting.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleSubmit = e => {

		e.preventDefault();

		switch (this.state.mode) {
			case "login":
				this.handleSubmitLogin();
				break;
			case "pwc":
				this.handleSubmitPwc();
				break;
			case "pwr":
				this.handleSubmitPwr();
				break;
			default:
				this.setState({error: true, errorDialog: true});
				break;
		}

	};


	/**
	 * We're submitting in login mode.
	 * 
	 * @return {void}
	 */
	handleSubmitLogin = () => {

		/**
		 * Authentication
		 */
		let username = this.state.username;
		const password = this.state.password;
		const target = (username.split(" ")[1] || null);
		username = username.split(" ")[0];

		/**
		 * Loading
		 */
		this.setState({loading: true});

		/**
		 * Login time
		 */
		AuthService.auth(username, password, this.state.prolonged).then(({auth, securityQuestions}) => {
			if (!auth.pwcr) return this.handleAuthReady(auth, target);
			else return this.setState({auth, securityQuestions, target}, this.handlePwcr);
		}).catch(e => {

			/**
			 * Error code
			 */
			const error = (e?.response?.status || true);

			/**
			 * State updates
			 */
			this.setState({
				error,
				errorOpen: this.constructor.shouldRenderErrorDialog(error),
				loading: false
			});

			if ((error === 404) && this.usernameRef?.current) {
				this.usernameRef.current.select();
			}

		});

	};


	/**
	 * We're submitting in password change mode.
	 * 
	 * @return {void}
	 */
	handleSubmitPwc = () => {

		if (this.state.passwordNew === this.state.password) {
			if (this.passwordNewRef.current) {
				this.passwordNewRef.current.focus();
			}
			return;
		}
		else if (this.state.passwordNew !== this.state.passwordNewConfirm) {
			if (this.passwordNewConfirmRef.current) {
				this.passwordNewConfirmRef.current.focus();
			}
			return;
		}

		this.setState({loading: true});

		const {auth, password, passwordNew, sq1, sq2, sq3} = this.state;
		const authToken = auth?.token;

		if (!authToken) {
			this.props.snack("There was an error setting your new password. Please login again.", "error");
			this.handleCancelLogin(true);
			return;
		}

		UserAccountService.setActiveUserPassword(password, passwordNew, sq1, sq2, sq3, null, authToken).then(() => {

			AuthService.ping(authToken).then(auth => {
				return this.handleAuthReady(auth, this.state.target);
			}).catch(e => {
				this.handleCancelLogin(true);
				this.props.snack(`Error logging you in: ${e}.`, "error");
			});

			this.props.snack("We've saved your new password.", "success");

		}).catch(e => {

			this.setState({loading: false});

			if (e?.response?.status === 400) {
				this.setState({pwcInvalidDialog: true});
			}
			else if (e?.response?.status === 406) {
				this.setState({error: 406});
			}
			else this.props.snack(`We couldn't save your password: ${e}.`, "error");

		});

	};


	/**
	 * We're submitting in password reset mode.
	 * 
	 * @return {void}
	 */
	handleSubmitPwr = () => {

		const username = this.state.username.split(" ")[0].trim();
		const email = this.state.email.trim();
		this.setState({loading: true});

		UserAccountService.resetPassword(username, email).then(() => {
			this.props.snack(Strings.pwr.success, "success");
			this.handleModeChangeLogin();
		}).catch(e => {
			this.props.snack(e);
		}).finally(() => {
			this.setState({loading: false});
		});

	};


	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Get the initial view mode using the query string
		 */
		const initialMode = (this.allowModeFromQueryString ? qs.parse(this.props.location.search)?.mode : undefined);

		/**
		 * State
		 * 
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Authentication object
			 * 
			 * Temporary auth object during authentication.
			 * 
			 * @type {Object|null}
			 */
			auth: null,

			/**
			 * Account information
			 *
			 * Acquired during login to get organisations.
			 *
			 * @type {Object|null}
			 */
			info: null,

			/**
			 * Email address
			 *
			 * @type {String}
			 */
			email: "",

			/**
			 * Username
			 *
			 * @type {String}
			 */
			username: "",

			/**
			 * Password
			 * 
			 * @type {String}
			 */
			password: "",

			/**
			 * Password (new)
			 *
			 * @type {String}
			 */
			passwordNew: "",

			/**
			 * Password (new, confirmation)
			 * 
			 * @type {String}
			 */
			passwordNewConfirm: "",

			/**
			 * Prolonged session wanted?
			 * 
			 * @type {Boolean}
			 */
			prolonged: false,

			/**
			 * Security question 1 answer
			 *
			 * @type {String|null}
			 */
			sq1: null,

			/**
			 * Security question 2 answer
			 *
			 * @type {String|null}
			 */
			sq2: null,

			/**
			 * Security question 3 answer
			 *
			 * @type {String|null}
			 */
			sq3: null,

			/**
			 * Login target (org)
			 *
			 * @param {String} Org shortcode
			 */
			target: null,

			/**
			 * Error status
			 *
			 * @type {Integer|null}
			 */
			error: null,

			/**
			 * Error dialog open
			 * 
			 * @type {Boolean}
			 */
			errorOpen: false,

			/**
			 * Loading?
			 * 
			 * @type {Boolean}
			 */
			loading: false,

			/**
			 * Form mode
			 *
			 * `login`, `pwc` or `pwr`.
			 *
			 * @type {String}
			 */
			mode: ((initialMode && this.constructor.isValidMode(initialMode)) ? initialMode : "login"),

			/**
			 * Organisation selection visible?
			 *
			 * @type {Boolean}
			 */
			orgSelection: false,

			/**
			 * Password change invalid dialog visible?
			 *
			 * @type {Boolean}
			 */
			pwcInvalidDialog: false,

			/**
			 * Security questions needing answer
			 *
			 * @type {Array|null}
			 */
			securityQuestions: null

		};

	}


	/**
	 * Component updated.
	 *
	 * We refocus inputs and handle query string `mode` parameter changes.
	 * 
	 * @param {Object} prevProps
	 * @param {Object} prevState
	 * @return {void}
	 */
	componentDidUpdate(prevProps, prevState) {

		const mode = qs.parse(this.props.location.search)?.mode;
		if ((mode !== this.state.mode) && this.constructor.isValidMode(mode)) {
			this.setState({mode});
		}

		if (prevState.error !== this.state.error) {
			if ((this.state.error === 406) && this.sqInputRef?.current) {
				this.sqInputRef.current.select();
			}
		}

	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<form onSubmit={this.handleSubmit}>
				{this.renderForm()}
				<LoginErrorDialog
					error={this.state.error}
					open={this.state.errorOpen}
					onClose={this.handleErrorDismiss} />
				<OrgSelectionDialog
					onClose={this.handleCancelLogin}
					orgs={(this.state.info?.orgs || [])}
					onSelect={this.handleOrgSelect}
					open={this.state.orgSelection} />
				<PasswordChangeInvalidDialog
					onClose={this.handlePwcInvalidDialogClose}
					open={this.state.pwcInvalidDialog} />
			</form>
		);
	}


	/**
	 * Render main content.
	 * 
	 * @return {ReactNode}
	 */
	renderForm() {
		switch (this.state.mode) {
			case "login":
				return this.renderLogin();
			case "pwc":
				return this.renderPwc();
			case "pwr":
				return this.renderPwr();
			default:
				return <String color="textSecondary" str="Error." />;
		}
	}


	/**
	 * Render the login form.
	 * 
	 * @return {ReactNode}
	 */
	renderLogin() {
		return (
			<LoginFormLogin
				disabled={this.state.loading}
				error={this.state.error}
				noFullWidthSubmit={this.props.noFullWidthSubmit}
				noMarginBottomOnControls={this.props.noMarginBottomOnControls}
				noTitle={this.props.noTitle}
				onChange={this.handleInputChange}
				onWantsPasswordReset={this.handleModeChangePwr}
				password={this.state.password}
				passwordInputRef={this.passwordRef}
				prolonged={this.state.prolonged}
				sticky={this.props.sticky}
				username={this.state.username}
				usernameInputRef={this.usernameRef} />
		);
	}


	/**
	 * Render the password change form.
	 * 
	 * @return {ReactNode}
	 */
	renderPwc() {
		return (
			<LoginFormPwc
				disabled={this.state.loading}
				noFullWidthSubmit={this.props.noFullWidthSubmit}
				noMarginBottomOnControls={this.props.noMarginBottomOnControls}
				noTitle={this.props.noTitle}
				onChange={this.handleInputChange}
				onWantsLoginCancel={this.handleCancelLogin}
				password={this.state.password}
				passwordNew={this.state.passwordNew}
				passwordNewRef={this.passwordNewRef}
				passwordNewConfirm={this.state.passwordNewConfirm}
				passwordNewConfirmRef={this.passwordNewConfirmRef}
				securityQuestions={this.state.securityQuestions}
				securityQuestionsError={(this.state.error === 406)}
				securityQuestionsInputRef={this.sqInputRef}
				sq1={this.state.sq1}
				sq2={this.state.sq2}
				sq3={this.state.sq3}
				sticky={this.props.sticky} />
		);
	}


	/**
	 * Render the password reset form.
	 * 
	 * @return {ReactNode}
	 */
	renderPwr() {
		return (
			<LoginFormPwr
				disabled={this.state.loading}
				email={this.state.email}
				emailInputRef={this.emailRef}
				error={this.state.error}
				noFullWidthSubmit={this.props.noFullWidthSubmit}
				noMarginBottomOnControls={this.props.noMarginBottomOnControls}
				noTitle={this.props.noTitle}
				onChange={this.handleInputChange}
				onWantsLogin={this.handleModeChangeLogin}
				sticky={this.props.sticky}
				username={this.state.username}
				usernameInputRef={this.usernameRef} />
		);
	}


	/**
	 * Get whether to allow/use the `mode` query string parameter.
	 *
	 * @return {Boolean}
	 */
	get allowModeFromQueryString() {
		return (this.props.location.pathname === "/login");
	}


	/**
	 * Get whether a mode identifier is valid.
	 *
	 * @param {String} mode
	 * @return {Boolean}
	 */
	static isValidMode(mode) {
		return ["login", "pwc", "pwr"].includes(mode);
	}


	/**
	 * Get whether to render the error dialog for an error code.
	 * 
	 * @param {Integer|null} error Error code
	 * @return {Boolean}
	 */
	static shouldRenderErrorDialog(error) {
		return ((error !== null) && ![404].includes(error));
	}

}

export default withRaildays(withRouter(withSnack(LoginForm)));
