import api from "api.js";
import apis from "Resources/Apis.json";

/**
 * Search service
 * 
 * @package HOPS
 * @subpackage Search
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class SearchService {

	/**
	 * Search globally.
	 *
	 * @param {String} query
	 * @param {Integer} limit optional Limit count of results (`10`)
	 * @return {Promise} Resolves with object with `results`/`totalCount`
	 */
	static search(query, limit=10) {
		return api.call({
			url: apis.search,
			params: {query, limit}
		}).then(({data}) => {
			return {
				results: data.results,
				totalCount: data.totalCount
			};
		});
	}

}

export default SearchService;
