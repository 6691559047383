import React from "react";
import Button from "Components/Button.js";
import Flex from "Components/Flex.js";
import String from "Components/String.js";
import TelemetryService from "Telemetry/TelemetryService.js";
import UiDebug from "Ui/UiDebug.js";
import dHistory from "Dispatchers/dHistory.js";
import {withRouter} from "react-router-dom";

/**
 * Error gate
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class ErrorGate extends React.PureComponent {

	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		TelemetryService.report("ErrorGate", {Error: (this.props.error?.toString() || null)});
	}


	/**
	 * Component updated.
	 *
	 * Force a reroute when route changes, so we're not stuck 
	 * on the same route in an error state after an error 
	 * boundary catches and renders this component.
	 *
	 * E.g. for use when rendering around the router as an 
	 * error boundary - refer also to #222 and its MR.
	 * 
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.match !== this.props.match) {
			dHistory();
		}
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Flex px={(!this.props.noPadding ? 1 : undefined)} py={(!this.props.noPadding ? 1 : undefined)}>
				<String str={this.strings.title} variant="h5" />
				<String color="textSecondary" gap={0.5} str={this.strings.help} />
				<div>
					<Flex columnar={true}>
						<Button label="Refresh" onClick={() => window.location.reload()} variant="outlined" />
						{(!this.props.noUiDebug && <UiDebug label="Copy Debugging Info" />)}
					</Flex>
				</div>
			</Flex>
		);
	}


	/**
	 * Strings.
	 * 
	 * @return {Object}
	 */
	get strings() {
		return {
			title: "Unhandled error",
			help: [
				"We couldn't load this page.",
				"Refreshing your browser might help.",
				(!this.props.noUiDebug ? "Please include the debugging information accessible below if you report this issue." : "Your device may not support HOPS if you keep seeing this.")
			]
		};
	}

}

export default withRouter(ErrorGate);
export {ErrorGate};
