import React from "react";
import dRaildays from "Dispatchers/dRaildays.js";
import Switch from "Components/Switch.js";
import {connect} from "react-redux";

/**
 * F12 control to enable toggling Raildays mode
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class F12Raildays extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Switch
				checked={this.props.raildays}
				helperText="Force enable behaviours used when rendering in Raildays."
				label="Raildays Mode"
				onChange={() => dRaildays(!this.props.raildays)} />
		);
	}

}

export default connect(({raildays}) => ({raildays}))(F12Raildays);
