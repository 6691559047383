import React from "react";
import Flex from "./Flex.js";
import String from "./String.js";
import scss from "./Ul.module.scss";

/**
 * `ul` using `String`
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Ul extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Flex pl={(1.25 + (this.props.pl || 0))}>
				<ul>
					{
						(this.props.items || this.props.children).map((item, key) => (
							<li className={scss.li} key={key} style={this.getStyles(key)}>
								{(this.props.children) ? item : this.renderStringItem(item)}
							</li>
						))
					}
				</ul>
			</Flex>
		);
	}


	/**
	 * Render a string item.
	 * 
	 * @param {String} item
	 * @return {ReactNode}
	 */
	renderStringItem(item) {
		return (
			<String
				bold={this.props.bold}
				color={this.props.color}
				ContainerProps={this.constructor.scp}
				p={true}
				str={item}
				style={this.constructor.stringStyles}
				variant={this.props.variant} />
		);
	}


	/**
	 * Get the styles for an item by its key (index).
	 *
	 * @param {Integer} k
	 * @return {Object}
	 */
	getStyles(k) {
		return {
			marginBottom: (((k + 1) !== (this.props.items || this.props.children)?.length) ? "1rem" : null)
		};
	}


	/**
	 * `String` `ContainerProps`.
	 * 
	 * @type {Object}
	 */
	static scp = {style: {display: "inline-block"}};

	/**
	 * `String` styles.
	 * 
	 * @type {Object}
	 */
	static stringStyles = {display: "inline"};

}

export default Ul;
