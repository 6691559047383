import React from "react";
import Container from "Components/Container.js";

/**
 * Flex container
 *
 * Renders a `Container` using flex layout.
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Flex extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container
				display="flex"
				flexDirection={(this.props.columnar ? "row" : "column")}
				flexWrap={this.props.wrap}
				{...this.props} />
		);
	}

}

export default Flex;
