import {useCallback, useEffect} from "react";
import {connect} from "react-redux";
import {SnackbarService} from "@heron-web/material";
import dKioskMode from "Dispatchers/dKioskMode.js";

/**
 * Kiosk mode component
 *
 * Renders nothing; simply listens for Ctrl+F10 to toggle kiosk mode.
 *
 * @param {Boolean} options.KioskMode
 * @return {null}
 */
const KioskMode = ({KioskMode}) => {

	const keyHandler = useCallback(e => {

		const enabled = !KioskMode;
		const msg = (enabled ? "Kiosk Mode enabled. Press Ctrl+F10 to escape." : "Kiosk Mode disabled.");

		if (e.ctrlKey && (e.key === "F10")) {
			dKioskMode(enabled);
			SnackbarService.snack(msg, "success");
		}

	}, [KioskMode]);

	useEffect(() => {
		window.addEventListener("keydown", keyHandler);
		return () => window.removeEventListener("keydown", keyHandler);
	});

	return null;

};

export default connect(({KioskMode}) => ({KioskMode}))(KioskMode);
