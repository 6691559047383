import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Flex from "Components/Flex.js";
import IconButton from "Components/IconButton.js";
import LibraryService from "./LibraryService.js";
import LibraryThumbnail from "./LibraryThumbnail.js";
import MenuIcon from "@material-ui/icons/ArrowDropDown";
import String from "Components/String.js";
import mime from "mime";
import scss from "./LibraryBrowserContentFile.module.scss";
import withTimes from "Hoc/withTimes.js";

/**
 * Library browser content file component
 * 
 * @package HOPS
 * @subpackage Library
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LibraryBrowserContentFile extends React.PureComponent {

	/**
	 * Context menu wanted.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleContextMenu = e => {
		e.preventDefault();
		e.stopPropagation();
		this.props.onContextMenu(this.props.file, e.target);
	};


	/**
	 * Downloading the file.
	 * 
	 * @return {void}
	 */
	handleDownload = () => {
		LibraryService.accessFile(this.props.file.Uuid);
	};


	/**
	 * Selection toggled.
	 * 
	 * @return {void}
	 */
	handleSelectionToggle = () => {
		this.props.onSelectionToggle(this.props.file.Uuid);
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Flex
				className={scss.tile}
				gap={0.75}
				onClick={this.handleDownload}
				onContextMenu={this.handleContextMenu}
				px={0.5}
				py={0.5}
				title={this.props.file.Name}>
				<LibraryThumbnail file={this.props.file} />
				<Flex
					gap={0.25}
					justifyContent="flex-end"
					style={this.constructor.detailsContainerStyles}>
					<String
						ContainerProps={this.constructor.filenameContainerProps}
						centre={true}
						lineClamp={2}
						str={(this.props.file.Name || `${mime.getExtension(this.props.file.Mime)} file`)}
						variant="body2" />
					<String
						centre={true}
						color="textSecondary"
						lineClamp={1}
						str={this.props.formatTime(this.props.file.UploadedTime)}
						variant="caption" />
					{(this.props.withCheckbox && this.renderCheckbox())}
					<IconButton
						classNameOuter={scss.menuButton}
						dimensions="3rem"
						onClick={this.handleContextMenu}
						icon={<MenuIcon />} />
				</Flex>
			</Flex>
		);
	}


	/**
	 * Render the item's checkbox.
	 * 
	 * @return {ReactNode}
	 */
	renderCheckbox() {
		return (
			<Checkbox
				className={scss.checkbox}
				checked={this.props.selected}
				color="primary"
				onChange={this.handleSelectionToggle}
				onClick={e => e.stopPropagation()}
				size="small" />
		);
	}


	/**
	 * Details container styles.
	 * 
	 * @type {Object}
	 */
	static detailsContainerStyles = {
		minHeight: "6rem"
	};

	/**
	 * Filename container props.
	 *
	 * @type {Object}
	 */
	static filenameContainerProps = {
		style: {
			marginTop: "auto",
			marginBottom: "auto"
		}
	};

}

export default withTimes(LibraryBrowserContentFile);
