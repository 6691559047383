import React from "react";
import PickerOption from "Components/PickerOption.js";
import UiBarSearchbarResultComponent from "./UiBarSearchbarResultComponent.js";

/**
 * UI bar searchbar result
 *
 * A `PickerOption` implementation for UI bar searchbar results.
 *
 * This should always be passed a search result object as delivered 
 * by the search API / search service as its constructor argument.
 *
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiBarSearchbarResult extends PickerOption {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return <UiBarSearchbarResultComponent result={this.data} query={this.query} />;
	}


	/**
	 * Get label.
	 *
	 * @return {String}
	 */
	get label() {
		return this.data.Title;
	}

}

export default UiBarSearchbarResult;
