import api from "api.js";
import React from "react";
import Flex from "Components/Flex.js";
import {CircularProgress} from "@material-ui/core";
import {Train as ErrorImage} from "@material-ui/icons";

/**
 * Image component
 *
 * An image with loading and error states.
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Image extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Error?
			 *
			 * @type {Boolean}
			 */
			error: false,

			/**
			 * Loading?
			 *
			 * @type {Boolean}
			 */
			loading: true

		};

		/**
		 * Method binds
		 */
		this.handleError = this.handleError.bind(this);
		this.handleLoad = this.handleLoad.bind(this);


	}


	/**
	 * Errored.
	 *
	 * @return {void}
	 */
	handleError() {
		this.setState({error: true, loading: false});
		if (this.props.onError) this.props.onError();
	}


	/**
	 * Loaded.
	 *
	 * @return {void}
	 */
	handleLoad() {
		this.setState({loading: false});
		if (this.props.onLoad) this.props.onLoad();
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (!this.state.error ? (!this.props.noImageContainer ? this.renderMain() : this.renderMainImage()) : (!this.props.noError && this.renderError()));
	}


	/**
	 * Render error state.
	 * 
	 * @return {ReactNode}
	 */
	renderError() {
		if (!this.props.errorComponent) {
			return (
				<Flex
					className={this.props.classNameContainer}
					fullCentre={true}>
					<ErrorImage color="primary" style={this.props.style} />
				</Flex>
			);
		}
		else return this.props.errorComponent;
	}


	/**
	 * Render loader.
	 * 
	 * @return {ReactNode}
	 */
	renderLoader() {
		return (this.props.loadingComponent || <CircularProgress disableShrink={true} />);
	}


	/**
	 * Render the main content.
	 *
	 * @return {ReactNode}
	 */
	renderMain() {
		return <>
			<Flex
				className={this.props.classNameContainer}
				fullCentre={true}
				minHeight={this.props.minHeight}>
				{this.renderMainImage()}
				{((this.state.loading && !this.props.noLoader && !this.props.loaderOutsideContainer) && this.renderLoader())}
			</Flex>
			{((this.state.loading && !this.props.noLoader && this.props.loaderOutsideContainer) && this.renderLoader())}
		</>;
	}


	/**
	 * Render actual image component.
	 * 
	 * @return {ReactNode|null}
	 */
	renderMainImage() {
		if (this.props.src) {
			return (
				<img
					alt={this.props.alt}
					className={this.props.className}
					onError={this.handleError}
					onLoad={this.handleLoad}
					src={this.src}
					style={this.props.style} />
			);
		}
		else return null;
	}


	/**
	 * Get image `src`.
	 * 
	 * @return {String}
	 */
	get src() {
		if (!this.props.api) return this.props.src;
		else return `${api.base}${this.props.src}`;
	}

}

export default Image;
