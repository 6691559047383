import React from "react";
import dCookieDomain from "Dispatchers/dCookieDomain.js";
import TextField from "Components/TextField.js";
import {connect} from "react-redux";
import Strings from "./F12.strings.json";

/**
 * F12 cookie domain control
 *
 * @package HOPS
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class F12CookieDomain extends React.PureComponent {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 * 
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Value
			 *
			 * @type {String}
			 */
			value: (this.props.cookieDomain || "")

		};

		/**
		 * Method binds
		 */
		this.handleBlur = this.handleBlur.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleReset = this.handleReset.bind(this);

	}


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @return {self}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.cookieDomain !== this.props.cookieDomain) {
			this.setState({value: (this.props.cookieDomain || "")});
		}
	}


	/**
	 * Blurred.
	 * 
	 * @return {void}
	 */
	handleBlur() {
		if (this.state.value !== this.props.cookieDomain) {
			dCookieDomain((this.state.value || null));
		}
	}


	/**
	 * Value changed.
	 * 
	 * @param {String} value
	 * @return {void}
	 */
	handleChange(value) {
		this.setState({value});
	}


	/**
	 * Reset.
	 * 
	 * @return {void}
	 */
	handleReset() {
		if (this.props.cookieDomain !== null) {
			dCookieDomain(null);
		}
		this.setState({value: ""});
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<TextField
				label="HOPS Cookie Domain"
				helperText={`${Strings.resetWarning} ${Strings.cookieDomainExample}`}
				onBlur={this.handleBlur}
				onChange={this.handleChange}
				onClear={this.handleReset}
				placeholder={Strings.defaultAutomatic}
				shrink={true}
				value={this.state.value}
				variant="standard" />
		);
	}

}

export default connect(({cookieDomain}) => ({cookieDomain}))(F12CookieDomain);
