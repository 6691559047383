import api from "api.js";
import DepartmentMenu from "Departments/DepartmentMenu/DepartmentMenu.js";
import Flex from "Components/Flexx.js";
import Hidden from "Components/Hidden.js";
import Link from "Components/Link.js";
import Loadable from "Components/Loadable.js";
import Navigator from "App/Navigator.js";
import Select from "Components/Select.js";
import Skeleton from "@material-ui/lab/Skeleton";
import String from "Components/Stringx.js";
import TabPanel from "Components/TabPanel.js";
import scss from "./RosterMenu.module.scss";
import moment from "moment";
import useAuth from "Hooks/useAuth.js";
import useData from "Hooks/useData.js";
import {memo, useCallback} from "react";
import {useLocation} from "react-router-dom";
import {Divider, useMediaQuery} from "@material-ui/core";
import Document from "Helpers/Document.js";
import {useOrgDate} from "Hooks/useOrgDate";
import {DateFormat} from "Helpers/DateFormat";

export default memo(() => {
	const location = useLocation();
	const {hasPermission} = useAuth();
	const {parseOrgDate} = useOrgDate();


	/**
	 * Convert start date and end date range to date string
	 * If the years are different, the year will be included in the start date
	 * @param {string|object} startDate
	 * @param {string|object} endDate
	 * @returns {string} e.g. Wednesday 1 November - Thursday 30 November 2023
	 */
	const formatLongDateRange = (startDate, endDate) => {
		const sDate = parseOrgDate(startDate);
		const eDate = parseOrgDate(endDate);
		const sameYear = (sDate && eDate) ? sDate.year() === eDate.year() : false;

		const sDateFormatted = sameYear ?
			parseOrgDate(sDate, DateFormat.LONG_DATE_WITHOUT_YEAR) :
			parseOrgDate(sDate, DateFormat.LONG_DATE);
		const eDateFormatted = parseOrgDate(eDate, DateFormat.LONG_DATE);

		return `${sDateFormatted} - ${eDateFormatted}`;
	};


	const currentDate = (new moment()).format("YYYY-MM-DD");
	const useTabsSelect = useMediaQuery("(max-width: 1100px)");


	/**
	 * Determine the Roster Period ID to render for
	 */
	const uriMatch = location.pathname.match("/roster/([0-9]+)/?(.*)/?");
	const rpId = parseInt(uriMatch?.[1]);
	const currentView = uriMatch?.[2];


	/**
	 * Roster period selection changed (via selector dropdown)
	 *
	 * @param {Event|Integer} rpId
	 * @param {Integer} e optional `rpId` when `rpId` is an `Event`
	 * @return {void}
	 */
	const handleChangeRosterPeriod = useCallback((rpId, e=null) => {
		if (isNaN(rpId)) rpId = e;
		Navigator.navigate(`/roster/${rpId}${(currentView ? `/${currentView}` : "")}`);
	}, [currentView]);


	/**
	 * Get the menu data
	 */
	const state = useData(useCallback(
		() => {
			if (rpId) {
				return api.call({url: `/api/rosters/periods/${rpId}/menu`}).then(({data}) => data);
			}
			else return null;
		},
		[rpId]
	));


	/**
	 * Don't render when we have no Roster Period ID
	 */
	if (!rpId) {
		return null;
	}


	/**
	 * Get the relative index of this roster period, within 
	 * all the roster periods that exist in its department
	 */
	const thisRosterPeriodIndex = state.data?.Department?.RosterPeriods?.indexOf?.(state.data?.Department?.RosterPeriods?.find?.(rp => (rp.Id === rpId)));

	/**
	 * The API gives the department's roster periods in their 
	 * sequential order, so now we can extract our previous 
	 * and next roster periods for the department
	 */
	const previousRosterPeriod = state.data?.Department?.RosterPeriods?.[(thisRosterPeriodIndex - 1)];
	const nextRosterPeriod = state.data?.Department?.RosterPeriods?.[(thisRosterPeriodIndex + 1)];

	/**
	 * ID of this Roster Period's department
	 */
	const deptId = state.data?.Department.Id;

	/**
	 * Base URI for this Roster Period's views
	 */
	const rpUri = `/roster/${rpId}`;

	if (state.error || state.loading) {
		Document.setTitle();
	}
	else if (location.pathname === rpUri) {
		const departmentName = state.data?.Department?.Name ?? "";
		const rosterName = state.data?.Name ?? "";
		Document.setTitle(`${departmentName} Roster: ${rosterName}`.trim());
	}

	/**
	 * Render!
	 */
	return (
		<Flex className={scss.root}>
			<Loadable
				loading={false}
				error={state.error}
				errorMessage="Error loading roster information."
				noCentre={true}
				noLoader={true}
				onRetry={state.fetch}>
				<Hidden hidden={!state.loading}>
					<Skeleton
						height={235}
						variant="rect" />
				</Hidden>
				<Hidden hidden={state.loading}>
					<DepartmentMenu
						data={state.data?.Department} />
					<div>
						<String
							bold={true}
							content={`Roster: ${state.data?.Name}`}
							variant="h5"
							style={{marginBottom: "0.75rem"}} />
						<span className={scss.rosterMenu_date}>{formatLongDateRange(state.data?.StartDate, state.data?.EndDate)}</span>
					</div>
					<Divider />
					<div className={scss.rosterPeriodSelector}>
						{(previousRosterPeriod && <Link label={`${previousRosterPeriod.Name} Roster`} onClick={handleChangeRosterPeriod} value={previousRosterPeriod.Id} variant="body2" startArrow={true} />)}
						<Flex
							alignItems="center"
							className={scss.selectContainer}
							columnar={true}
							gap={0.5}
							wrap={true}>
							<String
								className={scss.label}
								content="Jump to:"
								variant="body2" />
							<Select
								native={true}
								onChange={handleChangeRosterPeriod}
								value={(state.data ? rpId : undefined)}
								variant="outlined">
								{
									(state.data?.Department?.RosterPeriods || []).map(rp => {

										const isThis = (rp.Id === rpId);
										const isCurrent = ((rp.StartDate <= currentDate) && (rp.EndDate >= currentDate));

										let className = null;
										if (isThis) className = scss.selectedOption;
										else if (isCurrent) className = scss.currentOptions;

										return (
											<option
												className={className}
												key={rp.Id}
												value={rp.Id}>
												{rp.Name} {(isThis ? " (This)" : (isCurrent ? " (Current)" : null))}
											</option>
										);

									})
								}
							</Select>
						</Flex>
						{(nextRosterPeriod && <Link className={scss.nextLink} label={`${nextRosterPeriod.Name} Roster`} onClick={handleChangeRosterPeriod} value={nextRosterPeriod.Id} variant="body2" endArrow={true} />)}
					</div>
					<TabPanel
						hideWhenNoTabs={true}
						tabs={[
							{
								label: "Construct",
								uri: `${rpUri}/construct`,
								hidden: !((hasPermission(11, deptId) || hasPermission(3, deptId) || hasPermission(158, deptId)) && hasPermission(42, deptId))
							},
							{
								label: "Ops Info",
								uri: `${rpUri}/ops_info`,
								hidden: !(hasPermission(11, deptId) || hasPermission(3, deptId) || hasPermission(158, deptId))
							},
							{
								label: "Availability",
								uri: `${rpUri}/availability`,
								hidden: !hasPermission(18, deptId)
							},
							{
								label: "Print. Av. Sheet",
								uri: `${rpUri}/print_availability`,
								hidden: !hasPermission(18, deptId)
							},
							{
								label: "Validation",
								uri: `${rpUri}/validate`,
								hidden: !hasPermission(41, deptId)
							},
							{
								label: "View",
								uri: rpUri,
								hidden: !((hasPermission(43, deptId) && state.data?.Published) || hasPermission(42, deptId))
							},
							{
								label: "Functions",
								uri: `${rpUri}/operations`,
								hidden: !(hasPermission(12, deptId) || hasPermission(3, deptId) || hasPermission(16, deptId) || hasPermission(134, deptId))
							},
							{
								label: "Volunteers for Gaps",
								uri: `${rpUri}/volunteers`,
								hidden: !hasPermission(3, deptId)
							},
							{
								label: "Hours Challenges",
								uri: `${rpUri}/challenges`,
								hidden: !hasPermission(310, deptId)
							}
						]}
						useSelect={useTabsSelect} />
				</Hidden>
			</Loadable>
		</Flex>
	);

});
