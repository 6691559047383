import React from "react";
import Container from "Components/Container.js";
import String from "Components/String.js";

/**
 * Form group
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class FormGroup extends React.PureComponent {


	renderRight() {
		return (
			<Container
				alignItems="center"
				columnar={true}
				columns="max-content auto"
				wrap={this.props.wrap}
			>
				{this.props.control}
				<Container gap={0.1}>
					<String color="textSecondary" str={this.props.label} style={{minWidth: this.props.labelMinWidth}} />
					{(this.props.helperText ? this.renderHelperText() : null)}
				</Container>
			</Container>
		);
	}

	renderLeft() {
		return (
			<Container
				alignItems="center"
				columnar={true}
				columns="max-content auto"
				wrap={this.props.wrap}
			>
				<Container gap={0.1}>
					<String color="textSecondary" str={this.props.label} style={{minWidth: this.props.labelMinWidth}} />
					{(this.props.helperText ? this.renderHelperText() : null)}
				</Container>
				{this.props.control}
			</Container>
		);
	}

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			(this.props.variant === "left" ? this.renderLeft() : this.renderRight())
		);
	}


	/**
	 * Render helper text.
	 *
	 * @return {ReactNode}
	 */
	renderHelperText() {
		return <String color="textSecondary" str={this.props.helperText} variant="caption" />;
	}

}

export default FormGroup;
