import React from "react";
import Clipboard from "Helpers/Clipboard.js";
import CopyLinkIcon from "@material-ui/icons/Link";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import Flex from "Components/Flex.js";
import IconButton from "Components/IconButton.js";
import LibraryBrowserContentFile from "./LibraryBrowserContentFile.js";
import LibraryFileDeletionDialog from "./LibraryFileDeletionDialog.js";
import LibraryFileMenu from "./LibraryFileMenu.js";
import LibraryFileMoveDialog from "./LibraryFileMoveDialog.js";
import LibraryFileRenameDialog from "./LibraryFileRenameDialog.js";
import LibrarySetDeletionDialog from "./LibrarySetDeletionDialog.js";
import LibrarySetRenameDialog from "./LibrarySetRenameDialog.js";
import LibraryService from "./LibraryService.js";
import Paper from "Components/Paper.js";
import RenameIcon from "@material-ui/icons/EditOutlined";
import SelectAllIcon from "@material-ui/icons/SelectAll";
import String from "Components/String.js";
import UploadDropZone from "Components/UploadDropZone.js";
import UploadIcon from "@material-ui/icons/PublishOutlined";
import pluralize from "pluralize";
import withMobile from "Hoc/withMobile.js";
import withTimes from "Hoc/withTimes.js";
import {withRouter} from "react-router-dom";

/**
 * Library browser content set component
 * 
 * @package HOPS
 * @subpackage Library
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LibraryBrowserContentSet extends React.PureComponent {

	/**
	 * Ref to root component
	 *
	 * @type {ReactRef}
	 */
	ref = React.createRef();

	/**
	 * `UploadDropZone`
	 *
	 * @type {UploadDropZone}
	 */
	dropZoneRef = null;

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Active/selected file
		 * 
		 * @type {Object|null}
		 */
		activeFile: null,

		/**
		 * Deletion dialog?
		 *
		 * @type {Boolean}
		 */
		deleteDialog: false,

		/**
		 * File menu visible?
		 * 
		 * @type {Boolean}
		 */
		fileMenu: false,

		/**
		 * File menu Anchor node
		 *
		 * @type {DOMNode|null}
		 */
		fileMenuAnchor: null,

		/**
		 * File deletion dialog visible?
		 *
		 * @type {Boolean}
		 */
		fileDeletionDialog: false,

		/**
		 * File move dialog visible?
		 * 
		 * @type {Boolean}
		 */
		fileMoveDialog: false,

		/**
		 * File renaming dialog visible?
		 *
		 * @type {Boolean}
		 */
		fileRenameDialog: false,

		/**
		 * Rename dialog?
		 * 
		 * @type {Boolean}
		 */
		renameDialog: false,

		/**
		 * Selected file UUIDs
		 *
		 * @type {Array}
		 */
		selectedFiles: []

	};


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		if (this.props.location.hash?.substring(1) === this.props.set.Uuid) {
			if (this.ref.current) {
				this.ref.current.scrollIntoView();
			}
		}
	}


	/**
	 * Copying link to set.
	 *
	 * Assumes use on the current URI only!
	 * 
	 * @return {ReactNode}
	 */
	handleCopyLink = () => {
		Clipboard.copy(`${window.location.origin}${this.props.location.pathname}#${this.props.set.Uuid}`);
	};


	/**
	 * Deleting the set.
	 * 
	 * @return {void}
	 */
	handleDelete = () => {
		this.setState({deleteDialog: !this.state.deleteDialog});
	};


	/**
	 * Deletion completed.
	 * 
	 * @return {void}
	 */
	handleDeleteComplete = () => {
		this.props.onDelete(this.props.set);
	};


	/**
	 * File menu activated.
	 * 
	 * @param {Object} file
	 * @param {DOMNode} target
	 * @return {void}
	 */
	handleFileMenu = (file, target) => {
		this.setState({
			activeFile: file,
			fileMenu: true,
			fileMenuAnchor: target
		});
	};


	/**
	 * File menu closed.
	 * 
	 * @return {void}
	 */
	handleFileMenuClose = () => {
		this.setState({fileMenu: false});
	};


	/**
	 * Copying file's permalink.
	 *
	 * @return {void}
	 */
	handleFileCopyLink = () => {
		Clipboard.copy(LibraryService.getFileUri(this.state.activeFile.Uuid));
	};


	/**
	 * File deletion completed.
	 * 
	 * @param {Array} files Deleted file UUIDs
	 * @return {void}
	 */
	handleFileDeletionComplete = files => {
		this.setState({selectedFiles: []});
		this.props.onFilesDeleted(files);
	};


	/**
	 * File deletion dialog visibility toggled.
	 * 
	 * @return {void}
	 */
	handleFileDeletionDialog = () => {
		this.setState({fileDeletionDialog: !this.state.fileDeletionDialog});
	};


	/**
	 * File renaming completed.
	 * 
	 * @param {String} name
	 * @return {void}
	 */
	handleFileRenameComplete = name => {
		this.props.onFileRename(this.state.activeFile, name);
	};


	/**
	 * File rename dialog toggled.
	 * 
	 * @return {void}
	 */
	handleFileRenameDialog = () => {
		this.setState({fileRenameDialog: !this.state.fileRenameDialog});
	};


	/**
	 * Replacing the active file.
	 * 
	 * @return {void}
	 */
	handleFileReplace = () => {
		if (this.dropZoneRef) {
			this.dropZoneRef.handleSelectClick("replacement");
		}
	};


	/**
	 * File moving completed.
	 *
	 * @param {Array} files Moved file UUIDs
	 * @param {Object} set Set the files were moved to
	 * @return {void}
	 */
	handleFileMoveComplete = (files, set) => {
		this.setState({selectedFiles: []});
		this.props.onFilesMoved(files, set, this.props.set);
	};


	/**
	 * File moving dialog toggled.
	 * 
	 * @return {void}
	 */
	handleFileMoveDialog = () => {
		this.setState({fileMoveDialog: !this.state.fileMoveDialog});
	};


	/**
	 * File selection toggled.
	 *
	 * @param {String} file UUID
	 * @return {void}
	 */
	handleFileSelectToggle = file => {
		if (!this.state.selectedFiles.includes(file)) {
			this.setState({selectedFiles: [...this.state.selectedFiles, file]});
		}
		else this.setState({selectedFiles: this.state.selectedFiles.filter(f => (f !== file))});
	};


	/**
	 * Renaming the set.
	 *
	 * @return {void}
	 */
	handleRename = () => {
		this.setState({renameDialog: !this.state.renameDialog});
	};


	/**
	 * Renaming completed.
	 * 
	 * @param {String} name
	 * @return {void}
	 */
	handleRenameComplete = name => {
		this.props.onRename(this.props.set, name);
	};


	/**
	 * Select all button clicked.
	 * 
	 * @return {void}
	 */
	handleSelectAll = () => {
		if (this.state.selectedFiles?.length === this.props.set.Files.length) {
			this.setState({selectedFiles: []});
		}
		else this.setState({selectedFiles: this.props.set.Files.map(f => f.Uuid)});
	};


	/**
	 * Uploading files.
	 *
	 * @param {Array} files
	 * @return {void}
	 */
	handleUpload = (files, isReplacement) => {
		if (!isReplacement) this.props.onUpload(files, this.props.set);
		else this.props.onReplaceFile(this.state.activeFile, files[0]);
	};


	/**
	 * Upload button clicked.
	 * 
	 * @return {void}
	 */
	handleUploadClick = () => {
		if (this.dropZoneRef) {
			this.dropZoneRef.handleSelectClick();
		}
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return <>
			<UploadDropZone
				disabled={(this.props.disabled || !this.props.canEdit)}
				innerRef={this.ref}
				maxSizeMb={LibraryService.MAXIMUM_FILE_SIZE}
				mimes={LibraryService.ALLOWED_MIME_TYPES}
				multiple={true}
				onFileSelected={this.handleUpload}
				onMount={ref => (this.dropZoneRef = ref)}>
				<Paper>
					<Flex
						alignItems="center"
						columnar={true}
						gap={0.5}
						justifyContent="space-between"
						wrap={true}>
						<Flex gap={0.1}>
							<String
								bold={true}
								str={this.props.set.Name} />
							<String
								color="textSecondary"
								str={`Created ${this.props.formatTime(this.props.set.CreatedTime)} • ${this.props.set.Files.length} ${pluralize("File", this.props.set.Files.length)}`}
								variant="body2" />
						</Flex>
						<Flex
							columnar={true}
							gap={0.25}
							justifyContent="flex-end"
							wrap={true}>
							{(this.props.canEdit && this.renderUpload())}
							{(this.props.canEdit && this.renderRename())}
							{(this.props.canDelete && this.renderDelete())}
							{((this.props.canDelete || this.props.canEdit) && this.renderSelectAll())}
							{this.renderCopyLink()}
						</Flex>
					</Flex>
					{(!this.isEmpty ? this.renderMain() : this.constructor.renderEmpty())}
				</Paper>
			</UploadDropZone>
			<LibraryFileMenu
				affectedFiles={this.state.selectedFiles.length}
				anchorEl={this.state.fileMenuAnchor}
				open={this.state.fileMenu}
				onClose={this.handleFileMenuClose}
				onCopyLink={this.handleFileCopyLink}
				onRename={this.handleFileRenameDialog}
				onReplace={this.handleFileReplace}
				onDelete={this.handleFileDeletionDialog}
				onMove={this.handleFileMoveDialog}
				showMove={this.props.canEdit}
				showRename={this.props.canEdit}
				showReplace={this.props.canEdit}
				showDelete={this.props.canDelete} />
			<LibraryFileDeletionDialog
				files={this.menuSelectionFiles}
				onClose={this.handleFileDeletionDialog}
				onDeleted={this.handleFileDeletionComplete}
				open={this.state.fileDeletionDialog}
				set={this.props.set.Uuid} />
			<LibraryFileMoveDialog
				files={this.menuSelectionFiles}
				onClose={this.handleFileMoveDialog}
				onMoved={this.handleFileMoveComplete}
				open={this.state.fileMoveDialog}
				set={this.props.set.Uuid}
				sets={this.props.neighbouringSets} />
			<LibraryFileRenameDialog
				file={this.state.activeFile}
				onClose={this.handleFileRenameDialog}
				onDone={this.handleFileRenameComplete}
				open={this.state.fileRenameDialog} />
			<LibrarySetDeletionDialog
				name={this.props.set.Name}
				onClose={this.handleDelete}
				onDone={this.handleDeleteComplete}
				open={this.state.deleteDialog}
				set={this.props.set.Uuid} />
			<LibrarySetRenameDialog
				name={this.props.set.Name}
				onClose={this.handleRename}
				onDone={this.handleRenameComplete}
				open={this.state.renameDialog}
				set={this.props.set.Uuid} />
		</>;
	}


	/**
	 * Render the main content.
	 * 
	 * @return {ReactNode}
	 */
	renderMain() {
		return (
			<Flex columnar={true} wrap={true}>
				{
					this.props.set.Files.map((file, key) => (
						<LibraryBrowserContentFile
							file={file}
							key={key}
							onContextMenu={this.handleFileMenu}
							onSelectionToggle={this.handleFileSelectToggle}
							selected={this.state.selectedFiles.includes(file.Uuid)}
							set={this.props.set}
							withCheckbox={(this.props.canDelete || this.props.canEdit)} />
					))
				}
			</Flex>
		);
	}


	/**
	 * Render the "copy link" button.
	 * 
	 * @return {ReactNode}
	 */
	renderCopyLink() {
		return (
			<IconButton
				dimensions="3rem"
				disabled={this.props.disabled}
				icon={CopyLinkIcon}
				onClick={this.handleCopyLink}
				title="Copy Link" />
		);
	}


	/**
	 * Render the delete button.
	 * 
	 * @return {ReactNode}
	 */
	renderDelete() {
		return (
			<IconButton
				dimensions="3rem"
				disabled={this.props.disabled}
				icon={DeleteIcon}
				onClick={this.handleDelete}
				title="Delete" />
		);
	}


	/**
	 * Render the rename button.
	 * 
	 * @return {ReactNode}
	 */
	renderRename() {
		return (
			<IconButton
				dimensions="3rem"
				disabled={this.props.disabled}
				icon={RenameIcon}
				onClick={this.handleRename}
				title="Rename" />
		);
	}


	/**
	 * Render the "select all" button.
	 * 
	 * @return {ReactNode}
	 */
	renderSelectAll() {
		return (
			<IconButton
				dimensions="3rem"
				disabled={this.props.disabled}
				icon={SelectAllIcon}
				onClick={this.handleSelectAll}
				title="Toggle Selection" />
		);
	}


	/**
	 * Render the upload button.
	 * 
	 * @return {ReactNode}
	 */
	renderUpload() {
		return (
			<IconButton
				dimensions="3rem"
				disabled={this.props.disabled}
				icon={UploadIcon}
				onClick={this.handleUploadClick}
				title="Add" />
		);
	}


	/**
	 * Get whether the set is empty.
	 * 
	 * @return {Boolean}
	 */
	get isEmpty() {
		return !this.props.set?.Files?.length;
	}


	/**
	 * Get the file UUIDs to apply a selected menu action to.
	 *
	 * We use the selection (checkboxes) when there's more than one, 
	 * otherwise we use the file the user directly opened the menu on.
	 * 
	 * @return {Array}
	 */
	get menuSelectionFiles() {
		if (this.state.selectedFiles.length > 1) {
			return this.state.selectedFiles;
		}
		else return [this.state.activeFile?.Uuid];
	}


	/**
	 * Render the empty state.
	 * 
	 * @return {ReactNode}
	 */
	static renderEmpty() {
		return (
			<String
				color="textSecondary"
				str="There are no files in this set." />
		);
	}

}

export default withMobile(withTimes(withRouter(LibraryBrowserContentSet)));
