import React from "react";
import rem from "Helpers/Rem.js";
import {Box} from "@material-ui/core";

/**
 * Container
 *
 * A base grid container.
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Container extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Box
				{...this.forwards}
				ref={this.props.innerRef}
				style={this.styles}>
				{this.props.children}
			</Box>
		);
	}


	/**
	 * Get the `grid-template-columns` value to use.
	 * 
	 * @return {String} CSS
	 */
	get columns() {
		if (this.props.columns) return this.props.columns;
		else return (!this.props.columnar ? "100%" : null);
	}


	/**
	 * Get the `gap` value to use.
	 * 
	 * @return {Integer}
	 */
	get gap() {
		if (this.props.hasOwnProperty("gap")) {
			return this.props.gap;
		}
		else return 1;
	}


	/**
	 * Get props to forward.
	 *
	 * @return {Object}
	 */
	get forwards() {
		const props = {};
		this.constructor.propForwards.forEach(prop => {
			if (this.props.hasOwnProperty(prop)) {
				props[prop] = this.props[prop];
			}
		});
		return props;
	}


	/**
	 * Styles.
	 * 
	 * @return {Object}
	 */
	get styles() {
		return {
			alignContent: this.props.alignContent,
			alignItems: (this.props.alignItems || (this.props.fullCentre ? "center" : null)),
			alignSelf: this.props.alignSelf,
			display: (this.props.hidden ? "none" : (this.props.display || "grid")),
			flexBasis: this.props.flexBasis,
			flexGrow: this.props.flexGrow,
			flexDirection: this.props.flexDirection,
			flexShrink: this.props.flexShrink,
			flexWrap: (this.props.flexWrap ? "wrap" : undefined),
			columnGap: (this.props.columnGap ? rem(this.props.columnGap) : rem(this.gap)),
			gridAutoColumns: this.props.gridAutoColumns,
			gridAutoFlow: (!this.props.columnar ? "row" : "column"),
			gridAutoRows: (this.props.gridAutoRows || "max-content"),
			gridTemplateColumns: this.columns,
			gridTemplateRows: (this.props.fullHeightRow ? "100%" : this.props.rows),
			height: this.props.height,
			justifyContent: ((this.props.centre || this.props.fullCentre) ? "center" : this.props.justifyContent),
			justifyItems: (this.props.fullCentre ? "center" : this.props.justifyItems),
			justifySelf: this.props.justifySelf,
			maxWidth: this.props.maxWidth,
			minHeight: (this.props.viewport ? "100vh" : this.props.minHeight),
			order: this.props.order,
			overflowX: this.props.overflowX,
			overflowY: this.props.overflowY,
			position: this.props.position,
			rowGap: (this.props.rowGap ? rem(this.props.rowGap) : rem(this.gap)),
			textAlign: (this.props.centreText ? "center" : null),
			width: (this.props.fullWidth ? "100%" : this.props.width),
			...this.props.style
		};
	}


	/**
	 * Props to forward to Material UI box.
	 * 
	 * @type {Array}
	 */
	static propForwards = [
		"className",
		"dangerouslySetInnerHTML",
		"mb",
		"ml",
		"mr",
		"mt",
		"mx",
		"my",
		"onClick",
		"onContextMenu",
		"onMouseDown",
		"onDragLeave",
		"onDragOver",
		"onDrop",
		"onKeyDown",
		"onKeyPress",
		"onKeyUp",
		"pb",
		"pl",
		"pr",
		"pt",
		"px",
		"py",
		"onBlur",
		"onClick",
		"onFocus",
		"onScroll",
		"tabIndex",
		"title"
	];

}

export default Container;
