import React from "react";
import Dialog from "Components/Dialog.js";
import Flex from "Components/Flex.js";
import LinearProgress from "@material-ui/core/LinearProgress";
import pluralize from "pluralize";

/**
 * Modal progress dialog
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class ProgressDialog extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				disabled={true}
				fullWidth={true}
				hideDefaultActions={true}
				open={this.props.open}
				title={`${this.props.title} ${this.props.fileCount} ${pluralize("file", this.props.fileCount)} (${this.props.progress}%)`}>
				<Flex mb={1.5}>
					<LinearProgress
						value={this.props.progress}
						variant="determinate" />
				</Flex>
			</Dialog>
		);
	}

}

export default ProgressDialog;
