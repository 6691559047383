import React from "react";
import Button from "Components/Button.js";
import Flex from "Components/Flex.js";
import LibrarySortPicker from "./LibrarySortPicker.js";
import String from "Components/String.js";
import UploadIcon from "@material-ui/icons/Publish";

/**
 * Library browser toolbar
 * 
 * @package HOPS
 * @subpackage Library
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LibraryBrowserToolbar extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Flex
				alignItems="center"
				columnar={true}
				columnGap={4}
				justifyContent="space-between"
				wrap={true}>
				<String bold={true} str="Files" variant="h6" />
				<Flex columnar={true} wrap={true}>
					<LibrarySortPicker
						disabled={this.props.disabled}
						onChange={this.props.onSortChange}
						value={this.props.sort} />
					{(this.props.canUpload && this.renderUpload())}
				</Flex>
			</Flex>
		);
	}


	/**
	 * Render the upload button.
	 * 
	 * @return {ReactNode}
	 */
	renderUpload() {
		return (
			<Button
				disabled={this.props.disabled}
				label="Upload New Set of Files"
				onClick={this.props.onUpload}
				size="small"
				startIcon={<UploadIcon />}
				variant="outlined" />
		);
	}

}

export default LibraryBrowserToolbar;
