import React from "react";
import Container from "Components/Container.js";
import OrgSelectionSelect from "OrgSelection/OrgSelectionSelect.js";
import String from "Components/String.js";
import withAuth from "Hoc/withAuth.js";

/**
 * Drawer organisation display
 *
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiDrawerOrg extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container mx={1} pt={0.5} style={this.constructor.styles}>
				{((this.props.auth.orgs.length > 1) ? <OrgSelectionSelect size="small" /> : <String bold={true} centre={true} oneline={true} str={this.props.auth.orgs[0].NameShort} />)}
			</Container>
		);
	}

}
export default withAuth(UiDrawerOrg);
