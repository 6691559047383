import React from "react";
import Container from "Components/Container.js";
import OrgService from "Services/OrgService.js";
import String from "Components/String.js";
import {Card, CardActionArea, CardContent} from "@material-ui/core";

/**
 * Organisation for selection
 *
 * @package HOPS
 * @subpackage OrgSelection
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class OrgSelectionOrg extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleClick = this.handleClick.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);

	}


	/**
	 * Clicked.
	 *
	 * @return {void}
	 */
	handleClick() {
		this.props.onClick(this.props.org);
	}


	/**
	 * Key pressed.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleKeyPress(e) {
		if (e.which === 13) {
			this.handleClick();
		}
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Card
				onClick={this.handleClick}
				onKeyDown={this.handleKeyPress}
				style={this.constructor.stylesCard}
				variant="outlined">
				<CardActionArea style={this.constructor.stylesActionArea}>
					<CardContent style={this.constructor.stylesCardContent}>
						<Container
							fullCentre={true}
							style={this.constructor.styles}>
							<img
								alt={this.props.org.Name}
								src={OrgService.resolveLogoUri(this.props.org?.Logo)}
								style={this.stylesImage} />
							<String
								bold={true}
								centre={true}
								p={true}
								str={this.props.org.Name}
								style={{alignSelf: "flex-end"}} />
						</Container>
					</CardContent>
				</CardActionArea>
			</Card>
		);
	}


	/**
	 * Get our image styles.
	 * 
	 * @return {Object}
	 */
	get stylesImage() {
		return {
			maxHeight: (this.props.maxImageHeight || "10rem"),
			objectFit: "contain"
		};
	}


	/**
	 * Styles.
	 * 
	 * @type {Object}
	 */
	static styles = {
		height: "100%",
		gridTemplateRows: "1fr 3rem"
	};

	/**
	 * Styles (action area).
	 * 
	 * @type {Object}
	 */
	static stylesActionArea = {
		height: "100%"
	};

	/**
	 * Styles (card).
	 *
	 * @type {Object}
	 */
	static stylesCard = {
		height: "100%",
		width: "100%"
	};

	/**
	 * Styles (card content).
	 *
	 * @type {Object}
	 */
	static stylesCardContent = {
		height: "100%",
		paddingBottom: "1rem"
	};

}

export default OrgSelectionOrg;
