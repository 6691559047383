import React from "react";
import Checkbox from "Components/Checkbox.js";
import LoginFormBase from "./LoginFormBase.js";
import LoginFormPasswordInput from "./LoginFormPasswordInput.js";
import LoginFormUsernameInput from "./LoginFormUsernameInput.js";
import withFlags from "Hoc/withFlags.js";

/**
 * Login form - regular login
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LoginFormLogin extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<LoginFormBase
				disabled={this.props.disabled}
				noFullWidthSubmit={this.props.noFullWidthSubmit}
				noMarginBottomOnControls={this.props.noMarginBottomOnControls}
				noTitle={this.props.noTitle}
				linkLabel="Password Reset"
				onLinkClick={this.props.onWantsPasswordReset}
				sticky={this.props.sticky}
				submitLabel="Login"
				title="Login to HOPS">
				<LoginFormUsernameInput
					disabled={this.props.disabled}
					inputRef={this.props.usernameInputRef}
					loginStatus={this.props.error}
					onChange={this.props.onChange}
					value={this.props.username} />
				<LoginFormPasswordInput
					disabled={this.props.disabled}
					inputRef={this.props.passwordInputRef}
					onChange={this.props.onChange}
					value={this.props.password} />
				{(this.constructor.shouldAllowProlonged && this.renderProlonged())}
			</LoginFormBase>
		);
	}


	/**
	 * Render the "prolonged" session checkbox.
	 * 
	 * @return {ReactNode}
	 */
	renderProlonged() {
		return (
			<Checkbox
				checked={this.props.prolonged}
				disabled={this.props.disabled}
				label="Keep me signed in"
				name="prolonged"
				onChange={this.props.onChange}
				value={!this.props.prolonged} />
		);
	}


	/**
	 * Get whether we should allow the user to select the "prolonged" session.
	 *
	 * This is intended for use only on mobile devices but we accept this 
	 * check is loose, unreliable, and easily circumvented (#161).
	 * 
	 * @return {Boolean}
	 */
	static get shouldAllowProlonged() {
		return !!navigator.userAgent.match(/(Android.*?Mobile)|(iPhone)/);
	}

}

export default withFlags(LoginFormLogin);
