import React from "react";
import LibraryFileCard from "Library/LibraryFileCard.js";
import Link from "Components/Link.js";
import dompurify from "dompurify";
import {convertNodeToReactElement} from "@hedgedoc/html-to-react";

/**
 * HTML utility methods
 *
 * @package HOPS
 * @subpackage Helpers
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Html {

	/**
	 * Discover and convert plain text URLs to HTML links.
	 *
	 * We find links in attributes and temporarily remove them.
	 *
	 * Then we only detect and replace links actually in text.
	 *
	 * Ideally we'd use a negative lookbehind to find the 
	 * attributes, but this isn't supported in Safari.
	 *
	 * @param {String} text
	 * @return {String} With HTML `a` tags
	 */
	static convertPlainTextLinks(text) {
		const matches = (text.match(/((href|src)=['"])(https?:\/\/)?(www(.*?)[^\s'"]+)/gi) || []);
		matches.forEach((match, i) => (text = text.replaceAll(match, `__temp${i}`)));
		text = text.replaceAll(/(https?:\/\/)?(www(.*?)[^\s<]+)/gi, match => `<a href="${match}">${match}</a>`);
		matches.forEach((match, i) => (text = text.replaceAll(`__temp${i}`, match)));
		return text;
	}


	/**
	 * Purify HTML using DOMPurify with our custom configuration.
	 *
	 * @param {String} html
	 * @return {String}
	 */
	static purifyHtml(html) {

		return dompurify.sanitize(html, {

			/**
			 * We must explicitly allow use of our block-internal 
			 * data attributes as these will be unknown and unsafe
			 */
			ADD_ATTR: [
				"data-hops-library-file-uuid",
				"data-hops-library-file-name",
				"data-hops-library-file-mime"
			]

		});

	}


	/**
	 * Transform HTML to nodes for `ReactHtmlParser`.
	 *
	 * Implements `ReactHtmlParser` `transform` signature.
	 *
	 * `getNodeProps()` is called for each node, passing 
	 * its tag name, and may return an object of `props` 
	 * to apply to the transformed element for that node.
	 *
	 * @param {Object} node See `htmlparser2`
	 * @param {Integer} index Index of node being transformed
	 * @param {Function} getNodeProps optional See above.
	 * @return {ReactNode|undefined}
	 */
	static reactHtmlParserTransform(node, index, getNodeProps=null) {
		if (node.name === "a") {

			const libraryFileUuid = node?.attribs?.["data-hops-library-file-uuid"];

			if (libraryFileUuid) {

				const libraryFileName = node?.attribs?.["data-hops-library-file-name"];
				const libraryFileMime = node?.attribs?.["data-hops-library-file-mime"];

				const libraryFileDefinition = {
					Uuid: libraryFileUuid,
					Name: libraryFileName,
					Mime: libraryFileMime
				};

				return (
					<LibraryFileCard
						file={libraryFileDefinition}
						key={index} />
				);

			}

			else {

				let href = (node?.attribs?.href || "");
				if (href.split(":").length < 2) {
					href = `https://${href}`;
				}

				return (
					<Link
						blank={true}
						key={index}
						uri={href}
						{...getNodeProps?.(node.name)}>
						{node?.children.map(c => convertNodeToReactElement(c, null, (n, i) => this.reactHtmlParserTransform(n, i, getNodeProps)))}
					</Link>
				);

			}

		}
		else return undefined;
	}

}

export default Html;
