import React from "react";
import Dialog from "Components/Dialog.js";
import F12View from "./F12ViewContent.js";
import Strings from "./F12.strings.json";

/**
 * F12
 *
 * @package HOPS
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class F12 extends React.PureComponent {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Open?
			 * 
			 * @type {Boolean}
			 */
			open: false

		};

		/**
		 * Method binds
		 */
		this.handleClose = this.handleClose.bind(this);
		this.handleKey = this.handleKey.bind(this);

	}


	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		window.addEventListener("keydown", this.handleKey);
	}


	/**
	 * Component unmounting.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {
		window.removeEventListener("keydown", this.handleKey);
	}


	/**
	 * Close.
	 * 
	 * @return {void}
	 */
	handleClose() {
		this.setState({open: false});
	}


	/**
	 * Key pressed.
	 *
	 * @param {KeyboardEvent} e
	 * @return {void}
	 */
	handleKey(e) {
		if (e.ctrlKey && (e.key === "F12")) {
			this.setState({open: true});
		}
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				fullWidth={true}
				okLabel="Done"
				onClose={this.handleClose}
				open={this.state.open}
				size="md"
				title={Strings.title}>
				<F12View appOnly={true} />
			</Dialog>
		);
	}

}

export default F12;
