import React from "react";
import Button from "Components/Button.js";
import Container from "Components/Container.js";
import String from "Components/String.js";
import withMobile from "Hoc/withMobile.js";
import * as mui from "@material-ui/core";

/**
 * Dialog component
 *
 * A basic "OK" dialog.
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Dialog extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<mui.Dialog
				disableBackdropClick={this.disabled}
				disableEscapeKeyDown={this.disabled}
				disableScrollLock={true}
				fullWidth={true}
				maxWidth={this.props.size}
				onClose={this.props.onClose}
				open={this.props.open}
				PaperProps={this.PaperProps}>
				{(this.props.title && this.renderTitle())}
				<mui.DialogContent style={this.props.contentStyles}>
					{this.renderContent()}
				</mui.DialogContent>
				{(!this.props.hideDefaultActions && this.renderActions())}
			</mui.Dialog>
		);
	}


	/**
	 * Render the dialog actions.
	 * 
	 * @return {ReactNode}
	 */
	renderActions() {
		return (
			<Container
				alignItems="center"
				columns={((this.props.actionsContent && !this.props.isMobile) ? "1fr max-content" : null)}
				justifyItems={(this.props.actionsContent ? (!this.props.isMobile ? "space-between" : "center") : "flex-end")}
				mt={(this.props.children && 0.5)}
				pl={2}
				pr={1}
				pb={1}
				pt={0.5}>
				{this.props.actionsContent}
				<Container columnar={true} gridAutoColumns="max-content" className={this.props.actionsContentStyle}>
					{this.renderActionsContent()}
				</Container>
			</Container>
		);
	}


	/**
	 * Render the dialog actions content.
	 *
	 * @return {ReactNode}
	 */
	renderActionsContent() {
		if (!this.props.onOk) {
			return this.renderActionsOk();
		}
		else return this.renderActionsOkCancel();
	}


	/**
	 * Render the dialog actions with a single OK button.
	 *
	 * @return {ReactNode}
	 */
	renderActionsOk() {
		return (
			<Button
				disabled={(this.props.okDisabled || this.disabled)}
				label={(this.props.okLabel || "OK")}
				loading={this.props.okLoading}
				onClick={this.props.onClose} />
		);
	}


	/**
	 * Render the dialog actions with OK and cancel buttons.
	 * 
	 * @return {ReactNode}
	 */
	renderActionsOkCancel() {
		return (
			<React.Fragment>
				<Button
					disabled={this.disabled}
					label="Cancel"
					onClick={this.props.onClose} />
				<Button
					disabled={(this.props.okDisabled || this.disabled)}
					label={(this.props.okLabel || "OK")}
					loading={this.props.okLoading}
					onClick={this.props.onOk}
					variant="contained" />
			</React.Fragment>
		);
	}


	/**
	 * Render content.
	 *
	 * @return {ReactNode}
	 */
	renderContent() {
		if (this.props.children) return this.props.children;
		else return <String ContainerProps={this.constructor.stringContainerProps} color="textSecondary" str={this.props.content} />;
	}


	/**
	 * Render the title.
	 * 
	 * @return {ReactNode}
	 */
	renderTitle() {
		return (
			<mui.DialogTitle disableTypography={true} style={this.titleStyles}>
				<String str={this.props.title} variant="h6" />
				{(this.props.titleActions && this.renderTitleActions())}
			</mui.DialogTitle>
		);
	}


	/**
	 * Render the title actions.
	 * 
	 * @return {ReactNode}
	 */
	renderTitleActions() {
		return (
			<Container columnar={true} gap={0.5}>
				{this.props.titleActions}
			</Container>
		);
	}


	/**
	 * Get whether controls are disabled.
	 * 
	 * @return {Boolean}
	 */
	get disabled() {
		return (this.props.disabled || this.props.loading);
	}


	/**
	 * `PaperProps`
	 * 
	 * @return {Object}
	 */
	get PaperProps() {
		return {
			elevation: 0,
			style: {
				height: (this.props.fullHeight ? "100%" : undefined)
			}
		};
	}


	/**
	 * Title styles.
	 * 
	 * @return {Object}
	 */
	get titleStyles() {
		return {
			alignItems: "center",
			display: "grid",
			gridTemplateColumns: (this.props.titleActions ? "1fr max-content" : "1fr"),
			justifyContent: "space-between"
		};
	}


	/**
	 * Dialogs action styles.
	 * 
	 * @type {Object}
	 */
	static dialogActionsStyles = {marginTop: "0.5rem"};

	/**
	 * String `ContainerProps`.
	 * 
	 * @type {Object}
	 */
	static stringContainerProps = {mb: 0.75};

}

export default withMobile(Dialog);
