import React from "react";
import useAuth from "Hooks/useAuth.js";
import {memo} from "react";

/**
 * `withAuth` HOC wrapper to wrap a component with `ComponentWithAuth`
 *
 * @param {ReactComponent} Component Component to wrap
 * @return {ReactComponent} Wrapped component with authentication details
 */
const withAuth = Component => {

	return memo(props => {

		const auth = useAuth();

		return (
			<Component
				{...props}
				{...auth} />
		);

	});

};

export default withAuth;
