import React from "react";
import ReactHelper from "Helpers/React.js";
import EventSwallower from "Includes/EventSwallower.js";
import Navigator from "App/Navigator.js";
import rem from "Helpers/Rem.js";
import Classes from "Resources/Classes.json";
import * as mui from "@material-ui/core";
import CollapseIcon from "@material-ui/icons/ExpandLess";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import dUiDrawerClose from "Dispatchers/dUiDrawerClose.js";
import withMobile from "Hoc/withMobile.js";
import {withRouter} from "react-router-dom";

/**
 * UI drawer item
 *
 * A menu item to display within `UiDrawer`.
 *
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiDrawerItem extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Open?
			 *
			 * @type {Boolean}
			 */
			open: this.active

		};

		/**
		 * Cached components
		 */
		this.collapseIcon = <CollapseIcon />;
		this.expandIcon = <ExpandIcon />;

		/**
		 * Method binds
		 */
		this.handleClick = this.handleClick.bind(this);

	}


	/**
	 * Component updated.
	 * 
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.allowActive !== this.props.allowActive) {
			if (!this.props.allowActive) this.setState({open: false});
		}
		if (prevProps.location.pathname !== this.props.location.pathname) {
			this.setState({open: this.active});
		}
		if (prevProps.item !== this.props.item) {
			this.setState({open: this.active});
		}
	}


	/**
	 * Clicked.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleClick(e) {

		/**
		 * Close drawer
		 */
		if (!this.hasChildren) dUiDrawerClose();

		/**
		 * Clicked
		 */
		if (this.props.onClick) {
			this.props.onClick((!this.state.open ? this.props.item : null));
		}

		/**
		 * State change
		 */
		if (this.hasChildren) {
			this.setState({open: !this.state.open});
		}
		else if (this.props.item.onClick) {
			this.props.item.onClick();
		}
		else if (e?.ctrlKey || e?.metaKey) {
			window.open(this.props.item.Uri, "_blank");
		}
		else {
			Navigator.navigate(this.props.item.Uri);
		}

	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<React.Fragment>
				<mui.ListItem
					button={(!this.hasChildren || !this.forceAlwaysOpen)}
					dense={!this.props.isMobile}
					onClick={this.handleClick}
					selected={this.activeSpecific}
					style={{paddingLeft: this.padding}}
					title={this.props.item.Label}>
					{(this.props.item.Icon ? this.renderIcon() : null)}
					<mui.ListItemText className={Classes.oneline}>
						{(this.props.item.Uri ? this.renderLink() : this.props.item.Label)}
					</mui.ListItemText>
					{((this.hasChildren && !this.forceAlwaysOpen) ? this.renderSubIcon() : null)}
				</mui.ListItem>
				{(this.hasChildren ? this.renderSub() : null)}
			</React.Fragment>
		);
	}


	/**
	 * Render icon.
	 * 
	 * @return {ReactNode}
	 */
	renderIcon() {
		return (
			<mui.ListItemIcon>
				{ReactHelper.render(this.props.item.Icon)}
			</mui.ListItemIcon>
		);
	}


	/**
	 * Render as a link.
	 * 
	 * @return {ReactNode}
	 */
	renderLink() {
		return (
			<a
				href={this.props.item.Uri}
				onClick={EventSwallower.swallow}
				style={this.constructor.linkStyles}>
				{this.props.item.Label}
			</a>
		);
	}


	/**
	 * Render the children.
	 *
	 * @return {ReactNode}
	 */
	renderSub() {
		return (
			<mui.Collapse in={this.open} timeout="auto" unmountOnExit>
				<mui.List component="div" disablePadding>
					{
						this.props.item.Children?.map((item, key) => {
							return (
								<UiDrawerItemWrapped
									indentLevel={(this.indentLevel + 1)}
									item={item}
									key={key} />
							);
						})
					}
				</mui.List>
			</mui.Collapse>
		);
	}


	/**
	 * Render the children expansion icon.
	 * 
	 * @return {ReactNode}
	 */
	renderSubIcon() {
		return (this.open ? this.collapseIcon : this.expandIcon);
	}


	/**
	 * Get whether we're the active section.
	 * 
	 * @return {Boolean}
	 */
	get active() {
		return this.uris?.includes(this.props.location.pathname);
	}


	/**
	 * Get whether we're the active item by URI.
	 * 
	 * @return {Boolean}
	 */
	get activeSpecific() {
		const active = (this.props.location.pathname === this.props.item.Uri);
		return (active && !this.props.item.NoActiveHighlight);
	}


	/**
	 * Get whether to force to always open.
	 * 
	 * @return {Boolean}
	 */
	get forceAlwaysOpen() {
		return this.props.forceAlwaysOpen;
	}


	/**
	 * Get whether our item has children.
	 * 
	 * @return {Boolean}
	 */
	get hasChildren() {
		return !!this.props.item.Children?.length;
	}


	/**
	 * Get our current indentation (nesting) level.
	 * 
	 * @return {Integer}
	 */
	get indentLevel() {
		return (this.props.indentLevel || 0);
	}


	/**
	 * Get whether to render in the open state.
	 * 
	 * @return {Boolean}
	 */
	get open() {
		if (this.forceAlwaysOpen) return true;
		else return (this.state.open && this.props.allowActive);
	}


	/**
	 * Get padding to apply.
	 *
	 * @return {String|null} CSS
	 */
	get padding() {
		return (!this.indentLevel ? null : rem((this.indentLevel + 1)));
	}


	/**
	 * Get our item URIs.
	 * 
	 * @return {Array}
	 */
	get uris() {
		return this.props.item.Children?.map(i => i.Uri).filter(u => u);
	}


	/**
	 * Link styles.
	 * 
	 * @type {Object}
	 */
	static linkStyles = {
		color: "inherit",
		display: "block",
		textDecoration: "none",
		width: "100%"
	};

}

const UiDrawerItemWrapped = withMobile(withRouter(UiDrawerItem));
export default UiDrawerItemWrapped;
