import React from "react";
import Dialog from "Components/Dialog.js";
import TextField from "Components/TextField.js";
import withSnack from "Hoc/withSnack.js";

/**
 * Library renaming dialog
 * 
 * @package HOPS
 * @subpackage Library
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LibraryRenameDialog extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {void}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Name value
			 * 
			 * @type {String}
			 */
			name: props.initialName,

			/**
			 * Submitting?
			 * 
			 * @type {Boolean}
			 */
			submitting: false

		};

		/**
		 * Input element
		 * 
		 * @type {ReactRef}
		 */
		this.inputRef = React.createRef();

	}


	/**
	 * Component updated.
	 *  
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {

		if (prevProps.initialName !== this.props.initialName) {
			this.setState({name: this.props.initialName});
		}

		if ((prevProps.open !== this.props.open) && this.props.open) {
			this.setState({name: this.props.initialName, submitting: false});
		}

	}


	/**
	 * Name changed.
	 *
	 * @param {String} name
	 * @return {void}
	 */
	handleNameChange = name => {
		this.setState({name});
	};


	/**
	 * Handle submission.
	 * 
	 * @return {void}
	 */
	handleSubmit = () => {

		if (!(this.inputRef?.current && this.inputRef.current.reportValidity())) {
			return;
		}

		if (this.state.name === this.props.initialName) {
			this.props.onClose();
			return;
		}

		this.setState({submitting: true});

		this.props.onSubmit(this.state.name).then(() => {
			this.props.onClose();
		}).catch(e => {
			this.props.snack(e);
			this.setState({submitting: false});
		});

	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				fullWidth={true}
				loading={this.state.submitting}
				onClose={this.props.onClose}
				onOk={this.handleSubmit}
				open={this.props.open}
				title={this.props.title}>
				<TextField
					autoFocus={true}
					canEnter={true}
					fullWidth={true}
					inputRef={this.inputRef}
					label="Type a new name..."
					onChange={this.handleNameChange}
					onEnter={this.handleSubmit}
					placeholderLabel={true}
					required={true}
					shrink={true}
					value={this.state.name}
					varchar={true}
					variant="standard" />
			</Dialog>
		);
	}

}

export default withSnack(LibraryRenameDialog);
