import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import Button from "Components/Button.js";
import Checkbox from "Components/Checkbox.js";
import DateTimePicker from "Components/DateTimePicker.js";
import Flex from "Components/Flex.js";
import NotificationService from "Notifications/NotificationService.js";
import Paper from "Components/Paper.js";
import String from "Components/String.js";
import TextField from "Components/TextField.js";
import withSnack from "Hoc/withSnack.js";
import strings from "./F12.strings.json";

/**
 * Notifications development screen
 * 
 * @package HOPS
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class F12Notifications extends AsyncStatefulComponent {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Notification
		 * 
		 * @type {Object}
		 */
		notification: {...this.constructor.initialNotificationState},

		/**
		 * Sending?
		 * 
		 * @type {Boolean}
		 */
		sending: false

	};


	/**
	 * Value changed.
	 *
	 * @param {mixed} val
	 * @param {String} prop
	 * @return {void}
	 */
	handleChange = (val, prop) => {
		this.setState({
			notification: {
				...this.state.notification,
				[prop]: val
			}
		});
	};


	/**
	 * Notification push time value changed.
	 *
	 * @param {Date} val
	 * @param {String} prop
	 * @return {void}
	 */
	handleChangeNotificationPushTime = (val, prop) => {
		this.handleChange((val?.format("YYYY-MM-DD HH:mm:ss") || null), prop);
	};


	/**
	 * Submitting.
	 * 
	 * @return {void}
	 */
	handleSubmit = e => {

		e.preventDefault();

		this.setState({sending: true});

		NotificationService.send(this.state.notification).then(() => {
			this.props.snack("Notification sent.", "success");
		}).catch(e => {
			this.props.snack(e, "error");
		}).finally(() => {
			this.setState({sending: false});
		});

	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<form onSubmit={this.handleSubmit}>
				<Paper>
					<Flex>
						<Flex mb={0.75}>
							<String
								bold={true}
								str={strings.notifications.send} />
						</Flex>
						<TextField
							autoFocus={true}
							disabled={this.state.sending}
							label="Title"
							onChange={this.handleChange}
							placeholder="Title text"
							required={true}
							shrink={true}
							varchar={true}
							value={this.state.notification.Title} />
						<TextField
							disabled={this.state.sending}
							label="Text"
							onChange={this.handleChange}
							placeholder="Body content"
							required={true}
							shrink={true}
							varchar={true}
							value={this.state.notification.Text} />
						<TextField
							disabled={this.state.sending}
							label="Link URI"
							name="Uri"
							onChange={this.handleChange}
							placeholder="/an/example/uri"
							shrink={true}
							varchar={true}
							value={this.state.notification.Uri} />
						<DateTimePicker
							clearable={true}
							disabled={this.state.sending}
							disablePast={true}
							format="DD/MM/YYYY @ HH:mm"
							label="Send Push Notification After..."
							name="SendPushNotificationTime"
							onChange={this.handleChangeNotificationPushTime}
							value={this.state.notification.SendPushNotificationTime} />
						<Flex gap={0}>
							<Checkbox
								checked={this.state.notification.Dismissable}
								disabled={this.state.sending}
								label="Dismissable?"
								name="Dismissable"
								onChange={this.handleChange}
								value={!this.state.notification.Dismissable} />
							<Checkbox
								checked={this.state.notification.ShowInHops}
								disabled={this.state.sending}
								label="Show in HOPS?"
								name="ShowInHops"
								onChange={this.handleChange}
								value={!this.state.notification.ShowInHops} />
							<Checkbox
								checked={this.state.notification.SendPushNotification}
								disabled={this.state.sending}
								label="Send Push Notification?"
								name="SendPushNotification"
								onChange={this.handleChange}
								value={!this.state.notification.SendPushNotification} />
						</Flex>
						<Flex
							alignItems="center"
							columnar={true}
							mb={0.5}
							wrap={true}>
							<Button
								disabled={this.state.sending}
								label="Send"
								type="submit"
								variant="contained" />
							<String
								color="textSecondary"
								str={strings.notifications.sendCaption} />
						</Flex>
					</Flex>
				</Paper>
			</form>
		);
	}


	/**
	 * Notification state.
	 *
	 * @type {Object}
	 */
	static initialNotificationState = {

		/**
		 * Title text
		 *
		 * @type {String}
		 */
		Title: "Notification",

		/**
		 * Body content
		 * 
		 * @type {String}
		 */
		Text: "A test notification.",

		/**
		 * URI
		 *
		 * @type {String|null}
		 */
		Uri: null,

		/**
		 * Dismissable?
		 * 
		 * @type {Boolean}
		 */
		Dismissable: true,

		/**
		 * Show in HOPSA notifications tray?
		 * 
		 * @type {Boolean}
		 */
		ShowInHops: true,

		/**
		 * Send as a Push Notification?
		 * 
		 * @type {Boolean}
		 */
		SendPushNotification: true,

		/**
		 * Send as a Push Notification after this time?
		 *
		 * @type {String|null} YYYY-MM-DD HH:MM:SS
		 */
		SendPushNotificationTime: null

	};

}

export default withSnack(F12Notifications);
