import React from "react";
import Time from "Helpers/Time.js";
import moment from "moment-timezone";
import withAuth from "./withAuth.js";

/**
 * Time calculation HOC
 * 
 * Allows times to be calculated with the active org's timezone.
 *
 * @package HOPS
 * @subpackage Hoc
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const withTime = Component => {

	return withAuth(class extends React.PureComponent {

		/**
		 * Render.
		 *
		 * @return {ReactNode}
		 */
		render() {
			return (
				<Component
					{...this.props}
					parseTime={this.parseTime}
					formatTime={this.formatTime} />
			);
		}


		/**
		 * Get a Moment adjusted to the active org's timezone.
		 * 
		 * @param {String|Integer|null|undefined} time Unix timestamp
		 * @param {Boolean} options.timezone optional Use timezones, defaulting to active org's timezone
		 * @param {String|null} options.timezoneOverride optional Force a timezone (when `timezone` is `true`)
		 * @param {Boolean} options.timeIsUserTimezone optional `time` is in the local user (browser) timezone (requires `timezone`)
		 * @return {Moment}
		 */
		parseTime = (time, {timezone=true, timezoneOverride=null, timeIsUserTimezone=false}={}) => {

			let t;
			const tz = (timezoneOverride || this.props.org?.Timezone);

			if (!time) {
				time = Date.now();
				timeIsUserTimezone = true;
			}
			else if (typeof time !== "string") time *= 1000;

			if (!timeIsUserTimezone && timezone && tz) {
				t = moment.tz(time, "Europe/London");
			}
			else t = new moment(time);

			if (tz && timezone) {
				t.clone();
				t.tz(tz);
			}

			return t;

		};


		/**
		 * Get a timestamp formatted as a string using the active org's timezone.
		 * 
		 * @param {String|Integer|null|undefined} time Unix timestamp
		 * @param {String} options.dateFormat optional
		 * @param {String} options.timeFormat optional
		 * @param {Boolean} options.friendly optional Disable "friendly" display
		 * @param {Boolean} options.timezone optional Use timezones, defaulting to active org's timezone
		 * @param {String|null} options.timezoneOverride optional Force a timezone (when `timezone` is `true`)
		 * @param {Boolean} options.timeIsTimezoned optional `time` is already in the active timezone (overrides `timezone`, disables timezone conversion and forces appending the active timezone's ID)
		 * @param {Boolean} options.dateOnlyWhenNotToday optional Don't display the time when the date is not today
		 * @param {Boolean} options.timeIsUserTimezone optional `time` is in the local user (browser) timezone (requires `timezone`)
		 * @return {String}
		 */
		formatTime = (time, {dateFormat="DD/MM/YYYY", timeFormat="HH:mm", friendly=true, dateOnlyWhenNotToday=false, timezone=true, timezoneOverride=null, timeIsTimezoned=false, timeIsUserTimezone=false}={}) => {

			const timezoneActive = (timezone && !timeIsTimezoned);

			const m = this.parseTime(
				time,
				{
					timezone: timezoneActive,
					timezoneOverride,
					timeIsUserTimezone
				}
			);

			const tz = this.props.org?.Timezone;
			const shouldApplyTimezone = (tz && (tz !== "Europe/London"));
			const tzu = (timezoneActive && shouldApplyTimezone);

			let format = m.format(
				Time.prepareFormat(
					m,
					moment(),
					{
						friendly,
						dateFormat,
						timeFormat,
						dateOnlyWhenDifferentDates: dateOnlyWhenNotToday,
						timezone: tzu
					}
				)
			);

			if (timeIsTimezoned && shouldApplyTimezone) {
				const tz = this.parseTime(null, {timezoneOverride});
				format = `${format} ${tz.format("z")}`;
			}

			return format;

		};

	});

};

export default withTime;
