import React from "react";
import AsyncStatefulComponent from "Includes/AsyncStatefulComponent.js";
import ChatPaneList from "Chat/ChatPaneList.js";
import ChatWindowHost from "./ChatWindowHost.js";
import CheckChatsTask from "Tasks/CheckChatsTask.js";
import DropdownPane from "Components/DropdownPane.js";
import Flex from "Components/Flex.js";
import IconButton from "Components/IconButton.js";
import Loader from "Components/Loader.js";
import String from "Components/String.js";
import Strings from "./Chat.strings.json";
import dChatPane from "Dispatchers/dChatPane.js";
import withAuth from "Hoc/withAuth.js";
import withChat from "Hoc/withChat.js";
import withSnack from "Hoc/withSnack.js";
import {CreateOutlined as ComposeIcon} from "@material-ui/icons";
import {connect} from "react-redux";

/**
 * Chat pane
 *
 * Root component and UI bar dropdown for HOPS-Chat.
 * 
 * Wraps the dropdown and the `ChatWindowHost` window container.
 *
 * @package HOPS
 * @subpackage Chat
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class ChatPane extends AsyncStatefulComponent {

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Active chat windows
		 * 
		 * @type {Array}
		 */
		activeChats: [],

		/**
		 * ID of most recently opened chat window
		 *
		 * This is so each window can be guaranteed a unique ID 
		 * without any reuse, required for when `ChatWindowHost` 
		 * removes panes due to the viewport size changing.
		 *
		 * @type {Integer}
		 */
		activeChatId: 0,

		/**
		 * Error?
		 * 
		 * @type {Boolean}
		 */
		error: false

	};


	/**
	 * Add a chat window.
	 * 
	 * @param {Object} chat
	 * @return {void}
	 */
	addChat = chat => {

		/**
		 * Add the chat
		 */
		this.setState({
			activeChats: [
				...this.state.activeChats,
				{
					id: this.state.activeChatId,
					chat
				}
			],
			activeChatId: (this.state.activeChatId + 1)
		});

		/**
		 * We need to mark chat read
		 */
		if (chat.User) {
			this.props.onChatMarkedRead(chat.User);
		}

		/**
		 * Close the chat pane
		 */
		dChatPane();

	};


	/**
	 * User within a chat window changed.
	 *
	 * @param {Object|null} current Current chat user
	 * @param {Object} next Next chat user
	 * @return {void}
	 */
	handleChatUserChanged = (current, next) => {

		const activeChats = [...this.state.activeChats];
		const index = activeChats.indexOf(activeChats.find(c => {
			const isNew = (!current && !c.chat.User); 	// null when new chat
			const isSameId = (c.chat.User?.Id === current?.Id);
			return (isNew || isSameId);
		}));

		activeChats[index] = {
			...activeChats[index],
			chat: {
				...activeChats[index].chat,
				User: next
			}
		};
		this.setState({activeChats});

	};


	/**
	 * Closing a chat window.
	 *
	 * @param {Object} chat
	 * @param {Function} callback optional
	 * @return {void}
	 */
	handleCloseChat = (chat, callback=undefined) => {
		this.setState({
			activeChats: this.state.activeChats.filter(c => (c.chat !== chat))
		}, callback);
	};


	/**
	 * Chat deleted.
	 *
	 * @param {Object} chat
	 * @param {Object} user
	 * @return {void}
	 */
	handleDeleteChat = (chat, user) => {

		this.handleCloseChat(chat);

		this.setState({
			activeChats: this.state.activeChats.filter(c => {
				return (c.chat.User?.Id !== user.Id);
			})
		});

	};


	/**
	 * Adding a new empty chat window.
	 *
	 * @return {void}
	 */
	handleNewChat = () => {
		const addNew = () => this.addChat({User: null});
		const existingNewChat = this.state.activeChats.find(c => !c.chat.User);
		if (existingNewChat) this.handleCloseChat(existingNewChat.chat, addNew);
		else addNew();
	};


	/**
	 * Existing user chat selected.
	 *
	 * @param {Object} User
	 * @return {void}
	 */
	handleSelectUserChat = User => {
		if (!this.state.activeChats.find(c => (c.chat.User?.Id === User.Id))) {
			this.addChat({User});
		}
		else dChatPane();
	};


	/**
	 * Component updated.
	 *
	 * We may need to load chats.
	 * 
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.ChatPane !== this.props.ChatPane) {
			if (this.props.ChatPane) {
				CheckChatsTask().catch(() => {
					if (!this.props.Chats) {
						this.setState({error: true});
					}
				});
			}
		}
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return <>
			<DropdownPane
				icons={this.renderNew()}
				label={Strings.label}
				onClose={dChatPane}
				open={this.props.ChatPane}>
				<Flex gap={0.5}>
					<Flex minHeight="4rem">
						{this.renderContent()}
					</Flex>
				</Flex>
			</DropdownPane>
			<ChatWindowHost
				chats={this.state.activeChats}
				onChatMarkedRead={this.props.onChatMarkedRead}
				onChatUserChanged={this.handleChatUserChanged}
				onCloseChat={this.handleCloseChat}
				onDeleteChat={this.handleDeleteChat}
				onNewChatMessage={this.props.onNewMessage} />
		</>;
	}


	/**
	 * Render chats.
	 * 
	 * @return {ReactNode}
	 */
	renderChats() {
		return (
			<ChatPaneList
				chats={this.props.Chats}
				correspondents={this.props.Correspondents}
				onDeleteChat={this.handleDeleteChat}
				onMarkChatRead={this.props.onChatMarkedRead}
				onSelectChat={this.handleSelectUserChat}
				userAccount={this.props.userAccount}
				visible={this.props.ChatPane} />
		);
	}


	/**
	 * Render main content.
	 * 
	 * @return {ReactNode}
	 */
	renderContent() {
		if (this.state.error && !this.props.Chats) return <String centre={true} color="textSecondary" str={Strings.error} variant="body2" />;
		else if (this.props.Chats === null) return <Loader size={25} />;
		else if (!this.props.Chats?.length) return this.constructor.renderEmpty();
		else return this.renderChats();
	}


	/**
	 * Render the new button.
	 * 
	 * @return {ReactNode}
	 */
	renderNew() {
		return (
			<IconButton
				color="primary"
				icon={ComposeIcon}
				onClick={this.handleNewChat}
				tabIndex={(!this.props.ChatPane ? "-1" : "0")}
				title="New Chat" />
		);
	}


	/**
	 * Render the empty state.
	 * 
	 * @return {ReactNode}
	 */
	static renderEmpty() {
		return (
			<String
				color="textSecondary"
				centre={true}
				str="No chats available." />
		);
	}

}

export default connect(({ChatPane}) => ({ChatPane}))(withAuth(withChat(withSnack(ChatPane))));
