import React from "react";
import dUiDrawerToggle from "Dispatchers/dUiDrawerToggle.js";
import IconButton from "Components/IconButton.js";
import {Menu as MenuIcon} from "@material-ui/icons";
import scss from "./UiBar.module.scss";

export default props => (
	<IconButton
		className={scss.iconButton}
		color="secondary"
		icon={MenuIcon}
		onClick={dUiDrawerToggle}
		style={props.style}
		title="Menu" />
);
