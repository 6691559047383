import React from "react";
import Button from "Components/Button.js";
import Container from "Components/Container.js";
import Link from "Components/Link.js";
import String from "Components/String.js";

/**
 * Login form base
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LoginFormBase extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container pr={1}>
				{(!this.props.noTitle && this.renderTitle())}
				{this.props.children}
				<Container mt={1}>
					<Link
						disabled={this.props.disabled}
						endArrow={true}
						label={this.props.linkLabel}
						onClick={this.props.onLinkClick} />
					<Container
						columns={(this.props.noFullWidthSubmit && "minmax(max-content, 15rem)")}
						mb={((this.props.str || this.props.footer) ? 0.5 : 0)}>
						<Button
							disabled={(this.props.disabled || this.props.submitDisabled)}
							label={this.props.submitLabel}
							size={(this.props.noFullWidthSubmit && "large")}
							type="submit"
							variant="contained" />
					</Container>
					{(this.props.str && <String color="textSecondary" str={this.props.str} />)}
					{this.props.footer}
				</Container>
			</Container>
		);
	}


	/**
	 * Render title.
	 * 
	 * @return {ReactNode}
	 */
	renderTitle() {
		return (
			<Container py={0.5} style={this.stylesTitle}>
				<String bold={true} str={this.props.title} />
			</Container>
		);
	}


	/**
	 * Styles (title).
	 * 
	 * @type {Object}
	 */
	get stylesTitle() {
		return {
			background: "var(--base-color)",
			position: "sticky",
			top: (this.props.sticky || 0),
			zIndex: 10
		};
	}

}

export default LoginFormBase;
