import React from "react";
import Container from "Components/Container.js";
import withMobile from "Hoc/withMobile.js";

/**
 * UI bar container
 *
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiBarContainer extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container
				columnar={true}
				{...this.props}
				style={{...this.constructor.styles, ...this.props.style}}>
				{this.props.children}
			</Container>
		);
	}


	/**
	 * Styles.
	 * 
	 * @type {Object}
	 */
	static styles = {
		alignItems: "center",
		gridAutoRows: "100%",
		height: "100%"
	};

}

export default withMobile(UiBarContainer);
