import api from "api.js";

/**
 * User function service
 *
 * @package HOPS
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UserFunctionService {

	/**
	 * Get all user functions available to the user.
	 *
	 * @return {Promise} Resolves with user's API functions response
	 */
	static getAll() {
		return api.call({url: `/api/profile/functions`}).then(({data}) => data);
	}

}

export default UserFunctionService;
