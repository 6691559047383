import React from "react";
import Container from "Components/Container.js";
import scss from "./Pre.module.scss";

/**
 * `pre` wrapper
 * 
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Pre extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container
				className={`${scss.pre} ${(this.props.error ? scss.error : "")}`}
				gap={0.5}
				px={1}
				py={1}
				style={this.props.style}>
				{this.props.children}
			</Container>
		);
	}

}

export default Pre;
