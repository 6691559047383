import Bghost from "App/Bghost.js";
import HopsNoticesService from "NoticesHops/HopsNoticesService.js";
import dNoticesHops from "Dispatchers/dNoticesHops.js";

export default () => {

	return new Promise((resolve, reject) => {
		Bghost.register(HopsNoticesService.getNotices(), null, "ui").then(notices => {
			dNoticesHops(notices);
			resolve();
		}).catch(reject);
	});

};
