import React from "react";
import ChatWindow from "./ChatWindow.js";
import Container from "Components/Container.js";
import Doc from "Helpers/Document.js";
import Styles from "Resources/Styles.json";
import scss from "./ChatWindowHost.module.scss";
import throttle from "lodash.throttle";
import withMobile from "Hoc/withMobile.js";

/**
 * Chat window host
 *
 * The container for the user's active chat windows.
 *
 * @package HOPS
 * @subpackage Chat
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class ChatWindowHost extends React.PureComponent {

	/**
	 * Container `ref`
	 *
	 * Enables us to access our real width so we can compute 
	 * when we need to remove a window as the viewport's resized.
	 * 
	 * @type {ReactRef}
	 */
	containerRef = React.createRef();


	/**
	 * Remove overflowing message windows.
	 *
	 * As the window becomes smaller, we need to close windows 
	 * to prevent overflow. We account for the drawer width 
	 * and don't ever close the last message window.
	 */
	removeOverflowingMessages = throttle(() => {

		let drawerWidth = 0;
		if (!this.props.isMobile) {
			drawerWidth = Doc.computeRem(parseInt(Styles.uiDrawerWidth));
		}

		let targetWidth = (window.innerWidth - drawerWidth);
		if (window.innerWidth <= 640) {
			targetWidth = 0;
		}

		const containerWidth = this.containerRef.current?.scrollWidth;
		const hasMultiple = (this.props.chats.length > 1);

		if (containerWidth && (containerWidth > targetWidth) && hasMultiple) {
			this.props.onCloseChat(this.props.chats[0].chat);
		}

	}, 150);


	/**
	 * Component mounted.
	 *
	 * We have to make sure we've not got more windows than we can display.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.removeOverflowingMessages();
		window.addEventListener("resize", this.removeOverflowingMessages);
	}


	/**
	 * Component unmounting.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {
		window.removeEventListener("resize", this.removeOverflowingMessages);
	}


	/**
	 * Component updated.
	 *
	 * Make sure we've not got more windows than we can display.
	 * 
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.chats !== this.props.chats) {
			if (this.props.chats?.length > 1) {
				this.removeOverflowingMessages();
			}
		}
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container
				className={scss.container}
				columnar={true}
				innerRef={this.containerRef}>
				{
					this.props.chats.map(chat => (
						<ChatWindow
							chat={chat.chat}
							key={chat.id}
							onClose={this.props.onCloseChat}
							onDelete={this.props.onDeleteChat}
							onMarkedRead={this.props.onChatMarkedRead}
							onNewMessage={this.props.onNewChatMessage}
							onSelectUser={this.props.onChatUserChanged} />
					))
				}
			</Container>
		);
	}

}

export default withMobile(ChatWindowHost);
