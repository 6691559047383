import React from "react";
import Container from "Components/Container.js";
import UiBarHomeButton from "./UiBarHomeButton.js";
import UiBarIconStripChatButton from "./UiBarIconStripChatButton.js";
import UiBarIconStripHelpButton from "./UiBarIconStripHelpButton.js";
import UiBarIconStripNotificationsButton from "./UiBarIconStripNotificationsButton.js";
import UiBarIconStripUserButton from "./UiBarIconStripUserButton.js";
import UiBarMenuButton from "./UiBarMenuButton.js";
import withAuth from "Hoc/withAuth.js";
import withMobile from "Hoc/withMobile.js";

/**
 * UI bar icon strip
 *
 * @package HOPS
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class UiBarIconStrip extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container columnar={true} pl={(((this.props.auth.orgs.length === 1) && !this.props.isMobile) ? 1 : 0)}>
				{(this.props.isMobile && <UiBarMenuButton />)}
				{(this.props.isMobile && <UiBarHomeButton useOrgLogo={true} />)}
				{((this.props.orgs?.find(o => o.ChatEnabled) && !this.props.isAdminAuthed) && <UiBarIconStripChatButton />)}
				{(!this.props.isAdminAuthed && <UiBarIconStripNotificationsButton />)}
				<UiBarIconStripHelpButton />
				<UiBarIconStripUserButton />
			</Container>
		);
	}

}

export default withAuth(withMobile(UiBarIconStrip));
