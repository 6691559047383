import React from "react";
import Flags from "Resources/Flags.json";
import {connect} from "react-redux";

/**
 * `withFlags()` HOC wrapper to make feature flags available on a component
 *
 * @param {ReactComponent} Component Component to wrap
 * @return {ReactComponent} Wrapped component
 */
const withFlags = Component => {

	/**
	 * Feature flags HOC
	 *
	 * @package HOPS
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright Heritage Operations Processing Limited
	 */
	const componentWithFlags = class ComponentWithFlags extends React.PureComponent {

		/**
		 * Render.
		 *
		 * @return {ReactNode}
		 */
		render() {
			return (
				<Component
					hasFlag={this.hasFlag}
					isFlagOn={this.isFlagOn}
					{...this.props} />
			);
		}


		/**
		 * Get whether a flag is set and active.
		 *
		 * Flags can be enabled/disabled in their definition so 
		 * we have a way of temporarily disabling flags even 
		 * for users that have enabled them.
		 * 
		 * @param {String} flag
		 * @return {Boolean}
		 */
		hasFlag = flag => {
			if (!this.isFlagOn(flag)) return false;
			const f = Flags.find(({id}) => (id === flag));
			return (f?.active === true);
		};


		/**
		 * Get whether a feature flag is set by ID.
		 * 
		 * @param {String} flag
		 * @return {Boolean}
		 */
		isFlagOn = flag => this.props.flags.includes(flag);

	};

	return connect(({flags}) => ({flags}))(componentWithFlags);

};

export default withFlags;
