import React from "react";
import Alert from "Components/Alert.js";
import Html from "Components/Html.js";

/**
 * Organisation emergency notice banner
 * 
 * @package HOPS
 * @subpackage NoticesHops
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class HopsOrgEmergencyNotice extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Alert
				collapsible={true}
				content={this.props.content}
				initialCollapsed={this.props.initialCollapsed}
				title="Important Notice"
				variant="error"
				variantv="filled">
				<Html html={this.props.content} linkColor="inherit" />
			</Alert>
		);
	}

}

export default HopsOrgEmergencyNotice;
