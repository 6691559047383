import React from "react";
import Navigator from "App/Navigator.js";
import {Menu as MuiMenu, MenuItem} from "@material-ui/core";

/**
 * Menu component
 *
 * An abstraction over the base Material UI menu.
 *
 * @package HOPS
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Menu extends React.PureComponent {

	/**
	 * Item clicked.
	 *
	 * We close the menu and invoke the item's handler.
	 * 
	 * @param {Object} item
	 * @return {void}
	 */
	handleItemClick = item => {
		this.props.onClose();
		if (item.uri) Navigator.navigate(item.uri);
		else if (item.onClick) item.onClick();
		else if (this.props.onChange) this.props.onChange(item);
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		if (this.props.items.filter(item => !item.hidden).length <= 0) return <></>;
		return (
			<MuiMenu
				anchorEl={this.props.anchorEl}
				disableScrollLock={true}
				onClose={this.props.onClose}
				open={this.props.open}
				transitionDuration={0}>
				{
					this.props.items.filter(item => !item.hidden).map((item, key) => (
						<MenuItem
							dense={this.props.dense}
							disabled={(this.props.disabled || item.disabled)}
							key={key}
							onClick={() => this.handleItemClick(item)}
							title={(item.title || item.tooltip || item.caption)}>
							{(this.props.getItemLabel ? this.props.getItemLabel(item) : item.label)}
						</MenuItem>
					))
				}
			</MuiMenu>
		);
	}

}

export default Menu;
