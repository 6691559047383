import {memo} from "react";

export default memo(props => {

	const classes = [];

	if (props.className) {
		classes.push(props.className);
	}

	if (props.color) {
		classes.push(`MuiTypography-color${props.color.charAt(0).toUpperCase()}${props.color.slice(1)}`);
	}

	classes.push(`MuiTypography-${(props.variant || "body1")}`);

	const styles = {
		display: (props.hidden ? "none" : undefined),
		fontWeight: (props.bold ? "bold" : undefined),
		textAlign: (props.centre ? "center" : undefined),
		...props.style
	};

	const content = (props.content || props.children);
	if (!content) return null;

	return (
		<p
			className={classes.join(" ")}
			style={styles}
			title={props.title}>
			{content}
		</p>
	);

});
