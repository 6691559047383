import Bghost from "App/Bghost.js";
import dUserFunctions from "Dispatchers/dUserFunctions.js";
import Store from "App/Store.js";
import UserFunctionService from "Services/UserFunctionService.js";

export default () => {
	return new Promise((resolve, reject) => {
		Bghost.register(UserFunctionService.getAll(), null, "ui").then(funcs => {
			dUserFunctions(funcs);
			resolve();
		}).catch(e => {
			if (Store.getState().UserFunctions === null) dUserFunctions([]);
			reject(e);
		});
	});
};
