import React, {useState} from "react";
import Checkbox from "Components/Checkbox.js";
import {Flex, String, TextField} from "@heron-web/material";
import LibraryBrowser from "Library/LibraryBrowser.js";

/**
 * F12 Library screen
 *
 * Enables evaluation of the Library system.
 *
 * @return {ReactNode}
 */
const F12Library = () => {

	const [canDelete, setCanDelete] = useState(true);
	const [canUpload, setCanUpload] = useState(true);
	const [disabled, setDisabled] = useState(false);

	const [entityType, setEntityType] = useState(null);
	const [entityTypeInput, setEntityTypeInput] = useState(null);
	const entityTypeValid = ((entityType > 0) || (entityType === null));

	const [entityId, setEntityId] = useState(null);
	const [entityIdInput, setEntityIdInput] = useState(null);
	const entityIdValid = (((entityId > 0) && (entityType !== null)) || ((entityId === null) && (entityType === null)));

	const updateEntityType = () => {
		const int = parseInt(entityTypeInput);
		const val = ((!isNaN(int) && (int > 0)) ? int : null);
		setEntityType(val);
		setEntityTypeInput((val ? val.toString() : null));
	};

	const updateEntityId = () => {
		const int = parseInt(entityIdInput);
		const val = ((!isNaN(int) && (int > 0)) ? int : null);
		setEntityId(val);
		setEntityIdInput((val ? val.toString() : null));
	};

	const handleLibraryEntityTypeChange = etid => {
		setEntityType(etid);
		setEntityTypeInput((etid ? etid.toString() : null));
	};

	const handleLibraryEntityIdChange = eid => {
		setEntityId(eid);
		setEntityIdInput((eid ? eid.toString() : null));
	};

	/**
	 * Render the input error warning
	 */
	const renderInputError = () => (
		<String
			color="error"
			str="Please enter a valid Entity ID and Entity Type ID." />
	);

	/**
	 * Render the library browser warning
	 */
	const renderLibraryBrowser = () => (
		<LibraryBrowser
			canDelete={canDelete}
			canUpload={canUpload}
			disabled={disabled}
			entityId={entityId}
			entityType={entityType}
			onChangeEntityId={handleLibraryEntityIdChange}
			onChangeEntityType={handleLibraryEntityTypeChange} />
	);

	/**
	 * Render
	 */
	return (
		<Flex>
			<Flex columnar={true} flexWrap={true}>
				<Flex columnar={true}>
					<TextField
						label="Entity ID"
						onBlur={updateEntityId}
						onChange={setEntityIdInput}
						value={entityIdInput} />
					<TextField
						label="Entity Type ID"
						onBlur={updateEntityType}
						onChange={setEntityTypeInput}
						value={entityTypeInput} />
				</Flex>
				<Flex columnar={true} gap={0} flexWrap={true}>
					<Checkbox
						checked={canDelete}
						label="Can delete?"
						onChange={setCanDelete}
						value={!canDelete} />
					<Checkbox
						checked={canUpload}
						label="Can upload?"
						onChange={setCanUpload}
						value={!canUpload} />
					<Checkbox
						checked={disabled}
						label="Disabled?"
						onChange={setDisabled}
						value={!disabled} />
				</Flex>
			</Flex>
			{((entityIdValid && entityTypeValid) ? renderLibraryBrowser() : renderInputError())}
		</Flex>
	);

};

export default F12Library;
