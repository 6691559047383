import React from "react";
import Dialog from "Components/Dialog.js";
import LibraryService from "./LibraryService.js";
import withSnack from "Hoc/withSnack.js";

/**
 * Library set deletion confirmation dialog
 * 
 * @package HOPS
 * @subpackage Library
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class LibrarySetDeletionDialog extends React.PureComponent {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Submitting?
		 * 
		 * @type {Boolean}
		 */
		submitting: false

	};


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if ((prevProps.open !== this.props.open) && this.props.open) {
			this.setState({submitting: false});
		}
	}


	/**
	 * Submitting.
	 * 
	 * @return {void}
	 */
	handleSubmit = () => {

		this.setState({submitting: true});

		LibraryService.deleteSet(this.props.set).then(() => {
			this.props.onClose();
			this.props.onDone();
			this.props.snack("Deleted.", "success");
		}).catch(e => {
			this.props.snack(e);
			this.setState({submitting: false});
		});

	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				content="Are you sure you want to permanently delete this file set?"
				loading={this.state.submitting}
				onClose={this.props.onClose}
				onOk={this.handleSubmit}
				open={this.props.open}
				title={`Delete ${this.props.name}`} />
		);
	}

}

export default withSnack(LibrarySetDeletionDialog);
