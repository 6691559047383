import History from "./History.js";
import TelemetryService from "Telemetry/TelemetryService.js";
import dAccess from "Dispatchers/dAccess.js";
import dHistory from "Dispatchers/dHistory.js";
import dUiDrawerClose from "Dispatchers/dUiDrawerClose.js";
import qs from "query-string";

/**
 * Navigator
 *
 * Can be used to handle navigation changes.
 * 
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Navigator {

	/**
	 * Navigate to the homescreen.
	 * 
	 * @return {void}
	 */
	static home() {
		Navigator.navigate("/");
	}


	/**
	 * Navigate to a new URI.
	 *
	 * This automatically handles reloading the current URI.
	 *
	 * @param {String} uri URI to navigate to
	 * @return {void}
	 */
	static navigate(uri) {

		if (!uri.startsWith("/")) {
			uri = `/${uri}`;
		}

		dAccess(true);
		dUiDrawerClose();

		if (uri !== this.getCurrentUri()) {
			History.push(uri);
			TelemetryService.report(`Navigated to "${uri}".`);
		}
		else dHistory();

		window.scrollTo({top: 0});

	}


	/**
	 * Get current URI.
	 *
	 * @return {String}
	 */
	static getCurrentUri() {
		return `${History.location.pathname}${History.location.search}`;
	}


	/**
	 * Reload the current URI.
	 * 
	 * @return {void}
	 */
	static reload() {
		this.navigate(this.getCurrentUri());
	}


	/**
	 * Update the query string.
	 * 
	 * @param {Object} params
	 * @param {Boolean} replace optional Replace current query string (`false`)
	 * @return {void}
	 */
	static updateQueryString(params, replace=false) {

		let query;
		if (!replace) {
			query = qs.parse(History.location.search);
			Object.assign(query, params);
		}
		else query = params;

		let str = qs.stringify(query);
		if (str) str = `?${str}`;
		Navigator.navigate(`${History.location.pathname}${str}`);

	}

}

export default Navigator;
